<template>
  <div class="components">
    <div class="topBtn">
      <el-button v-if="sso" type="primary" @click="add">新增项目</el-button>
<!--      <el-button type="primary" @click="">预览</el-button>-->
    </div>
    <div class="content">
      <el-table
          :data="tableData"
          :span-method="objectSpanMethod"
          :header-cell-style="{background:'#cccccc',color:'black',height:'58px',textAlign:'center'}"
          border
          style="width: 100%; margin-top: 20px">
        <el-table-column
            prop="dataName"
            label="项目名称"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="data.data.dataName"
            label="手续/资料种类"
            align="center">
        </el-table-column>
        <el-table-column
            label="时间"
            align="center">
          <template slot-scope="scope">{{ scope.row.dataYear === null ? '--' : scope.row.dataYear }}</template>
        </el-table-column>
        <el-table-column
            label="批复/备案文号"
            align="center">
          <template slot-scope="scope">{{ scope.row.filingCopy === null ? '--' : scope.row.filingCopy }}</template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
            width="200px">
          <template slot-scope="scope">
            <el-link class="table-operation" v-if="sso" type="primary" @click="edit(scope.row)">编辑</el-link>
            <el-link v-if="sso" style="margin-left: 15px" class="table-operation" type="primary"
                     @click="deleteById(scope.row)">
              删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <edit ref="editForm" @submitSuccess="submitSuccess"></edit>
  </div>
</template>

<script>
import CorpDataApi from "@/api/ep/corpData"
import Edit from './edit'
import UserApi from "@/api/uc/user"
export default {
  components: {Edit},
  data() {
    return {
      tableData: [],
      firstLevelIndexArr: [],
      userInfo: {},
      sso: this.$store.getters.sso
    };
  },
  mounted() {
    this.getData()
    this.getUser()
  },
  methods: {
    getUser(){
      UserApi.getMine().then((res)=>{
        this.userInfo=res.data || {}
      })
    },
    getData() {
      CorpDataApi.listExistingFiles().then(res => {
        if (!res.isSuccess) {
          return
        }
        this.tableData = res.data
        this.getSpanArr(this.tableData)
      })
    },
    add() {
      this.$refs.editForm.init()
    },
    submitSuccess() {
      this.getData()
    },
    edit(item) {
      this.$refs.editForm.init(item.dataName)
    },
    deleteById(item) {
      this.$confirm('是否确认删除?', '提示', {type: 'warning'}).then(() => {
        CorpDataApi.deleteEepaByDataName({'dataName': item.dataName}).then(res => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('删除成功')
          this.getData()
        })
      })
    },
    //合并的方法
    getSpanArr(data) {
      this.firstLevelIndexArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.firstLevelIndexArr.push(1)
          this.firstLevelIndexPos = 0
        } else {
          // 判断当前元素与上一个元素是否相同(第1和第2列)
          if (data[i].dataName === data[i - 1].dataName) {
            this.firstLevelIndexArr[this.firstLevelIndexPos] += 1
            this.firstLevelIndexArr.push(0)
          } else {
            this.firstLevelIndexArr.push(1)
            this.firstLevelIndexPos = i
          }
        }
      }
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      //项目名合并
      if (columnIndex === 0) {
        const _row = this.firstLevelIndexArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
      //操作合并
      if (columnIndex === 4) {
        const _row = this.firstLevelIndexArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },
  }
};
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}

.topBtn {
  margin-top: 30px;
  margin-bottom: 30px;
}

.topBtn button {
  width: 120px;
  margin-right: 25px;
}
</style>
