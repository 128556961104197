// 新增配置
export const AddConfig = function () {
  return {
    list: [
      {
        label: '项目名称',
        key: 'dataName',
        type: 'select',
        placeholder: '请选择项目名称'
      },
      {
        label: '立项材料',
        key: 'dataYear1',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy1',
        type: 'input',
        placeholder: '输入批复/备案文号'
      },
      {
        label: '环境影响评价',
        key: 'dataYear2',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy2',
        type: 'input',
        placeholder: '输入批复/备案文号'
      },
      {
        label: '环评批复',
        key: 'dataYear3',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy3',
        type: 'input',
        placeholder: '输入批复/备案文号'
      },
      {
        label: '环保竣工验收',
        key: 'dataYear4',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy4',
        type: 'input',
        placeholder: '输入批复/备案文号'
      },
      {
        label: '验收意见',
        key: 'dataYear5',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy5',
        type: 'input',
        placeholder: '输入批复/备案文号'
      }
      ,
      {
        label: '项目时间',
        key: 'dataYear',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        label: '批复/备案文号',
        key: 'filingCopy',
        type: 'input',
        placeholder: '输入批复/备案文号'
      }
    ],
    config: {
      onChange: (v1, v2) => {
        if (v1 === 'dataName') {
          this.changeDataName(v2)
        }
      },
      field: {
        dataYear: {show: false},
        filingCopy: {show: false},
        dataYear1: {show: false},
        filingCopy1: {show: false},
        dataYear2: {show: false},
        filingCopy2: {show: false},
        dataYear3: {show: false},
        filingCopy3: {show: false},
        dataYear4: {show: false},
        filingCopy4: {show: false},
        dataYear5: {show: false},
        filingCopy5: {show: false}
      },
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        dataName: '',
        dataYear: '',
        filingCopy: '',
        dataYear1: '',
        filingCopy1: '',
        dataYear2: '',
        filingCopy2: '',
        dataYear3: '',
        filingCopy3: '',
        dataYear4: '',
        filingCopy4: '',
        dataYear5: '',
        filingCopy5: '',
      },
      rules: {
        dataName: [{required: true, message: '请选择项目名称 ', trigger: 'blur'}],
        // dataYear: [{required: true, message: '请填写项目时间 ', trigger: 'blur'}],
        // filingCopy: [{required: true, message: '请输入批复/备案文号 ', trigger: 'blur'}]
      },
      selects: {
        dataName: []
      }
    }
  }
}

// 编辑配置
export const EditConfig = function () {
  return {
    list: [
      {
        label: '项目名称',
        key: 'dataName',
        type: 'input',
        disabled: 'disabled',
        placeholder: '请选择项目名称'
      },
      {
        label: '立项材料',
        key: 'dataYear1',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy1',
        type: 'input',
        placeholder: '输入批复/备案文号'
      },
      {
        label: '环境影响评价',
        key: 'dataYear2',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy2',
        type: 'input',
        placeholder: '输入批复/备案文号'
      },
      {
        label: '环评批复',
        key: 'dataYear3',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy3',
        type: 'input',
        placeholder: '输入批复/备案文号'
      },
      {
        label: '环保竣工验收',
        key: 'dataYear4',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy4',
        type: 'input',
        placeholder: '输入批复/备案文号'
      },
      {
        label: '验收意见',
        key: 'dataYear5',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        key: 'filingCopy5',
        type: 'input',
        placeholder: '输入批复/备案文号'
      }
      ,
      {
        label: '项目时间',
        key: 'dataYear',
        type: 'year',
        placeholder: '选择时间'
      },
      {
        label: '批复/备案文号',
        key: 'filingCopy',
        type: 'input',
        placeholder: '输入批复/备案文号'
      }
    ],
    config: {
      field: {
        dataYear: {show: false},
        filingCopy: {show: false},
        dataYear1: {show: false},
        filingCopy1: {show: false},
        dataYear2: {show: false},
        filingCopy2: {show: false},
        dataYear3: {show: false},
        filingCopy3: {show: false},
        dataYear4: {show: false},
        filingCopy4: {show: false},
        dataYear5: {show: false},
        filingCopy5: {show: false}
      },
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        id: '',
        id1: '',
        id2: '',
        id3: '',
        id4: '',
        id5: '',
        dataId1: '',
        dataId2: '',
        dataId3: '',
        dataId4: '',
        dataId5: '',
        dataName: '',
        dataYear: '',
        filingCopy: '',
        dataYear1: '',
        filingCopy1: '',
        dataYear2: '',
        filingCopy2: '',
        dataYear3: '',
        filingCopy3: '',
        dataYear4: '',
        filingCopy4: '',
        dataYear5: '',
        filingCopy5: '',
      },
      rules: {
        dataName: [{required: true, message: '请选择项目名称 ', trigger: 'blur'}],
        // dataYear: [{required: true, message: '请填写项目时间 ', trigger: 'blur'}],
        // filingCopy: [{required: true, message: '请输入批复/备案文号 ', trigger: 'blur'}]
      },
      selects: {
        dataName: []
      }
    }
  }
}
