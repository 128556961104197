<template>
	<el-dialog title="编辑" :visible.sync="dialogVisible" :close-on-click-modal="false" width="40%"
		:before-close="handleClose">
		<div class="container">
			<div class="title">企业基本信息</div>
			<div class="item">
				<div class="item-text">*企业名称</div>
				<el-input v-model="copyName" placeholder="请输入内容" disabled></el-input>
			</div>
			<div class="item">
				<div class="item-text">*厂区危废仓库位置</div>
				<div class="upload">
					<el-upload class="avatar-uploader" :action="uploadAction" :data="{bizType: 'DISCHARGE_PORT_IMAGE'}"
						:show-file-list="false" :on-success="handleAvatarSuccess" :headers="headers"
						:before-upload="beforeAvatarUpload">
						<img v-if="imageUrl" :src="imageUrl" class="avatar-img-code">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</div>
			</div>
			<div class="item">
				<div class="item-text">*危废贮存场所名称</div>
				<el-select v-model="detailObj.id" placeholder="请选择" @change="wfChange">
					<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="item">
				<div class="item-text">*贮存场所编号</div>
				<el-input v-model="detailObj.code" placeholder="请输入内容" disabled></el-input>
			</div>
			<div class="item">
				<div class="item-text">*主要贮存种类</div>
				<el-input v-model="detailObj.wareCategory" placeholder="请输入内容" disabled></el-input>
			</div>
			<!-- 循环 -->
			<div v-for="(item,index) in wfDetailList" :key="item.id">
				<div class="title">危险废物详情{{index+1}}</div>
				<div class="item">
					<div class="item-text">*危险废物名称</div>
					<el-input v-model="item.name" placeholder="请输入内容" disabled></el-input>
				</div>
				<div class="item">
					<div class="item-text">*危险废物种类</div>
					<el-input v-model="item.category" placeholder="请输入内容" disabled></el-input>
				</div>
				<div class="item">
					<div class="item-text">*危险废物代码</div>
					<el-input v-model="item.code" placeholder="请输入内容" disabled></el-input>
				</div>
				<div class="item">
					<div class="item-text">*危险废物产生量</div>
					<el-input v-model="item.produceNum" placeholder="请输入内容" disabled></el-input>
				</div>
				<div class="title">危废处置详情{{index+1}}</div>
				<div class="item">
					<div class="item-text">*处置方式</div>
					<el-input :value="item.disposeType==1 ? '自行处置' : '委托处置'" placeholder="请输入内容" disabled></el-input>
				</div>
				<template v-if="item.disposeType==2">
					<div class="item">
						<div class="item-text">*处置单位名称</div>
						<el-input v-model="item.corpWaste.name" placeholder="请输入内容" disabled></el-input>
					</div>
					<div class="item">
						<div class="item-text">*处置单位经营许可证编号</div>
						<el-input v-model="item.corpWaste.businessNumber" placeholder="请输入内容" disabled></el-input>
					</div>
					<div class="item">
						<div class="item-text">*危废处置协议</div>
						<div class="item-img line-l">
							<img :src="getImage(item.corpWaste.hmAgreementFile)" alt="">
							<div>{{item.corpWaste.hmAgreementFile.submittedFileName}}</div>
						</div>
					</div>
					<div class="item">
						<div class="item-text">*危废处置单位资质证明材料</div>
						<div class="item-img line-l">
							<img :src="getImage(item.corpWaste.hmFile)" alt="">
							<div>{{item.corpWaste.hmFile.submittedFileName}}</div>
						</div>
					</div>
				</template>
			</div>
			<div class="title">证明材料</div>
			<div class="item-text">*排污许可证</div>
			<div class="zmcl-img">
				<div class="line-l zmcl-img-item" v-for="item in wfZmList" :key="item.id">
					<img :src="getImage(item)" alt="">
					<div>{{item.submittedFileName}}</div>
				</div>
			</div>
			<div class="item">
				<div class="item-text">*贮存场所照片</div>
				<div class="upload">
					<el-upload list-type="picture-card" :action="uploadAction" :data="{bizType: 'DISCHARGE_PORT_IMAGE'}"
						:headers="headers" :on-remove="handleImageRemove" :on-success="onImageUploadSuccess"
						:file-list="images">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<div class="title">负责人</div>
			<div class="item">
				<div class="item-text">*该危废贮存场所负责人</div>
				<el-input v-model="detailObj.linkName" disabled placeholder="请输入"></el-input>
			</div>
			<div class="item">
				<div class="item-text">*责任人联系方式</div>
				<el-input v-model="detailObj.linkPhone" disabled placeholder="请输入"></el-input>
			</div>
			<div class="btn_group">
				<div class="cancel" @click="handleClose">取消</div>
				<div class="submit" @click="submit">保存</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import FileApi from '@/api/fs/file'
	import DischargePortAPI from "@/api/ep/dischargePortOne"

	export default {
		props: {
			// 显示弹窗
			dialogVisible: {
				type: Boolean,
				default: false,
			},
			id: {
				type: String,
				default: ''
			}
		},
		components: {},

		data() {
			return {
				iconWord: require("@/assets/image/icon/icon-word.png"),
				iconExcel: require("@/assets/image/icon/icon-excel.png"),
				iconPDF: require("@/assets/image/icon/icon-pdf.png"),
				iconPNG: require("@/assets/image/icon/icon_png.png"),
				iconJPG: require("@/assets/image/icon/icon_jpg.png"),
				detailObj: {},
				//图片
				images: [],
				uploadAction: FileApi.singleUpload.bind(this)(),
				imageUrl: '',
				headers: {
					Authorization: this.$store.state.user.token
				},
				// 详情
				wfDetailList: [],
				// 材料证明
				wfZmList: [],


				options: [],
				copyName: ""

			};
		},

		created() {},

		mounted() {},
		watch: {
			dialogVisible: {
				immediate: true,
				handler(val) {
					if (val) {
						this.getDischargePortInfo()
						this.getWareHouseList()
					}
				}
			}

		},
		methods: {
			wfChange(e) {
				DischargePortAPI.getWareHouseDetailById({
					warehouseId: e
				}).then(res => {
					this.detailObj = res.data
					this.wfDetailList = res.data.hazardousWasteList
					this.wfZmList = res.data.emissionPermitList
				})
			},
			getWareHouseList() {
				DischargePortAPI.getWareHouseList().then(res => {
					this.options = res.data
				})
			},
			getImage(file) {
				if (['xlsx', 'XLSX', 'xls', 'XLS'].includes(file.ext)) return this.iconExcel
				if (['doc', 'docx', 'DOC', 'DOCX'].includes(file.ext)) return this.iconWord
				if (['doc', 'docx', 'DOC', 'DOCX'].includes(file.ext)) return this.iconWord
				if (['png', 'PNG'].includes(file.ext)) return this.iconPNG
				if (['jpg', 'JPG'].includes(file.ext)) return this.iconJPG
				return this.iconPDF
			},
			// 获取数据
			getDischargePortInfo() {
				this.images = []
				DischargePortAPI.get(this.id).then(res => {
					this.copyName = res.data.corpName
					this.detailObj = res.data.warehouse
					this.imageUrl = res.data.wareHouseAddressImage
					this.wfDetailList = res.data.warehouse.hazardousWasteList
					this.wfZmList = res.data.warehouse.emissionPermitList
					res.data.wareHouseImageList.map(item => {
						this.images.push({
							url: item
						})
					})
				})
			},


			handleAvatarSuccess(res, file) {
				this.imageUrl = res.data.url

				// 成功的
				console.log(res)
			},
			beforeAvatarUpload(file) {
				let isJPG = true
				if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
					isJPG = false
				}
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},

			// 多文件上传
			handleImageRemove(file, fileList) {
				this.images = fileList
			},
			/**
			 * 多图片上传成功
			 */
			onImageUploadSuccess(e) {
				let files = this.images
				files.push({
					url: e.data.url
				})
			},


			// 保存
			submit() {
				// 主键id this.id

				// 仓库对象id
				console.log('仓库对象', this.detailObj.id)
				console.log('主键', this.id)
				console.log('厂区位置照片', this.imageUrl)
				console.log('贮存场所照片集合', this.images)

				let imgs = []
				if (this.images.length > 0) {
					this.images.map(item => {
						imgs.push(item.url)
					})
				}

				let query = {
					id: this.id,
					warehouseId:this.detailObj.id,
					wareHouseAddressImage:this.imageUrl,
					wareHouseImage:imgs.join(',')
				}
				DischargePortAPI.update(query).then(res=>{
					if (res.code === 0) {
					  this.$message({
					    message: '修改成功',
					    type: 'success'
					  });
					  this.handleClose()
					}
				})
			},
			handleClose() {
				this.$emit("handleClose");
			},
		},
	};
</script>
<style scoped>
	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		font-size: 16px;
		color: #000000;
	}

	.line-l {
		display: flex;
		align-items: center;
	}

	.line-l .line {
		flex: 1;
		padding: 15px 10px;
	}

	.line-l .el-select {
		flex: 1;
	}

	.container {
		padding: 15px;
		margin: 0 auto;
		border: 1px solid #EEEEEE;
		width: 375px;
	}

	.title {
		position: relative;
		color: #359029;
		border-left: 4px solid #359029;
		height: 20px;
		padding-left: 10px;
		margin-bottom: 16px;
	}

	.item {
		margin-bottom: 16px;
	}

	.item-text {
		color: #050505;
		font-size: 15px;
		margin-bottom: 8px;
	}

	.el-select {
		width: 100%;
	}

	.el-select-dropdown__item {
		padding: 0 20px;
	}

	.item-img {
		height: 80px;
		background: #FFFFFF;
		box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.16);
		border-radius: 8px;
		padding-left: 10px;

	}

	.item-img img {
		width: 40px;
		height: 50px;
		margin-right: 10px;
	}

	.zmcl-img {
		padding: 0 10px;
		background: #FFFFFF;
		box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.16);
		border-radius: 8px;
		margin-bottom: 16px;
	}

	.zmcl-img img {
		width: 40px;
		height: 50px;
		margin-right: 10px;

	}

	.zmcl-img-item {
		padding: 10px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.16);
	}

	.btn_group {
		display: flex;
		justify-content: space-between;
		margin: 25px 0 40px;
	}

	.btn_group div {
		width: 157px;
		height: 44px;
		border-radius: 22px;
		text-align: center;
		line-height: 44px;
		cursor: pointer;
	}

	.cancel {
		background: #fff;
		color: #359029;
		border: 1px solid #359029;
	}

	.submit {
		color: #FFCC2D;
		background: #359029;
	}

	/deep/.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	/deep/.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	/deep/.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	/deep/.avatar-img-code {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
