<template>
  <div class="index-root">
    <Head></Head>
    <div class="banner">
      <el-carousel height="calc(100vh - 144px)" :interval="2000">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <div class="banner-item">
            <img :src="item.picPath">
            <div class="banner-contain">
              <div class="title">{{item.title}}</div>
              <div class="text">{{item.describe}}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="phone-banner">
      <el-carousel height="280px" :interval="2000">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <div class="banner-item">
            <img :src="item.picPath">
            <div class="banner-contain">
              <div class="title">{{item.title}}</div>
              <div class="text">{{item.describe}}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="business">
      <div class="business-nav">
        <div class="title">主营业务</div>
        <div class="learn-more" @click="handleMore('/mainBusiness')">了解更多<i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="classify">
        <div class="item" :class="classifyIndex===-1?'':(classifyIndex===index?'active':'active-1')"
             v-for="(item,index) in classifyList" :key="index"
             @mouseenter="mouseenter(index,item.code)" @mouseleave="outStyle('-1')"
        >
          <div class="bj_1"
               :style="{backgroundImage:'url(' + item.pic+ ')',backgroundRepeat:'no-repeat',backgroundSize: 'auto 100%'}"></div>
          <div class="title" v-if="classifyIndex!=index">{{item.sub}}</div>
          <div class="mark" v-if="classifyIndex===index">
            <div class="sub">{{item.sub}}</div>
            <div class="list"></div>
            <div class="look-more" @click="handleBusiness(index,item.code)">查看更多<i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="case">
      <div class="case-wrap">
        <div class="case-nav">
          <div class="title">优秀案例</div>
          <div class="learn-more" @click="handleMore('/case')">了解更多<i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="caseList">
          <div class="caseList-item" v-for="(item,index) in caseList.slice(0,3)" :key="index"
               @click="goCaseDetail(item)">
            <div class="sub">{{item.title}}</div>
            <div class="pic"><img :src="item.coverUrl"></div>
            <div class="type" v-if="item.categoryType.code==='WRFZ'">
              <div class="info">环保类型：</div>
              <div class="text">{{item.caseType}}</div>
            </div>
            <div class="process" v-if="item.categoryType.code==='WRFZ'">
              <div class="info">工艺流程：</div>
              <div class="text">{{item.subtitle}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="information">
      <div class="info-wrap">
        <div class="info-nav">
          <div class="title">信息公开</div>
          <div class="learn-more" @click="handleMore('/information')">了解更多<i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="info-contain">
          <div class="info-l" @click="goInfoDetail(infoData.id)">
            <div class="pic">
              <img :src="infoData.picPath">
            </div>
            <div class="content">
              <div class="time">
                <div class="day">{{infoData.day}}</div>
                <div class="year">{{infoData.year}}</div>
              </div>
              <div class="text">
                <div class="sub">{{infoData.title}}</div>
                <div class="txt">{{infoData.summary}}</div>
              </div>
            </div>
          </div>
          <div class="info-r">
            <div class="content" v-for="(item,index) in infoList.slice(1 , 3)" :key="index"
                 @click="goInfoDetail(item.id)">
              <div class="time">
                <div class="day">{{item.day}}</div>
                <div class="year">{{item.year}}</div>
              </div>
              <div class="text">
                <div class="sub">{{item.title}}</div>
                <div class="txt">{{item.summary}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperation">
      <div class="coopera-wrap">
        <div class="title">合作客户</div>
        <div class="coopera-list">
          <div class="coopera-item"><img src="@/assets/image/coopera_1.png"></div>
          <div class="coopera-item"><img src="@/assets/image/coopera_2.png"></div>
          <div class="coopera-item"><img src="@/assets/image/coopera_3.png"></div>
          <div class="coopera-item"><img src="@/assets/image/coopera_4.png"></div>
          <div class="coopera-item"><img src="@/assets/image/coopera_5.png"></div>
          <div class="coopera-item"><img src="@/assets/image/coopera_6.png"></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Head from '@/components/head'
  import Footer from '@/components/footer'
  import HomeBannerApi from '@/api/ep/homeBanner'
  import HomeCategoryApi from '@/api/ep/homeCategory'
  import HomeArticleApi from '@/api/ep/homeArticle'
  import ImageApi from '@/api/ep/image'

  export default {
    name: 'index',
    data() {
      return {
        classifyIndex: -1,
        code: 'HBGJ',
        //banner图数据
        bannerList: [],
        //主营业务数据
        businessList: [],
        //优秀案例
        caseList: [],
        //信息公开
        infoData: [],
        infoList: [],
        classifyList: [
          {
            sub: '环保管家',
            code: 'HBGJ',
            pic: '',
          },
          {
            sub: '环保咨询服务',
            code: 'HBZX',
            pic: '',
          },
          {
            sub: '污染防治设施',
            code: 'WRFZ',
            pic: '',
          },
          {
            sub: '其他环保工程',
            code: 'OTHER',
            pic: '',
          },
        ],
      }
    },
    mounted() {
      this.getBannerData()
      this.getBusinessData()
      this.getCaseData()
      this.getInfoData()
      this.getImage()
    },
    methods: {
      goInfoDetail(item) {
        this.$router.push('/infoDetail?id=' + item)
      },
      goCaseDetail(item) {
        this.$router.push('/caseDetail?id=' + item.id)
      },
      handleMore(item) {
        this.$router.push(item)
      },
      handleBusiness(index, code) {
        this.$router.push(`/mainBusiness?index=${index}&code=${code}`)
      },
      mouseenter(item, code) {
        this.classifyIndex = item
        this.code = code
        this.getBusinessData()
      },
      outStyle(i) {
        this.classifyIndex = -1
      },
      //banner图数据
      getBannerData() {
        let params = {
          dictionary: 'HOMEPAFE'
        }
        HomeBannerApi.list(params).then((res) => {
          let data = res.data
          let list = data.map(item => {
            let obj = {}
            obj.picPath = item.fileId
            obj.title = item.title
            obj.describe = item.describe

            return obj
          })
          this.bannerList = list
        })
      },
      //主营业务背景图
      getImage() {
        let params = {}
        ImageApi.list(params).then((res) => {
          let data = res.data
          let list = this.classifyList
          for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < data.length; j++) {
              if (list[i].code === data[j].categoryType) {
                list[i].pic = data[j].categoryImage
              }
            }
          }
        })
      },
      //主营业务数据
      getBusinessData() {
        let params = {
          type: {
            code: this.code
          }
        }
        HomeCategoryApi.list(params).then((res) => {
          this.businessList = res.data
        })
      },
      //优秀案例数据
      getCaseData() {
        let params = {
          type: 'CLASSISE'
        }
        HomeArticleApi.list(params).then((res) => {
          this.caseList = res.data
        })
      },
      //信息公开数据
      getInfoData() {
        let params = {
          type: {
            code: 'XXGK'
          }
        }
        HomeArticleApi.list(params).then((res) => {
          let data = res.data
          let list = []
          data.forEach((item) => {
            list.push({
              id: item.id,
              picPath: item.coverUrl,
              title: item.title,
              summary: item.summary,
              day: item.createTime.slice(8, 10),
              year: item.createTime.slice(0, 7)
            })
          })
          this.infoList = list
          this.infoData = this.infoList[0]
        })
      },
    },
    components: {Head, Footer}
  }
</script>

<style scoped leng="scss">
  @media screen and (min-width: 900px) {
    .phone-banner{
      display: none;
    }
    .banner {
      width: 100%;
      height: calc(100vh - 144px);
    }

    .banner img {
      width: 100%;
      height: 100%;
    }

    .banner .banner-item {
      position: relative;
    }

    .banner .banner-contain {
      width: 1200px;
      position: absolute;
      top:40%;
      left: 0px;
      z-index: 9999;
      left: 50%;
      transform: translateX(-50%);
    }

    .banner .banner-contain .title {
      font-size: 36px;
      line-height: 47px;
      color: #FFFFFF;
    }

    .banner .banner-contain .text {
      width: 495px;
      padding-right: 25px;
      padding-top: 30px;
      margin-top: 30px;
      font-size: 16px;
      line-height: 32px;
      color: #CFD9C2;
      border-top: 1px solid #ffffff;
    }

    /*主营业务*/
    .business-nav {
      width: 1200px;
      padding: 55px 0px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .business-nav .title {
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      color: #000000;
    }

    .business-nav .learn-more {
      font-size: 14px;
      line-height: 40px;
      color: #333333;
      cursor: pointer;
    }

    .business-nav .learn-more:hover {
      color: #359029;
    }

    .business-nav .learn-more .el-icon-arrow-right {
      margin-left: 10px;
    }

    .classify {
      width: 100%;
      height: calc(100vh - 150px);
      display: flex;
    }

    .classify .item {
      transition: 1s;
      width: 25%;
      position: relative;
      cursor: pointer;
    }

    .classify .active {
      width: 40%;
      transition: 1s;
    }

    .classify .active-1 {
      transition: 1.5s;
      width: 20%;
    }

    .classify .item .bj_1 {
      height: calc(100vh - 150px);
      width: 100%;
    }

    .classify .item .bj_1 img {
      height: 100%;
      width: 100%;
    }

    .classify .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      line-height: 32px;
      color: #FFFFFF;
    }

    .mark {
      background-color: rgba(53, 144, 41, 0.5);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 12;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .mark .sub {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color: #FFFFFF;
    }

    .mark .list {
      display: flex;
      flex-wrap: wrap;
      min-height: 32px;
      margin: 25px 0px;
    }

    .mark .list .list-item {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      color: #FFFFFF;
      padding: 0px 22px;
    }

    .mark .look-more {
      width: 95px;
      height: 26px;
      border: 1px solid #FFFFFF;
      border-radius: 13px;
      font-size: 12px;
      line-height: 26px;
      color: #FFFFFF;
      text-align: center;
    }

    .mark .look-more .el-icon-arrow-right {
      margin-left: 5px;
    }

    /*优秀案例*/
    .case-wrap {
      width: 1200px;
      margin: 0 auto;
    }

    .case-nav {
      width: 1200px;
      padding: 55px 0px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .case-nav .title {
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      color: #000000;
    }

    .case-nav .learn-more {
      font-size: 14px;
      line-height: 40px;
      color: #333333;
      cursor: pointer;
    }

    .case-nav .learn-more:hover {
      color: #359029;
    }

    .case-nav .learn-more .el-icon-arrow-right {
      margin-left: 10px;
    }

    .case .caseList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    .case .caseList .caseList-item {
      width: 25%;
      margin-right: 90px;
      margin-top: 20px;
      cursor: pointer;
    }

    .case .caseList .caseList-item:nth-child(3n+3) {
      margin-right: unset;
    }

    .case .caseList .caseList-item .sub {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
    }

    .case .caseList .caseList-item .pic {
      width: 340px;
      height: 284px;
      margin: 30px 0px;
    }

    .case .caseList .caseList-item .pic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .case .caseList-item .type,
    .case .caseList-item .process {
      display: flex;
    }

    .case .caseList-item .type .info,
    .case .caseList-item .process .info {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
    }

    .case .caseList-item .type .text,
    .case .caseList-item .process .text {
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      flex: 1;
    }

    .info-wrap {
      width: 1200px;
      padding-bottom: 60px;
      margin: 0 auto;
    }

    .info-nav {
      width: 1200px;
      padding: 55px 0px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .info-nav .title {
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      color: #000000;
    }

    .info-nav .learn-more {
      font-size: 14px;
      line-height: 40px;
      color: #333333;
      cursor: pointer;
    }

    .info-nav .learn-more:hover {
      color: #359029;
    }

    .info-nav .learn-more .el-icon-arrow-right {
      margin-left: 10px;
    }

    .info-contain {
      display: flex;
    }

    .info-contain .info-l {
      width: 470px;
      cursor: pointer;
    }

    .info-contain .info-l .pic {
      width: 470px;
      height: 237px;
    }

    .info-contain .info-l .pic img {
      width: 100%;
      height: 100%;
    }

    .info-contain .content {
      margin-top: 14px;
      display: flex;
    }

    .info-contain .content .time .day {
      font-size: 48px;
      font-weight: bold;
      color: #000000;
      text-align: center;
    }

    .info-contain .content .time .year {
      font-size: 14px;
      line-height: 26px;
      color: #666666;
      text-align: center;
    }

    .info-contain .content .text .txt {
      font-size: 14px;
      line-height: 24px;
      color: #999999;
    }

    .info-contain .info-l .content .text {
      padding: 0px 0px 0px 40px;
    }

    .info-contain .info-l .content .text .txt {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;

    }

    .info-contain .info-r {
      margin-left: 90px;
      padding-top: 10px;
    }

    .info-contain .info-r .text {
      margin-left: 36px;
      padding-left: 36px;
      border-left: 1px solid #EEEEEE;
    }

    .info-contain  .text .sub {
      font-size: 16px;
      line-height: 32px;
      color: #333333;
    }

    .info-contain .info-r .content {
      margin-bottom: 72px;
      cursor: pointer;
    }

    .info-contain .info-r .content:nth-last-child(1) {
      margin-bottom: unset;
    }

    .cooperation {
      width: 100%;
      padding-top: 37px;
      padding-bottom: 50px;
      background-color: #F6F6F6;
    }

    .cooperation .coopera-wrap {
      width: 1200px;
      margin: 0 auto;
    }

    .coopera-list {
      display: flex;
      flex-wrap: wrap;
    }

    .coopera-list .coopera-item {
      width: 194px;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #CBCBCB;
      margin-right: 4px;
      margin-bottom: 4px;
      transition: 1s;
    }

    .coopera-list .coopera-item:hover {
      box-shadow: 0px 0px 10px #dddddd;
      transform: translateY(-10px);
    }

    .coopera-list .coopera-item:nth-chlid(6n+6) {
      margin-right: unset;
    }
    .coopera-wrap .title {
      font-size: 32px;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
      padding-bottom: 30px;
    }
  }
  @media screen and (max-width: 900px){
    .banner{
      display: none;
    }
    .phone-banner {
      width: 100%;
    }
    .phone-banner img {
      width: 100%;
      object-fit: cover;
    }
    .phone-banner .banner-item {
      position: relative;
    }
    .phone-banner .banner-contain {
      width: 95%;
      position: absolute;
      left: 0px;
      bottom: 0px;
      z-index: 9999;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 10px;
    }
    .phone-banner .banner-contain .title {
      font-size: 15px;
      line-height: 47px;
      color: #FFFFFF;
    }
    .phone-banner .banner-contain .text {
      padding-right: 25px;
      padding-top: 5px;
      margin-top: 5px;
      font-size: 12px;
      line-height: 32px;
      color: #CFD9C2;
    }

    /*主营业务*/
    .business-nav {
      width: 95%;
      padding: 0px 0px 20px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .business-nav .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 40px;
      color: #000000;
    }

    .business-nav .learn-more {
      font-size: 14px;
      line-height: 40px;
      color: #333333;
      cursor: pointer;
    }

    .business-nav .learn-more:hover {
      color: #359029;
    }

    .business-nav .learn-more .el-icon-arrow-right {
      margin-left: 10px;
    }

    .classify {
      width: 100%;
    }

    .classify .item {
      transition: 1s;
      width: 100%;
      position: relative;
      cursor: pointer;
    }

    .classify .active {
      width: 100%;
      transition: 1s;
    }

    .classify .active-1 {
      transition: 1.5s;
      width:100%;
    }

    .classify .item .bj_1 {
      height:150px;
      width: 100%;
      background-size: 100% 100%!important;
    }

    .classify .item .bj_1 img {
      height: 100%;
      width: 100%;
    }

    .classify .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      line-height: 32px;
      color: #FFFFFF;
    }

    .mark {
      background-color: rgba(53, 144, 41, 0.5);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 12;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .mark .sub {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
      color: #FFFFFF;
    }

    .mark .list {
      min-height: 10px;
    }

    .mark .list .list-item {
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      color: #FFFFFF;
      padding: 0px 22px;
    }

    .mark .look-more {
      width: 95px;
      height: 26px;
      border: 1px solid #FFFFFF;
      border-radius: 13px;
      font-size: 12px;
      line-height: 26px;
      color: #FFFFFF;
      text-align: center;
    }

    .mark .look-more .el-icon-arrow-right {
      margin-left: 5px;
    }

    /*优秀案例*/
    .case-wrap {
      width: 96%;
      margin: 0 auto;
    }
    .case-nav {
      width: 96%;
      padding: 20px 0px 10px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .case-nav .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      color: #000000;
    }
    .case-nav .learn-more {
      font-size: 14px;
      line-height: 40px;
      color: #333333;
      cursor: pointer;
    }
    .case-nav .learn-more:hover {
      color: #359029;
    }
    .case-nav .learn-more .el-icon-arrow-right {
      margin-left: 10px;
    }
    .caseList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .case .caseList .caseList-item {
      width: 48%;
      margin-right: 4%;
      cursor: pointer;
      margin-bottom: 15px;
    }
    .case .caseList .caseList-item:nth-child(2n+2) {
      margin-right: unset;
    }
    .case .caseList .caseList-item .sub {
      font-size: 14px;
      line-height: 32px;
      color: #333333;
      padding-bottom: 5px;
    }
    .case .caseList .caseList-item .pic {
      width: 100%;
      height: 115px;
    }
    .case .caseList .caseList-item .pic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .case .caseList-item .type,
    .case .caseList-item .process {
      display: flex;
    }
    .case .caseList-item .type .info,
    .case .caseList-item .process .info {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
    }
    .case .caseList-item .type .text,
    .case .caseList-item .process .text {
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      flex: 1;
    }

    .info-wrap {
      width: 96%;
      padding-bottom: 60px;
      margin: 0 auto;
    }
    .info-nav {
      width: 100%;
      padding: 20px 0px 10px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .info-nav .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 40px;
      color: #000000;
    }

    .info-nav .learn-more {
      font-size: 14px;
      line-height: 40px;
      color: #333333;
      cursor: pointer;
    }

    .info-nav .learn-more:hover {
      color: #359029;
    }

    .info-nav .learn-more .el-icon-arrow-right {
      margin-left: 10px;
    }


    .info-contain .info-l {
      width: 100%;
      box-shadow: 0px 0px 10px #eeeeee;
      border-radius: 10px;
      cursor: pointer;
    }

    .info-contain .info-l .pic {
      width: 100%;
    }

    .info-contain .info-l .pic img {
      width: 100%;
      height: auto;
    }

    .info-contain .content {
      margin-top: 14px;
      height: 78px;
      display: flex;
      padding: 0px 20px;
    }
    .info-contain .content .time{
      width: 25%;
    }
    .info-contain .content .time .day {
      font-size: 18px;
      font-weight: bold;
      color: #000000;
      text-align: center;
    }

    .info-contain .content .time .year {
      font-size: 14px;
      line-height: 26px;
      color: #666666;
      text-align: center;
    }

    .info-contain .content .text .txt {
      font-size: 14px;
      line-height: 24px;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .info-contain .info-l .content .text {
    }

    .info-contain .info-l .content .text .txt {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp:2;

    }

    .info-contain .info-r {
      width: 100%;
    }
    .info-contain .content{
      box-shadow: 0px 0px 10px #eeeeee;
      border-radius: 10px;
      padding: 10px 20px;
    }
    .info-contain .content .time{
      width: 25%;
    }
    .info-contain  .text {
      flex: 1;
      border-left: 1px solid #EEEEEE;
      padding-left: 20px;
    }

    .info-contain .text .sub {
      font-size: 16px;
      line-height: 25px;
      color: #333333;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .cooperation {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 30px;
      background-color: #F6F6F6;
    }

    .cooperation .coopera-wrap {
      width: 96%;
      margin: 0 auto;
    }

    .coopera-list {
      display: flex;
      flex-wrap: wrap;
    }

    .coopera-list .coopera-item {
      width: 48%;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #CBCBCB;
      margin-right: 4px;
      margin-bottom: 4px;
      transition: 1s;
    }
    .coopera-list .coopera-item img{
      max-width: 95%;
      height: auto;
    }
    .coopera-list .coopera-item:nth-chlid(6n+6) {
      margin-right: unset;
    }

    .coopera-wrap .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
      padding-bottom: 10px;
    }
  }
</style>
