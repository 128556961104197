import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'assistCapacityRecord/'
const PREFIXONE = CTX.EP + 'assistCapacity/'
export default {
    /**
     *新增
     * @param params
     * @returns {*}
     */
    save(params) {
        return http.post(PREFIX + `save`, params);
    },

    /**
     * 修改
     * @param params
     * @returns {*}
     */
    update(params) {
        return http.post(PREFIX + `updateList`, params);
    },
	/**
	 * 删除
	 * @param params
	 * @returns {*}
	 */
	batchDelete(params) {
	    return http.delete(PREFIX + `batchDelete`, params);
	},
    /**
     * 列表
     * @param params
     * @returns {*}
     */
    getList(params) {
        return http.post(PREFIX + `search`, params);
    },
	
	
	
      /**
     * 分类数据
     * @param params
     * @returns {*}
     */
       getSelect(params) {
		   console.log(params)
        return http.post(PREFIXONE + `search`,params);
    },
	/**
	 * 导出excel
	 * @param params
	 * @returns {*}
	 */
	exportExcel(params) {
	  return http.postDownload(PREFIX + `export`, params);
	},
	
	/**
	 *根据id获取原辅材料列表
	 * @param params
	 * @returns {*}
	 */
	getListById(params) {
	    return http.get(PREFIX + `getListById`, params);
	},
	
	/* 编辑 */
	getListByRecordCode(params){
		return http.get(PREFIX + `getListByRecordCode`, params);
	}
}
