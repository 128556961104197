import {CTX} from '@/api/api';
import http from '@/utils/http';

const PREFIX = CTX.COMMON + 'dictionaryItem/';

export default {
    /**
     * 分页
     * @param params
     * @returns {*}
     */
    search(params) {
        return http.post(PREFIX + `search`, params);
    },

    /**
     * 查询结果
     * @param id
     * @returns {*}
     */
    get(id) {
        return http.get(PREFIX + `${id}`);
    },

    /**
     * 查询结果
     * @param params
     * @returns {*}
     */
    findCode(params) {
        return http.get(PREFIX + `codes`, params);
    },

    /**
     * 保存
     * @param params
     * @returns {*}
     */
    save(params) {
        return http.post(PREFIX + `save`, params);
    },

    /**
     * 通过编号获取字典信息
     * @param params
     * @returns {*}
     */
    getByCode(params) {
        return http.post(PREFIX + `getByCode`, params);
    },

    /**
     * 修改
     * @param params
     * @returns {*}
     */
    update(params) {
        return http.put(PREFIX + `update`, params);
    },

    /**
     * 批量删除
     * @param params
     * @returns {*}
     */
    batchDelete(params) {
        return http.delete(PREFIX + `batchDelete`, params);
    },

    /**
     * 更新状态
     * @param params
     * @returns {*}
     */
    updateStatus(params) {
        return http.post(PREFIX + `updateStatus`, params);
    },

    /**
     * 预览excel
     * @param params
     * @returns {*}
     */
    previewExcel(params) {
        return http.post(PREFIX + `previewExcel`, params);
    },

    /**
     * 导出excel
     * @param params
     * @returns {*}
     */
    exportExcel(params) {
        return http.postDownload(PREFIX + `export`, params);
    },

    /**
     * 获取字典值
     * @param params
     * @returns {*}
     */
    getByParentCode(params) {
        return http.post(PREFIX + `getByParentCode`, params);
    }
}