<template>
    <el-container style="height: 100vh" class="body">
        <template v-if="!$route.meta.fullscreen">
            <el-header class="header" height="80px">
                <div class="logo-box" @click="handleHome">
                    <img src="../assets/image/logo-mini.png" alt="" class="header-logo">
                    <p class="company-name">一二三环保</p>
                    <div class="search-box" v-if="false">
                        <i class="el-icon-search"></i>
                        <el-input placeholder="请输入内容" v-model="input3" class="input-with-select" size="mini"></el-input>
                    </div>
                </div>
                <div class="user-box">
                    <div class="photo-box">
                        <img :src=" photo ? photo : require('@/assets/image/logo-mini.png')" alt="" class="user-photo">
                    </div>
                    <p class="welcome">您好！{{ companyName }}</p>
                    <a href="javascript:;" class="logout" @click="loginout">退出登录</a>
                </div>
            </el-header>

            <el-container style="height: 90vh">
                <el-aside width="260px">
                    <el-menu
                            text-color="#999999"
                            active-text-color="#359029"
                            :router="true"
                            :default-active="$route.path"
                            style="height: 100%;box-shadow: 0px 60px 18px rgba(103, 103, 103, 0.16);">
                        <template v-for="(menu,index) in routers" >
                            <el-submenu :index="menu.path" :key="index">
                                <template slot="title">
                                    <i :class="menu.icon"></i>
                                    <span>{{ menu.name }}</span>
                                </template>
                                <el-menu-item-group>
                                    <el-menu-item
                                            :key="index"
                                            :index="item.path"
                                            @click="openRoute(item)"
                                            v-for="(item,index) in (menu.children || [])">{{ item.name }}
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                        </template>
                    </el-menu>
                </el-aside>
                <el-main style="background-color: #F9F9FA;">
                    <router-view/>
                </el-main>
            </el-container>
        </template>
        <template v-else>
            <router-view/>
        </template>
    </el-container>
</template>

<script>
    import {mapGetters} from "vuex"
    import CorpAccountApi from "@/api/ep/corpAccount"

    const routers = [
        {
            path: '/accountManage',
            icon: 'el-icon-user',
            name: '账号资料管理',
            children: [
                {
                    path: '/userCenter/account',
                    name: '企业账号资料管理'
                },
                {
                    path: '/userCenter/outSideAccount',
                    name: '官方平台账号管理'
                }
            ]
        },
        {
            path: '/companyManage',
            icon: 'el-icon-office-building',
            name: '企业管理',
            children: [
                {
                    path: '/userCenter/companyFolder',
                    name: '企业资料管理'
                },
                {
                    path: '/userCenter/protectionArchives',
                    name: '企业电子环保档案'
                },
                {
                    path: '/userCenter/ware',
                    name: '企业危废详情',
                },
                {
                    path: '/userCenter/enterpriseEquipment',
                    name: '企业设备',
                },
                {
                    path: '/userCenter/selfCheckReport',
                    name: '自行监测要求'
                },
                {
                    path: '/userCenter/businessCard',
                    name: '电子名片管理'
                },
                {
                    path: '/userCenter/employee',
                    name: '员工管理'
                },
                {
                    path: '/userCenter/assistMaterial',
                    name: '原辅材料列表'
                },
                {
                    path: '/userCenter/mainMaterial',
                    name: '主要产品列表'
                },
            ]
        },
        {
            path: '/standingManage',
            icon: 'el-icon-s-data',
            name: '台账管理',
            children: [
                {
                    path: '/standing/environment',
                    name: '环保设备'
                },
                {
                    path: '/standing/production',
                    name: '生产设备'
                },
                {
                    path: '/standing/danger',
                    name: '危险废物转移'
                },
                {
                    path: '/standing/equipmentRecoed',
                    name: '生产设备台账'
                },
                {
                    path: '/standing/protectiveRecoed',
                    name: '防治设施台账'
                }
            ]
        },
        {
            path: '/brandManage',
            icon: 'el-icon-wallet',
            name: '标示牌管理',
            children: [
                // {
                //   path: '/userCenter/signageBrand',
                //   name: '排污口电子化标示牌'
                // },
                {
                    path: '/dischargePort',
                    name: '排污口标示牌'
                },
                {
                    path: '/userCenter/sewageOutletEquipment',
                    name: '企业设备标示牌管理',
                },
            ]
        },
    ]
    export default {
        name: "layout",
        data() {
            return {
                routers,
                input3: '',
                photo: '',
                $route: this.$route,
                companyName: ''
            }
        },
        computed: {
            ...mapGetters(['userInfo'])
        },
        created() {
            this.getData()
        },
        mounted() {
        },
        methods: {
            //获取企业数据
            getData() {
                CorpAccountApi.corpAccount().then(res => {
                    this.companyName = res.data.companyName
                    this.photo = res.data.logo
                })
            },
            //跳转首页
            handleHome() {
                this.$router.push('/')
            },
            openRoute(item) {
                this.$router.push({path: item.path})
            },
            loginout() {
                this.$confirm('确认退出吗?', '提示', {type: 'warning'}).then(() => {
                    this.$store.dispatch('user/logout')
                    this.$router.push('/')
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    $screen: 1920;
    @function px2vw($px) {
        @return $px*100/$screen +vw
    }

    .header {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 3px 18px rgba(120, 120, 120, 0.16);
    }

    .logo-box {
        display: flex;
        align-items: center;
    }

    .header-logo {
        display: block;
        width: 48px;
        height: 48px;
    }

    .company-name {
        margin-left: 22px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        cursor: pointer;
    }

    .search-box {
        margin-left: 120px;
        position: relative;
    }

    .el-icon-search {
        position: absolute;
        top: 7px;
        left: 10px;
        font-size: 15px;
        z-index: 1;
    }

    .user-box {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .photo-box {
        width: 45px;
        height: 45px;
        border: 2px solid #359029;
        box-sizing: border-box;
        border-radius: 100%;

        img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;
        }
    }

    .welcome {
        display: flex;
        align-items: center;
        padding-right: 30px;
        margin-left: 20px;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        border-right: 1px solid #DCDCDC;
    }

    .logout {
        display: flex;
        align-items: center;
        padding: 0 23px 0 33px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #666666;
    }

    /deep/ .el-submenu__title:hover, .el-menu-item:hover, .el-menu-item:focus {
        background-color: #fff;
        color: #359029 !important;
    }

    /deep/ .el-submenu__title {
        padding-left: 30px !important;
    }

    /deep/ .el-submenu__title > i:first-child {
        margin-right: 10px !important;
        font-size: 22px !important;
    }

    /deep/ .el-submenu .el-menu-item {
        padding-left: 65px !important;
    }
</style>
