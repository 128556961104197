<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`生产工艺流程图编辑`"
  />
</template>

<script>
import DischargePortAPI from "@/api/ep/dischargePort"
import ProductPocessAPI from "@/api/ep/productPocess"
import {ProductProcessConfig} from './config.js'

export default {
  data() {
    return {
      visible: false,
      formConfig: ProductProcessConfig.bind(this)(),
      id: ''
    }
  },
  methods: {
    init(id) {
      this.id = id || ''
      this.visible = true
      this.formConfig = ProductProcessConfig.bind(this)()
      if (!id) {
        return
      }
      DischargePortAPI.get(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.getProcess(data.id)
        this.processList()
      })
    },
    getProcess(id) {
      DischargePortAPI.getProcess(id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        const model = this.formConfig.config.model
        model.productProcessRemark = data.remark || ''
        model.productProcessId = data.process.id || ''
        model.image = data.process.image || ''
        return data.process.image;
      })
    },
    processList() {
      DischargePortAPI.processList().then((res) => {
        if (!res.isSuccess) {
          return
        }
        this.formConfig.config.selects['productProcessId'] = res.data.map(item => {
          let obj = {}
          obj.value = item.id
          obj.text = `${item.title}`
          return obj
        })
      })
    },
    handleChange(key, value) {
      switch (key) {
        case 'productProcessId':
          if(value==='' || !value){
            this.formConfig.config.model.image = ''
            return
          }
          ProductPocessAPI.get(value).then((res) => {
            if (!res.isSuccess) {
              return
            }
            this.formConfig.config.model.image = res.data.image
          })
          break;
      }
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      let params = {};
      params.productProcessId = result.productProcessId;
      params.productProcessRemark = result.productProcessRemark;
      params.id = this.id
      DischargePortAPI.updateProductProcess(params).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        this.visible = false
        this.$message.success('编辑成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>


<style scoped>
/deep/.el-form-item__label {
  width: 120px !important;
}

/deep/.el-form-item__content {
  margin-left: 120px !important;
}
</style>
