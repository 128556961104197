const getters = {
  sidebar: state => state.app.sidebar,
  theme: state => state.app.theme,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  avatar: state => state.user.avatar,
  permissions: state => state.permission.permissions,
  refresh_token: state => state.user.refreshToken,
  sso: state => state.user.sso,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs
}
export default getters
