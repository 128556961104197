<template>
  <div class="components">
    <div class="content">
      <div class="operation-box">
        <div>
          <el-button type="info" icon="el-icon-plus" @click="add">上传信息文档</el-button>
          <el-button type="info" icon="el-icon-export" @click="batchDownFile" :disabled="!isEditStatus">批量导出</el-button>
          <el-button type="info" icon="el-icon-share" @click="batchDelete" :disabled="!isEditStatus">批量删除</el-button>
          <el-button type="info" :icon="isEditStatus ? 'el-icon-check' : 'el-icon-edit'"
                     @click="isEditStatus = !isEditStatus">{{ isEditStatus ? '完成' : '编辑' }}
          </el-button>
        </div>
        <div class="search-box">
          <el-form :inline="true" :show-message="false">
            <el-form-item label="生产设备信息" style="margin-bottom: 0">
              <el-input v-model="params.standingName" placeholder="生产设备信息检索" clearable></el-input>
            </el-form-item>
            <el-form-item style="margin-bottom: 0">
              <el-button type="primary" @click="getData">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="list">
        <div class="item-box" v-for="(item,index) in list" :style="getColumns()">
          <div class="item">
            <div class="select-box"
                 @click.stop="chooseRow(index)"
                 v-if="isEditStatus"
                 :class="{'select-box-active': item.checked}"></div>
            <div class="file-box">
              <img :src="getImage(item.file)" alt="">
            </div>
            <div class="file-info">
              <div class="file-name">
                <p class="file-label">{{ item.standingName }}</p>
              </div>
              <div class="btn-box">
                <a href="javascript:;" v-if="item.file.source.code === 'CREATE'" @click.stop="downFile(item)">下载</a>
                <a href="javascript:;" v-if="item.file.source.code === 'CREATE'" @click.stop="viewData(item)">查看数据</a>
                <a href="javascript:;" v-if="item.file.source.code === 'UPLOAD'" @click.stop="downFile(item)">下载</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="上传信息文档"
        :visible.sync="formVisible"
        top="10vh"
        width="600">
      <el-form :model="form" label-width="150px">
        <el-row>
          <el-col>
            <el-form-item label="文件名称">
              <el-input v-model="form.standingName" placeholder="请输入文件名称或直接上传文件后，自动带入名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="文件上传">
              <el-upload
                  class="upload-box"
                  accept=".pdf,.xlsx,.xls,.doc,.docx,.zip,.rar"
                  :headers="headers"
                  :data="{bizType: 'Files'}"
                  :on-success="(e) => onSuccess(e)"
                  :before-upload="(e)=>onBeforeUpload(e)"
                  :on-progress="onProgress"
                  :show-file-list="false"
                  :action="action">
                <img v-if="file && file.url" :src="getImage(file)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 20px;"></i>
              </el-upload>
              <div class="upload-tip">请上传文件、文件类型为word、excel、pdf、zip、rar</div>
              <el-progress :percentage="uploadPercentage" v-if="uploadPercentage>0 && uploadPercentage<100"></el-progress>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>

    <el-drawer
        size="90%"
        title="台账数据"
        :visible.sync="dataDialogVisble">
      <div class="table-box">
        <el-table
            :data="tableData"
            stripe
            height="80%"
            style="width: 100%">
          <el-table-column
              v-for="column in config"
              :prop="column.prop"
              :label="column.label">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import StandingBookDataApi from "@/api/ep/standingBook"
import FileApi from "@/api/file"
import {downloadBlobFile} from "@/utils/commons"
export default {
  name: "Files",
  data() {
    return {
      tableData: [],
      config: [],
      dataDialogVisble: false,
      iconWord: require("@/assets/image/icon/icon-word.png"),
      iconExcel: require("@/assets/image/icon/icon-excel.png"),
      iconPDF: require("@/assets/image/icon/icon-pdf.png"),
      iconZIP: require("@/assets/image/icon-zip.png"),
      headers: {
        Authorization: this.$store.state.user.token
      },
      action: FileApi.singleUpload(),
      search_form: {name: ''},
      visible: false,
      form: {
        standingName: '',
        standingType: 'PRODUCTION',
        status: 'ENABLE',
        type: 'STANDING',
        fieldsType: '',
        fields: '',
        fileId: ''
      },
      file: null,
      list: [],
      folder: null,
      formVisible: false,
      isEditStatus: false,
      params: {standingType: 'PRODUCTION',status:'ENABLE',type: 'STANDING',standingName:''},
      width: 0,
      uploadPercentage: 0
    }
  },
  created() {
    this.getData()
    this.width = window.innerWidth
    window.addEventListener('resize',this.resizeWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWidth)
  },
  watch:{
    width(){
      this.resizeWidth()
    }
  },
  methods: {
    //查看列表数据
    viewData(item) {
      StandingBookDataApi.getDetail(item.id).then(res => {
        let data = res.data;
        if(!data.length) {
          this.$message.error('暂无数据展示')
          return
        }
        let headers = data[0].headersName.split(","),
            fields = data[0].fields.split(","),
            config = [],
            tableData = data.map(item => {
              let obj = {},
                  values = item.uploadFields.split(",");
              fields.forEach((cell, i) => {
                obj[cell] = values[i]
              })
              return obj
            })
        headers.forEach((item, i) => {
          let obj = {}
          obj.label = item
          obj.prop = fields[i]
          config.push(obj)
        })
        this.config = config
        this.tableData = tableData
        this.dataDialogVisble = true
      })
    },
    //下载文件
    downFile(item){
      FileApi.downloadFile({ids: [item.file.id]}).then(res => {
        downloadBlobFile(res)
      })
    },
    resizeWidth(){
      this.width = window.innerWidth
    },
    getImage(file){
      if(['xlsx','XLSX','xls','XLS'].includes(file.ext)) return this.iconExcel
      if(['doc','docx','DOC','DOCX'].includes(file.ext)) return this.iconWord
      if(['zip','rar'].includes(file.ext)) return this.iconZIP
      return this.iconPDF
    },
    //计算一行防几个
    getColumns(){
      let width = this.width
      return width < 1440 ? 'width: 33.33%;min-width: 330px;' : width < 1660 ? 'width: 25%;min-width: 330px;': 'width: 20%;min-width: 330px;'
    },
    batchDelete() {
      let ids = this.list.filter(item => {
        return item.checked
      }).map(item => {
        return item.id
      })
      if (ids.length <= 0) {
        this.$message.warning('至少选择一项')
        return false
      }

      this.$confirm('此操作将永久删除文件吗？, 是否继续?', '提示', {type: 'warning'}).then(() => {
        StandingBookDataApi.deleteStanding({ids:ids.join(",")}).then((res) => {
          if (!res.isSuccess) {
            return
          }
          this.$message.success('删除成功')
          this.getData()
        })
      })
    },
    batchDownFile(){
      let ids = this.list.filter(item => {
        return item.checked
      }).map(item => {
        return item.file.id
      })

      if (ids.length <= 0) {
        this.$message.warning('至少选择一项')
        return false
      }

      FileApi.downloadFile({ids: ids}).then(res => {
        downloadBlobFile(res)
      })
    },
    //选中
    chooseRow(index) {
      let item = this.list[index]
      item.checked = !item.checked
      this.$set(this.list, index, item)
    },
    back() {
      this.$emit('back')
    },
    getData() {
      StandingBookDataApi.list(this.params).then(res => {
        this.list = res.data || []
      })
    },
    //添加文件夹
    add() {
      this.formVisible = true
    },
    //添加文件夹
    edit(item) {
      this.file = item
      this.form['neoname'] = item.submittedFileName.split(".")[0]
      this.form['file'] = res.data.id
      this.formVisible = true
    },
    //取消
    cancel() {
      this.formVisible = false
      this.file = null
      this.form = {
        standingName: '',
        standingType: 'PRODUCTION',
        status: 'ENABLE',
        type: 'STANDING',
        fieldsType: '',
        fields: '',
        fileId: ''
      }
    },
    //提交
    submit() {
      if(true) {
        StandingBookDataApi.save(this.form).then(res => {
          if (!res.isSuccess) {
            return
          }
          const data = res.data
          this.cancel()
          this.$message.success('保存成功')
          this.getData()
          this.$emit("onSuccess",'device')
        })
        return
      }
      StandingBookDataApi.update(this.form).then(res => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.cancel()
        this.$message.success('保存成功')
        this.getData()
      })
    },
    //上传成功
    onSuccess(res) {
      this.file = res.data
      this.form['standingName'] = res.data.submittedFileName.split(".")[0]
      this.form['fileId'] = res.data.id
    },
    onBeforeUpload(e){
      let fileName = e.name
      fileName = fileName.toLowerCase()
      if(!fileName.endsWith('pdf') && !fileName.endsWith('xlsx')
        && !fileName.endsWith('xls') && !fileName.endsWith('doc') && !fileName.endsWith('docx')
        && !fileName.endsWith('zip') && !fileName.endsWith('rar')){
        this.$message.error('文件格式不正确')
        return false
      }

      return true
    },
    onProgress(e){
      this.uploadPercentage = e.percent
      return true
    },
  }
}
</script>

<style scoped lang="scss">
.components {
  height: 100%;
}

.breadcrumb-label {
  cursor: pointer;

  &:hover {
    color: #359029;
  }
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.breadcrumb {
  & > span:last-child {
    color: #359029;
  }

  .icon {
    margin: 0 10px;
  }
}

.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 1px dashed #ddd;
  img {
    display: block;
    width: 80px;
    margin: 0 auto;
    max-width: 80px;
    max-height: 80px;
  }
}

.content {
  padding: 20px 0;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}

.operation-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-box {
  display: flex;
  align-items: center;
}

.list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  .item-box {
    padding: 10px;
    box-sizing: border-box;
  }

  .item {
    position: relative;
    display: flex;
    padding: 10px;
    height: 140px;
    box-shadow: 6px 6px 28px rgba(194, 194, 194, 0.34);
    border-radius: 20px;
    box-sizing: border-box;

    .file-box {
      padding-top: 10px;
      flex: 0 0 auto;
      width: 100px;
      padding-left: 10px;

      img {
        display: block;
        width: 73px;
      }
    }

    .file-info {
      flex: 1;
      flex-wrap: wrap;
    }
  }

  .file-name {
    display: flex;
    align-items: center;
    height: 40px;
    width: 126px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;

    .file-label {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
  }

  .btn-box {
    margin-top: 27px;
    display: flex;

    & > a {
      padding: 0 20px;
      display: block;
      height: 30px;
      line-height: 34px;
      text-align: center;
      border-radius: 30px;
      border: 1px solid #359029;
      color: #359029;

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .select-box {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;
    border: 1px solid #359029;
    border-radius: 100%;
  }

  .select-box-active {
    background-color: #359029;
    color: #fff;
    border: 1px solid #359029;

    &:after {
      display: block;
      content: '\e63b';
      font-size: 14px;
      color: #fff;
      font-family: "iconfont" !important;
    }
  }
}

.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  width: 80px;
  height: 80px;
}

.upload-tip {
  font-size: 13px;
  color: #999999;
}

.introduce {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}
</style>
