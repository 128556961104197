<template>
  <div class="components">
    <div class="title">企业账号资料管理</div>
    <div class="content">
      <div class="change-photo">
        <el-upload
            :show-file-list="false"
            class="logo-uploader"
            :action="action"
            accept=".jpg,.jpeg,.png,.gif,.bmp,JPG,.JPEG,.PBG,.GIF"
            :headers="headers"
            :data="{bizType: 'REGISTER'}"
            :on-success="(e) => onSuccess(e,'logo')">
          <div class="zh-upload-box">
            <div class="photo-box">
              <img :src="form.logo" alt="" v-if="form.logo">
              <div v-else class="empty-logo">LOGO</div>
            </div>
          </div>
        </el-upload>

        <p class="change-label">修改logo</p>

      </div>
      <div class="form">
        <div class="form-title">详细信息</div>
        <el-form ref="form" :model="form" class="form-body" label-width="160px">

          <el-form-item label="公司名称">
            <el-input v-model="form.companyName" :disabled="disableMap.companyName"
                      style="padding-right: 55px"></el-input>
            <span class="edit-btn" @click="disableMap.companyName = false" v-if="false">修改</span>
          </el-form-item>

          <el-form-item label="营业执照号">
            <el-input v-model="form.licenseNumber" :disabled="disableMap.licenseNumber"
                      style="padding-right: 55px"></el-input>
            <span class="edit-btn" @click="disableMap.licenseNumber = false" v-if="false">修改</span>
          </el-form-item>

          <el-form-item label="公司性质">
            <el-input v-model="form.nature" :disabled="disableMap.nature" style="padding-right: 55px"></el-input>
            <span class="edit-btn" @click="disableMap.nature = false">修改</span>
          </el-form-item>

          <el-form-item label="经营范围">
            <el-input v-model="form.businessScope" type="textarea" :disabled="disableMap.businessScope"
                      style="padding-right: 55px"></el-input>
            <span class="edit-btn" @click="disableMap.businessScope = false">修改</span>
          </el-form-item>

          <el-form-item label="法人代表名称">
            <el-input v-model="form.legalPerson" :disabled="disableMap.legalPerson"
                      style="padding-right: 55px"></el-input>
            <span class="edit-btn" @click="disableMap.legalPerson = false" v-if="false">修改</span>
          </el-form-item>

          <el-form-item label="省市区">

            <el-select v-model="form.province" @change="getCity(form.province)" placeholder="请选择省" :disabled="disableMap.address">
              <el-option
                  v-for="item in this.provinces"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
              </el-option>
            </el-select>

            <el-select v-model="form.city" @change="getArea(form.city)" placeholder="请选择市" style="margin-left: 20px" :disabled="disableMap.address">
              <el-option
                  v-for="item in this.citys"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
              </el-option>
            </el-select>

            <el-select v-model="form.area" placeholder="请选择区" style="margin-left: 20px" :disabled="disableMap.address">
              <el-option
                  v-for="item in this.areas"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="地址">
            <el-input v-model="form.address" :disabled="disableMap.address" style="padding-right: 55px"></el-input>
            <span class="edit-btn" @click="disableMap.address = false">修改</span>
          </el-form-item>

          <el-form-item label="联系电话（登录账号）">
            <el-input v-model="form.phone" :disabled="disableMap.phone" style="padding-right: 55px"></el-input>
          </el-form-item>

          <el-form-item label="营业执照">
            <div style="display: flex;">
              <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  accept=".jpg,.jpeg,.png,.gif,.bmp,JPG,.JPEG,.PBG,.GIF"
                  :action="action"
                  :headers="headers"
                  :data="{bizType: 'REGISTER'}"
                  :on-success="(e) => onSuccess(e,type)">
                <div class="zh-upload-box">
                  <img v-if="form.license" :src="form.license" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 30px;"></i>
                </div>
              </el-upload>
              <div class="photo-tip">
                <div class="view-btn" v-if="form.license" @click.stop="viewHandle">预览营业执照</div>
                <div>点击图片重新上传</div>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button class="submit" :loading="loading" @click="save">提交</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>

    <el-image-viewer
        v-if="isShowPhoto"
        :on-close="()=> isShowPhoto = false"
        :url-list="[form.license]"/>

  </div>
</template>

<script>
import AreaApi from "@/api/common/area"
import FileApi from "@/api/file"
import CorpAccountApi from "@/api/ep/corpAccount"
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  name: "account",
  data() {
    return {
      isShowPhoto: false,
      headers: {
        Authorization: this.$store.state.user.token
      },
      loading: false,
      action: FileApi.singleUpload(),
      provinces: [],
      citys: [],
      areas: [],
      form: {
        companyName: '',
        licenseNumber: '',
        nature: '',
        businessScope: '',
        legalPerson: '',
        address: '',
        phone: '',
        logo: '',
        province:'',
        city:'',
        area:''
      },
      disableMap: {
        companyName: true,
        licenseNumber: true,
        nature: true,
        businessScope: true,
        legalPerson: true,
        address: true,
        phone: true,
        provinces: true,
        citys: true,
        areas: true,
      }
    }
  },
  components: {ElImageViewer},
  created() {
    this.getData()
  },
  methods: {
    //预览图片
    viewHandle() {
      this.isShowPhoto = true
    },
    //获取企业数据
    getData() {
      CorpAccountApi.corpAccount().then(res => {
        this.getProvince();
        this.getCity(res.data.province)
        this.getArea(res.data.city)
        this.form = res.data
      })
    },
    //查询省
    getProvince() {
      AreaApi.getProvince().then(res => {
        let data = res.data
        let list = data.map(item => {
          let obj = {}
          obj.text = item.fullName
          obj.value = item.id
          return obj
        })
        this.provinces = list
      })
    },
    //查询市
    getCity(id) {
      this.form.city=''
      this.form.area=''
      AreaApi.getCity(id).then(res => {
        let data = res.data
        let list = data.map(item => {
          let obj = {}
          obj.text = item.fullName
          obj.value = item.id
          return obj
        })
        this.citys = list

      })
    },
    //查询区
    getArea(id) {
      this.form.area=''
      AreaApi.getCity(id).then(res => {
        let data = res.data
        let list = data.map(item => {
          let obj = {}
          obj.text = item.fullName
          obj.value = item.id
          return obj
        })
        this.areas = list
      })
    },
    //上传成功
    onSuccess(res, type) {
      if (type === 'logo') {
        this.form.logo = res.data.url
        return
      }
      this.form.license = res.data.url
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          CorpAccountApi.update(this.form).then(res => {
            if (!res.isSuccess) {
              return
            }
            this.$message.success('保存成功')
            this.disableMap = {
              companyName: true,
              licenseNumber: true,
              nature: true,
              businessScope: true,
              legalPerson: true,
              address: true,
              phone: true
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@function px2vw($px) {
  @return 150*100/1920 + vw
}

/deep/ .el-input__inner, /deep/ .el-textarea__inner {
  padding-right: 80px !important;
}

.empty-logo {
  background-color: #dddddd;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #359029;
  height: 100%;
  border-radius: 100%;
}

.zh-upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.zh-upload-box img {
  display: block;
  width: 100%;
  height: 100%;
}

/deep/ .avatar-uploader .el-upload {
  display: block !important;
  width: 100%;
  height: 100% !important;
}

.components {
  height: 100%;
  overflow-x: hidden;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  display: flex;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
}

.change-photo {
  padding: 0 30px;
  flex: 0 0 auto;
}

.form {
  flex: 1;
  //padding-left: px2vw(130);
}

.photo-box {
  padding: 20px;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(158, 158, 158, 0.16);
  border-radius: 50%;
}

.photo-box img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.change-label {
  margin-top: 30px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  color: #888888;
  text-align: center;
}

.form-title {
  font-size: 16px;
  color: #030303;
  padding-left: 89px;
  font-weight: bold;
}

.edit-btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 40px;
  color: #359029;
  cursor: pointer;
}

.form-body {
  margin-top: 30px;
}

.avatar-uploader {
  display: flex;
  width: 100px;
  height: 100px;
  border: 1px dashed #DCDFE6;
  align-items: center;
  justify-content: center;
}

.photo-tip {
  margin-left: 20px;
  display: flex;
  flex-direction: column-reverse;
  color: #359029;
  line-height: 2;
}

.submit {
  margin-top: 20px;
  width: 180px;
  display: block;
  background-color: #359029;
  color: #fff;
}

.view-btn {
  display: flex;
  background-color: #359029;
  color: #fff;
  border-radius: 4px;
  line-height: 1.8;
  justify-content: center;
  cursor: pointer;
}
</style>
