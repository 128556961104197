<template>
  <div class="components">
    <folder ref="folder" @openFiles="openFiles"/>
    <files ref="files" @back="back"/>
    <folder-child ref="childBack" @back="back" @openFiles="openFiles"></folder-child>
  </div>
</template>

<script>
import Files from "./components/files"
import Folder from "./components/folder";
import FolderChild from "./components/folderChild";

export default {
  name: "companyFolder",
  components: {Folder, Files, FolderChild},
  data() {
    return {}
  },
  methods: {
    openFiles(item, dataName) {
      if (item.operate.code === 'YSYSTEM') {
        this.$refs.files.init(item, dataName)
      } else {
        this.$refs.childBack.init(item)
      }
    },
    back(item) {
      if (item == null) {
        this.$refs.folder.init()
      } else {
        this.$refs.childBack.initBack(item)
      }
    },
  }
}
</script>
<style scoped>
.components {
  height: 100%;
}
</style>


