<template>
  <div class="page-wrap" style="padding: 20px;background-color: #fff">
    <hm-search-form ref="searchForm" :data="searchConfig" size="normal" label-width="120"/>
    <div class="btns-box">
      <el-button type="primary" @click="exportExcel">导出</el-button>
    </div>
    <div class="component-table">
      <div class="table-box" style="height: calc(100% - 112px); margin-top: 10px;">
        <el-table
            :data="tableData"
            style="min-height: 650px;"
            border
            ref="table"
            :span-method="objectSpanMethod">
          <el-table-column
              prop="portTypeName"
              label="污染源"
              align="center">
          </el-table-column>
          <el-table-column
              prop="equipment"
              label="生产设备"
              align="center">

          </el-table-column>
          <el-table-column
              prop="protective"
              label="防治设施"
              align="center">
          </el-table-column>
          <el-table-column
              prop="monitoringPoint"
              label="监测点"
              align="center">
          </el-table-column>
          <el-table-column
              prop="name"
              label="检测项目"
              align="center">
          </el-table-column>
          <el-table-column
              prop="basisType"
              label="依据类型"
              align="center">
          </el-table-column>
          <el-table-column
              prop="standardName"
              label="标准名称"
              align="center">
          </el-table-column>
          <el-table-column
              prop="limitData"
              label="限值"
              align="center">
          </el-table-column>
          <el-table-column
              prop="detectionMode"
              label="检测方式"
              align="center">
          </el-table-column>
          <el-table-column
              prop="frequency"
              label="监测频次"
              align="center">
          </el-table-column>
          <el-table-column
              prop="testEquipment"
              label="监测设备"
              align="center">
          </el-table-column>
          <el-table-column
              prop="testMethod"
              label="检测方法"
              align="center">
          </el-table-column>
          <!--          操作-->
          <el-table-column
              align="center"
              label="操作">
            <template slot-scope="scope">
              <el-tooltip v-if="sso" content="编辑" placement="top">
                <el-link icon="el-icon-edit" class="table-operation" type="primary" @click="edit(scope.row)"/>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 表格底部新增插槽-->
      <div ref="tableBackendExtend" class="table-backend-extend">
        <slot name="table-backend-extend"/>
      </div>
      <!--        分页-->
      <div class="pagination-box">
        <el-pagination
            :current-page="searchParams.tableParams.current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="searchParams.tableParams.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchParams.tableParams.total"
            @size-change="sizeChange"
            @current-change="currentChange"
        />
      </div>
    </div>
    <self-test-report-edit ref="selfTestReportEdit" @submitSuccess="submitSuccess"/>
  </div>
</template>

<script>
import SelfTestReport from '@/api/ep/selfTestReport'
import {SelfTestReportSearchConfig} from "./config"
import SelfTestReportEdit from "./edit"
import {downloadBlobFile, mergeJson} from '@/utils/commons'
import UserApi from "@/api/uc/user"

export default {
  components: {
    SelfTestReportEdit,
  },
  data() {
    return {
      tableData: [],
      searchConfig: SelfTestReportSearchConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {
          model: {}
        },
        // table下拉条件
        tableParams: {
          size: 10,
          current: 1,
          total: 0
        }
      },
      params: {
        extend: {},
        model: {}
      },
      sso: this.$store.getters.sso
    }
  },
  mounted() {
    const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
    this.getData(params)
  },
  methods: {
    getData(params) {
      SelfTestReport.searchSelfTestReport(params).then(res => {
        if (!res.isSuccess) {
          return
        }
        this.tableData = res.data.records
        const tableParams = this.searchParams.tableParams
        tableParams.current = res.data.current
        tableParams.total = res.data.total
        tableParams.size = res.data.size
        this.getSpanArr(this.tableData)
      })
    },
    getSpanArr(data) {
      // firstLevelIndexArr来存放要合并的格数，同时还要设定一个变量firstLevelIndexPos来记录
      this.firstLevelIndexArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.firstLevelIndexArr.push(1)
          this.firstLevelIndexPos = 0
        } else {
          // 判断当前元素与上一个元素是否相同(第1和第2列)
          if (data[i].monitoringPoint === data[i - 1].monitoringPoint) {
            this.firstLevelIndexArr[this.firstLevelIndexPos] += 1
            this.firstLevelIndexArr.push(0)
          } else {
            this.firstLevelIndexArr.push(1)
            this.firstLevelIndexPos = i
          }
        }
      }
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      //项目名合并
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        const _row = this.firstLevelIndexArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },
    edit(item) {
      this.$refs.selfTestReportEdit.init(item.id)
    },
    exportExcel() {
      const params = this.searchParams.params || {}
      if (!params.extend) {
        params.extend = {}
      }
      params.extend.fileName = '自行检测报告'
      SelfTestReport.exportExcel(params).then((data) => {
        console.log(data)
        downloadBlobFile(data)
      })
    },
    handleSearch(data) {
      this.searchParams.params = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.getData(params)
    },
    handleFilterSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.getData(params)
    },
    submitSuccess() {
      this.refresh()
    },
    /**
     * 不改变参数，加载数据
     */
    refresh(isFirst = true) {
      if (isFirst) {
        this.searchParams.tableParams.current = 1
      }

      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.getData(params)
    },
    /**
     * pageSize变化
     * @param val pageSize 条数
     */
    sizeChange(val) {
      this.searchParams.tableParams.size = val
      this.searchParams.tableParams.current = 1
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.getData(params)
    },
    /**
     *  pageIndex 变化
     * @param val 当前第几页
     */
    currentChange(val) {
      this.searchParams.tableParams.current = val
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.getData(params)
    },
  }
}
</script>

<style scoped>
</style>
