<template>
  <div class="case-root">
    <Head ref="header"></Head>
    <div class="banner">
      <img :src="bannerPath">
    </div>
    <div class="case">
      <div class="case-wrap">
        <div class="case-nav">
          <div class="title">相关合作案例</div>
          <div class="tabs">
            <div class="tabs-item" :class="tabsIndex===index?'active':''" v-for="(item,index) in tabsList" :key="index"
                 @click="changeActive(index,item.code)">
              {{item.sub}}案例
            </div>
          </div>
        </div>
        <div class="caseList">
          <div class="caseList-item" v-for="(item,index) in pageInfo.list" :key="index" @click="toCaseDetail(item)">
            <div class="sub">{{item.title}}</div>
            <div class="pic"><img :src="item.coverUrl"></div>
            <div class="type" v-if="code==='WRFZ'">
              <div class="info">环保类型：</div>
              <div class="text">{{item.caseType}}</div>
            </div>
            <div class="process" v-if="code==='WRFZ'">
              <div class="info">工艺流程：</div>
              <div class="text">{{item.subtitle}}</div>
            </div>
          </div>
        </div>
        <!--分页器-->
        <div class="pagination">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageInfo.pagenum"
              :page-sizes="[5, 10, 20, 50]"
              pager-count="5"
              :page-size="pageInfo.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Head from '@/components/head'
  import Footer from '@/components/footer'
  import HomeArticleApi from '@/api/ep/homeArticle'
  import HomeBannerApi from '@/api/ep/homeBanner'

  export default {
    name: "about",
    data() {
      return {
        bannerPath: '',
        allData: [],
        tabsIndex: 0,
        code: 'HBGJ',
        tabsList: [
          {
            sub: '环保管家',
            code: 'HBGJ'
          },
          {
            sub: '环保咨询服务',
            code: 'HBZX'
          },
          {
            sub: '污染防治设施',
            code: 'WRFZ'
          },
          {
            sub: '其他环保工程或服务',
            code: 'OTHER'
          },
        ],
        //2、分页信息
        pageInfo: {
          pagenum: 1,
          pagesize: 10,
          total: 0,
          list: [],
          start: 0,
          end: 0,
          totalpage: 0
        }
      }
    },
    mounted() {
      this.getCaseData()
      this.getBannerData()
    },
    methods: {
      //banner图数据
      getBannerData() {
        let params = {
          dictionary: 'CASE'
        }
        HomeBannerApi.list(params).then((res) => {
          this.bannerPath =res.data[0].fileId
        })
      },
      getCaseData() {
        let params = {
          categoryType: {
              code: this.code
            }
        }
        HomeArticleApi.list(params).then((res) => {
          this.allData = res.data
          this.getpages()
        })
      },
      //分页
      getpages() {
        //总条数
        this.pageInfo.total = this.allData.length
        //总页数
        this.pageInfo.totalpage = Math.ceil(this.pageInfo.total / this.pageInfo.pagesize)
        //计算起点
        let start = (this.pageInfo.pagenum - 1) * this.pageInfo.pagesize
        //计算终点
        let end = start + this.pageInfo.pagesize
        if (end > this.pageInfo.total) {
          end = this.pageInfo.total
        }
        //截取数组元素
        let arr = this.allData
        this.pageInfo.list = []
        for (let i = start; i < end; i++) {
          this.pageInfo.list.push(arr[i])
        }
      },
      //2、页码发生变化时，重新加载数据
      handleCurrentChange(pagenum) {
        this.pageInfo.pagenum = pagenum
        this.getCaseData()
      },
      //3、一页几条发生变化时，重新加载数据
      handleSizeChange(pagesize) {
        this.pageInfo.pagesize = pagesize
        this.getCaseData()
      },
      changeActive(item, code) {
        this.code = code
        this.tabsIndex = item
        this.getCaseData()

      },
      toCaseDetail(item) {
        this.$router.push('/caseDetail?id=' + item.id)
      }
    },
    components: {Head, Footer}
  }
</script>

<style scoped>
  @media screen and (min-width: 900px){
    .banner img {
      width: 100%;
      height: 475px;
      object-fit: cover;
    }

    /*优秀案例*/
    .case-wrap {
      width: 1200px;
      margin: 0 auto;
    }

    .case-nav {
      width: 1200px;
      padding: 50px 0px 10px 0px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .case-nav .title {
      font-size: 24px;
      line-height: 32px;
      color: #000000;
    }

    .case-nav .tabs {
      display: flex;
      align-items: center;
    }

    .case-nav .tabs .tabs-item {
      padding: 10px 23px;
      font-size: 14px;
      color: #666666;
      cursor: pointer;
      margin-left: 25px;
    }

    .case-nav .tabs .active {
      background-color: #359029;
      color: #ffffff;
    }

    .case .caseList {
      display: flex;
      flex-wrap: wrap;
    }

    .case .caseList .caseList-item {
      width: 340px;
      margin-right: 90px;
      margin-top: 50px;
      cursor: pointer;
    }

    .case .caseList .caseList-item:nth-child(3n+3) {
      margin-right: unset;
    }

    .case .caseList .caseList-item .sub {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
    }

    .case .caseList .caseList-item .pic {
      width: 340px;
      height: 284px;
      margin: 30px 0px;
    }

    .case .caseList .caseList-item .pic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .case .caseList-item .type,
    .case .caseList-item .process {
      display: flex;
    }

    .case .caseList-item .type .info,
    .case .caseList-item .process .info {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
    }

    .case .caseList-item .type .text,
    .case .caseList-item .process .text {
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      flex: 1;
    }

    .pagination {
      padding: 54px 0px 39px;
      text-align: center;
    }
  }

  @media screen and (max-width: 900px){
    .banner img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    /*优秀案例*/
    .case-wrap {
      width: 98%;
      margin: 0 auto;
    }

    .case-nav {
      width: 98%;
      padding: 20px 0px 10px 0px;
      margin: 0 auto;
    }

    .case-nav .title {
      font-size: 20px;
      line-height: 30px;
      color: #000000;
    }

    .case-nav .tabs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    .case-nav .tabs .tabs-item {
      width: 48%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 15px;
      color: #666666;
      cursor: pointer;
      margin: 5px 0px;
    }
    .case-nav .tabs .active {
      background-color: #359029;
      color: #ffffff;
    }

    .case .caseList {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .case .caseList .caseList-item {
      width: 90%;
      margin-top: 20px;
      cursor: pointer;
      box-shadow: 0px 0px 10px #eeeeee;
      border-radius: 10px;
    }

    .case .caseList .caseList-item:nth-child(3n+3) {
      margin-right: unset;
    }

    .case .caseList .caseList-item .sub {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
      text-align: center;
    }

    .case .caseList .caseList-item .pic {
      width: 100%;
      height: 150px;
      margin: 10px 0px;
      text-align: center;
    }

    .case .caseList .caseList-item .pic img {
      width: 90%;
      height: 100%;
      object-fit: cover;
    }

    .case .caseList-item .type,
    .case .caseList-item .process {
      display: flex;
    }

    .case .caseList-item .type .info,
    .case .caseList-item .process .info {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
    }

    .case .caseList-item .type .text,
    .case .caseList-item .process .text {
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      flex: 1;
    }

    .pagination {
      padding: 54px 0px 39px;
      text-align: center;
    }
    /deep/ .el-pagination__jump{
      margin: 0px !important;
    }
    /deep/ .el-pagination__total{
      margin: 0px;
    }
    /deep/ .el-pagination__sizes{
      margin: 0px;
    }

    /deep/  .el-pagination button{
      padding: 0px 3px;
      min-width: 0px;
    }
    /deep/ .el-pager li{
      min-width: 0px;
    }
    /deep/ .el-pagination .el-select .el-input {
      width: 80px;
    }
  }
</style>