<template>
  <div class="components" style="margin: auto" v-if="visible">
    <div class="title breadcrumb">
      <span @click="back" class="breadcrumb-label">企业资料管理</span>
      <span class="el-icon-d-arrow-right icon"></span>
      <span class="breadcrumb-label">{{ dataName || '' }}</span>
    </div>
    <div style="background-color: #fff;padding:30px;margin-top: 20px">
      <div class="table-box" style="height: calc(100% - 112px); margin-top: 20px">
        <el-card v-for="(item,index) in tableData" :key="index">
          <div class="folder-list">
            <div @click="uploadFile(item)" style="display: flex;align-items: center;width: calc(100% - 180px)">
              <!--          文件夹图片-->
              <div class="folder-box">
                <img
                    :src=" item.operate.code !== 'YSYSTEM' ? require('../../../assets/image/icon-folder.png') : require('../../../assets/image/icon-sys-folder.png')"
                    alt="">
              </div>
              <!--          文件夹名称-->
              <div class="folder-name">
                <span>{{ item.dataName }}</span>
              </div>
            </div>
            <!--          操作-->
            <div class="operator">
              <span  @click="share">分享</span>
              <div
                  style="height: 24px;width: 2px; background: #359029;margin: 0 10px;"></div>
              <span @click="uploadFile(item)">上传</span>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <share-dialog ref="share"/>
  </div>
</template>

<script>
import CorpDataApi from "@/api/ep/corpData"
import {FolderSearchConfig, FolderTableConfig} from '../config/folder.js'
import CorpShareApi from "@/api/ep/corpShare"
import FileApi from "@/api/file"
import Share from "../share";
import ShareDialog from "./share";

export default {
  components: {ShareDialog, Share},
  data() {
    return {
      visible: false,
      listRequest: CorpDataApi.search,
      tableConfig: FolderTableConfig.bind(this)(),
      searchConfig: FolderSearchConfig.bind(this)(),
      params: {
        model: {}
      },
      tableData: [],
      dataName: '',
      form: {
        dataName: '',
        operate: 'NSYSTEM'
      },
      rules: {
        dataName: [{required: true, message: '文件夹名称', trigger: 'blur'}]
      },
      isEditStatus: false, //编辑状态
      shareLink: '', //分享链接
      list: [],
    }
  },
  methods: {
    init(item) {
      this.visible = true
      this.dataName = item.dataName
      this.params.model.parentId = item.id
      this.getData(this.params)
    },
    initBack(item) {
      this.visible = true
      this.dataName = item.dataName
      this.params.model.parentId = item.parentId
      this.getData(this.params)
    },
    getData(params) {
      CorpDataApi.search(params).then(res => {
        if (!res.isSuccess) {
          return
        }
        this.tableData = res.data.records
      })
    },
    //设置分享
    share() {
      let ids = this.list.filter(item => {
        return item.checked
      }).map(item => {
        return item.id
      })
      let params = {
        fileIds: [],
        dataIds: ids
      }
      CorpShareApi.getShare(params).then(res => {
        let data = res.data
        this.$refs.share.init(data)
      })

    },
    back() {
      this.visible = false
      this.$emit('back')
    },
    uploadFile(item) {
      this.$emit("openFiles", item, this.dataName)
      this.visible = false
    }
  },
  mounted() {
    this.getData(this.params)
  }
}
</script>

<style scoped lang="scss">
/deep/ .breadcrumb-label{
  font-size: 20px;
  color: #359029;
}
/deep/ .breadcrumb-label:nth-child(1){
  color: #000;
}
/deep/ .breadcrumb-label:nth-child(1):hover{
   font-size: 20px;
   color: #359029;
 }
/deep/.icon{
  font-size: 20px;
  color: #000;
  padding: 0px 7px;
}
  /deep/ .el-card__body{
    padding: 10px 20px !important;
  }
  /deep/.el-button--primary{
    color: #fff;
  }
  .el-card{
    margin-bottom: 30px;
  }
  .folder-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .folder-box img{
    width: 80px;
    object-fit: cover;
  }
  .folder-name span{
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-left: 13px;
    cursor: pointer;
  }
  .operator{
    display: flex;
    align-items: center;
  }
  .operator span{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #359029;
    cursor: pointer;
  }
</style>
