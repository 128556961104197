<template>
  <div class="case-detail">
    <Head ref="header"></Head>
    <div class="banner">
      <img :src="bannerPath">
    </div>
    <div class="crumb">
      <div class="crumb-wrap">
        <span>您当前所在的位置：</span>
        <span class="page" @click="toPage('/')">首页</span>
        <span class="icon">>></span>
        <span class="page" @click="toPage('/information')">公开信息</span>
        <span class="icon">>></span>
        <span class="page">{{ caseDetail.title }}</span>
      </div>
    </div>
    <div class="detail">
      <div class="title">{{ caseDetail.title }}</div>
      <div class="time">发布日期：{{ caseDetail.createTime }}</div>
      <p>{{ caseDetail.summary }}</p>
      <p v-html="caseDetail.content"></p>
    </div>
    <div class="file" v-if="files.length">
      <div class="sub">相关附件：</div>
      <p v-for="item in files">
        <span @click="downLoad(item.url)">{{ item.submittedFileName }}</span>
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from '@/components/head'
import Footer from '@/components/footer'
import HomeArticleApi from '@/api/ep/homeArticle'
import HomeBannerApi from '@/api/ep/homeBanner'
import FileAPi from '@/api/fs/file'

export default {
  name: "caseDetail",
  data() {
    return {
      bannerPath: '',
      id: '',
      caseDetail: {
        title: '',
        createTime: '',
        content: '',
        summary: ''
      },
      files: []
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getcaseDetalData()
    this.getBannerData()
  },
  methods: {
    downLoad(url) {
      window.open(url);
    },
    //banner图数据
    getBannerData() {
      let params = {
        dictionary: 'INFORMATION'
      }
      HomeBannerApi.list(params).then((res) => {
        this.bannerPath = res.data[0].fileId
      })
    },
    getcaseDetalData() {
      HomeArticleApi.get(this.id).then((res) => {
        let data = res.data
        this.caseDetail.title = data.title
        this.caseDetail.createTime = data.createTime.slice(0, 10)
        this.caseDetail.summary = data.summary
        this.caseDetail.content = data.content
        if (res.data.fileIds) {
          FileAPi.listByIds({ids: res.data.fileIds}).then(res => {
            this.files = res.data || []
          })
        }

      })
    },
    toPage(item) {
      this.$router.push(item)
    }
  },
  components: {Head, Footer}
}
</script>

<style scoped>
@media screen and (min-width: 900px) {
  .banner img {
    width: 100%;
    height: 475px;
    object-fit: cover;
  }

  .crumb-wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 19px 0px 44px 0px;
  }

  .crumb-wrap .icon {
    margin: 0px 3px;
  }

  .crumb-wrap span {
    font-size: 14px;
    line-height: 19px;
    color: #999999;
  }

  .crumb-wrap .page {
    cursor: pointer;
  }

  .crumb-wrap span:nth-last-child(1) {
    color: #359029;
  }

  .crumb-wrap .page:hover {
    color: #359029;
  }

  .detail {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 28px;
  }

  .detail .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 32px;
    color: #000000;
    text-align: center;
    padding-bottom: 22px;
  }

  .detail .time {
    font-size: 14px;
    line-height: 32px;
    color: #999999;
    text-align: center;
  }

  .detail {
    text-align: center;
  }

  .detail img {
    max-width: 1200px;
    margin: 20px 0px;
    object-fit: cover;
  }

  .detail p {
    text-align: left;
    font-size: 14px;
    line-height: 32px;
    color: #666666;
  }

  .file {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 28px;
  }

  .file .sub {
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px;
  }

  .file p {
    line-height: 2;
    font-size: 14px;
    cursor: pointer;
  }

  .file p span:hover {
    color: #084DB3;
  }
}

@media screen and (max-width: 900px) {
  .banner img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .crumb-wrap {
    width: 95%;
    margin: 0 auto;
    padding: 19px 0px 20px 0px;
  }

  .crumb-wrap .icon {
    margin: 0px 3px;
  }

  .crumb-wrap span {
    font-size: 14px;
    line-height: 19px;
    color: #999999;
  }

  .crumb-wrap .page {
    cursor: pointer;
  }

  .crumb-wrap span:nth-last-child(1) {
    color: #359029;
  }

  .crumb-wrap .page:hover {
    color: #359029;
  }

  .detail {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 28px;
    text-align: center;
  }

  .detail .title {
    font-size: 23px;
    font-weight: bold;
    line-height: 32px;
    color: #000000;
    text-align: center;
    padding-bottom: 15px;
  }

  .detail .time {
    font-size: 14px;
    line-height: 32px;
    color: #999999;
    text-align: center;
  }

  .detail img {
    max-width: 100%;
    margin: 20px 0px;
    object-fit: cover;
  }

  .detail p {
    text-align: left;
    font-size: 14px;
    line-height: 32px;
    color: #666666;
  }

  .file {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 28px;
  }

  .file .sub {
    font-size: 16px;
    color: #000000;
    margin-bottom: 15px;
  }

  .file p {
    line-height: 2;
    font-size: 14px;
    cursor: pointer;
  }

  .file p span:hover {
    color: #084DB3;
  }

  /deep/ .detail img {
    max-width: 94% !important;
    height: auto;
    margin: 20px 3%;
  }
}
</style>
