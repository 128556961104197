// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const SearchConfig = function () {
    return {
        list: [
            {
                label: '危险废物名称',
                key: 'name',
                type: 'input',
                placeholder: '危险废物名称'
            },
            {
                label: '危险废物类别',
                key: 'category',
                type: 'input',
                placeholder: '危险废物类别'
            },
        ],
        config: {
            onSearch: data => {
                this.handleSearch(data)
            },
            selects: {},
            model: {
                code: '',
                startTime: '',
                endTime: ''
            }
        }
    }
}

// 表格展示配置
export const TableConfig = function () {
    return {
        list: [
            {
                type: 'selection',
                align: "center",
                width: 60
            },
            {
                label: '序号',
                align: "center",
                type: 'index',
                width: 60
            },
            {
                align: 'center',
                label: '危险废物名称',
                slotScope: 'name'
            },
            {
                prop: 'category',
                align: 'center',
                label: '危险废物类别',
            },
            {
                prop: 'code',
                label: '废物代码',
            },
            {
                prop: 'remark',
                label: '废物描述',
            },
            {
                prop: 'produce',
                label: '危险废物产生描述',
            },
            {
                prop: 'produceNum',
                align: 'center',
                label: '危险废物产生量'
            },
            {
                prop: 'selfStorage',
                align: 'center',
                label: '自行贮存',
            },
            {
                prop: 'selfUtilization',
                align: 'center',
                label: '自行利用',
            },
            {
                prop: 'selfHandle',
                align: 'center',
                label: '自行处置',
            },
            {
                prop: 'entrustedUtilization',
                align: 'center',
                label: '委托利用',
            },
            {
                prop: 'entrustedHandle',
                align: 'center',
                label: '委托处置',
            },
            {
                prop: 'handleCorp',
                align: 'center',
                label: '委托处置单位名称',
            },
            {
                prop: 'handleCorpCode',
                align: 'center',
                label: '委托处置单位编号',
            }
        ]
    }
}

// 预览配置
export const DetailConfig = function () {
    return {
        list: [
            {
                label: '危险废物名称',
                key: 'name',
                type: 'input'
            },
            {
                label: '危险废物类别',
                key: 'category',
                type: 'input'
            },
            {
                label: '废物代码',
                key: 'code',
                type: 'input'
            },
            {
                label: '废物描述',
                key: 'remark',
                type: 'input'
            },
            {
                label: '危险废物产生描述',
                key: 'produce',
                type: 'input'
            },
            {
                label: '危险废物产生量(t/a)',
                key: 'produceNum',
                type: 'input'
            },
            {
                label: '自行贮存(t/a)',
                key: 'selfStorage',
                type: 'input'
            },
            {
                label: '自行利用(t/a)',
                key: 'selfUtilization',
                type: 'input'
            },
            {
                label: '自行处置(t/a)',
                key: 'selfHandle',
                type: 'input'
            },
            {
                label: '委托利用(t/a)',
                key: 'entrustedUtilization',
                type: 'input'
            },
            {
                label: '委托处置(t/a)',
                key: 'entrustedHandle',
                type: 'input'
            },
            {
                label: '委托处置单位名称',
                key: 'handleCorp',
                type: 'input'
            },
            {
                label: '委托处置单位编号',
                key: 'handleCorpCode',
                type: 'input'
            },
        ],
        config: {
            model: {
                name: '',
                category: '',
                code: '',
                remark: '',
                produce: '',
                produceNum: '',
                selfStorage: '',
                selfUtilization: '',
                selfHandle: '',
                entrustedUtilization: '',
                entrustedHandle: '',
                handleCorp: '',
                handleCorpCode: ''
            }
        }
    }
}