<template>
  <el-dialog
      :visible.sync="isShareVisible"
      top="0"
      width="609px">
    <div class="share-box">
      <div class="share-title">分享企业资料</div>
      <div class="link-box">
        <el-image
            style="width: 140px; height: 140px"
            :src="shareLink"
            :preview-src-list="[shareLink]">
        </el-image>
      </div>
      <div class="share-tip">发送链接即可分享该文件夹中所有内容，请谨慎分享（点击图片可放大查看）</div>
      <div class="share-btn">
        <a href="javascript:;" @click="isShareVisible = false">取消</a>
        <a href="javascript:;" class="primary" @click="downImage">下载</a>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {downloadBlobFile} from "@/utils/commons"
import fileAPI from "@/api/fs/file"
export default {
  name: "shareDialog",
  data(){
    return {
      isShareVisible: false,
      shareLink: ''
    }
  },
  methods:{
    init(shareLink){
      this.isShareVisible = true
      this.shareLink = shareLink
    },
    //复制链接
    copySuccess(){
      this.$message.success('复制成功')
      this.isShareVisible = false
    },
    copyError(){
      this.$message.success('复制失败，请重新复制')
    },
    //下载
    downImage(){
      fileAPI.downloadByUrl(this.shareLink).then(res => {
        downloadBlobFile(res)
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto!important;
  height: 380px;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 0;
}
.share-box {
  padding: 28px 38px 38px;
  .share-title {
    text-align: center;
    height: 24px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 35px;
    color: #000000;
    -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
    text-stroke: 1 rgba(0, 0, 0, 0);
    opacity: 1;
  }
  .share-tip {
    margin-top: 26px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 35px;
    height: 35px;
    color: #999999;
    -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
    text-stroke: 1 rgba(0, 0, 0, 0);
    text-align: center;
  }
  .link-box {
    margin: 20px auto 0 auto;
    height: 140px;
    width: 140px;
    border: 1px solid #ddd;
  }
  .share-btn {
    margin-top: 31px;
    display: flex;
    justify-content: flex-end;
    a {
      display: block;
      width: 122px;
      height: 39px;
      background: #BEBEBE;
      opacity: 1;
      text-align: center;
      line-height: 39px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      border-radius: 39px;
      &:first-child {
        margin-right: 27px;
      }
      &.primary {
        background-color: #359029;
      }
    }
  }
}

</style>
