// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const SearchConfig = function () {
  return {
    list: [
      {
        label: '原辅材料名称',
        key: 'assistProductName',
        type: 'input',
        placeholder: '请输入原辅材料名称'
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
	  deriveBtn:()=>{
		  this.deriveBtnExcelClick()
	  },
      selects: {
		  assistProductName:[]
	  },
      model: {
        'assistProductName': ''
      }
    }
  }
}

// 表格展示配置
export const TableConfig = function () {
  return {
    list: [
      {
        type: 'selection',
        align: "center",
        width: 60
      },
      {
        label: '序号',
        align: "center",
        type: 'index',
        width: 60
      },
      {
        prop: 'recordCode',
        align: 'center',
        label: '日期',
      },
	  {
	    prop: 'number',
	    align: 'center',
	    label: '用量',
	  },
	  {
	    prop: 'unit',
	    align: 'center',
	    label: '单位',
	  },
      {
        fixed: 'right',
        label: '操作',
        align: 'center',
        slotScope: 'operation',
        'min-width': 100
      }
    ]
  }
}

// 新增对象配置
export const SignageBrandAddConfig = function () {
  return {
    list: [
      {
        label: '排放编号',
        key: 'number',
        type: 'input',
        span: 12,
        placeholder: '请输入排放编号'
      },
      {
        label: '主要污染物',
        key: 'pollutants',
        type: 'input',
        span: 12,
        placeholder: '请输入主要污染物'
      },
      {
        label: '污染类别',
        key: 'pollutionType',
        type: 'select',
        span: 12,
        placeholder: '请选择'
      },
      {
        label: '监制单位',
        key: 'unit',
        span: 12,
        type: 'input',
        disabled: true,
        placeholder: '请输入监制单位'
      },
      {
        label: '废气污染',
        key: 'pollutionCategory',
        span: 12,
        type: 'input',
        placeholder: '请输入废气污染类别'
      },
      {
        label: '处理工艺',
        key: 'treatmentProcess',
        span: 12,
        type: 'input',
        placeholder: '请输入处理工艺'
      },
      {
        label: '二维码内容 ',
        key: 'content',
        type: 'editor',
        placeholder: '请输入监制单位'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      onChange: (k, v) => {
        this.onformChange(k, v)
      },
      model: {
        'number': '',
        'pollutants': '',
        'pollutionType': '',
        'unit': '',
        'content': '',
        'treatmentProcess': '',
        'pollutionCategory': '',
      },
      rules: {
        company: [{required: true, message: '项目名称', trigger: 'blur'}]
      },
      selects: {
        pollutionType: [{text: '废气污染', value: 'EXHAUSR'}, {text: '污水排放', value: 'SEWAGE'}, {
          text: '雨水排放',
          value: 'RAIN'
        }, {text: '噪音排放', value: 'NOISE'}, {text: '危险品排放', value: 'DANGER'}]
      }
    }
  }
}

// 排污口示意图上传配置
export const SignalImageConfig = function () {
  return {
    list: [
      {
        label: '示意图上传',
        key: 'signalImage',
        type: 'avatar'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        'signalImage': ''
      },
      rules: {
        signalImage: [{required: true, message: '请上传排污口示意图 ', trigger: 'blur'}],
      },
      selects: {}
    }
  }
}

// 生产工艺流程编辑配置
export const ProductProcessConfig = function () {
  return {
    list: [
      {
        label: '描述信息',
        key: 'productProcessRemark',
        type: 'textarea',
        placeholder: '请输入描述信息'
      },
      {
        label: '生产工艺流程图',
        key: 'productProcessId',
        type: 'select',
        placeholder: '请选择生产工艺'
      },
      {
        label: '流程图预览',
        key: 'image',
        type: 'avatar'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onChange: (key, value) => {
        this.handleChange(key, value)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        'productProcessRemark': '',
        'productProcessId': '',
        'image': ''
      },
      rules: {
        signalImage: [{required: true, message: '请上传排污口示意图 ', trigger: 'blur'}],
      },
      selects: {
        'productProcessId': []
      }
    }
  }
}


// 近期检测报告配置
export const CheckFileConfig = function () {
  return {
    list: [
      {
        label: '近期检测报告',
        key: 'checkFileId',
        type: 'select',
        multiple: true,
        placeholder: '请选择近期检测报告'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onChange: (key, value) => {
        this.handleChange(key, value)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        'checkFileId': ''
      },
      rules: {
        signalImage: [{required: true, message: '请选择近期检测报告 ', trigger: 'blur'}],
      },
      selects: {
        'checkFileId': []
      }
    }
  }
}

// 编辑配置
export const EditConfig = function () {
  return {
    list: [
      {
        label: '排污口编号',
        key: 'code',
        type: 'input'
      },
      {
        label: '生产设备',
        key: 'code',
        type: 'select'
      },
      {
        label: '防治设施',
        key: 'code',
        type: 'input'
      },
      {
        label: '排放口名称',
        key: 'code',
        type: 'input'
      },
      {
        label: '排放口类型',
        key: 'code',
        type: 'input'
      },
      {
        label: '排污口编号',
        key: 'code',
        type: 'input'
      },
      {
        label: '排污口编号',
        key: 'code',
        type: 'input'
      },

    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        'signalImage': ''
      },
      rules: {
        signalImage: [{required: true, message: '请上传排污口示意图 ', trigger: 'blur'}],
      },
      selects: {}
    }
  }
}
