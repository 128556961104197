<template>
  <el-dialog
      :visible.sync="visible"
      :show-close="false"
      width="830px"
      :before-close="handleClose">
    <div class="contain">
      <div class="title">环保档案二维码</div>
      <div class="info" ref="imageWrapper">
        <div class="info-top">
          <div class="company-1">{{data.corpAccount && data.corpAccount.companyName}}电子环保档案卡</div>
          <div class="company-2">安徽一二三环保科技有限公司监制</div>
        </div>
        <div class="info-button">
          <div class="list">
            <div class="item">环保立项、影响评价、竣工验收资料</div>
            <div class="item" v-for="(item,index) in data.dataNameList" :key="index">{{item}}</div>
          </div>
          <div class="pic">
            <img v-if="data.file" :src="data.file.url">
          </div>
        </div>
      </div>
      <div class="btns">
        <div class="btn" @click="handleClose">取消</div>
        <div class="btn" @click="onCreateImage">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CorpDataApi from "@/api/ep/corpData"
import html2canvas from "html2canvas"
  export default {
    name: "archivesCode",
    data(){
      return{
        visible:false,
        data:{}
      }
    },
    methods:{
      init(){
        this.visible=true,
        this.getData()
      },
      onCreateImage(){
        html2canvas(this.$refs.imageWrapper, {
          backgroundColor: '#606266',
          useCORS: true
        }).then(canvas => {
          let link = document.createElement("a");
          link.href = canvas.toDataURL();
          link.setAttribute("download","code.png");
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          this.$message.success('下载成功')
          this.handleClose()
        });
      },
      getData(){
        CorpDataApi.getArchivesCode().then((res=>{
          this.data=res.data ||{}
        }))
      },
      handleClose(){
        this.visible=false
      }
    }
  }
</script>

<style scoped>
  /deep/.el-dialog{
    background-color: #F5F5F5 !important;
    border-radius: 10px !important;
  }
  .contain{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title{
    font-size: 20px;
    color: #000000;
    margin-bottom: 47px;
    text-align: center;
  }
.info{
  width: 575px;
}
  .info-top{
    background-color: #ffffff;
    padding: 20px 42px 20px 33px;
  }
  .info-top .company-1{
    font-size: 22px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 11px;
  }
  .info-top .company-2{
    font-size: 16px;
    color: #666666;
  }
  .info-button{
    display: flex;
    padding: 30px 42px 20px 30px;
    background-color: #1E4299;
    justify-content: space-between;
  }
  .list .item{
    font-size: 20px;
    color: #FFFFFF;
    margin: 10px 0px;
  }
  .list .item:first-child{
    margin-top: 0;
  }
  .pic{
    /*padding: 8px;*/
    background-color: #ffffff;

    width: 150px;
    height: 150px;
  }
  .pic img{
    width: 150px;
    height: 150px;
    border-radius: 8px;
  }
  .btns{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
  .btn{
    width: 233px;
    height: 48px;
    border-radius: 24px;
    font-size: 18px;
    line-height: 48px;
    color: #FFFFFF;
    text-align: center;
    margin: 50px 25px 0px;
    cursor: pointer;
  }
  .btn:nth-child(1){
    background: #BEBEBE;
  }
  .btn:nth-child(2){
    background: #359029;
  }
</style>