<template>
  <div class="components">
    <div class="title">员工管理</div>
    <div class="content">
      <hm-search-form ref="searchForm" :data="searchConfig" label-width="150" size="normal"/>
      <div class="btns-box">
        <el-button type="primary" @click="add">新增员工</el-button>
        <el-button type="primary" @click="batchEnable('ENABLE')">批量启用</el-button>
        <el-button type="primary" @click="batchDelete">批量删除</el-button>
        <el-button type="primary" @click="batchEnable('DISABLED')">批量禁用</el-button>
        <el-button type="primary" @click="resetPasswords('123456')">重置密码</el-button>
      </div>
      <div style="margin-top: 20px;">
        <hm-table ref="table"
                  :stripe = "true"
                  :border = "true"
                  :header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}"
                  :api="listRequest"
                  :table-config="tableConfig">

          <div slot="operation" slot-scope="row">
            <el-button class="table-operation" type="text" @click="handleEnable(row.data, 'ENABLE')" :disabled="row.data.status.code === 'ENABLE'">启用</el-button>
            <el-button class="table-operation" type="text" @click="handleEnable(row.data, 'DISABLED')" :disabled="row.data.status.code === 'DISABLED'">禁用</el-button>
            <el-button class="table-operation" type="text" @click="edit(row.data)">编辑</el-button>
<!--            <el-button class="table-operation" type="text" @click="remove(row.data)">删除</el-button>-->
          </div>
        </hm-table>
      </div>
    </div>
    <employee-edit ref="form" @submitSuccess="submitSuccess"></employee-edit>
  </div>
</template>

<script>
import CorpUserApi from "@/api/ep/corpUser"
import EmployeeEdit from "./employeeEdit"
import {EmployeeSearchConfig, EmployeeTableConfig} from "./config/employee";
import { mergeJson } from '@/utils/commons'
export default {
  name: "employee",
  components:{EmployeeEdit},
  data() {
    return {
      listRequest: CorpUserApi.search,
      searchConfig: EmployeeSearchConfig.bind(this)(),
      tableConfig: EmployeeTableConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {},
        // table下拉条件
        tableParams: {},
      },
    }
  },
  methods: {
    //启动或者禁用
    handleEnable(row,type){
      this.batchEnable(type,row.id)
    },
    //批量启用禁用
    batchEnable(type,id){
      let ids;
      if(id) {
        ids = [id]
      }else  {
        ids  = this.$refs.table.getSelections()['ids']
      }
      if (ids.length <= 0) {
        this.$message.error('至少选择一项')
        return false
      }

      this.$confirm('是否确认修改用户状态继续?', '提示', {type: 'warning'}).then(() => {
        CorpUserApi.updateStatus({ids: ids, status: type}).then((res) => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('修改成功')
          this.$refs.table.refresh()
        })
      })
    },
    //重置密码
    resetPasswords(password){
      let {ids}  = this.$refs.table.getSelections()
      if (ids.length <= 0) {
        this.$message.error('至少选择一项')
        return false
      }

      this.$confirm('是否确认重置用户密码继续?', '提示', {type: 'warning'}).then(() => {
        CorpUserApi.resetPasswords({ids: ids, password: password}).then((res) => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('重置成功,默认密码为123456')
          this.$refs.table.refresh()
        })
      })
    },
    edit(item) {
      this.$refs.form.init(item.id)
    },
    add() {
      this.$refs.form.init()
    },
    remove(data){
      this.batchDelete(data.id)
    },
    batchDelete(id) {
      // let ids;
      // if(id) {
      //   ids = [id]
      // }else {
      //   ids = this.$refs.table.getSelections()['ids']
      // }
      const { ids } = this.$refs.table.getSelections()
      if (ids.length <= 0) {
        this.$message.error('至少选择一项')
        return false
      }

      this.$confirm('此操作将永久删除, 是否继续?', '提示', {type: 'warning'}).then(() => {
        CorpUserApi.batchDelete({ids: ids}).then((res) => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
      })
    },
    handleSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.$refs.table.reload(params)
    },
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}
.table-operation {
  margin: 0 5px;
}
</style>
