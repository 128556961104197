<template>
  <div>
    <div class="identification container">
      <div class="top">
        <p>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.companyName }}</p>
          <p>电子标示{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.code }}</p>
        <p>安徽一二三环保科技有限公司</p>
      </div>
      <div class="main">
        <div class="title">
          <div class="number">01</div>
          <p>企业基本情况</p>
        </div>
        <div class="info">
          <ul>
            <li>单位名称:</li>
            <li>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.companyName }}</li>
          </ul>
          <ul>
            <li>法定代表人:</li>
            <li>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.legalPerson }}</li>
          </ul>
          <ul>
            <li>统一社会信用代码:</li>
            <li>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.licenseNumber }}</li>
          </ul>
          <ul>
            <li>单位住址:</li>
            <li>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.address }} </li>
          </ul>
          <ul>
            <li>全场排污口分布示意图:</li>
            <li><img :src="dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.signalImage" alt=""></li>
          </ul>
        </div>
        <div class="title">
          <div class="number">02</div>
          <p>电子标识牌</p>
        </div>
        <div class="signboard">
          <ul>
            <li>标识牌类别:</li>
            <li><img :src="dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.cover" alt=""></li>
            <li>{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.name }}-{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.code }}</li>
          </ul>
          <table class="table" >
            <tr>
              <th colspan="3">{{ dischargePortDetail.corpData && dischargePortDetail.corpData.companyName }}-{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.code }}</th>
            </tr>
            <tr>
              <td>排污口类型</td>
              <td colspan="2">{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.portType.desc }}</td>
            </tr>
            <tr>
              <td>排放口参数</td>
              <td>{{ '高度:' + (dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.portHeight) + 'M' || "暂无"}}</td>
              <td>{{ '内径:' + (dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.portInradium) + 'M' || "暂无"}}</td>
            </tr>
            <tr>
              <td>污染源生产工序</td>
              <td colspan="2">{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.pollutionProductProcess }}</td>
            </tr>
            <tr>
              <td>特征污染物</td>
              <td colspan="2"><span v-for="(item, index) in dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.dischargePortCpList"><span v-if="index > 0">,</span>{{item.name}}</span></td>
            </tr>
            <tr>
              <td>前端治理工艺</td>
              <td colspan="2">{{ dischargePortDetail.dischargePortDatadischargePortData && dischargePortDetail.dischargePortData.treatmentProcess }}</td>
            </tr>
            <tr>
              <td>排污许可要求检测频次</td>
              <td colspan="2"><span v-for="(item, index) in dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.dischargePortCpList"><span v-if="index > 0">,</span>{{item.name}}{{item.monitorData}}{{item.monitorUnit.key === 'OTHER' ? item.monitorOtherUnit : item.monitorUnit.data}}</span></td>
            </tr>
            <tr>
              <td>浓度限制</td>
              <td colspan="2"><span v-for="(item, index) in dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.dischargePortCpList"><span v-if="index > 0">,</span>{{item.name}}{{item.limitData}}{{ item.limitUnit.key === 'OTHER' ? item.limitOtherUnit : item.limitUnit.data}}</span></td>
            </tr>
            <tr>
              <td>近期检测报告</td>
              <td colspan="2"><span v-for="(item, index) in dischargePortDetail.checkFileData"><span v-if="index > 0">,</span>{{item.submittedFileName}}</span></td>
            </tr>
          </table>
          <div class="step-box">
            <hr>
            <div class="step">1、对应生产工艺流程</div>
          </div>
          <div class="technological_process">
            <p>{{dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.productProcessRemark}}</p>
            <p>
              {{dischargePortDetail.processData && dischargePortDetail.processData.title}}
            </p>
            <div ><img :src="dischargePortDetail.processData && dischargePortDetail.processData.image" alt=""></div>
          </div>
          <div ><img :src="dischargePortDetail.processData && dischargePortDetail.processData.image" alt=""></div>
          <div class="step-box">
            <hr>
            <div class="step">2、对应近期检测报告</div>
          </div>
          <div class="file-box" v-if="dischargePortDetail.checkFileData">
            <div class="file" v-for="checkFile in dischargePortDetail.checkFileData">
              <a :href="checkFile.url">
                <ul class="img-box">
                  <img v-if="checkFile.ext === 'png'" src="../../../assets/image/icon/icon_png.png" alt="">
                  <img v-else-if="checkFile.ext === 'pdf'" src="../../../assets/image/icon/icon-pdf.png" alt="">
                  <img v-else-if="checkFile.ext === 'word'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="checkFile.ext === 'docx'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="checkFile.ext === 'jpg'" src="../../../assets/image/icon/icon_jpg.png" alt="">
                  <img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
                </ul>
                <ul>
                  <li class="submittedFileName">{{checkFile.submittedFileName}}</li>
                  <li>{{ (checkFile.size / 1024).toFixed(2)}}kb</li>
                </ul>
              </a>
            </div>
          </div>
          <div v-else>正在完善...请期待!</div>
          <div class="step-box">
            <hr>
            <div class="step">3、对应排污许可证</div>
          </div>
          <div class="file-box" v-if="dischargePortDetail.premissionFileData">
            <div class="file" v-for="file in dischargePortDetail.premissionFileData">
              <a :href="file.url">
                <ul class="img-box">
                  <img v-if="file.ext === 'png'" src="../../../assets/image/icon/icon_png.png" alt="">
                  <img v-else-if="file.ext === 'pdf'" src="../../../assets/image/icon/icon-pdf.png" alt="">
                  <img v-else-if="file.ext === 'word'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="file.ext === 'docx'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="file.ext === 'jpg'" src="../../../assets/image/icon/icon_jpg.png" alt="">
                  <img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
                </ul>
                <ul>
                  <li class="submittedFileName">{{file.submittedFileName}}</li>
                  <li>{{ (file.size / 1024).toFixed(2)}}kb</li>
                </ul>
              </a>
            </div>
          </div>
          <div v-else>正在完善...请期待!</div>
          <div class="step-box">
            <hr>
            <div class="step">对应排污口照片</div>
          </div>
          <div class="swiper" v-if="imgList.length !== 0">
            <el-carousel :interval="4000" type="card" height="200px">
              <el-carousel-item v-for="img in imgList">
                <img :src="img" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div v-else>正在完善...请期待!</div>
        </div>
        <div class="bottom">
          <div class="logo-box">
            <p class="corpContent" v-if="dischargePortDetail.corpContent !== null" v-html="dischargePortDetail.corpContent && dischargePortDetail.corpContent.value"></p>
            <p class="time">本排污口电子标识制作时间:{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.createTime.substring(0,10) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DischargePortApi from "@/api/ep/dischargePort"
export default {
  name: "detail",
  data(){
    return{
      visible:false,
      dischargePortId:'',
      dischargePortDetail:{},
      imgList:[]
    }
  },
  mounted(){
    this.dischargePortId = this.$route.query.dischargePortId
    DischargePortApi.getDetail(this.dischargePortId).then(res => {
      consolo.log(res)
      this.dischargePortDetail = res.data || {}
      this.setSwiperImage()
    })
  },
  methods:{
    setSwiperImage(){
      if (this.dischargePortDetail.dischargePortData.image !== ''){
        let image = this.dischargePortDetail.dischargePortData.image
        this.imgList = image.split(',')
      }
    }
  }
}
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /*font-size: 1.6rem;*/
}
img{
  width: 100%;
  height: 100%;
}
.container{
  width:100%;
  margin: 0 auto;
  font-size: 1.6rem;
}
.identification .top{
  background: #20985A;
  width: 100%;
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 5.5rem 0;
}
.main{
  padding: 3rem 1.6rem;
  border: .1rem solid;
}
.main .title{
  display: flex;
  justify-content: flex-start;
  height: 4.8rem;
  align-items: center;
  background: #C7E5D6;
  color: #20985A;
}
.main .title .number{
  width: 4.8rem;
  height: 4.8rem;
  background: #20985A;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  line-height: 4.8rem;
  color: #FFFFFF;
}
.main .title p{
  padding-left: 1.5rem;
}
.main .info ul{
  margin-top: .3rem;
  color: #333333;
}
.main .info ul li:nth-child(1){
  font-weight: bold;
  margin-bottom: .5rem;
  color: #000000;
}
.signboard {
  margin-top: 3rem;
}
.signboard ul{
  margin-bottom: 1.5rem;
}
.signboard ul:nth-child(1) li:nth-child(1){
  font-weight: bold;
  margin-bottom: .5rem;
  color: #000000;
  text-align: left;
}
.signboard ul:nth-child(1) li{
  margin: 0 auto;
  text-align: center;
}
table{
  border-collapse: collapse;
  color: #000000;
  margin-bottom: 4rem;
}
table,table tr td {
  border:.1rem solid #ccc;
}
.table th{
  font-weight: bold;
  background: #F0F2F5;
  font-size: 1.6rem;
}
.table th, .table td{
  width: 33%;
  padding: 1.2rem 1rem;
}
.table td{
  font-size: 1.2rem;
}
.step-box{
  position: relative;
  margin: 4rem 0;
}
.step{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 3.7rem;
  width: 21.4rem;
  border-radius: .4rem;
  background: #20985A;
  color: #FFFFFF;
  text-align: center;
  line-height: 3.7rem;
  z-index: 10;
}
.file-box{
  box-shadow: #f1f0f0 0 0 .8rem .3rem;
  border-radius: .6rem;
  padding: 1.6rem;
}
.file-box .file a{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: .5rem 0;
  border-bottom: .1rem solid #CCCCCC;
}
.file-box .file:last-child a{
  border: none;
}
.file-box .file .img-box{
  width: 3.9rem;
  height: 4.85rem;
}
.file-box .file ul:nth-child(2){
  padding-right: 3rem;
  margin-left: 1rem;
  width: calc(100% - 5rem);
}
.file-box .file ul:nth-child(2) li:nth-child(2){
  font-size: 1.2rem;
}
.file-box .submittedFileName{
  word-wrap: break-word;
}
.logo-box{
  border-top: .1rem solid #20985A;
  margin: 5rem 0 4rem;
  padding-top: 3rem;
}
.logo-box .time{
  text-align: center;
  font-size: 1.4rem;
  margin-top: 2rem;
  color: #000000;
}
/deep/.corpContent img{
  max-width: 100% !important;
}
/*.logo-box .logo{*/
/*  width: 7.8rem;*/
/*  height: 7.8rem;*/
/*  border: .1rem solid;*/
/*  margin: 2.5rem auto 1rem;*/
/*}*/
/*.logo-box h4{*/
/*  font-size: 1.6rem;*/
/*  text-align: center;*/
/*  color: #000000;*/
/*}*/
/*.bottom ul{*/
/*  margin: 2rem 0;*/
/*}*/
/*.bottom ul li:nth-child(1){*/
/*  color: #000000;*/
/*}*/
/*.bottom p{*/
/*  text-align: center;*/
/*  margin-top: 1.5rem;*/
/*}*/
/*.bottom .number{*/
/*  text-align: center;*/
/*  color: #000000;*/
/*  margin: 4rem 0;*/
/*}*/
/*.bottom h6 span{*/
/*  color: #666666;*/
/*}*/
/*.bottom h5{*/
/*  text-align: center;*/
/*}*/
/*.bottom .QR-code{*/
/*  width: 11.4rem;*/
/*  height: 11.4rem;*/
/*  margin: 3rem auto;*/
/*  border: .1rem solid;*/
/*}*/
/*.bottom h1{*/
/*  text-align: center;*/
/*}*/
</style>
