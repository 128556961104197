<template>
  <div class="components">
    <div class="content">
      <hm-search-form ref="searchForm" :data="searchConfig" size="normal" label-width="120"/>
      <div class="btns-box">
        <el-button type="primary" @click="exportExcel" :disabled="!flag">导出</el-button>
      </div>
      <div style="margin-top: 20px;">
        <el-table
            :data="tableList"
            height="460"
            style="width: 100%">
          <el-table-column
              label="序号"
              type="index"
              align="center"
              width="60"
          >
          </el-table-column>
          <el-table-column
              label="危险废物名称"
              align="center"
          >
            <template slot-scope="slotScope">
              <el-link type="primary" style="color: #42AB34;text-align: center" :underline="false" @click="onView(slotScope.row.id)">{{
                  slotScope.row.name
                }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
              label="危险废物类别"
              align="center"
              prop="category">
          </el-table-column>
          <el-table-column
              label="废物代码"
              align="center"
              prop="code">
          </el-table-column>
          <el-table-column
              label="废物描述"
              align="center"
              prop="remark">
          </el-table-column>
          <el-table-column
              label="废物产生描述"
              align="center"
              prop="produce">
          </el-table-column>
          <el-table-column
              label="废物产生量(t/a)"
              align="center"
              prop="produceNum">
          </el-table-column>
          <el-table-column label="危险废物处理去向" align="center">
            <el-table-column
                prop="selfStorage"
                align="center"
                label="自行贮存(t/a)">
            </el-table-column>
            <el-table-column
                prop="selfUtilization"
                align="center"
                label="自行利用(t/a)">
            </el-table-column>
            <el-table-column
                prop="selfHandle"
                align="center"
                label="自行处置(t/a)">
            </el-table-column>
            <el-table-column
                prop="entrustedUtilization"
                align="center"
                label="委托利用(t/a)">
            </el-table-column>
            <el-table-column
                prop="entrustedHandle"
                align="center"
                label="委托处置(t/a)">
            </el-table-column>
          </el-table-column>
          <el-table-column
              label="委托处置单位名称"
              align="center"
              prop="handleCorp">
          </el-table-column>
          <el-table-column
              label="委托处置单位编号"
              align="center"
              prop="handleCorpCode">
          </el-table-column>
        </el-table>
      </div>
      <div class="page" style="margin-top: 10px">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <detail ref="detailForm" @submitSuccess="submitSuccess"></detail>
  </div>
</template>

<script>
import HazardousWasteAPI from "@/api/ep/hazardousWaste"
import {SearchConfig, TableConfig} from "./config"
import {downloadBlobFile, mergeJson} from '@/utils/commons'
import Detail from './detail'

export default {
  components: {Detail},
  data() {
    return {
      listRequest: HazardousWasteAPI.search,
      searchConfig: SearchConfig.bind(this)(),
      tableConfig: TableConfig.bind(this)(),
      params: {
        size: 10,
        current: 1,
        model: {}
      },
      tableList: [],
      total: 0,
      currentPage: 1,
      flag:true,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      HazardousWasteAPI.search(this.params).then((res) => {
        if (!res.isSuccess) {
          return
        }
        this.tableList = res.data.records
        this.total = res.data.total
      })
    },
    onView(id) {
      this.$refs.detailForm.init(id)
    },
    exportExcel() {
      if (this.flag){
        this.flag = false
        const params = mergeJson(this.params) || {}
        if (!params.extend) {
          params.extend = {}
        }
        params.extend.fileName = '危废产生情况'
        HazardousWasteAPI.exportExcel(params).then((data) => {
          downloadBlobFile(data)
        })
      }
      setTimeout(() => {
        this.flag = true
      },500)
    },
    handleSearch(data) {
      let model = data.model
      this.params.model = model
      this.getData()
    },
    handleSizeChange(e) {
      this.params.size = e
      this.getData()
    },
    handleCurrentChange(e) {
      this.params.current = e
      this.currentPage = e
      this.getData()
    },
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}

.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  width: 50px;
  height: 50px;
}
</style>
