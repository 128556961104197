<template>
  <div class="components">
    <div class="title">企业设备管理</div>
    <div class="page">
      <el-tabs v-model="activeName">
        <el-tab-pane label="生产设备" name="first"></el-tab-pane>
        <el-tab-pane label="防治设施" name="second"></el-tab-pane>
      </el-tabs>
      <div class="tabs">
        <Equipment v-if="activeName==='first'"></Equipment>
        <Protective v-if="activeName==='second'"></Protective>
      </div>
    </div>
  </div>
</template>

<script>
import Equipment from './equipment/index'
import Protective from './protective/index'
import UserApi from '@/api/uc/user'

export default {
  components: {Equipment, Protective},
  name: "index",
  data() {
    return {
      activeName: "first",
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<style scoped>
.page{
  position: relative;
  background-color: #fff;
  padding: 20px;
  height: calc(100vh - 210px);
  overflow: auto;
}
.title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #030303;
}
</style>
<style>
.el-tabs__nav-wrap::after {
  height: 4px;
  margin-top: 15px;
}
.el-tabs__item {
  color: #999;
  margin-bottom: 10px;
}
.el-tabs__item:hover{
  color: #359029;
}
.el-tabs__item.is-active{
  color: #359029;
}
.el-tabs__active-bar{
  background: #359029;
}
/*.table-box{*/
/*  height: 200px !important;*/
/*  overflow: auto;*/
/*}*/
</style>

