<template>
  <div class="case-detail">
    <Head ref="header"></Head>
    <div class="banner">
      <img :src="bannerPath">
    </div>
    <div class="crumb">
      <div class="crumb-wrap">
        <span>您当前所在的位置：</span>
        <span class="page" @click="toPage('/')">首页</span>
        <span class="icon">>></span>
        <span class="page" @click="toPage('/case')">优秀案例</span>
        <span class="icon">>></span>
        <span class="page">{{caseDetail.title}}</span>
      </div>
    </div>
    <div class="detail">
      <div class="title">{{caseDetail.title}}</div>
      <div class="time">发布日期：{{caseDetail.createTime}}</div>
      <div class="type-process" v-if="this.caseDetail.categoryType.code==='WRFZ'">
        <div class="type">
          <div class="sub">环保类型：</div>
          <div class="info">{{caseDetail.caseType}}</div>
        </div>
        <div class="process">
          <div class="sub">工艺流程：</div>
          <div class="info">{{caseDetail.subtitle}}</div>
        </div>
      </div>
      <p v-html="caseDetail.content"></p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Head from '@/components/head'
  import Footer from '@/components/footer'
  import HomeArticleApi from '@/api/ep/homeArticle'
  import HomeBannerApi from '@/api/ep/homeBanner'
  export default {
    name: "caseDetail",
    data() {
      return {
        bannerPath: '',
        id: '',
        caseDetail: {
          title: '',
          createTime: '',
          summary: '',
          subtitle: '',
          content: '',
          categoryType:{}
        }
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.getCaseDetailData()
      this.getBannerData()
    },
    methods: {
      //banner图数据
      getBannerData() {
        let params = {
          dictionary: 'CASE'
        }
        HomeBannerApi.list(params).then((res) => {
          this.bannerPath =res.data[0].fileId
        })
      },
      getCaseDetailData() {
        HomeArticleApi.get(this.id).then((res) => {
          let data = res.data
          this.caseDetail.caseType=data.caseType
          this.caseDetail.title = data.title
          this.caseDetail.createTime = data.createTime.slice(0, 10)
          this.caseDetail.summary = data.summary
          this.caseDetail.subtitle = data.subtitle
          this.caseDetail.content = data.content
          this.caseDetail.categoryType=data.categoryType
        })
      },
      toPage(item) {
        this.$router.push(item)
      }
    },
    components: {Head, Footer}
  }
</script>

<style scoped>
  @media screen and (min-width: 900px){
    .banner img {
      width: 100%;
      height: 475px;
      object-fit: cover;
    }

    .crumb-wrap {
      width: 1200px;
      margin: 0 auto;
      padding: 19px 0px 44px 0px;
    }

    .crumb-wrap .icon {
      margin: 0px 3px;
    }

    .crumb-wrap span {
      font-size: 14px;
      line-height: 19px;
      color: #999999;
    }

    .crumb-wrap .page {
      cursor: pointer;
    }

    .crumb-wrap span:nth-last-child(1) {
      color: #359029;
    }

    .crumb-wrap .page:hover {
      color: #359029;
    }

    .detail {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 28px;
    }

    .detail .title {
      font-size: 28px;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
      text-align: center;
      padding-bottom: 22px;
    }

    .detail .time {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
      text-align: center;
    }

    .type-process {
      display: flex;
      padding: 51px 0px 38px 0px;
    }

    .type-process .type,
    .type-process .process {
      display: flex;
    }

    .type-process .process {
      padding-left: 100px;
    }

    .type-process .sub {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
    }

    .type-process .info {
      font-size: 14px;
      line-height: 32px;
      color: #333333;
      flex: 1;
    }

    .detail {
      text-align: center;
    }

    .detail img {
      max-width: 1200px;
      margin: 20px 0px;
      object-fit: cover;
    }

    .detail p {
      text-align: left;
      font-size: 14px;
      line-height: 32px;
      color: #666666;
    }
  }

  @media screen and (max-width: 900px){
    .banner img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    .crumb-wrap {
      width: 96%;
      margin: 0 auto;
      padding: 19px 0px 30px 0px;
    }

    .crumb-wrap .icon {
      margin: 0px 3px;
    }

    .crumb-wrap span {
      font-size: 14px;
      line-height: 19px;
      color: #999999;
    }

    .crumb-wrap .page {
      cursor: pointer;
    }

    .crumb-wrap span:nth-last-child(1) {
      color: #359029;
    }

    .crumb-wrap .page:hover {
      color: #359029;
    }

    .detail {
      width: 98%;
      margin: 0 auto;
      padding-bottom: 28px;
    }

    .detail .title {
      font-size: 23px;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
      text-align: center;
      padding-bottom: 10px;
    }

    .detail .time {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
      text-align: center;
    }

    .type-process {
      padding: 10px 0px 15px 0px;
    }

    .type-process .type,
    .type-process .process {
      display: flex;
    }


    .type-process .sub {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
    }

    .type-process .info {
      font-size: 14px;
      line-height: 32px;
      color: #333333;
      flex: 1;
    }
    .detail img {
      width: 100%!important;
      margin: 20px 0px;
      object-fit: cover;
    }

    .detail p {
      text-align: left;
      font-size: 14px;
      line-height: 32px;
      color: #666666;
    }
    /deep/.detail p span{
      font-size: 14px!important;
    }
    /deep/ .detail img {
      max-width: 94%!important;
      height: auto;
      margin: 10px 3%;
    }
  }
</style>