import { render, staticRenderFns } from "./archivesCode.vue?vue&type=template&id=39c6f95a&scoped=true&"
import script from "./archivesCode.vue?vue&type=script&lang=js&"
export * from "./archivesCode.vue?vue&type=script&lang=js&"
import style0 from "./archivesCode.vue?vue&type=style&index=0&id=39c6f95a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c6f95a",
  null
  
)

export default component.exports