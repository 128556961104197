<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" width="700px" :before-close="handleClose">
		<el-form label-width="120px" label-position="right" style="width: 600px" status-icon>
			<el-form-item label="产品名称">
				<el-input v-model="formData.mainCategoryName" disabled></el-input>
			</el-form-item>
			<el-form-item label="设计产能">
				<el-input v-model="formData.designNumber" disabled></el-input>
			</el-form-item>
			<el-form-item label="正常工作天数">
				<el-input v-model="formData.normalDays" disabled></el-input>
			</el-form-item>
			<el-form-item label="对应日产能">
				<el-input v-model="formData.dayNumber" disabled></el-input>
			</el-form-item>
			<el-form-item label="日期筛选">
				<el-date-picker v-model="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" type="date"
					placeholder="选择日期" @change="changeDate">
				</el-date-picker>
			</el-form-item>
			<div style="color: #67C23A;font-size: 20px;margin: 10px 0;">本日实际生产情况：</div>
			<div v-for="(item,index) in viewData" :key="item.id">
				<el-form-item :label="`产品名称:${index+1}`">
					<el-input v-model="item.mainCapacityName" disabled></el-input>
				</el-form-item>
				<el-form-item :label="`本日产星:${index+1}`">
					<el-input v-model="item.dayNumber" disabled></el-input>
				</el-form-item>
				<el-form-item :label="`单位:${index+1}`">
					<el-input v-model="item.unit" disabled></el-input>
				</el-form-item>
			</div>
			<el-form-item label="生产负荷">
				<el-input v-model="formData.load" disabled></el-input>
			</el-form-item>
			<el-form-item style="display: flex;justify-content: flex-end;">
				<el-button type="primary" @click="handleClose">确定</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			// 弹窗标题
			title: {
				type: String,
				default: "查看记录",
			},
			// 显示弹窗
			dialogVisible: {
				type: Boolean,
				default: false,
			},
			formData: {
				type: Object,
				default: function() {
					return {};
				},
			},
			viewData:{
				type:Array,
				default :function(){
					return []
				}
			}
		},
		components: {},

		data() {
			return {
				date: ""
			};
		},
		watch:{
		},
		created() {},

		mounted() {},

		methods: {
			handleClose() {
				this.$emit("handleViewClose");
			},
			changeDate() {
				this.$emit('changeDate',this.date)
			}
		},
	};
</script>
<style scoped>
</style>
