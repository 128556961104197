import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'sceneImage/'

export default {
    /**
     * 分页
     * @param params
     * @returns {*}
     */
    search(params) {
        return http.post(PREFIX + `search`, params)
    },

    /**
     * 保存
     * @param params
     * @returns {*}
     */
    save(params) {
        return http.post(PREFIX + `save`,params);
    },

    /**
     * 修改
     * @param params
     * @returns {*}
     */
    update(params) {
        return http.put(PREFIX + `update`,params);
    },
    /**
     * 查询结果
     * @param id
     * @returns {*}
     */
    get(id) {
        return http.get(PREFIX + `${id}`);
    },
    /**
     * 删除
     * @param params
     * @returns {*}
     */
    remove(params) {
        return http.delete(PREFIX + `delete`, params);
    },
}
