import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'hazardousNameplate/'

export default {
    /**
     * 分页
     * @param params
     * @param params
     * @returns {*}
     */
    search(params) {
        return http.post(PREFIX + `search`, params)
    },

    /**
     * 导出excel
     * @param params
     * @returns {*}
     */
    exportExcel(params) {
        return http.postDownload(PREFIX + `exportOptionRecord`, params);
    },

    /**
     * 查询排污口详情
     * @returns {*}
     * @param id
     */
    get(id) {
        return http.get(PREFIX + `${id}`);
    },

    /**
     * 修改厂区照片
     * @param params
     * @returns {*}
     */
    updateSignalImage(params) {
        return http.get(PREFIX + `updateAddressImage`, params);
    },

    /**
     * 查询生产工艺流程
     * @param params
     * @returns {*}
     */
    getProcess(id) {
        return http.get(PREFIX + `getProcess?id=` + `${id}`);
    },

    /**
     * 查询生产工艺流程列表
     * @param params
     * @returns {*}
     */
    processList() {
        return http.get(PREFIX + `processList`);
    },

    /**
     * 编辑生产工艺流程
     * @param params
     * @returns {*}
     */
    updateProductProcess(params) {
        return http.put(PREFIX + `updateProductProcess`, params);
    },

    /**
     * 查询近期检测报告
     * @param params
     * @returns {*}
     */
    getCheckFile(id) {
        return http.get(PREFIX + `getCheckFile?id=` + `${id}`);
    },

    /**
     * 查询近期检测报告列表
     * @param params
     * @returns {*}
     */
    checkFileList() {
        return http.get(PREFIX + `checkFileList`);
    },

    /**
     * 编辑近期检测报告
     * @param params
     * @returns {*}
     */
    updateCheckFile(params) {
        return http.put(PREFIX + `updateCheckFile`,  params);
    },


    /**
     * 删除
     * @param params
     * @returns {*}
     */
    remove(params) {
        return http.delete(PREFIX + `delete`,  params);
    },

    /**
     * 排放口详情
     * @param params
     * @returns {*}
     */
    getDetail(id) {
        return http.get(PREFIX + `getDetail?dischargePortId=` +  `${id}`);
    },

    /**
     * 排放口编辑
     * @param params
     * @returns {*}
     */
    update(params) {
        return http.put(PREFIX + `update`,  params);
    },
	updateName(params){
		return http.get(PREFIX + `updateName`, params);
	},
	updatePhone(params){
		return http.get(PREFIX + `updatePhone`, params);
	},
	getQrcode(params){
		return http.get(PREFIX + `getQrcode`, params);
	},
	getWareHouseList(){
		return http.get(PREFIX + `getWareHouseList`);
	},
	getWareHouseDetailById(params){
		return http.get(PREFIX + `getWareHouseDetailById`,params);
	}
}
