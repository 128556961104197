<template>
  <el-drawer
      :visible.sync="visible"
      :before-close="handleClose">
    <div class="identification container" v-if="visible">
      <div class="top">
        <p>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.companyName }}</p>
        <p>电子标示{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.code }}</p>
        <p>安徽一二三环保科技有限公司</p>
      </div>
      <div class="main">
        <div class="title">
          <div class="number">01</div>
          <p>企业基础信息</p>
        </div>
        <div class="info">
          <ul>
            <li>单位名称:</li>
            <li>{{dischargePortDetail.corpData && dischargePortDetail.corpData.companyName }}</li>
          </ul>
          <ul>
            <li>法定代表人:</li>
            <li>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.legalPerson }}</li>
          </ul>
          <ul>
            <li>统一社会信用代码:</li>
            <li>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.licenseNumber }}</li>
          </ul>
          <ul>
            <li>单位住址:</li>
            <li>{{ dischargePortDetail.corpData && dischargePortDetail.corpData.address }} </li>
          </ul>
          <ul>
            <li>全厂排污口分布示意图:</li>
            <li><img :src="dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.signalImage" alt=""></li>
          </ul>
        </div>
        <div class="title">
          <div class="number">02</div>
          <p>电子标识牌</p>
        </div>
        <div class="signboard">
          <ul>
            <li>标识牌类别:</li>
            <li><img :src="dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.cover" alt=""></li>
            <li>{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.name }}-{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.code }}</li>
          </ul>
          <table class="table" >
            <tr>
              <th colspan="3">{{ dischargePortDetail.corpData && dischargePortDetail.corpData.companyName }}-{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.code }}</th>
            </tr>
            <tr>
              <td>排污口类型</td>
              <td colspan="2">{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.portType.desc }}</td>
            </tr>
            <tr>
              <td>排放口参数</td>
              <td>{{ '高度:' + (dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.portHeight) + 'M' || "暂无"}}</td>
              <td>{{ '内径:' + (dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.portInradium) + 'M' || "暂无"}}</td>
            </tr>
            <tr>
              <td>污染源生产工序</td>
              <td colspan="2">{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.pollutionProductProcess }}</td>
            </tr>
            <tr>
              <td>特征污染物</td>
              <td colspan="2"><span v-for="(item, index) in dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.dischargePortCpList"><span v-if="index > 0">,</span>{{item.name}}</span></td>
            </tr>
            <tr>
              <td>前端治理工艺</td>
              <td colspan="2">{{ dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.treatmentProcess }}</td>
            </tr>
            <tr>
              <td>排污许可要求检测频次</td>
              <td colspan="2"><span v-for="(item, index) in dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.dischargePortCpList"><span v-if="index > 0">,</span>{{item.name}}{{item.monitorData}}{{item.monitorUnit.key === 'OTHER' ? item.monitorOtherUnit : item.monitorUnit.data}}</span></td>
            </tr>
            <tr>
              <td>浓度限制</td>
              <td colspan="2"><span v-for="(item, index) in dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.dischargePortCpList"><span v-if="index > 0">,</span>{{item.name}}{{item.limitData}}{{ item.limitUnit.key === 'OTHER' ? item.limitOtherUnit : item.limitUnit.data}}</span></td>
            </tr>
            <tr>
              <td>近期检测报告</td>
              <td colspan="2"><span v-for="(item, index) in dischargePortDetail.checkFileData"><span v-if="index > 0">,</span>{{item.submittedFileName}}</span></td>
            </tr>
          </table>
          <div class="step-box">
            <hr>
            <div class="step">1、对应生产工艺流程</div>
          </div>
          <div class="technological_process" v-if="dischargePortDetail.processData">
            <p>{{dischargePortDetail.dischargePortData && dischargePortDetail.dischargePortData.productProcessRemark}}</p>
            <p>
              {{dischargePortDetail.processData && dischargePortDetail.processData.title}}
            </p>
            <div ><img :src="dischargePortDetail.processData && dischargePortDetail.processData.image" alt=""></div>
          </div>
          <div v-else>正在完善...请期待!</div>
          <div class="step-box">
            <hr>
            <div class="step">2、对应近期检测报告</div>
          </div>
          <div class="file-box" v-if="dischargePortDetail.checkFileData">
            <div class="file" v-for="checkFile in dischargePortDetail.checkFileData">
              <a :href="checkFile.url">
                <ul class="img-box">
                  <img v-if="checkFile.ext === 'png'" src="../../../assets/image/icon/icon_png.png" alt="">
                  <img v-else-if="checkFile.ext === 'pdf'" src="../../../assets/image/icon/icon-pdf.png" alt="">
                  <img v-else-if="checkFile.ext === 'word'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="checkFile.ext === 'docx'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="checkFile.ext === 'jpg'" src="../../../assets/image/icon/icon_jpg.png" alt="">
                  <img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
                </ul>
                <ul>
                  <li class="submittedFileName">{{checkFile.submittedFileName}}</li>
                  <li>{{ (checkFile.size / 1024).toFixed(2)}}kb</li>
                </ul>
              </a>
            </div>
          </div>
          <div v-else>正在完善...请期待!</div>
          <div class="step-box">
            <hr>
            <div class="step">3、对应排污许可证</div>
          </div>
          <div class="file-box" v-if="dischargePortDetail.premissionFileData">
            <div class="file" v-for="file in dischargePortDetail.premissionFileData">
              <a :href="file.url">
                <ul class="img-box">
                  <img v-if="file.ext === 'png'" src="../../../assets/image/icon/icon_png.png" alt="">
                  <img v-else-if="file.ext === 'pdf'" src="../../../assets/image/icon/icon-pdf.png" alt="">
                  <img v-else-if="file.ext === 'word'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="file.ext === 'docx'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="file.ext === 'jpg'" src="../../../assets/image/icon/icon_jpg.png" alt="">
                  <img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
                </ul>
                <ul>
                  <li class="submittedFileName">{{file.submittedFileName}}</li>
                  <li>{{ (file.size / 1024).toFixed(2)}}kb</li>
                </ul>
              </a>
            </div>
          </div>
          <div v-else>正在完善...请期待!</div>
          <div class="step-box">
            <hr>
            <div class="step">4、对应排污口照片</div>
          </div>
          <div class="swiper" v-if="imgList.length !== 0">
            <el-carousel :interval="4000" type="card" height="200px">
              <el-carousel-item v-for="img in imgList">
                <img :src="img" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div v-else>正在完善...请期待!</div>
        </div>
        <div class="bottom">
          <div class="logo-box">
            <p class="corpContent" v-if="dischargePortDetail.corpContent !== null" v-html="dischargePortDetail.corpContent && dischargePortDetail.corpContent.value"></p>
            <p class="time">本排污口电子标识制作时间:{{ date }}</p>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import DischargePortApi from "@/api/ep/dischargePort"
import moment from "moment";
export default {
  name: "detail",
  data(){
    return{
      visible:false,
      id:'',
      dischargePortDetail:{},
      imgList:[],
      date:''
    }
  },
  mounted(){

  },
  methods:{
    init(id){
      this.id=id
      this.visible=true
      this.getDischargePortDetail(id)
    },
    handleClose(){
      this.visible=false
    },
    getDischargePortDetail(id){
      DischargePortApi.getDetail(id).then(res => {
        // console.log(res)
        // let eepaData = res.data.eepaData
        let date = res.data.corpData.createTime
        this.date = moment(date).format("YYYY年MM月DD日");
        this.dischargePortDetail = res.data || {}
        this.setSwiperImage()
      })
    },
    setSwiperImage(){
      if (this.dischargePortDetail.dischargePortData.image !== ''){
        let image = this.dischargePortDetail.dischargePortData.image
        this.imgList = image.split(',')
      }
    }
  }
}
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
img{
  width: 100%;
  height: 100%;
}
.container{
  width:375px;
  margin: 0 auto;
}
.identification .top{
  background: #20985A;
  width: 100%;
  height: 150px;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 33px;
}
.main{
  padding: 30px 16px;
  border: 1px solid;
}
.main .title{
  display: flex;
  justify-content: flex-start;
  height: 48px;
  align-items: center;
  background: #C7E5D6;
  color: #20985A;
}
.main .title .number{
  width: 48px;
  height: 48px;
  background: #20985A;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 48px;
  color: #FFFFFF;
}
.main .title p{
  padding-left: 15px;
}
.main .info ul{
  margin-top: 30px;
  color: #333333;
}
.main .info ul li:nth-child(1){
  font-weight: bold;
  margin-bottom: 5px;
  color: #000000;
}
.signboard {
  margin-top: 30px;
}
.signboard ul{
  margin-bottom: 15px;
}
.signboard ul:nth-child(1) li:nth-child(1){
  font-weight: bold;
  margin-bottom: 5px;
  color: #000000;
  text-align: left;
}
.signboard ul:nth-child(1) li:nth-child(2){
  width: 120px;
  height: 120px;
  margin-top: 20px;
}
.signboard ul:nth-child(1) li:nth-child(3){
  margin-top: 10px;
}
.signboard ul:nth-child(1) li:nth-child(2) img{
  border-radius: 8px;
}
.signboard ul:nth-child(1) li{
  margin: 0 auto;
  text-align: center;
}
table{
  border-collapse: collapse;
  color: #000000;
  margin-bottom: 40px;
}
table,table tr td {
  border:1px solid #ccc;
}
.table th{
  font-weight: bold;
  background: #F0F2F5;
  font-size: 16px;
}
.table th, .table td{
  width: 33%;
  padding: 12px 10px;
}
.table td{
  font-size: 12px;
}
.step-box{
  position: relative;
  margin: 40px 0;
}
.step{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 37px;
  width: 214px;
  border-radius: 4px;
  background: #20985A;
  color: #FFFFFF;
  text-align: center;
  line-height: 37px;
  z-index: 10;
}
.file-box{
  box-shadow: #f1f0f0 0 0 8px 3px;
  border-radius: 6px;
  padding: 16px;
  width: 90%;
  margin: 0 auto ;
}
.file-box .file a{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #CCCCCC;
}
.file-box .file:last-child a{
  border: none;
}
.file-box .file .img-box{
  width: 39px;
  height: 48.5px;
}
.file-box .file ul:nth-child(2){
  padding-right: 30px;
  margin-left: 10px;
  width: calc(100% - 50px);
}
.file-box .file ul:nth-child(2) li:nth-child(2){
  font-size: 12px;
}
.file-box .submittedFileName{
  word-wrap: break-word;
}
.logo-box{
  border-top: 1px solid #20985A;
  margin: 50px 0 40px;
  padding-top: 30px;
}
.logo-box p{
  color: #000;
  padding: 10px 0;
}
.logo-box .time{
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  color: #000000;
}
/deep/.corpContent img{
  max-width: 100% !important;
}
/*.logo-box .logo{*/
/*  width: 78px;*/
/*  height: 78px;*/
/*  margin: 25px auto 10px;*/
/*}*/
/*.logo-box h4{*/
/*  font-size: 16px;*/
/*  text-align: center;*/
/*  color: #000000;*/
/*}*/
/*.bottom ul{*/
/*  margin: 20px 0;*/
/*}*/
/*.bottom ul li:nth-child(1){*/
/*  color: #000000;*/
/*}*/
/*.bottom p{*/
/*  text-align: center;*/
/*  margin-top: 15px;*/
/*}*/
/*.bottom .number{*/
/*  text-align: center;*/
/*  color: #000000;*/
/*  margin: 40px 0;*/
/*}*/
/*.bottom h6 span{*/
/*  color: #666666;*/
/*}*/
/*.bottom h5{*/
/*  text-align: center;*/
/*}*/
/*.bottom .QR-code{*/
/*  width: 114px;*/
/*  height: 114px;*/
/*  margin: 30px auto;*/
/*}*/
/*.bottom h1{*/
/*  text-align: center;*/
/*}*/
</style>
