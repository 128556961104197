import Regular from '@/utils/regular'
// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const SelfTestReportSearchConfig = function () {
  return {
    list: [
      {
        label: '污染源',
        key: 'portType',
        type: 'select',
        placeholder: '请输入污染源名称'
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
      selects: {
        portType: [
          {text: '废气污染', value: 'EXHAUSR'},
          {text: '污水排放', value: 'SEWAGE'},
          {text: '雨水排放', value: 'RAIN'},
          {text: '噪音排放', value: 'NOISE'},
          {text: '危险品排放', value: 'DANGER'}]
      },
      model: {}
    }
  }
}

// 编辑对象配置
export const SelfTestReportEditConfig = function () {
  return {
    list: [
      {
        label: '标准名称',
        key: 'standardName',
        type: 'input',
        placeholder: "请输入"
      },
      {
        label: '检测设备',
        key: 'testEquipment',
        type: 'input',
        placeholder: '请输入'
      },
      {
        label: '检测方法',
        key: 'testMethod',
        type: 'input',
        placeholder: '请输入'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        standardName: '',
        testEquipment: '',
        testMethod: ''
      },
      rules: {},
      selects: {}
    }
  }
}

// 导出对象配置
export const SelfTestReportExportConfig = function () {
  return {
    list: [
      {
        label: '标准名称',
        prop: 'standardName'
      },
      {
        label: '检测设备',
        prop: 'testEquipment'
      },
      {
        label: '检测方法',
        prop: 'testMethod'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        standardName: '',
        testEquipment: '',
        testMethod: ''
      },
      rules: {},
      selects: {}
    }
  }
}
