import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'corpCard/'

export default {
  /**
   * 分页
   * @param params
   * @returns {*}
   */
  search(params) {
    return http.post(PREFIX + `search`, params)
  },
  /**
   * 查询结果
   * @param id
   * @returns {*}
   */
  get(id) {
    return http.get(PREFIX + `${id}`);
  },
  /**
   * 保存
   * @param params
   * @returns {*}
   */
  save(params) {
    return http.post(PREFIX + `save`,params);
  },

  /**
   * 修改
   * @param params
   * @returns {*}
   */
  update(params) {
    return http.put(PREFIX + `update`,params);
  },

  /**
   * 批量删除
   * @param params
   * @returns {*}
   */
  batchDelete(params) {
    return http.delete(PREFIX + `batchDelete`, params);
  },

  /**
   * 查询列表
   * @param params
   * @returns {*}
   */
  list(params) {
    return http.post(PREFIX + `list`, params || {})
  },
}
