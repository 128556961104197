<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      labelWidth="120px"
      :title="`企业现有环保档案--${dataName?'编辑':'新增'}`"
  />
</template>

<script>
import CorpDataApi from "@/api/ep/corpData"
import {AddConfig, EditConfig} from './config.js'
import {mergeModelValue} from "@/utils/commons"

export default {
  data() {
    return {
      visible: false,
      formConfig: AddConfig.bind(this)(),
      dataName: '',
      tableData: []
    }
  },
  methods: {
    init(dataName) {
      this.dataName = dataName || ''
      this.visible = true
      this.formConfig = dataName ? EditConfig.bind(this)() : AddConfig.bind(this)()
      this.listDataName(dataName)
      if (!dataName) {
        return
      }
      CorpDataApi.getEepaByDataName({dataName: this.dataName}).then((res) => {
        if (!res.isSuccess) {
          return
        }
        this.tableData = res.data
        if (this.tableData.length === 5) {
          const model = {}
          for (let i = 0; i < this.tableData.length; i++) {
            let data = this.tableData[i]
            switch (data.data.data.dataName) {
              case '立项材料':
                model.dataId1 = data.data.key
                model.id1 = data.id
                model.dataYear1 = data.dataYearTime
                model.filingCopy1 = data.filingCopy
                break;
              case '环境影响评价':
                model.dataId2 = data.data.key
                model.id2 = data.id
                model.dataYear2 = data.dataYearTime
                model.filingCopy2 = data.filingCopy
                break;
              case '环评批复':
                model.dataId3 = data.data.key
                model.id3 = data.id
                model.dataYear3 = data.dataYearTime
                model.filingCopy3 = data.filingCopy
                break;
              case '环保竣工验收':
                model.dataId4 = data.data.key
                model.id4 = data.id
                model.dataYear4 = data.dataYearTime
                model.filingCopy4 = data.filingCopy
                break;
              case '验收意见':
                model.dataId5 = data.data.key
                model.id5 = data.id
                model.dataYear5 = data.dataYearTime
                model.filingCopy5 = data.filingCopy
                break;
            }
          }
          model.dataName = dataName
          mergeModelValue(this.formConfig.config.model, model)
        } else {
          const data = res.data[0]
          data.dataName = dataName
          const model = this.formConfig.config.model
          mergeModelValue(model, data)
          model.dataYear = data.dataYearTime
        }
        let field = this.formConfig.config.field
        if (res.data.length === 5) {
          field.dataYear.show = false
          field.filingCopy.show = false
          field.dataYear1.show = true
          field.filingCopy1.show = true
          field.dataYear2.show = true
          field.filingCopy2.show = true
          field.dataYear3.show = true
          field.filingCopy3.show = true
          field.dataYear4.show = true
          field.filingCopy4.show = true
          field.dataYear5.show = true
          field.filingCopy5.show = true
        } else {
          field.dataYear.show = true
          field.filingCopy.show = true
          field.dataYear1.show = false
          field.filingCopy1.show = false
          field.dataYear2.show = false
          field.filingCopy2.show = false
          field.dataYear3.show = false
          field.filingCopy3.show = false
          field.dataYear4.show = false
          field.filingCopy4.show = false
          field.dataYear5.show = false
          field.filingCopy5.show = false
        }
      })
    },
    async getChildData(id) {
      await CorpDataApi.getDataChild({id: id}).then(res => {
        if (!res.isSuccess) {
          return
        }
        this.tableData = res.data
      })
    },
    async changeDataName(v2) {
      let field = this.formConfig.config.field
      this.tableData = []
      await this.getChildData(v2)
      if (this.tableData.length === 5) {
        field.dataYear.show = false
        field.filingCopy.show = false
        field.dataYear1.show = true
        field.filingCopy1.show = true
        field.dataYear2.show = true
        field.filingCopy2.show = true
        field.dataYear3.show = true
        field.filingCopy3.show = true
        field.dataYear4.show = true
        field.filingCopy4.show = true
        field.dataYear5.show = true
        field.filingCopy5.show = true
      } else {
        field.dataYear.show = true
        field.filingCopy.show = true
        field.dataYear1.show = false
        field.filingCopy1.show = false
        field.dataYear2.show = false
        field.filingCopy2.show = false
        field.dataYear3.show = false
        field.filingCopy3.show = false
        field.dataYear4.show = false
        field.filingCopy4.show = false
        field.dataYear5.show = false
        field.filingCopy5.show = false
      }
    },
    listDataName(dataName) {
      if (!dataName) {
        CorpDataApi.listDataName().then((res) => {
          if (!res.isSuccess) {
            return
          }
          this.formConfig.config.selects['dataName'] = res.data
        })
      } else {
        this.formConfig.config.model['dataName'] = dataName
      }
    },
    handleChange(key, value) {
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    //字段转换
    changeField(result) {
      const eepaList = []
      if (this.tableData.length === 5) {
        for (let i = 0; i < this.tableData.length; i++) {
          let id = `id${i + 1}`
          let dataId = `dataId${i + 1}`
          let dataYear = `dataYear${i + 1}`
          let filingCopy = `filingCopy${i + 1}`
          let data = ''
          switch (i + 1) {
            case 1:
              data = '立项材料'
              break;
            case 2:
              data = '环境影响评价'
              break;
            case 3:
              data = '环评批复'
              break;
            case 4:
              data = '环保竣工验收'
              break;
            case 5:
              data = '验收意见'
              break;
          }
          let eepa = {
            dataName: data,
            dataId: result[dataId],
            dataYear: result[dataYear],
            filingCopy: result[filingCopy],
            id: result[id]
          }
          eepaList.push(eepa)
        }
      } else {
        let eepa = {
          id: result.id,
          dataYear: result.dataYear,
          filingCopy: result.filingCopy
        }
        eepaList.push(eepa)
      }
      return eepaList
    },
    handleSubmit(result) {
      //字段转换
      const eepaList = this.changeField(result)
      if (!this.dataName) {
        CorpDataApi.updateEepa({'eepaList': eepaList, 'id': result.dataName}).then((res) => {
          if (!res.isSuccess) {
            return
          }
          this.visible = false
          this.$message.success('保存成功')
          this.$emit('submitSuccess')
        })
        return false
      }

      CorpDataApi.updateEepaById(eepaList).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.visible = false
        this.$message.success('编辑成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>

</style>
