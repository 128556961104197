<template>
  <div class="wrap register-wrap">
    <div class="banner">
      <img src="../assets/image/login-mini.png" alt="">
    </div>
    <div class="form">
      <div class="form-box">
        <el-row>
          <img src="../assets/image/logo-mini.png" alt="" class="logo">
          <p class="logo-text" style="margin-top: 20px">欢迎登录一二三环保平台</p>
        </el-row>
        <el-form ref="form" :form="form" :inline-message="true" class="el-form">
          <el-row>
            <el-col>
              <el-form-item>
                <el-input v-model="form.mobile" placeholder="输入联系方式"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <div style="position: relative;">
                <el-form-item>
                  <el-input v-model="form.img" placeholder="输入图形验证码" style="flex:1"></el-input>
                  <div class="img-code">
                    <img :src="imageSrc" alt="" @click.stop="getCodeImage">
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col>
              <div style="position: relative;">
                <el-form-item>
                  <el-input v-model="form.code" placeholder="输入验证码" style="flex:1"></el-input>
                  <div class="img-code">
                    <el-button size="small" @click="getMessageCode" :disabled="disabled">
                      {{ disabled ? `剩余${count}S` : '获取验证码' }}
                    </el-button>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col>
              <el-form-item>
                <el-input type="password" v-model="form.password" placeholder="输入新密码"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item>
                <el-input type="password" v-model="form.confirmPassword" placeholder="确认新密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-button class="submit" @click="onsubmit">提交</el-button>
            <p class="tip">已有账号？<span @click="toLogin">去登录！</span></p>
          </el-row>

        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {randomNum} from '@/utils/index'
import verificationApi from '@/api/ep/verification'
import LoginApi from '@/api/uc/login'
import UserApi from '@/api/uc/user'

export default {
  name: 'register',
  data() {
    return {
      disabled: false,
      count: 60,
      form: {
        mobile: '',
        img: '',
        code: '',
        password: '',
        confirmPassword: '',
        key: randomNum(24, 16)
      },
      imageSrc: ''
    }
  },
  created() {
    this.getCodeImage()
  },
  components: {},
  methods: {
    toLogin() {
      this.$router.push('/login')
    },
    //获取验证码
    getMessageCode() {
      if (!(this.form.mobile && /^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.form.mobile))) {
        this.$message.error('请输入正确格式的手机号')
        return
      }
      if (!this.form.img) {
        this.$message.error('请输入图形验证码')
        return
      }
      this.disabled = true
      let params = {
        mobile: this.form.mobile,
        type: 'REGISTER_USER',
        changeType:'FORGET',
        code: this.form.img,
        key: this.form.key
      }
      this.tt = setInterval(() => {
        if (this.count <= 0) {
          clearInterval(this.tt)
          this.disabled = false
          this.count = 60
        }
        this.count = this.count - 1
      }, 1000)


      verificationApi.send(params).then(res => {
        if (res.isSuccess) {
          this.$message.success('验证码已发送')
          return
        }
        clearInterval(this.tt)
        this.disabled = false
        this.count = 60
      }).catch(() => {
        clearInterval(this.tt)
        this.disabled = false
        this.count = 60
      })
    },
    getCodeImage() {
      LoginApi.getCaptcha(this.form.key).then(res => {
        res = res.data
        if (res.byteLength <= 100) {
          this.$message({
            message: this.$t('tips.systemError'),
            type: 'error'
          })
        }
        return 'data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))
      }).then(res => {
        this.imageSrc = res
      }).catch(e => {
        if (e.toString().indexOf('429') !== -1) {
          this.$message({
            message: '请求次数太多',
            type: 'error'
          })
        } else {
          this.$message({
            message: '获取图形验证码失败',
            type: 'error'
          })
        }
      })
    },
    onsubmit(){
      if(!this.form.mobile) {
        this.$message.error('请输入手机号码')
        return
      }
      if(!this.form.img) {
        this.$message.error('请输入图形验证码')
        return
      }
      if(!this.form.code) {
        this.$message.error('请输入验证码')
        return
      }
      if(!this.form.password) {
        this.$message.error('请输入密码')
        return
      }
      if(!this.form.confirmPassword) {
        this.$message.error('请输入确认密码')
        return
      }
      if(this.form.confirmPassword != this.form.password ) {
        this.$message.error('请保持密码一致')
        return
      }
      UserApi.updatePwd(this.form).then(res => {
        this.getCodeImage()
        if (!res.isSuccess) {
          return
        }
        this.$message.success('重置成功')
        this.$router.push('/login')
      })

    }
  }
}
</script>

<style scoped>
.img-code {
  position: absolute;
  top: -2px;
  right: 0;
}
.wrap {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.banner{
  width: 50%;
}
.form {
  flex: 1;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.form {
  transform: translateY(-5vh);
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  display: block;
  width: 140px;
  margin: 0 auto;
}

.logo-text {
  margin-top: -10px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  line-height: 30px;
  color: #333333;
  text-align: center;
}

.submit {
  margin-top: 7vh;
  display: block;
  width: 100%;
  background-color: #359029;
  color: #fff;
  box-shadow: 0px 7px 22px rgba(103, 175, 94, 0.44);
}

.el-form {
  margin-top: 15vh;
}

.form-box {
  max-width: 60%;
}

.tip {
  margin-top: 10px;
  height: 13px;
  color: #999999;
  text-align: center;
}

.tip span {
  color: #359029;
}

.tip span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.check-code {
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  border: 1px solid #359029;
  color: #359029;
  font-size: 12px;
  cursor: pointer;
}
</style>
<style>
.register-wrap .el-input__inner {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #DCDFE6;
}

.register-wrap .el-input__inner:focus {
  border-bottom: 1px solid #359029;
}

.register-wrap .el-form-item {
  margin-bottom: 30px;
}

.register-wrap .el-upload__tip {
  margin-top: 10px;
  line-height: 20px;
}
.tip {
  margin-top: 15px;
  height: 13px;
  color: #999999;
  text-align: center;
}

.tip span {
  color: #359029;
}

.tip span:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
