<template>
  <div class="components">
    <div class="title">生产设备台账</div>
    <div class="content">
      <hm-search-form ref="searchForm" :data="searchConfig" label-width="150" size="normal"/>
      <div class="btns-box">
        <el-button type="primary" @click="exportExcel">导出</el-button>
        <el-button type="primary" @click="oneMonth">近一个月数据</el-button>
        <el-button type="primary" @click="threeMonth">近三个月数据</el-button>
        <el-button type="primary" @click="sixMonth">近半年数据</el-button>
        <el-button type="primary" @click="oneYear">近一年数据</el-button>
      </div>
      <div style="margin-top: 20px;">
        <hm-table ref="table"
                  :stripe="true"
                  :border="true"
                  :header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}"
                  :api="listRequest"
                  :table-config="tableConfig"
                  :params="searchParams.params">
          <div slot="signImg" slot-scope="row">
            <el-image style="width: 100px; height: 100px" :src="row.data.signImg"></el-image>
          </div>
        </hm-table>
      </div>
    </div>
  </div>
</template>

<script>
import OptionRecordApi from "@/api/ep/optionRecord"
import {SearchConfig, TableConfig} from "../config/optionRecord";
import {downloadBlobFile, mergeJson} from '@/utils/commons'
import moment from 'moment'

export default {
  name: "employee",
  data() {
    return {
      listRequest: OptionRecordApi.search,
      searchConfig: SearchConfig.bind(this)(),
      tableConfig: TableConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {
          model: {
            optionType: "EQUIPMENT"
          }
        },
        // table下拉条件
        tableParams: {},
      },
    }
  },
  methods: {
    exportExcel() {
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams) || {}
      if (!params.extend) {
        params.extend = {}
      }
      params.extend.fileName = '生产设备台账'
      OptionRecordApi.exportExcel(params).then((data) => {
        downloadBlobFile(data)
      })
    },
    //近一个月数据
    oneMonth() {
      const dates = new Date()
      dates.setMonth(dates.getMonth() - 1)
      var pastMonth = dates.getMonth() + 1
      var pastDay = dates.getDate()
      if (pastMonth >= 1 && pastMonth <= 9) {
        pastMonth = '0' + pastMonth
      }
      if (pastDay >= 0 && pastDay <= 9) {
        pastDay = '0' + pastDay
      }
      const endDate = dates.getFullYear() + '-' + pastMonth + '-' + pastDay
      const data = {
        model:{}
      }
      data.model.startTime = endDate
      data.model.endTimt = moment().format('YYYY-MM-DD');
      this.handleSearch(data)
    },
    //近三个月数据
    threeMonth() {
      const dates = new Date()
      dates.setMonth(dates.getMonth() - 3)
      var pastMonth = dates.getMonth() + 1
      var pastDay = dates.getDate()
      if (pastMonth >= 1 && pastMonth <= 9) {
        pastMonth = '0' + pastMonth
      }
      if (pastDay >= 0 && pastDay <= 9) {
        pastDay = '0' + pastDay
      }
      const endDate = dates.getFullYear() + '-' + pastMonth + '-' + pastDay
      const data = {
        model:{}
      }
      data.model.startTime = endDate
      data.model.endTimt = moment().format('YYYY-MM-DD');
      this.handleSearch(data)
    },
    //近半年数据
    sixMonth() {
      const dates = new Date()
      dates.setMonth(dates.getMonth() - 6)
      var pastMonth = dates.getMonth() + 1
      var pastDay = dates.getDate()
      if (pastMonth >= 1 && pastMonth <= 9) {
        pastMonth = '0' + pastMonth
      }
      if (pastDay >= 0 && pastDay <= 9) {
        pastDay = '0' + pastDay
      }
      const endDate = dates.getFullYear() + '-' + pastMonth + '-' + pastDay
      const data = {
        model:{}
      }
      data.model.startTime = endDate
      data.model.endTimt = moment().format('YYYY-MM-DD');
      this.handleSearch(data)
    },
    //近一年数据
    oneYear() {
      const dates = new Date()
      dates.setMonth(dates.getMonth() - 12)
      var pastMonth = dates.getMonth() + 1
      var pastDay = dates.getDate()
      if (pastMonth >= 1 && pastMonth <= 9) {
        pastMonth = '0' + pastMonth
      }
      if (pastDay >= 0 && pastDay <= 9) {
        pastDay = '0' + pastDay
      }
      const endDate = dates.getFullYear() + '-' + pastMonth + '-' + pastDay
      const data = {
        model:{}
      }
      data.model.startTime = endDate
      data.model.endTimt = moment().format('YYYY-MM-DD');
      this.handleSearch(data)
    },
    handleSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.$refs.table.reload(params)
    },
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}

.table-operation {
  margin: 0 5px;
}
</style>
<style>
.el-range-editor.is-active{
  border-color: #359029 !important;
}
</style>