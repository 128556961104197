<template>
  <div class="about-root">
    <Head ref="header"></Head>
    <div class="banner">
      <!--      <img src="@/assets/image/about.png">-->
      <img :src="bannerPath">
    </div>
    <div class="about">
      <div class="about-wrap">
        <div class="about-contain">
          <div class="about-l">
            <div class="title-1">Profile and Organization</div>
            <div class="content">{{aboutData.detail}}</div>
          </div>
          <div class="about-r">
            <div class="title-2">走进我们</div>
            <div class="experience">{{aboutData.experience}}</div>
          </div>
        </div>
        <div class="pic"><img :src="aboutData.content"></div>
      </div>
    </div>
    <div class="team">
      <div class="team-wrap">
        <div class="title">团队介绍</div>
        <div class="person-list">
          <div class="person-item" v-for="(item,index) in userData" :key="index">
            <div class="pic"><img :src="item.coverUrl"></div>
            <div class="name">{{item.title}}</div>
            <div class="post">{{item.subtitle}}</div>
            <div class="info">{{item.summary}}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Head from '@/components/head'
  import Footer from '@/components/footer'
  import HomeBannerApi from '@/api/ep/homeBanner'
  import HomeAboutApi from '@/api/ep/homeAbout'
  import HomeArticleApi from '@/api/ep/homeArticle'

  export default {
    name: "about",
    data() {
      return {
        activeIndex: 1,
        aboutData: '',
        bannerPath: '',
        userData: []
      }
    },
    created() {
    },
    mounted() {
      this.getBannerData()
      this.getContactData()
      this.getUserData()
    },
    methods: {
      //banner图数据
      getBannerData() {
        let params = {
          dictionary: 'WITHUS'
        }
        HomeBannerApi.list(params).then((res) => {
          this.bannerPath = res.data[0].fileId
        })
      },
      getContactData() {
        let params = {}
        HomeAboutApi.getInfo(params).then((res) => {
          this.aboutData = res.data
        })
      },
      getUserData() {
        let params = {
          type: {
            code: 'TEAM'
          }
        }
        HomeArticleApi.list(params).then((res) => {
          this.userData = res.data
        })
      }
    },
    components: {Head, Footer}
  }
</script>

<style scoped>
  @media screen and (min-width: 900px) {
    .banner img {
      width: 100%;
      height: 475px;
      object-fit: cover;
    }

    .about-wrap {
      width: 1200px;
      margin: 0 auto;
      padding-top: 20px;
    }

    .about-contain {
      padding-bottom: 20px;
    }

    .about-contain .about-l {
      display: flex;
    }

    .about-contain .about-r {
      display: flex;
    }

    .about-contain .title-1 {
      font-size: 24px;
      font-family: Arial;
      line-height: 35px;
      color: #333333;
      width: 270px;

    }

    .about-contain .title-2 {
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      width: 270px;
    }

    .about-contain .about-r {
      flex: 1;
      padding-top: 50px;
      font-size: 14px;
      line-height: 28px;
      color: #888888;
    }

    .about-contain .about-l .content {
      padding: 0px 20px 0px 160px;
      line-height: 35px;
      flex: 1;
      /*overflow:hidden;*/
      /*text-overflow:ellipsis;*/
      /*display:-webkit-box;*/
      /*-webkit-box-orient:vertical;*/
      /*-webkit-line-clamp:2;*/
    }

    .about-contain .about-r .experience {
      padding: 0px 20px 0px 160px;
      flex: 1;
      /*overflow:hidden;*/
      /*text-overflow:ellipsis;*/
      /*display:-webkit-box;*/
      /*-webkit-box-orient:vertical;*/
      /*-webkit-line-clamp:2;*/
    }

    .about-wrap .pic {
      width: 1200px;
      height: 433px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .about-wrap .pic img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .team-wrap {
      width: 1200px;
      margin: 0 auto;
      padding: 50px 0px 20px 0px;
    }

    .team-wrap .title {
      font-size: 24px;
      line-height: 32px;
      color: #000000;
    }

    .person-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;
    }

    .person-list .person-item {
      width: 339px;
      height: 436px;
      background: #FFFFFF;
      box-shadow: 0px 3px 18px rgba(113, 113, 113, 0.16);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 90px;
      margin-bottom: 40px;
      cursor: pointer;
      transition: 1s;
    }

    .person-list .person-item:nth-child(3n+3) {
      margin-right: unset;
    }

    .person-list .person-item .pic {
      width: 146px;
      height: 146px;
      border-radius: 50%;
      margin-top: 33px;
      box-sizing: border-box;
    }

    .person-list .person-item .pic img {
      width: 146px;
      height: 146px;
      object-fit: cover;
      border-radius: 50%;
      box-sizing: border-box;
    }

    .person-list .person-item .name {
      font-size: 20px;
      line-height: 32px;
      color: #000000;
      padding: 25px 34px 0px;
    }

    .person-list .person-item .post {
      font-size: 16px;
      line-height: 32px;
      color: #333333;
      padding: 10px 34px 0px;
    }

    .person-list .person-item .info {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
      text-align: center;
      padding: 20px 34px 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }

    .person-list .person-item:hover {
      box-shadow: 0px 14px 24px rgba(81, 181, 68, 0.17);
    }

    .person-list .person-item:hover .pic img {
      border: 1px solid #359029;
    }
  }

  @media screen and (max-width: 900px){
    .banner img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
    .about-wrap {
      width: 96%;
      margin: 0 auto;
      padding-top: 20px;
    }

    .about-contain {
      padding-bottom: 20px;
    }

    .about-contain .about-l {
    }

    .about-contain .about-r {
    }

    .about-contain .title-1 {
      font-size: 24px;
      font-family: Arial;
      line-height: 35px;
      color: #333333;
    }
    .about-contain .title-2 {
      font-size: 24px;
      line-height: 32px;
      color: #000000;
    }
    .about-contain .about-r {
      flex: 1;
      padding-top: 20px;
      font-size: 14px;
      line-height: 28px;
      color: #888888;
    }

    .about-contain .about-l .content {
      line-height: 30px;
    }

    .about-contain .about-r .experience {
      line-height: 30px;
    }

    .about-wrap .pic {
      padding: 0px 2%;
      width: 96%;
    }
    .about-wrap .pic img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .team-wrap {
      width: 96%;
      margin: 0 auto;
      padding: 30px 0px 20px 0px;
    }

    .team-wrap .title {
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }

    .person-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
    }

    .person-list .person-item {
      width: 48%;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(113, 113, 113, 0.16);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 4%;
      margin-bottom: 20px;
      cursor: pointer;
      transition: 1s;
      padding-bottom: 10px;
    }

    .person-list .person-item:nth-child(2n+2) {
      margin-right: unset;
    }

    .person-list .person-item .pic {
      width: 110px;
      height: 110px;;
      border-radius: 50%;
      margin-top:10px;
      box-sizing: border-box;
    }

    .person-list .person-item .pic img {
      width: 110px;
      height: 110px;
      object-fit: cover;
      border-radius: 50%;
      box-sizing: border-box;
    }

    .person-list .person-item .name {
      font-size: 20px;
      line-height: 32px;
      color: #000000;
      padding: 10px 0px 0px;
    }

    .person-list .person-item .post {
      font-size: 16px;
      line-height: 32px;
      color: #333333;
    }

    .person-list .person-item .info {
      font-size: 14px;
      line-height: 21px;
      color: #999999;
      text-align: center;
      padding: 0px 10px 0px;
      /*overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;*/
    }

    .person-list .person-item:hover {
      box-shadow: 0px 14px 24px rgba(81, 181, 68, 0.17);
    }

    .person-list .person-item:hover .pic img {
      border: 1px solid #359029;
    }
  }
</style>
