<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
		<el-form ref="formData" :model="formData" label-width="120px" label-position="right" style="width: 400px"
			status-icon :rules="rules">
			<el-form-item label="主要产品名称:">
				<el-select v-model="formData.id" :disabled="disabled" placeholder="请选择主要产品名称" @change="changeClick">
					<el-option v-for="item in optionsList" :key="item.id" :label="item.mainCategoryName"
						:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="设计产能" prop="designNumber">
				<el-input-number v-model="formData.designNumber" disabled :min="1" :max="10000" label="设计产能">
				</el-input-number>
			</el-form-item>
			<el-form-item label="正常工作天数" prop="normalDays">
				<el-input-number v-model="formData.normalDays" disabled :min="1" :max="10000" label="正常工作天数">
				</el-input-number>
			</el-form-item>
			<el-form-item label="对应日产能" prop="dayNumber">
				<el-input-number v-model="formData.dayNumber" disabled :min="1" :max="10000" label="对应日产能">
				</el-input-number>
			</el-form-item>

			<div v-for="(item,index) in formData.mainCapacityChildrenList" :key="item.id">
				<el-form-item label="产品名称:" :rules="rules.id" :prop="`mainCapacityChildrenList[${index}].id`">
					<el-select v-model="item.id" :disabled="disabled" @change="byChange(index)" placeholder="请选择主要产品名称">
						<el-option v-for="(itemOne,indexOne) in byList" :key="itemOne.id" :label="itemOne.productName"
							:value="itemOne.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="本日产量:" :rules="rules.dayNumber"
					:prop="`mainCapacityChildrenList[${index}].dayNumber`">
					<el-input-number v-model="item.dayNumber"  :disabled="disabled" :min="1" :max="10000" label="请输入产量">
					</el-input-number>
				</el-form-item>
				<el-form-item label="单位:">
					<el-input v-model="item.unit" disabled></el-input>
				</el-form-item>
			</div>
			<el-form-item label="日期" prop="date" v-if="!disabled">
				<el-date-picker v-model="formData.date" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
					value-format="yyyy-MM-dd">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="日期:" v-if="disabled">
				<el-input v-model="recordCode" disabled></el-input>
			</el-form-item>
			<el-form-item style="display: flex;justify-content: flex-end;" v-if="!disabled">
				<el-button @click="handleClose">取消</el-button>
				<el-button type="primary" @click="onSubmit('formData')">确定</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import api from "@/api/pro/mainCapacity";
	export default {
		props: {
			// 弹窗标题
			title: {
				type: String,
				default: "",
			},
			// 显示弹窗
			dialogVisible: {
				type: Boolean,
				default: false,
			},
			recordCode: {
				type: [String, Number],
				default: 1
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		components: {},
		watch: {
			dialogVisible: {
				immediate: true,
				handler(val) {
					if (val) {
						this.getData()
						this.getSelectFun()
					}
				}
			}
		},
		data() {
			return {
				rules: {
					date: [{
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}],
					dayNumber: [{
						required: true,
						message: '请输入产量',
						trigger: 'blur'
					}],
					id: [{
						required: true,
						message: '请选择产品名称',
						trigger: 'change'
					}]
				},
				formData: {},
				optionsList: [],
				byList: []

			};
		},

		created() {},

		mounted() {},

		methods: {
			byChange(e) {
				this.byList.map(item => {
					if (item.id == this.formData.mainCapacityChildrenList[e].id) {
						this.$set(this.formData.mainCapacityChildrenList[e], 'unit', item.unit)
					}
				})
			},
			changeClick() {
				this.formData.mainCapacityChildrenList.forEach(item => {
					item.id = ''
					item.unit = ''
					item.dayNumber = ''
				})
				this.getById(this.formData.id)
			},
			getById(id) {
				api.getById({
					id
				}).then(res => {
					this.byList = res.data.mainCapacityChildrenList
				})
			},
			getSelectFun() {
				api.getList({
					model: {}
				}).then(res => {
					this.optionsList = res.data.records
				})
			},
			getData() {
				api.getListByRecordCode({
					recordCode: this.recordCode
				}).then(res => {
					res.data.date = this.recordCode
					this.formData = res.data
					this.getById(res.data.id)
				})
			},
			handleClose() {
				this.$emit("handleClose");
			},
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.submitData();
					} else {
						return false;
					}
				});
			},
			// 提交表单数据
			async submitData() {
				let res = null;
				let query = {
					date: this.formData.date,
					oldRecordCode: this.recordCode,
					dayCapacities: []
				}

				query.dayCapacities = this.formData.mainCapacityChildrenList.map(item => {
					return {
						dayNumber: item.dayNumber,
						mainCapacityId: this.formData.id,
						productId: item.id
					}
				})
				res = await api.update(query);

				if (res.code == 0) {
					this.$message({
						message: "修改成功",
						type: "success",
					});
					this.handleClose();
				} else {
					this.$message.error(res.message);
				}
			},
		},
	};
</script>
<style scoped>
</style>
