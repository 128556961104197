<template>
  <hm-drawer-description
      :visible="visible"
      :title="title"
      size="600px"
      @button-event="buttonEvent"
  >
    <div slot="drawer-body">
      <hm-description
          :config="detailConfig"
          :column-number="1"
      >
      </hm-description>
    </div>
  </hm-drawer-description>
</template>
<script>
import ProductApi from '@/api/ep/equipment'
import {ProductDetailConfig, ProtectiveDetailConfig} from './config.js'
import {mergeModelValue} from '@/utils/commons'

export default {
  name: 'EquipmentDetail',
  data() {
    return {
      visible: false,
      title: '生产设备',
      detailConfig: ProductDetailConfig.bind(this)()
    }
  },
  created() {

  },
  methods: {
    init(id) {
      this.visible = true
      this.getProductData(id)
    },
    getProductData(id) {
      ProductApi.get(id).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        const model = this.detailConfig.config.model
        mergeModelValue(model, data)
      })
    },
    buttonEvent(type) {
      if (type === 'cancel') {
        this.visible = false
      }
    }
  }
}
</script>
