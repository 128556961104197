<template>
  <div id="global-uploader">
    <uploader
      ref="uploader"
      :options="options"
      :auto-start="false"
      class="uploader-app"
      @file-added="onFileAdded"
      @file-success="onFileSuccess"
      @file-progress="onFileProgress"
      @file-error="onFileError"
    >
      <uploader-unsupport />

      <uploader-btn id="global-uploader-btn" :attrs="attrs">选择文件</uploader-btn>

      <uploader-list v-show="panelShow">
        <div slot-scope="props" class="file-panel" :class="{'collapse': collapse}">
          <div class="file-title">
            <h2>文件列表</h2>
            <div class="operate">
              <el-button type="text" :title="collapse ? '展开':'折叠' " @click="fileListShow">
                <i class="iconfont" :class="collapse ? 'inuc-fullscreen': 'inuc-minus-round'" />
              </el-button>
              <el-button type="text" title="关闭" @click="close">
                <i class="iconfont icon-close" />
              </el-button>
            </div>
          </div>

          <ul class="file-list">
            <li v-for="file in props.fileList" :key="file.id">
              <uploader-file
                ref="files"
                :class="'file_' + file.id"
                :file="file"
                :list="true"
              />
            </li>
            <div v-if="!props.fileList.length" class="no-file"><i class="iconfont icon-empty-file" />
              暂无待上传文件
            </div>
          </ul>
        </div>
      </uploader-list>

    </uploader>
  </div>
</template>

<script>
/**
     *   全局上传插件
     *   调用方法：this.$bus.$emit('openUploader', {}) 打开文件选择框，参数为需要传递的额外参数
     *   监听函数：this.$bus.$on('fileAdded', fn); 文件选择后的回调
     *   this.$bus.$on('fileSuccess', fn); 文件上传成功的回调
     */
import { ACCEPT_CONFIG } from './config'
import SparkMD5 from 'spark-md5'
import $ from 'jquery'
import FileApi from '@/api/fs/file'
import store from '../../store'

export default {
  data() {
    return {
      options: {
        target: FileApi.chunkUpload,
        chunkSize: 5242880,
        fileParameterName: 'file',
        maxChunkRetries: 3,
        testChunks: true,
        checkChunkUploadedByResponse: this.checkChunkUploadedByResponse,
        headers: {
          Authorization: this.$store.state.user.token
        },
        query() {
        }
      },
      attrs: {
        accept: ACCEPT_CONFIG.getAll()
      },
      // 选择文件后，展示上传panel
      panelShow: false,
      collapse: false
    }
  },
  computed: {
    // Uploader实例
    uploader() {
      return this.$refs.uploader.uploader
    }
  },
  mounted() {
    this.$bus.$on('openUploader', query => {
      this.params = query || {}
      // 将自定义参数直接加载uploader实例的opts上
      Object.assign(this.uploader.opts, {
        query: {
          ...query
        }
      })

      $('#global-uploader-btn').click()
    })
  },
  destroyed() {
    this.$bus.$off('openUploader')
  },
  methods: {
    checkChunkUploadedByResponse(chunk, message) {
      const objMessage = JSON.parse(message)
      if (!objMessage.success) {
        return false
      }

      return (objMessage.data || []).indexOf(chunk.offset + 1) >= 0
    },
    onFileAdded(file) {
      this.panelShow = true
      this.computeMD5(file)
      this.$bus.$emit('fileAdded')
    },
    onFileProgress(rootFile, file, chunk) {
      console.log(`上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${chunk.endByte / 1024 / 1024}`)
    },
    onFileSuccess(rootFile, file) {
      const params = {
        totalSize: file.size,
        filename: file.name,
        chunkSize: this.options.chunkSize,
        totalChunks: file.chunks.length,
        contextType: file.fileType,
        identifier: file.uniqueIdentifier
      }

      this.statusSet(file.id, 'merging')
      FileApi.chunkMerge(params).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        this.statusRemove(file.id)
        this.close()
        this.$bus.$emit('fileSuccess', data)
      })
    },
    onFileError(rootFile, file, response) {
      this.$message.error(response)
    },
    computeMD5(file) {
      const fileReader = new FileReader()
      const time = new Date().getTime()
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let currentChunk = 0
      const chunkSize = this.options.chunkSize
      const chunks = Math.ceil(file.size / chunkSize)
      const spark = new SparkMD5.ArrayBuffer()
      // 文件状态设为"计算MD5"
      this.statusSet(file.id, 'md5')
      file.pause()
      loadNext()
      const that = this
      fileReader.onload = e => {
        spark.append(e.target.result)
        if (currentChunk < chunks) {
          currentChunk++
          loadNext()

          // 实时展示MD5的计算进度
          that.$nextTick(() => {
            $(`.myStatus_${file.id}`).text('校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%')
          })
        } else {
          const md5 = spark.end()
          that.computeMD5Success(md5, file)
          console.log(`MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${new Date().getTime() - time} ms`)
        }
      }

      fileReader.onerror = () => {
        that.error(`文件${file.name}读取出错，请检查该文件`)
        file.cancel()
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
      }
    },
    computeMD5Success(md5, file) {
      FileApi.checkMd5({ md5: md5 }).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        if (data) {
          this.$bus.$emit('fileSuccess', data)
          this.statusRemove(file.id)
          return false
        }

        file.uniqueIdentifier = md5
        file.resume()
        this.statusRemove(file.id)
      })
    },

    fileListShow() {
      const $list = $('#global-uploader .file-list')
      if ($list.is(':visible')) {
        $list.slideUp()
        this.collapse = true
      } else {
        $list.slideDown()
        this.collapse = false
      }
    },
    close() {
      this.uploader.cancel()
      this.panelShow = false
    },
    /**
             * 新增的自定义的状态: 'md5'、'transcoding'、'failed'
             * @param id
             * @param status
             */
    statusSet(id, status) {
      const statusMap = {
        md5: {
          text: '校验MD5',
          bgc: '#fff'
        },
        merging: {
          text: '合并中',
          bgc: '#e2eeff'
        },
        transcoding: {
          text: '转码中',
          bgc: '#e2eeff'
        },
        failed: {
          text: '上传失败',
          bgc: '#e2eeff'
        }
      }
      this.$nextTick(() => {
        $(`<p class="myStatus_${id}"></p>`).appendTo(`.file_${id} .uploader-file-status`).css({
          'position': 'absolute',
          'top': '0',
          'left': '0',
          'right': '0',
          'bottom': '0',
          'zIndex': '1',
          'backgroundColor': statusMap[status].bgc
        }).text(statusMap[status].text)
      })
    },
    statusRemove(id) {
      this.$nextTick(() => {
        $(`.myStatus_${id}`).remove()
      })
    },
    error(msg) {
      this.$notify({
        title: '错误',
        message: msg,
        type: 'error',
        duration: 2000
      })
    }
  }
}
</script>

<style scoped lang="scss">
  #global-uploader {
    position: fixed;
    z-index: 20;
    right: 15px;
    bottom: 60px;

    .file-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1px;
    }

    .uploader-app {
      width: 520px;
    }

    .file-panel {
      background-color: #fff;
      border: 1px solid #e2e2e2;
      border-radius: 7px 7px 0 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      .file-title {
        display: flex;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        border-bottom: 1px solid #ddd;

        .operate {
          flex: 1;
          text-align: right;
        }
      }

      .file-list {
        position: relative;
        height: 240px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #fff;

        > li {
          background-color: #fff;
        }
      }

      &.collapse {
        .file-title {
          background-color: #e7ecf2;
        }
      }
    }

    .no-file {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
    }

    .uploader-file-icon {
      &::before {
        content: '' !important;
      }
    }

    .uploader-file-actions > span {
      margin-right: 6px;
    }
  }

  /* 隐藏上传按钮 */
  #global-uploader-btn {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

</style>
