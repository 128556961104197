<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`自行检测报告编辑`"
  />
</template>

<script>
import SelfTestReport from '@/api/ep/selfTestReport'
import {SelfTestReportEditConfig} from './config'
import {mergeModelValue} from '@/utils/commons'

export default {
  data() {
    return {
      visible: false,
      formConfig: SelfTestReportEditConfig.bind(this)(),
      id: ''
    }
  },
  methods: {
    init(id) {
      this.id = id
      this.visible = true
      this.formConfig = SelfTestReportEditConfig.bind(this)()
      SelfTestReport.getSelfTestReportById(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        const model = this.formConfig.config.model
        mergeModelValue(model, data)
      })
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      result.id = this.id
      SelfTestReport.updateSelfTestReport(result).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        this.visible = false
        this.$message.success('保存成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>

</style>
