/**
 * 正则表达式定义
 */
export default {
    //手机号
    MOBILE: /^1[2|3|4|5|6|7|8|9]\d{9}$/,
    //身份证号码
    ID_CARD: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
    //邮箱
    EMAIL: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
    //中文
    CHINESE: /^[\u4e00-\u9fa5]{0,}$/,
    //网络地址
    INTERNET_URL: /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
    //正数、负数、和小数
    NUMBER: /^(\-|\+)?\d+(\.\d+)?$/,
    //非负整数
    NON_NEGATIVE: /^[1-9]\d*|0$/,
    //密码强度正则，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
    PASSWORD_STRONG : /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/,
    //密码是否含有小写字母、大写字母、数字、特殊符号的两种及以上
    PASSWORD_COMMON : /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{6,}$/,
}