import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'corpData/'

export default {
  /**
   * 动态获取环保档案二维码模板字段
   * @param id
   * @returns {*}
   */
  getArchivesCode() {
    return http.get(PREFIX + `getArchivesCode`);
  },
  /**
   * 分页
   * @param params
   * @returns {*}
   */
  search(params) {
    return http.post(PREFIX + `search`, params)
  },
  /**
   * 查询结果
   * @param id
   * @returns {*}
   */
  get(id) {
    return http.get(PREFIX + `${id}`);
  },
  /**
   * 保存
   * @param params
   * @returns {*}
   */
  save(params) {
    return http.post(PREFIX + `save`, params);
  },

  /**
   * 修改
   * @param params
   * @returns {*}
   */
  update(params) {
    return http.put(PREFIX + `update`, params);
  },

  /**
   * 批量删除
   * @param params
   * @returns {*}
   */
  batchDelete(params) {
    return http.delete(PREFIX + `batchDelete`, params);
  },

  /**
   * 查询列表
   * @param params
   * @returns {*}
   */
  list(params) {
    return http.post(PREFIX + `list`, params || {})
  },

  /**
   * 上传文件
   * @param params
   * @returns {*}
   */
  uploadfiles(params) {
    return http.post(PREFIX + `uploadfiles`, params || {})
  },
  /**
   * 根据文件夹获取文件
   * @param params
   * @returns {*}
   */
  getFiles(params) {
    return http.post(PREFIX + `getFiles`, params || {})
  },
  /**
   * 删除文件
   * @param params
   * @returns {*}
   */
  deleteFile(params) {
    return http.post(PREFIX + `deleteFile`, params || {})
  },
  /**
   * 修改文件
   * @param params
   * @returns {*}
   */
  changeFile(params) {
    return http.post(PREFIX + `changeFile`, params || {})
  },

  /**
   * 设置分享链接
   * @param params
   * @returns {*}
   */
  getShare(params) {
    return http.get(PREFIX + `getShare`, params || {})
  },
  /**
   * 删除
   * @param params
   * @returns {*}
   */
  delete(id) {
    return http.delete(PREFIX + `delete`, id);
  },
  /**
   * 企业现有环保档案
   * @param params
   * @returns {*}
   */
  listExistingFiles() {
    return http.get(PREFIX + `listExistingFiles`);
  },
  /**
   * 企业现有环保档案名称
   * @param params
   * @returns {*}
   */
  listDataName(params) {
    return http.get(PREFIX + `listDataName`, params);
  },
  /**
   * 获取对应文件夹档案
   * @param params
   * @returns {*}
   */
  getDataChild(id) {
    return http.get(PREFIX + `getDataChild`, id);
  },
  /**
   * 保存企业现有环保档案
   * @param params
   * @returns {*}
   */
  updateEepa(params) {
    return http.post(PREFIX + `updateEepa`, params);
  },
  /**
   * 通过Id获取环保档案
   * @param params
   * @returns {*}
   */
  getEepaByDataName(params) {
    return http.get(PREFIX + `getEepaByDataName`, params);
  },
  /**
   * 通过dataName删除环保档案
   * @param params
   * @returns {*}
   */
  deleteEepaByDataName(params) {
    return http.delete(PREFIX + `deleteEepaByDataName`, params);
  },
  /**
   * 通过dataName编辑环保档案
   * @param params
   * @returns {*}
   */
  updateEepaById(params) {
    return http.put(PREFIX + `updateEepaById`, params);
  },

  /**
   *
   * 预览环保模板详情
   * @params params
   * @returns {*}
   */
  getDetail(params) {
    return http.get(PREFIX + `getDetail?corpId=`+`${params}` );
  },
}
