<template>
  <div class="hm-tree-input">
    <treeselect
      v-model="value"
      clearable
      :clear-value-text="'清除'"
      :multiple="multiple"
      :options="data"
      :searchable="true"
      :placeholder="placeholder"
      :value-consists-of="valueConsistsOf"
      :default-expand-level="2"
      :no-children-text="'没有子集了'"
      no-options-text="暂无可选内容"
      no-results-text="无结果"
      :normalizer="normalizerData"
      :disabled="disabled"
      @input="select"
    />
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'HmTreeInput',
  components: { Treeselect },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    valueConsistsOf: {
      type: String,
      default: 'LEAF_PRIORITY'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    normalizer: {
      type: Object,
      default: () => {
        return {}
      }
    },
    data: {
      type: Array,
      default: null
    },
    param: {
      type: Object,
      default: null
    },
    request: {
      type: Function,
      default: null
    },
    defaultValue: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      value: null
    }
  },
  watch: {
    defaultValue(val) {
      this.value = val || null
    }
  },
  created() {
    if (typeof this.request === 'function') {
      this.getData()
    }
  },
  methods: {
    getData() {
      this.request(this.param).then(res => {
        if (!res.isSuccess) {
          return
        }

        this.data = res.data
      })
    },
    select(id) {
      this.$emit('select', id || '')
    },
    normalizerData(node) {
      const data = {
        id: node[this.normalizer.id || 'id'],
        label: node[this.normalizer.label || 'label']
      }

      data.children = node[this.normalizer.children || 'children'] || undefined
      return data
    }
  }
}
</script>

