<template>
	<div class="components">
		<div class="title">主要产品材料</div>
		<div class="content">
			<div style="display: flex;justify-content: space-between">
				<div class="btns-box">
					<el-button type="primary" @click="add">新增主要产品材料</el-button>
					<el-button type="primary" @click="batchDelete()">批量删除</el-button>
				</div>
				<hm-search-form ref="searchForm" deriveShow :data="searchConfig" label-width="150" size="normal" />
			</div>
			<div>
				<hm-table ref="table" :stripe="true" :border="true"
					:header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}" :api="listRequest"
					:table-config="tableConfig">

					<div slot="operation" slot-scope="row">
						<!-- <el-button class="table-operation" type="text" @click="viewClck(row.data)">导出</el-button> -->
						<el-button class="table-operation" type="text" @click="viewClck(row.data)">查看</el-button>
						<el-button class="table-operation" type="text" @click="edit(row.data)">编辑</el-button>
						<el-button class="table-operation" type="text" @click="remove(row.data)">删除</el-button>
					</div>
					<div slot="avatar" slot-scope="row">
						<el-avatar :key="row.data.qrCard" :src="row.data.qrCard" fit="fill">
						</el-avatar>
					</div>
				</hm-table>
			</div>
		</div>

		<el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<el-form ref="form" :model="form" label-width="120px" :rules="rules">
				<el-form-item label="主要产品名称" prop="mainCategoryName">
					<el-input v-model="form.mainCategoryName" label="主要产品名称" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="设计产能" prop="designNumber">
					<el-input-number v-model="form.designNumber" @change="handleChange" :min="1" :max="10000"
						label="设计产能"></el-input-number>
				</el-form-item>
				<el-form-item label="正常工作天数" prop="normalDays">
					<el-input-number v-model="form.normalDays" @change="handleChange" :min="1" :max="10000"
						label="正常工作天数"></el-input-number>
				</el-form-item>
				<el-form-item label="对应日产能" prop="dayNumber">
					<el-input-number v-model="form.dayNumber" @change="handleChange" :min="1" :max="10000"
						label="对应日产能"></el-input-number>
				</el-form-item>
				<el-divider></el-divider>
				<div style="margin-bottom: 20px">
					<!--                    <span style="font-weight: 800;margin-left: 5%">实际生产情况</span>-->
					<!--                    <el-date-picker style="margin-left: 30%" v-model="selectDate" align="right" type="date"-->
					<!--                                    placeholder="选择日期" :picker-options="pickerOptions">-->
					<!--                    </el-date-picker>-->
				</div>
				<div v-for="(item,index) in form.mainCapacityChildrenList">
					<el-form-item label="产品名称" :prop="'mainCapacityChildrenList.'+index+'.productName'" :rules="{
						  required: true, message:'产品名称不能为空', trigger: 'blur'
						}">
						<el-input v-model="item.productName" label="产品名称" style="width: 50%"></el-input>
						<el-button type="success" icon="el-icon-circle-plus" circle style="margin-left: 5%"
							@click="addMainCapacityChildren"></el-button>
						<el-button type="danger" icon="el-icon-delete" circle @click="delMainCapacityChildren(index)"
							v-if="form.mainCapacityChildrenList.length>1"></el-button>
					</el-form-item>
					<el-form-item label="单位" :prop="'mainCapacityChildrenList.'+index+'.unit'" :rules="{
						  required: true, message:'单位不能为空', trigger: 'blur'
						}">
						<el-input v-model="item.unit" label="单位" style="width: 50%"></el-input>
					</el-form-item>

				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="canal">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
		<ck-view :dialogVisible="dialogCkVisible" :viewData="viewData" @changeDate="changeDate" :formData="formCkData"
			@handleViewClose="dialogCkVisible=false">
		</ck-view>
		<edit-view :dialogVisible="dialogEditVisible" :title="title" :disabled="disabled" :recordCode="recordCode" @handleClose="handleCloseClick"></edit-view>
	</div>
</template>

<script>
	import MainCapacityApi from "@/api/pro/mainCapacity"
	import {
		SearchConfig,
		TableConfig
	} from "../config/mainCapacity";
	import {
		mergeJson,
		downloadBlobFile
	} from '@/utils/commons'
	import BusinessEdit from "..//businessEdit";
	import editView from "./edit.vue"
	import ckView from "./ckView.vue"

	export default {
		name: "mainMaterial",
		components: {
			BusinessEdit,
			ckView,
			editView
		},
		data() {
			return {
				rules: {
					mainCategoryName: [{
						required: true,
						message: '请输入主要产品',
						trigger: 'blur'
					}],
					designNumber: [{
						required: true,
						message: '请输入设计产能',
						trigger: 'blur'
					}, ],
					normalDays: [{
						required: true,
						message: '请输入正常工作天数',
						trigger: 'blur'
					}, ],
					dayNumber: [{
						required: true,
						message: '请输入对应日产能',
						trigger: 'blur'
					}, ],
					/*
										unit: [{
											required: true,
											message: '单位不能为空',
											trigger: 'blur'
										}, ], */
					// productName: [{
					//     required: true,
					//     message: '产品名称不能为空',
					//     trigger: 'blur'
					// },]

				},
				selectDate: '',
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
				optionProps: {
					value: "id",
					label: "categoryName",
					children: "children",
				},
				selectData: [],
				selectChildrenData: [],
				form: {
					designNumber: null,
					normalDays: null,
					dayNumber: null,
					mainCapacityChildrenList: [{
						productName: null,
						unit: null
					}]
				},
				dialogVisible: false,
				title: '',
				listRequest: MainCapacityApi.search,
				searchConfig: SearchConfig.bind(this)(),
				tableConfig: TableConfig.bind(this)(),
				searchParams: {
					// 搜索条件
					params: {},
					// table下拉条件
					tableParams: {},
				},
				flag: true,
				dialogCkVisible: false,
				formCkData: {},
				itemId: '',
				dayNumber: "",
				viewData: [],
				dialogEditVisible:false,
				recordCode:'',
				disabled:false
			}
		},
		created() {

		},
		methods: {
			getNowFormatDate() {
				var date = new Date();
				var seperator1 = "-";
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var strDate = date.getDate();
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				if (strDate >= 0 && strDate <= 9) {
					strDate = "0" + strDate;
				}
				var currentdate = year + seperator1 + month + seperator1 + strDate;
				return currentdate
			},
			viewClck(item) {
				this.recordCode=item.recordCode
				this.title = '查看主要产品'
				this.dialogEditVisible=true
				this.disabled=true
				
				return
				this.itemId = item.id
				this.dayNumber = item.dayNumber
				let num = 0
				this.formCkData.mainCategoryName = item.mainCategoryName
				this.formCkData.designNumber = item.designNumber
				this.formCkData.normalDays = item.normalDays
				this.formCkData.dayNumber = item.dayNumber
				MainCapacityApi.getListById({
					id: item.id,
					date: this.getNowFormatDate()
				}).then(res => {
					this.dialogCkVisible = true
					this.viewData = res.data
					res.data.map(item => {
						num += item.dayNumber
					})
					this.formCkData.load = (num / item.dayNumber) + '%'
				})
			},
			changeDate(date) {
				let num = 0
				MainCapacityApi.getListById({
					id: this.itemId,
					date
				}).then(res => {
					this.viewData = res.data

					if (res.data.length == 0) {
						this.$set(this.formCkData, 'load', '0%')
					} else {
						res.data.map(item => {
							num += item.dayNumber
						})
						this.$set(this.formCkData, 'load', (num / this.dayNumber) + '%')
					}

					this.$forceUpdate()
				})
			},
			addMainCapacityChildren() {
				var item = {
					productName: null,
					unit: null
				}
				this.form.mainCapacityChildrenList.push(item)

			},
			delMainCapacityChildren(index) {
				this.form.mainCapacityChildrenList.splice(index, 1)

			},


			canal() {
				this.resetForm();
				this.dialogVisible = false
			},

			selectChange(val) {
				console.log(val)
				var selectData = {
					id: val
				}
				MainCapacityApi.getSelectChildren(selectData).then(res => {
					console.log(res)
					this.selectChildrenData = res.data
				})
			},

			submit() {
				if (this.title == '新增主要产品') {
					MainCapacityApi.save(this.form).then(res => {
						console.log(res)
						if (res.code == 0) {
							this.$message.success('保存成功')
							this.resetForm();
							this.$refs.table.getTableData()
							this.dialogVisible = false
						}
					})
				} else {
					console.log('修改')
					MainCapacityApi.update(this.form).then(res => {
						console.log(res)
						if (res.code == 0) {
							this.$message.success('修改成功')
							this.resetForm();
							this.$refs.table.getTableData()
							this.dialogVisible = false
						}
					})
				}
			},
			resetForm() {
				this.form = {
					mainCapacityChildrenList: [{
							productName: null,
							unit: null
						}

					]
				}
			},
			handleChange(value) {
				console.log(value);
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			handleCloseClick(){
				this.dialogEditVisible=false
				this.$refs.table.refresh();
			},
			edit(item) {
				this.recordCode=item.recordCode
				this.disabled=false
				this.title = '编辑主要产品'
				this.dialogEditVisible=true
				return
				
				var data = {
					id: item.id
				}
				var selectData = {
					id: item.mainCategoryId
				}
				MainCapacityApi.getSelect().then(res => {
					console.log(res)
					this.selectData = res.data
				})
				MainCapacityApi.getSelectChildren(selectData).then(res => {
					console.log(res)
					this.selectChildrenData = res.data
				})
				MainCapacityApi.getById(data).then(res => {
					this.form = res.data
				})
				this.dialogVisible = true
			},
			add() {
				MainCapacityApi.getSelect().then(res => {
					this.selectData = res.data
				})

				this.title = '新增主要产品'
				this.dialogVisible = true
			},
			remove(data) {
				this.batchDelete(data.id)
			},
			batchDelete(id) {
				let ids;
				if (id) {
					ids = [id]
				} else {
					ids = this.$refs.table.getSelections().ids
				}
				if (ids.length <= 0) {
					this.$message.error('至少选择一项')
					return false
				}

				this.$confirm('此操作将永久删除, 是否继续?', '提示', {
					type: 'warning'
				}).then(() => {
					MainCapacityApi.batchDelete({
						ids: ids
					}).then((res) => {
						if (!res.isSuccess) {
							return
						}
						this.$message.success('删除成功')
						this.$refs.table.refresh()
					})
				})
			},
			handleSearch(data) {
				this.searchParams.tableParams = data
				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.$refs.table.reload(params)
			},
			submitSuccess() {
				this.$refs.table.refresh()
			},
			/*
			            excel导出事件

			            */
			deriveBtnExcelClick() {

				if (this.flag) {
					this.flag = false
					const params = mergeJson(this.searchParams) || {}
					if (!params.extend) {
						params.extend = {}
					}
					params.extend.fileName = '主要产品材料'
					MainCapacityApi.exportExcel(params).then((data) => {
						downloadBlobFile(data)
					})
				}
				setTimeout(() => {
					this.flag = true
				}, 500)

			}
		}
	}
</script>

<style scoped>
	.components {
		height: 100%;
	}

	.title {
		font-size: 20px;
		font-weight: 400;
		color: #030303;
	}

	.content {
		padding: 20px;
		margin-top: 20px;
		background-color: #fff;
		height: calc(100% - 60px);
		border-radius: 5px;
		box-sizing: border-box;
	}

	.table-operation {
		margin: 0 5px;
	}
</style>
