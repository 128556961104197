// 表格展示配置
export const TableConfig = function() {
  return {
    list: [
      {
        prop: 'createTime',
        label: '导入时间',
        type: 'date'
      },
      {
        prop: 'status',
        label: '状态',
        slotScope: 'status'
      },
      {
        fixed: 'right',
        label: '操作',
        align: 'center',
        slotScope: 'operation',
        'min-width': 150
      }
    ]
  }
}
