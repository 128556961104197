import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'productProcess/'

export default {
    /**
     * 查询生产工艺流程详情
     * @returns {*}
     * @param id
     */
    get(id) {
        return http.get(PREFIX + `${id}`);
    }
}
