<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`生产工艺流程--${id?'编辑':'新增'}`"
  />
</template>

<script>
import ProductProcessAPI from "@/api/ep/productProcess"
import {AddConfig, EditConfig} from './config.js'
import {mergeModelValue} from "@/utils/commons"

export default {
  data() {
    return {
      visible: false,
      formConfig: AddConfig.bind(this)(),
      id: ''
    }
  },
  methods: {
    init(id) {
      this.id = id || ''
      this.visible = true
      this.formConfig = id ? EditConfig.bind(this)() : AddConfig.bind(this)()
      if (!id) {
        return
      }
      ProductProcessAPI.get(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        const model = this.formConfig.config.model
        mergeModelValue(model, data)
      })
    },
    handleChange(key, value) {
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      if (!this.id) {
        ProductProcessAPI.save(result).then((res) => {
          if (!res.isSuccess) {
            return
          }
          const data = res.data
          const model = this.formConfig.config.model
          mergeModelValue(model,data)
          this.visible = false
          this.$message.success('保存成功')
          this.$emit('submitSuccess', data)
        })
        return false
      }

      result.id = this.id
      ProductProcessAPI.update(result).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.visible = false
        this.$message.success('保存成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>
  /deep/.el-form-item__label {
    width: 100px !important;
  }

  /deep/.el-form-item__content {
    margin-left: 100px !important;
  }
</style>
