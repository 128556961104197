  import Regular from '@/utils/regular'
// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const SearchConfig = function() {
  return {
    list: [
      {
        label: '设备名称',
        key: 'name',
        type: 'input',
        placeholder: '请输入设备名称'
      },
      {
        label: '编号',
        key: 'code',
        type: 'input',
        placeholder: '请输入编号'
      },
      {
        label: '时间',
        key: 'startTime',
        key1: 'endTime',
        type: 'daterange',
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
      selects: {},
      model: {
        'name':'',
        'code':'',
      }
    }
  }
}

// 表格展示配置
export const TableConfig = function() {
  return {
    list: [
      {
        type: 'selection',
        width: 60
      },
      {
        label: '序号',
        type: 'index',
        align: 'center',
        width: 60
      },
      {
        prop: 'name',
        align: 'center',
        label: '设备名称',
      },
      {
        prop: 'code',
        align: 'center',
        label: '设备编号',
      },
      {
        prop: 'signImg',
        align: 'center',
        label: '签名',
        slotScope: 'signImg'
      },
      {
        prop: 'openType',
        align: 'center',
        label: '操作设备状态',
      },
      {
        prop: 'createTime',
        align: 'center',
        label: '操作时间',
      }
    ]
  }
}

// 搜索表单配置
export const PtotectiveSearchConfig = function() {
  return {
    list: [
      {
        label: '设施名称',
        key: 'name',
        type: 'input',
        placeholder: '请输入设施名称'
      },
      {
        label: '编号',
        key: 'code',
        type: 'input',
        placeholder: '请输入编号'
      },
      {
        label: '时间',
        key: 'startTime',
        key1: 'endTime',
        type: 'daterange',
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
      selects: {},
      model: {
        'name':'',
        'code':'',
      }
    }
  }
}

// 表格展示配置
export const PtotectiveTableConfig = function() {
  return {
    list: [
      {
        type: 'selection',
        width: 60
      },
      {
        label: '序号',
        type: 'index',
        align: 'center',
        width: 60
      },
      {
        prop: 'name',
        align: 'center',
        label: '设施名称',
      },
      {
        prop: 'code',
        align: 'center',
        label: '设施编号',
      },
      {
        prop: 'signImg',
        align: 'center',
        label: '签名',
        slotScope: 'signImg'
      },
      {
        prop: 'openType',
        align: 'center',
        label: '操作设施状态',
      },
      {
        prop: 'createTime',
        align: 'center',
        label: '操作时间',
      }
    ]
  }
}


