<template>
  <el-dialog
    :close-on-click-modal="false"
    title="图标列表"
    :visible.sync="isVisible"
    :width="width"
    top="50px"
  >
    <el-input
      v-model="name"
      class="filter-item search-item"
      clearable
      placeholder="icon"
      @keyup.native="searchIcon"
    />
    <el-tabs v-model="activeName" style="margin-top: 5px;">
      <el-tab-pane label="公共图标" name="first">
        <ul>
          <li v-for="icon in icons.commonIcons" :key="icon" class="icons-item">
            <span :class="{'active':activeIndex === icon}">
              <el-icon
                :class="icon"
                :title="icon"
                @click.native="chooseIcon(icon)"
                @dblclick.native="confirm"
              />
            </span>
            <p>{{ icon }}</p>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="方向图标" name="second">
        <ul>
          <li v-for="icon in icons.directivityIcons" :key="icon">
            <span :class="{'active':activeIndex === icon}">
              <el-icon :class="icon" :title="icon" @click.native="chooseIcon(icon)" @dblclick.native="confirm" />
            </span>
            <p>{{ icon }}</p>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="实心图标" name="third">
        <ul>
          <li v-for="icon in icons.solidIcons" :key="icon">
            <span :class="{'active':activeIndex === icon}">
              <el-icon
                :class="icon"
                :title="icon"
                @click.native="chooseIcon(icon)"
                @dblclick.native="confirm"
              />
            </span>
            <p>{{ icon }}</p>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="实物图标" name="fourth">
        <ul>
          <li v-for="icon in icons.foodIcons" :key="icon">
            <span :class="{'active':activeIndex === icon}">
              <el-icon
                :class="icon"
                :title="icon"
                @click.native="chooseIcon(icon)"
                @dblclick.native="confirm"
              />
            </span>
            <p>{{ icon }}</p>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button plain type="warning" @click="isVisible = false">取消</el-button>
      <el-button plain type="primary" @click="confirm">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { CommonIconData, DirectivityIconData, SolidIconData, FoodIconData } from './config'

export default {
  name: 'Icons',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: '',
      icons: {
        commonIcons: CommonIconData,
        directivityIcons: DirectivityIconData,
        solidIcons: SolidIconData,
        foodIcons: FoodIconData
      },
      activeIndex: '',
      choosedIcon: '',
      activeName: 'first',
      screenWidth: 0,
      width: this.initWidth()
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.dialogVisible
      },
      set() {
        this.close()
      }
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.width = this.initWidth()
      })()
    }
  },
  methods: {
    initWidth() {
      this.screenWidth = document.body.clientWidth
      if (this.screenWidth < 991) {
        return '90%'
      } else if (this.screenWidth < 1400) {
        return '60%'
      } else {
        return '800px'
      }
    },
    close() {
      this.$emit('close')
      this.activeName = 'first'
      this.choosedIcon = this.activeIndex = ''
    },
    chooseIcon(icon) {
      this.activeIndex = icon
      this.choosedIcon = icon
    },
    confirm() {
      if (!this.choosedIcon) {
        this.$message({
          message: this.$t('tips.chooseNothing'),
          type: 'warning'
        })
        return
      }
      this.$emit('choose', this.choosedIcon)
      this.activeName = 'first'
      this.choosedIcon = this.activeIndex = ''
    },
    searchIcon() {
      if (this.name.trim() === '') {
        this.icons.commonIcons = CommonIconData
        this.icons.directivityIcons = DirectivityIconData
        this.icons.solidIcons = SolidIconData
        this.icons.foodIcons = FoodIconData
        this.activeName = 'first'
      }
      const commonList = CommonIconData.filter(item => item.indexOf(this.name) !== -1)
      const directivityList = DirectivityIconData.filter(item => item.indexOf(this.name) !== -1)
      const solidList = SolidIconData.filter(item => item.indexOf(this.name) !== -1)
      const foodIconsList = FoodIconData.filter(item => item.indexOf(this.name) !== -1)

      this.icons.commonIcons = commonList
      this.icons.directivityIcons = directivityList
      this.icons.solidIcons = solidList
      this.icons.foodIcons = foodIconsList

      if (commonList.length > 0) {
        this.activeName = 'first'
        return
      }

      if (directivityList.length > 0) {
        this.activeName = 'second'
        return
      }

      if (solidList.length > 0) {
        this.activeName = 'third'
        return
      }

      if (foodIconsList.length > 0) {
        this.activeName = 'fourth'
        return
      }

      this.activeName = 'first'
    }
  }
}
</script>
<style lang="scss" scoped>
  .search-item {
    margin: 0 0 10px;
  }

  ul {
    padding: 0;
    margin: 0;
    overflow: auto;
    zoom: 1;
    height: 500px;

    li {
      padding: 0;
      float: left;
      margin: 2px;
      width: 100px;
      text-align: center;
      list-style: none;
      cursor: pointer;
      color: #5c6b77;
      transition: all 0.2s ease;
      position: relative;
    }

    i {
      font-size: 1.7rem;
      border: 1px solid #f1f1f1;
      padding: 0.2rem;
      margin: 0.3rem;
      cursor: pointer;

      &:hover {
        border-radius: 2px;
        border-color: #4a4a48;
        background-color: #4a4a48;
        color: #fff;
        transition: all 0.3s;
      }
    }

    span.active {
      i {
        border-radius: 2px;
        border-color: #4a4a48;
        background-color: #4a4a48;
        color: #fff;
        transition: all 0.3s;
      }
    }

    li p {
      word-break: break-all;
      overflow: hidden;
      margin: 2px 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
