<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`描述信息`"
  />
</template>

<script>
import ParameterAPI from "@/api/common/parameter"
import {EditRemarkConfig} from './config.js'
import {mergeModelValue} from "@/utils/commons"

export default {
  data() {
    return {
      visible: false,
      formConfig: EditRemarkConfig.bind(this)(),
      id: ''
    }
  },
  methods: {
    init() {
      this.visible = true
      this.formConfig = EditRemarkConfig.bind(this)()
      ParameterAPI.getProcessRemark().then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        data.describe = data.value
        const model = this.formConfig.config.model
        mergeModelValue(model, data)
        if (res.data != null) {
          this.id = data.id
        }
      })
    },
    handleChange(key, value) {
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      if (!this.id) {
        ParameterAPI.saveProcessRemark(result).then((res) => {
          if (!res.isSuccess) {
            return
          }
          const data = res.data
          const model = this.formConfig.config.model
          mergeModelValue(model, data)
          this.visible = false
          this.$message.success('保存成功')
          this.$emit('submitSuccess')
        })
        return false
      }

      result.id = this.id
      ParameterAPI.updateProcessRemark(result).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.visible = false
        this.$message.success('保存成功')
        this.$emit('submitSuccess')
      })
    }
  }
}
</script>

<style scoped>
  /deep/.el-form-item__label{
    width: 120px !important;
  }
  /deep/.el-form-item__content{
    margin-left: 120px !important;
  }
/deep/ .el-textarea textarea{
  height: 100px!important;
}
</style>
