<template>
  <hm-drawer-description
    :visible="visible"
    title="预览现场照片"
    size="600px"
    @button-event="buttonEvent"
  >
    <div slot="drawer-body">
      <hm-description
        :config="detailConfig"
        :column-number="1"
      >
        <div slot="workDescribe" slot-scope="row" style="width: 100%;">
          <hm-editor v-if="row.data" v-model="row.data" :preview="true" />
        </div>
      </hm-description>
    </div>
  </hm-drawer-description>
</template>
<script>
import ScenePhotoAPI from "@/api/ep/scenePhoto"
import { DetailConfig } from './config.js'
import { mergeModelValue } from '@/utils/commons'

export default {
  name: 'Detail',
  data() {
    return {
      visible: false,
      detailConfig: DetailConfig.bind(this)()
    }
  },
  created() {

  },
  methods: {
    init(id) {
      this.visible = true
      this.getData(id)
    },
    getData(id) {
      ScenePhotoAPI.get(id).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        const model = this.detailConfig.config.model
        mergeModelValue(model, data)
      })
    },
    buttonEvent(type) {
      if (type === 'cancel') {
        this.visible = false
        this.detailConfig =  DetailConfig.bind(this)()
      }
    }
  }
}
</script>
