import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'homeCategory/'

export default {
  /**
   * 主营业务
   * @param params
   * @returns {*}
   */
  tree(params) {
    return http.get(PREFIX + `tree`, params)
  },
  /**
   * 查询结果
   * @param id
   * @returns {*}
   */
  get(id) {
    return http.get(PREFIX + `${id}`);
  },
  /**
   *
   * @param params
   * @returns {*}
   */
  list(params) {
    return http.post(PREFIX + `list`, params)
  },
  /**
   *获取主营业务
   * @param params
   * @returns {*}
   */
  getHomeCategoryList(params) {
    return http.get(PREFIX + `getHomeCategoryList`)
  }
}
