import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.COMMON + 'importRecord/'

export default {
  /**
     * 分页
     * @param params
     * @returns {*}
     */
  search(params) {
    return http.post(PREFIX + `search`, params)
  },
  /**
   * 批量删除
   * @param params
   * @returns {*}
   */
  batchDelete(params) {
    return http.delete(PREFIX + `batchDelete`, params);
  },
}
