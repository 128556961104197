<template>
  <el-drawer
      size="90%"
      :title="`电子名片--${id?'编辑':'新增'}`"
      :visible.sync="formVisible"
      :before-close="handleClose">
    <div class="components">
      <div class="content">
       <div class="form">
         <hm-form
             ref="form"
             label-width = "140px"
             :columns = "formConfig.list"
             :model = "formConfig.config.model"
             :rules = "formConfig.config.rules"
             :selects = "formConfig.config.selects"
             :on-submit="onSubmit"
         >
         </hm-form>
         <div class="row" @click="save" v-if="false">
           <a href="javascript:;" class="btn-submit">生成二维码</a>
         </div>
       </div>
        <div class="qrcode">
          <div class="code-box">
            <div class="code">
              <img :src="formConfig.config.model.qrCard ?  formConfig.config.model.qrCard : require('../../assets/image/qrcode.png')" alt="">
            </div>
            <a href="javascript:;" class="btn-submit btn-download" @click="save">生成二维码</a>
          </div>

          <div class="link-box" @click="downloadQrcode">
            <i class="el-icon-share" style="font-size: 30px;"></i>
            <span>下载该二维码</span>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import CorpCardApi from "@/api/ep/corpCard"
import CorpDataApi from '@/api/ep/corpData'
import {FormConfig} from "./config/businessCard"
import {mergeModelValue, downloadBlobFile} from "@/utils/commons"
import fileAPI from "@/api/fs/file"
export default {
  name: "businessEdit",
  data() {
    return {
      formVisible: false,
      formConfig: FormConfig.bind(this)(),
      id:''
    }
  },

  methods: {
    //下载图片
    downloadQrcode(){
      let url = this.formConfig.config.model.qrCard
      if(!url) {
        this.$message.warning('请先生成微信二维码')
        return
      }
      fileAPI.downloadByUrl(url).then(res => {
        downloadBlobFile(res)
      })
    },
    save(){
      this.$refs.form.valid()
    },
    //form校验
    onSubmit(result){
      result.dataIds = result.dataIds.join(",")
      result.link = `${window.location.host}/share?isSign=true&dataIds=`
      if (!this.id) {
        CorpCardApi.save(result).then((res) => {
          if (!res.isSuccess) {
            return
          }
          const data = res.data
          const model = this.formConfig.config.model
          mergeModelValue(model,data)
          this.$message.success('保存成功')
          this.$emit('submitSuccess', data)
        })

        return false
      }

      result.id = this.id
      CorpCardApi.update(result).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        this.$message.success('保存成功')
        this.$emit('submitSuccess', data)
      })
    },
    init(id){
      //获取关联企业资料
      let params={}
      CorpDataApi.list(params).then((res)=>{
        let data=res.data
        let dict = data.map(item => {
          let obj = {};
          obj.id = item.id
          obj.label = item.dataName
          let array = []
          if(item.filelist) {
            array = item.filelist.map(cell => {
              return {
                id:cell.id,
                label: cell.submittedFileName.split(".")[0]
              }
            })
          }
          obj.children = array.length ? array : null
          return obj
        })
        this.formConfig.config.selects['dataIds']=dict
      })
      this.id = id || ''
      this.formConfig = !id ? FormConfig.bind(this)() : FormConfig.bind(this)()
      this.formVisible = true
      if (!id) {
        return
      }
      CorpCardApi.get(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        data.dataIds = data.dataIds.split(",")
        const model = this.formConfig.config.model
        mergeModelValue(model, data)
      })
    },
    //添加文件夹
    add() {
      this.formVisible = true
    },
    //取消
    handleClose() {
      this.formVisible = false
      this.form = {
        name: ''
      }
    },
    //提交
    submit() {
      this.cancel()
    }
  }
}
</script>

<style scoped lang="scss">
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  display: flex;
  padding: 20px;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #F9F9FA;

  .form {
    padding: 20px 50px;
    box-sizing: border-box;
    flex: 1;
    background-color: #fff;

    .row {
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }
    }

    .label {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #999999;
      margin-bottom: 10px;
    }

    .rich-box {
      height: 400px;
      border: 1px solid #ddd;
    }
  }

  .btn-submit {
    display: block;
    width: 202px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: #359029;
    box-shadow: 0px 6px 18px rgba(65, 152, 54, 0.55);
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    border-radius: 46px;
  }

  .qrcode {
    margin-left: 60px;
    flex: 0 0 auto;
    width: 400px;

    .code-box {
      height: 350px;
      background-color: #fff;
      padding-top: 45px;
      box-sizing: border-box;
    }

    .code {
      display: block;
      width: 180px;
      height: 180px;
      margin: 0 auto;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .btn-download {
      margin: 50px auto 0 auto;
    }

    .link-box {
      margin-top: 30px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 70px;
      background-color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #359029;
      cursor: pointer;

      span {
        margin-left: 10px;
      }
    }
  }
}
</style>
