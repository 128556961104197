<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
.el-button--primary {
  background-color: #359029!important;
  border: #359029!important;
}
.el-button--primary:hover, .el-button--primary:focus {
  color: #42AB34;
  border-color: #42AB34;
  background-color: #42AB34;
}
.el-button--info:hover, .el-button--info:focus {
  background-color: #E4FFE1!important;
  border-color: #E4FFE1!important;
  color: #359029!important;
}
.el-button--info {
  background-color: #C7FFBF!important;
  border-color: #C7FFBF!important;
  color: #359029!important;
}
.el-tabs__item.is-active {
  color: #359029!important;
}
.el-tabs__item:hover {
  color: #359029!important;
}
.el-tabs__active-bar {
  background-color: #359029!important;
}
.el-input__inner:focus {
  border-color:  #359029!important;
}
.el-textarea__inner:focus {
  border-color:  #359029!important;
}
</style>
