export const componentsData = {}

// 搜索表单配置
export const SearchConfig = function () {
    return {
        list: [
            {
                label: '名称',
                key: 'name',
                type: 'input',
                placeholder: '名称'
            },
            {
                label: '编号',
                key: 'code',
                type: 'input',
                placeholder: '编号'
            },
            {
                label: '状态',
                key: 'open',
                type: 'select',
                placeholder: '状态'
            }
        ],
        config: {
            onSearch: data => {
                this.handleSearch(data)
            },
            selects: {
                open: [{text: '开启', value: true}, {text: '关闭', value: false}]
            },
            model: {
                code: '',
                startTime: '',
                endTime: ''
            }
        }
    }
}

// 表格展示配置
export const TableConfig = function () {
    return {
        list: [
            {
                type: 'selection',
                align: "center",
                width: 60
            },
            {
                label: '序号',
                align: "center",
                type: 'index',
                width: 60
            },
            {
                prop: '',
                align: 'center',
                label: '防治设施名称',
                slotScope: 'protectiveName',
            },
            {
                prop: '',
                align: 'center',
                label: '防治设施编号',
                slotScope: 'protectiveCode',
            },
            {
                align: "center",
                prop: 'qrCode',
                label: '二维码',
                slotScope: 'qrCode',
            },
            {
                align: "center",
                prop: 'open',
                label: '设施状态',
                formatter(row) {
                    if (row.open === true) {
                        return '开启'
                    } else if (row.open === false) {
                        return '关闭'
                    }
                }
            },
            {
                align: "center",
                prop: 'password',
                label: '密码',
            },
            {
                prop: 'createTime',
                align: 'center',
                label: '创建时间',
            },
            {
                fixed: 'right',
                label: '操作',
                align: 'center',
                slotScope: 'operation',
                'min-width': 100
            }
        ]
    }
}

// 新增对象配置
export const AddConfig = function () {
    return {
        list: [
            {
                label: '防治设施编号',
                key: 'referIds',
                type: 'slot',
                slotScope: 'selects',
                span: 24,
                placeholder: '防治设施编号'
            },
            {
                label: '防治设施名称',
                key: 'name',
                type: 'input',
                disabled:'disabled',
                span: 24,
            }
        ],
        config: {
            onSubmit: data => {
                this.handleSubmit(data)
            },
            onCancel: () => {
                this.handleCancel()
            },
            onChange: (key, value) => {
                this.handleChange(key, value)
            },
            model: {
                'referIds': '',
                'name': '',
                'code': ''
            },
            rules: {},
            selects: {
                referIds: []
            }
        }
    }
}

// 设置密码配置
export const SetPwdConfig = function () {
    return {
        list: [
            {
                label: '密码',
                key: 'password',
                type: 'input',
                span: 12,
                placeholder: '密码'
            }
        ],
        config: {
            onSubmit: data => {
                this.handleSubmit(data)
            },
            onCancel: () => {
                this.handleCancel()
            },
            onChange: (key, value) => {
                this.handleChange(key, value)
            },
            model: {
                'password': ''
            },
            rules: {},
        }
    }
}
