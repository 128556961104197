<template>
  <hm-drawer-description
      :visible="visible"
      title="预览危废品仓库  "
      size="600px"
      @button-event="buttonEvent"
  >
    <div slot="drawer-body">
      <hm-description
          :config="detailConfig"
          :column-number="1"
      >
        <div slot="signboard" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>
        <div slot="sealed" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>
        <div slot="paint" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>

        <div slot="posted" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>
        <div slot="agreement" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>

        <div slot="pallets" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>
        <div slot="channel" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>
        <div slot="monitor" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>
        <div slot="account" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>
        <div slot="isLock" slot-scope="row">
          <span style="color: black"> {{ row.data === 2 ? '' : (row.data === 1 ? "是" : "否") }}</span>
        </div>
        <div slot="sizeDetail" slot-scope="row">
          <span style="color: black"> {{ row.data }}</span>
        </div>
      </hm-description>
    </div>
  </hm-drawer-description>
</template>
<script>
import WareHouseAPI from "@/api/ep/wareHouse"
import {DetailConfig} from './config.js'
import {mergeModelValue} from '@/utils/commons'

export default {
  name: 'Detail',
  data() {
    return {
      visible: false,
      detailConfig: DetailConfig.bind(this)()
    }
  },
  created() {

  },
  methods: {
    init(id) {
      this.visible = true
      this.getData(id)
    },
    getData(id) {
      WareHouseAPI.get(id).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        const model = this.detailConfig.config.model
        mergeModelValue(model, data)
      })
    },
    buttonEvent(type) {
      if (type === 'cancel') {
        this.visible = false
      }
    }
  }
}
</script>
