import Regular from '@/utils/regular'

// 搜索表单配置
export const FolderSearchConfig = function () {
  return {
    list: [
      {
        label: '文件夹名称',
        key: 'dataName',
        type: 'input',
        placeholder: '请输入文件夹名称',
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
      selects: {},
      model: {}
    }
  }
}
// 新增对象配置
export const FolderAddConfig = function () {
  return {
    list: [
      {
        label: '账号',
        key: 'account',
        type: 'input',
        placeholder: '请输入账号'
      }, {
        label: '姓名',
        key: 'name',
        type: 'input',
        placeholder: '请输入姓名'
      }, {
        label: '密码',
        key: 'password',
        type: 'password',
        placeholder: '不输入时默认密码为123456'
      }, {
        label: '头像',
        key: 'avatar',
        bizType: 'Folder_AVATAR',
        type: 'avatar'
      }, {
        label: '邮箱',
        key: 'email',
        type: 'input',
        placeholder: '请输入邮箱'
      }, {
        label: '电话',
        key: 'mobile',
        type: 'input',
        placeholder: '请输入电话'
      }, {
        label: '性别',
        key: 'sex',
        type: 'select',
        placeholder: '性别'
      }, {
        label: '个人简介',
        key: 'content',
        type: 'editor',
        placeholder: '个人简介'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        account: '',
        name: '',
        password: '',
        avatar: '',
        email: '',
        mobile: '',
        sex: '',
        content: ''
      },
      rules: {
        account: [{required: true, message: '账号必填', trigger: 'blur'}, {
          validator: this.validator,
          trigger: 'blur'
        }],
        name: [{required: true, message: '姓名必填', trigger: 'blur'}],
        email: [{pattern: Regular.EMAIL, message: '邮箱格式不正确'}],
        mobile: [{pattern: Regular.MOBILE, message: '手机号格式不正确'}],
        sex: [{required: true, message: '性别必填', trigger: 'change'}],
      },
      selects: {
        sex: [{text: '女', value: 'WOMAN'}, {text: '男', value: 'MAN'}, {text: '未知', value: 'NO'}],
      }
    }
  }
}
// 表格展示配置
export const FolderTableConfig = function () {
  return {
    list: [
      {
        prop: 'dataName',
        'min-width': 1050,
        'max-width': 1250,
      },
      {
        fixed: 'right',
        align: 'center',
        slotScope: 'operation',
        'min-width': 100
      }
    ],
    config: {
      filters: {},
      model: {},
      onSearch: data => {
        this.handleFilterSearch(data)
      }
    }
  }
}
