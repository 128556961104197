import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.OAUTH + 'anno/'

export default {
  /**
     * 获取图形验证码
     * @param key
     */
  getCaptcha(key) {
    const config = { responseType: 'arraybuffer', meta: { 'X-isToken': false }}
    return http.get(PREFIX + `captcha`, { key: key }, config)
  },
  /**
     * 登录
     * @param params
     */
  login(params) {
    params.grantType = 'captcha'
    return http.post(PREFIX + `login`, params)
  },
  /**
     * 登录
     * @param params
     */
  ssoLogin(params) {
    params.grantType = 'sso'
    return http.post(PREFIX + `login`, params)
  },
  /**
     * 刷新token
     */
  refreshToken() {
    return PREFIX + `login`
  },
  /**
     * 获取路由
     * @returns {*}
     */
  getRouter() {
    return http.get(PREFIX + `getRouter`)
  },
  /**
     * 获取按钮权限
     * @returns {*}
     */
  getResource() {
    return http.get(PREFIX + `getResource`)
  }
}
