<template>
  <div class="page-wrap" style="margin: auto" v-if="visible">
    <div class="title breadcrumb" style="margin-bottom: 20px">
      <span @click="disable?'':back" class="breadcrumb-label">企业资料管理</span>
      <span class="el-icon-d-arrow-right icon"></span>
    </div>
    <div style="background-color: #fff;padding:30px;">
      <hm-search-form ref="searchForm" label-width="85" :data="searchConfig" size="normal"/>
      <div class="operation">
        <el-button type="primary" plain @click="add">新增文件夹</el-button>
      </div>
      <div class="table-box" style="height: calc(100% - 112px); margin-top: 20px">
        <el-card v-for="(item,index) in tableData" :key="index">
          <div class="folder-list">
            <div style="display: flex;align-items: center; width: calc(100% - 180px)" @click="nextData(item)">
              <!--          文件夹图片-->
              <div class="folder-box">
                <img
                    :src=" item.operate.code !== 'YSYSTEM' ? require('../../../assets/image/icon-folder.png') : require('../../../assets/image/icon-sys-folder.png')"
                    alt="">
              </div>
              <!--          文件夹名称-->
              <div class="folder-name">
                <span>{{ item.dataName }}</span>
              </div>
            </div>
            <!--          操作-->
            <div class="operator">
              <template v-if="item.operate.code !== 'YSYSTEM'">
                <span @click="deleteBtn(item.id)">删除</span>
                <div style="height: 20px;width: 2px; background: #359029;margin: 0px 15px;"></div>
              </template>
              <span @click="share">分享</span>
              <div style="height: 20px;width: 2px; background: #359029;margin: 0px 15px;"></div>
              <span @click="uploadFile(item)">上传</span>
            </div>
          </div>
        </el-card>
      </div>
      <!--    分页-->
      <el-pagination
          background
          layout="prev, pager, next"
          :total="params.total"
          @current-change="currentChange"
          style="text-align: center;">
      </el-pagination>
    </div>
    <!--    添加编辑文件夹 -->
    <el-dialog
        title="新增企业资料管理文件夹"
        :visible.sync="formVisible"
        top="10vh"
        width="50%">
      <el-form :model="form" ref="form" label-width="100px" :rules:="rules">
        <el-row>
          <el-col :span="23">
            <el-form-item label="文件夹名称">
              <el-input v-model="form.dataName" placeholder="请输入文件夹名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <share-dialog ref="share"/>
  </div>


</template>

<script>
import CorpDataApi from "@/api/ep/corpData"
import {FolderSearchConfig, FolderTableConfig} from '../config/folder.js'
import CorpShareApi from "@/api/ep/corpShare"
import FileApi from "@/api/file"
import Share from "../share";
import ShareDialog from "./share";


export default {
  components: {ShareDialog, Share},
  data() {
    return {
      visible: true,
      listRequest: CorpDataApi.search,
      tableConfig: FolderTableConfig.bind(this)(),
      searchConfig: FolderSearchConfig.bind(this)(),
      params: {
        model: {},
        total: 0,
        size: 10,
        current: 1
      },
      tableData: [],
      disable: false,
      formVisible: false,
      form: {
        dataName: '',
        operate: 'NSYSTEM'
      },
      rules: {
        dataName: [{required: true, message: '文件夹名称', trigger: 'blur'}]
      },
      isEditStatus: false, //编辑状态
      shareLink: '', //分享链接
      list: [],

    }
  },
  methods: {
    init() {
      this.visible = true
      this.params.current = 1
      this.getData(this.params)
    },
    currentChange(val) {
      this.params.current = val
      this.getData(this.params)
    },
    //取消
    cancel() {
      this.formVisible = false
      this.form = {
        dataName: '',
        operate: 'NSYSTEM'
      }
    },
    //设置分享
    share() {
      let ids = this.list.filter(item => {
        return item.checked
      }).map(item => {
        return item.id
      })
      let params = {
        fileIds: [],
        dataIds: ids
      }
      CorpShareApi.getShare(params).then(res => {
        let data = res.data
        this.$refs.share.init(data)
      })

    },

    //提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.form.id) {
            CorpDataApi.save(this.form).then(res => {
              if (!res.isSuccess) {
                return
              }
              const data = res.data
              this.cancel()
              this.$message.success('保存成功')
              this.getData(this.params)
            })
            return
          }
          CorpDataApi.update(this.form).then(res => {
            if (!res.isSuccess) {
              return
            }
            const data = res.data
            this.cancel()
            this.$message.success('保存成功')
            this.getData(this.params)
          })
        }
      })
    },
    handleSearch(data) {
      this.params = data
      this.getData(this.params)
    },
    getData(params) {
      CorpDataApi.search(params).then(res => {
        if (!res.isSuccess) {
          return
        }
        this.params.total = res.data.total
        this.params.size = res.data.size
        this.params.current = res.data.current
        this.tableData = res.data.records
      })
    },
    deleteBtn(id) {
      this.$confirm('此操作将永久删除文件夹以及文件下内容吗, 是否继续?', '提示', {type: 'warning'}).then(() => {
        CorpDataApi.delete({id: id}).then((res) => {
          if (!res.isSuccess) {
            return
          }
          this.$message.success('删除成功')
          if (this.tableData && this.tableData.length === 1) {
            this.params.current = this.params.current - 1
          }
          this.getData(this.params)
        })
      })
    },

    //添加文件夹
    add() {
      this.formVisible = true
    },

    nextData(item) {
      this.$emit("openFiles", item)
      this.visible = false
    },
    back() {
      this.visible = false
      this.$emit('back')
    },
    uploadFile(item) {
      this.$emit("openFiles", item)
      this.visible = false
    }
  },
  mounted() {
    this.getData(this.params)
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-card__body {
  padding: 10px 20px 5px !important;
}

/deep/ .el-button--primary {
  color: #fff;
}

.el-card {
  margin-bottom: 30px;
}

.folder-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.folder-box img {
  width: 80px;
  object-fit: cover;
}

.folder-name span {
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin-left: 13px;
  cursor: pointer;
}

.operator {
  display: flex;
  align-items: center;
}

.operator span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #359029;
  cursor: pointer;
}
</style>
