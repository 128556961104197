// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const SearchConfig = function () {
    return {
        list: [
            {
                label: '危废仓库名称',
                key: 'name',
                type: 'input',
                placeholder: '危废仓库名称'
            },
            {
                label: '危废仓库编号',
                key: 'code',
                type: 'input',
                placeholder: '危废仓库编号'
            },
        ],
        config: {
            onSearch: data => {
                this.handleSearch(data)
            },
            selects: {},
            model: {
                code: '',
                startTime: '',
                endTime: ''
            }
        }
    }
}

// 表格展示配置
export const TableConfig = function () {
    return {
        list: [
            {
                type: 'selection',
                align: "center",
                width: 60
            },
            {
                label: '序号',
                align: "center",
                type: 'index',
                width: 60
            },
            {
                align: 'center',
                label: '危废仓库名称',
                slotScope: 'name'
            },
            {
                prop: 'code',
                align: 'center',
                label: '危废仓库编号',
            },
            {
                prop: '',
                label: '是否有危险废物标识牌',
                align: 'center',
                formatter(row) {
                    return row.signboard === null ? '/' : row.signboard ? '是' : '否'
                }
            },
            {
                prop: '',
                label: '是否张贴危废管理制度标牌',
                align: 'center',
                formatter(row) {
                    return row.posted === null ? '/' : row.posted ? '是' : '否'
                }
            },
            {
                prop: '',
                label: '是否签署危废处置协议',
                align: 'center',
                formatter(row) {
                    return row.agreement === null ? '/' : row.agreement ? '是' : '否'
                }
            },
            {
                prop: '',
                align: 'center',
                label: '危废仓库是否密封',
                formatter(row) {
                    return row.sealed === null ? '/' : row.sealed ? '是' : '否'
                }
            },
            {
                prop: '',
                align: 'center',
                label: '仓库是否覆盖放渗透涂料',
                formatter(row) {
                    return row.paint === null ? '/' : row.paint ? '是' : '否'
                }
            },
            {
                prop: '',
                align: 'center',
                label: '危废品是否放在托盘上',
                formatter(row) {
                    return row.pallets === null ? '/' : row.pallets ? '是' : '否'
                }
            },
            {
                prop: '',
                align: 'center',
                label: '仓库是否设置导流槽',
                formatter(row) {
                    return row.channel === null ? '/' : row.channel ? '是' : '否'
                }
            },
            {
                prop: '',
                align: 'center',
                label: '仓库是否安装监控设施',
                formatter(row) {
                    return row.monitor === null ? '/' : row.monitor ? '是' : '否'
                }
            },
            {
                prop: '',
                align: 'center',
                label: '仓库是否设置台账并记录',
                formatter(row) {
                    return row.account === null ? '/' : row.account ? '是' : '否'
                }
            },
            {
                prop: '',
                align: 'center',
                label: '是否设置双人双锁',
                formatter(row) {
                    return row.isLock === null ? '/' : row.isLock ? '是' : '否'
                }
            },
            {
                prop: 'sizeDetail',
                align: 'center',
                label: '尺寸',
            },
            {
                prop: 'area',
                align: 'center',
                label: '面积',
            }
        ]
    }
}

// 预览配置
export const DetailConfig = function () {
    return {
        list: [
            {
                label: '危废仓库名称',
                key: 'name',
            },
            {
                label: '危废仓库编号',
                key: 'code'
            },
            {
                label: '是否有危险废物标识牌',
                key: 'signboardNull',
                slotScope: 'signboard'
            },
            {
                label: '是否张贴危废管理制度标牌',
                key: 'postedNull',
                slotScope: 'posted'
            },
            {
                label: '是否签署危废处置协议',
                key: 'agreementNull',
                slotScope: 'agreement'
            },
            {
                label: '危废仓库是否密封',
                key: 'sealedNull',
                slotScope: 'sealed'
            },
            {
                label: '仓库是否覆盖防渗透涂料',
                key: 'paintNull',
                slotScope: 'paint'
            },
            {
                label: '危废品是否放在托盘上',
                key: 'palletsNull',
                slotScope: 'pallets'
            },
            {
                label: '仓库是否设置导流槽',
                key: 'channelNull',
                slotScope: 'channel'
            },
            {
                label: '仓库是否安装监控设施',
                key: 'monitorNull',
                slotScope: 'monitor'
            },
            {
                label: '仓库是否设置台账并记录',
                key: 'accountNull',
                slotScope: 'account'
            },
            {
                label: '是否设置双人双锁',
                key: 'isLockNull',
                slotScope: 'isLock'
            },
            {
                label: '尺寸',
                key: 'sizeDetail',
                slotScope: 'sizeDetail'
            },
            {
                label: '仓库现场照片',
                key: 'sceneImage',
                type: 'images'
            },
        ],
        config: {
            model: {
                name: '',
                code: '',
                corpId: '',
                signboardNull: '',
                sealedNull: '',
                paintNull: '',
                palletsNull: '',
                channelNull: '',
                monitorNull: '',
                accountNull: '',
                isLockNull: '',
                sizeDetail: '',
                sceneImage: '',
                postedNull: '',
                agreementNull: ''
            }
        }
    }
}