<template>
  <div>
    <el-upload
      action=""
      class="disUoloadSty"
      list-type="picture-card"
      :file-list="files"
      :disabled="true"
      :auto-upload="false"
    >
      <div slot="file" slot-scope="{file}">
        <img
          class="el-upload-list__item-thumbnail"
          :src="file.url"
          :alt="file.name"
        >
        <span class="el-upload-list__item-actions">
          <span
            v-if="file.dataType && file.dataType.code === 'IMAGE'"
            class="el-upload-list__item-preview"
            @click="onPreview(file)"
          >
            <i class="el-icon-zoom-in" />
          </span>
          <span
            v-if="options.indexOf('DOWNLOAD') >= 0"
            class="el-upload-list__item-delete"
            @click="onDownload(file.id)"
          >
            <i class="el-icon-download" />
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="imageVisible" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import FileApi from '@/api/fs/file'
import { downloadBlobFile } from '@/utils/commons'
export default {
  name: 'HmPictureCard',
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      imageVisible: false
    }
  },
  computed: {
    files() {
      return this.config.files
    },
    options() {
      return this.config.options || []
    }
  },
  methods: {
    onPreview(file) {
      this.dialogImageUrl = file.url
      this.imageVisible = true
    },
    onDownload(id) {
      FileApi.downloadFile({ ids: [id] }).then((response) => {
        downloadBlobFile(response)
      })
    }
  }
}
</script>
<style lang="scss">
    .disUoloadSty .el-upload--picture-card{
        display:none;   /* 上传按钮隐藏 */
    }
</style>
