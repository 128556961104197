<template>
  <div class="components">
    <div class="title">排污口电子化标示牌</div>
    <div class="content">
      <hm-search-form ref="searchForm" :data="searchConfig" size="normal" label-width="150"/>
      <div class="btns-box">
        <el-button type="primary" @click="add"  icon="el-icon-plus">新增标示牌</el-button>
        <el-button type="primary" @click="batchDelete()" icon="el-icon-delete">批量删除</el-button>
      </div>
      <div style="margin-top: 20px;">
        <hm-table ref="table"
                  :stripe = "true"
                  :border = "true"
                  :header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}"
                  :api="listRequest"
                  :table-config="tableConfig">
          <div slot="content" slot-scope="row">
            <el-image
                style="width: 100px; height: 100px"
                :src="row.data.content"
                :preview-src-list="[row.data.content]">
            </el-image>
          </div>
          <div slot="cover" slot-scope="row">
            <el-avatar :key="row.data.cover" :src="row.data.cover" shape="square" :size="50"></el-avatar>
          </div>
          <div slot="operation" slot-scope="row">
            <el-button class="table-operation" type="text" @click="edit(row.data)">编辑</el-button>
            <el-button class="table-operation" type="text" @click="remove(row.data)">删除</el-button>
            <el-button class="table-operation" type="text" @click="viewCard(row.data)" v-if="downloadAuth">查看图片</el-button>
          </div>
        </hm-table>
      </div>
    </div>
    <signage-brand-edit ref="form" @submitSuccess="submitSuccess"></signage-brand-edit>
    <signage-brand-card ref="signageBrandCard" />
  </div>
</template>

<script>
import SidnboardApi from "@/api/ep/sidnboard"
import {SignageBrandSearchConfig, SignageBrandTableConfig} from "./config/signageBrand"
import {mergeJson} from '@/utils/commons'
import OutSideAccountEdit from "./outSideAccountEdit";
import SignageBrandEdit from "./signageBrandEdit";
import SignageBrandCard from "./signageBrandCard";

export default {
  name: "signageBrand",
  components: {SignageBrandCard, SignageBrandEdit},
  data() {
    return {
      listRequest: SidnboardApi.search,
      searchConfig: SignageBrandSearchConfig.bind(this)(),
      tableConfig: SignageBrandTableConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {},
        // table下拉条件
        tableParams: {},
      },
      downloadAuth: this.$store.getters.sso
    }
  },
  methods: {
    viewCard(data){
      this.$refs.signageBrandCard.init(data)
    },
    edit(item) {
      this.$refs.form.init(item.id)
    },
    add() {
      this.$refs.form.init()
    },
    remove(data){
      this.batchDelete(data.id)
    },
    batchDelete(id) {
      let ids;
      if(id) {
        ids = [id]
      }else {
        ids = this.$refs.table.getSelections()['ids']
      }
      if (ids.length <= 0) {
        this.$message.error('至少选择一项')
        return false
      }
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {type: 'warning'}).then(() => {
        SidnboardApi.batchDelete({ids: ids}).then((res) => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
      })
    },
    handleSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.$refs.table.reload(params)
    },
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}
.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  width: 50px;
  height: 50px;
}
</style>
