<template>
  <el-drawer
    :append-to-body="appendToBody"
    :before-close="beforeClose"
    :close-on-press-escape="closeOnPressEscape"
    :custom-class="customClass"
    :destroy-on-close="destroyOnClose"
    :modal="modal"
    :modal-append-to-body="modalAppendToBody"
    :direction="direction"
    :show-close="showClose"
    :size="size"
    :with-header="false"
    :wrapper-closable="wrapperClosable"
    :visible="visible"
    @close="close"
  >
    <div class="draw-main">
      <slot name="drawer-header">
        <div class="drawer-header">
          <div class="title-box">{{ title }}</div>
          <div class="draw-btns">
            <i class="el-icon-close close-btn" @click="close" />
            <el-dropdown v-if="buttons && buttons.length" @command="handleCommand">
              <el-button size="small">
                更多按钮<i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(button,index) in buttons" :key="index" :command="button">{{ button }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </slot>
      <div class="drawer-body">
        <el-tabs v-model="activeIndex" type="border-card">
          <el-tab-pane v-for="(tab,index) in tabs" :key="index" :label="tab.label" :name="tab.label">
            <slot v-if="activeIndex === tab.label" :name="tab.slotScope" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-drawer>
</template>

<script>

/**
     * buttons  默认null， 详情页操作按钮
     * title    String  默认 '详情'
     */
export default {
  name: 'HmDrawerTabs',
  props: { // drawer配置，保留elementUI全部的配置项
    // 新增 start ,用于引用hm-description 组件
    title: {
      type: String,
      default: '详情'
    },
    buttons: { // 页面按钮
      type: Array,
      default: null
    },
    // 新增 end
    appendToBody: {
      type: Boolean,
      default: false
    },
    beforeClose: {
      type: Function,
      default: null
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: String,
      default: null
    },
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      default: true
    },
    modalAppendToBody: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String,
      default: 'rtl'
    },
    showClose: {
      type: Boolean,
      default: true
    },
    size: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: '50%'
    },
    wrapperClosable: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      required: false
    },
    tabs: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      activeIndex: this.tabs[0].label
    }
  },
  methods: {
    // emit用户点击事件
    handleCommand(button) {
      this.$emit('button-event', button)
    },
    // 关闭事件
    close() { // emit父节点关闭
      this.$emit('button-event', 'cancel')
    }
  }
}
</script>

<style lang="scss">
.draw-main {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.drawer-header {
  display: flex;
  font-size: 14px;
  padding: 10px 20px;
  box-sizing: border-box;
}

.drawer-body {
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
}

.title-box {
  flex-grow: 1;
  width: 0;
  line-height: 30px;
  word-break: break-word;
  font-weight: bold;
  color: #333;
}

.close-btn {
  margin-right: 5px;
  cursor: pointer;
  font-size: 24px;
}

.draw-main .el-tabs--border-card {
  border: 0;
  box-shadow: none;
}

.draw-main .el-tabs__item {
  height: 38px !important;
  line-height: 38px !important;
}

.draw-main .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #f5f7fa;
  border-right-color: #f5f7fa;
  border-left-color: #f5f7fa;
}
</style>
