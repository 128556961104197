<template>
  <el-drawer
      :visible.sync="detailVisible"
      :before-close="handleClose">
    <div class="archives container" v-if="detailVisible">
      <div class="top">
        <p>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.companyName }}</p>
        <p>电子环保档案</p>
        <p>安徽一二三环保科技有限公司</p>
      </div>
      <div class="main">
        <div class="title">
          <div class="number">01</div>
          <p>企业基础信息</p>
        </div>
        <div class="info">
          <ul>
            <li>单位名称:</li>
            <li>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.companyName }}</li>
          </ul>
          <ul>
            <li>法定代表人:</li>
            <li>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.legalPerson }}</li>
          </ul>
          <ul>
            <li>统一社会信用代码:</li>
            <li>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.licenseNumber }}</li>
          </ul>
          <ul>
            <li>单位住址:</li>
            <li>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.address }} </li>
          </ul>
          <ul>
            <li>全厂排污口分布示意图:</li>
            <li><img :src="protectionArchivesDetail.signalImage && protectionArchivesDetail.signalImage.value" alt=""></li>
          </ul>
          <ul class="process">
            <li>生产工艺流程:</li>
            <p style="margin-bottom: 5px">{{protectionArchivesDetail.processRemark}}</p>
            <div v-for="item in protectionArchivesDetail.processData">
              <div class="msg">{{item.title}}</div>
              <img :src="item.image" alt="">
            </div>
          </ul>
        </div>
        <div class="title">
          <div class="number">02</div>
          <p>企业现有环保档案</p>
        </div>
        <div class="signboard">
          <a class="down" href="#">下列文档可点击查看/下载</a>
          <table class="table">
            <tr>
              <th>项目名称</th>
              <th>手续/资料种类</th>
              <th>时间</th>
              <th>批复/备案文号</th>
            </tr>
            <tr v-for="eepaData in protectionArchivesDetail.eepaData">
              <td :rowspan="eepaData.rowspan" v-if="eepaData.dataHandleName">{{ eepaData.dataHandleName }}</td>
              <td>{{ eepaData.data.data.dataName }}</td>
              <td>{{ eepaData.dataYear }}</td>
              <td>{{ eepaData.filingCopy }}</td>
            </tr>
          </table>
          <div v-for="(data, index) in protectionArchivesDetail.eepaData" v-if="data.files && data.files.length">
            <h3 class="project-name" v-if="data.data.data.dataName!==data.dataName">{{ index + 1 }}、{{ data.dataName }}——{{ data.data.data.dataName }}</h3>
            <h3 class="project-name" v-else>{{ index + 1 }}、{{ data.dataName }}</h3>
            <div class="file-box">
              <div class="file" v-for="file in data.files">
                <a :href="file.url">
                  <ul class="img-box">
                    <img v-if="file.ext === 'png'" src="../../../assets/image/icon/icon_png.png" alt="">
                    <img v-else-if="file.ext === 'pdf'" src="../../../assets/image/icon/icon-pdf.png" alt="">
                    <img v-else-if="file.ext === 'word'" src="../../../assets/image/icon/icon-word.png" alt="">
                    <img v-else-if="file.ext === 'docx'" src="../../../assets/image/icon/icon-word.png" alt="">
                    <img v-else-if="file.ext === 'jpg'" src="../../../assets/image/icon/icon_jpg.png" alt="">
                    <img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
                  </ul>
                  <ul>
                    <li class="submittedFileName">{{ file.submittedFileName }}</li>
                    <li>{{ (file.size / 1024).toFixed(2)}}kb</li>
                  </ul>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="title">
          <div class="number">03</div>
          <p>企业现场照片</p>
        </div>
        <div class="swiper" v-if="protectionArchivesDetail.sceneImages && protectionArchivesDetail.sceneImages.length">
          <div v-for="(sceneImage,index) in protectionArchivesDetail.sceneImages" :key="index" v-if="sceneImage.image">
            <h3 class="project-name">{{ index + 1 }}、{{ sceneImage.title }}</h3>
            <el-carousel :interval="4000" type="card" height="200px" :loop="true">
              <el-carousel-item :key="url" v-for="(url,index) in sceneImage.image.split(',')">
                <img :src="url" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="bottom">
          <div class="logo-box">
            <p v-if="protectionArchivesDetail.corpContent !== null" v-html="protectionArchivesDetail.corpContent && protectionArchivesDetail.corpContent.value"></p>
<!--            <p class="time">本排污口电子标识制作时间:{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.createTime }}</p>-->
            <p class="time">本环保信息电子标识制作时间:{{date }}</p>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import CorpDataApi from '@/api/ep/corpData'
  import moment from "moment";

  export default {
    name: "detail",
    data() {
      return {
        id: '',
        detailVisible: false,
        protectionArchivesDetail: {},
        date:'',
      }
    },
    methods: {
      init(id) {
        this.id = id
        this.detailVisible = true
        this.getTemplateDetail(id)
      },
      handleClose() {
        this.detailVisible = false
      },
      getTemplateDetail(id) {
        CorpDataApi.getDetail(this.id).then(res => {
          let eepaData = res.data.eepaData
          let date = res.data.corpData.createTime
          this.date = moment(date).format("YYYY年MM月DD日");
          if (eepaData && eepaData.length) {
            let count = 0, firstItem = null;
            eepaData.forEach((item) => {
              if (!firstItem) {
                item.dataHandleName = item.dataName
                firstItem = item
                ++count;
                return true
              }

              if(firstItem.dataName === item.dataName){
                item.dataHandleName = ''
                ++count;
                return true
              }

              firstItem.rowspan = count;
              item.dataHandleName = item.dataName
              firstItem = item
              count = 1;
            })

            firstItem.rowspan = count;
          }

          this.protectionArchivesDetail = res.data
        })
      },
    },
  }
</script>

<style scoped>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .container {
    width: 375px;
    margin: 0 auto;
  }

  .main {
    padding: 30px 16px;
    border: 1px solid #CCCCCC;
  }

  .main .title {
    display: flex;
    justify-content: flex-start;
    height: 48px;
    align-items: center;
    background: #C7E5D6;
    color: #20985A;
  }

  .main .title .number {
    width: 48px;
    height: 48px;
    background: #20985A;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    line-height: 48px;
    color: #FFFFFF;
  }

  .main .title p {
    padding-left: 15px;
  }

  .main .info ul {
    margin-top: 30px;
    color: #333333;
  }

  .main .info ul li:nth-child(1) {
    font-weight: bold;
    margin-bottom: 5px;
    color: #000000;
  }

  .signboard {
    margin-top: 30px;
  }

  .signboard ul {
    margin-bottom: 15px;
  }

  .signboard ul:nth-child(1) li:nth-child(1) {
    font-weight: bold;
    margin-bottom: 5px;
    color: #000000;
    text-align: left;
  }

  .signboard ul:nth-child(1) li {
    margin: 0 auto;
    text-align: center;
  }

  table {
    border-collapse: collapse;
    color: #000000;
    margin-bottom: 40px;
  }

  table, table tr td {
    border: 1px solid #ccc;
  }

  table {
    table-layout: fixed;
    word-break: break-all;
    word-wrap: break-word;
  }

  .table th {
    font-weight: bold;
    background: #F0F2F5;
    font-size: 16px;
  }

  .table th, .table td {
    padding: 12px 10px;
  }

  .table td {
    font-size: 12px;
  }

  .file-box {
    box-shadow: #f1f0f0 0 0 8px 3px;
    border-radius: 6px;
    padding: 16px;
    width: 90%;
    margin: 0 auto;
  }

  .file-box .file a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #CCCCCC;
  }

  .file-box .file a:last-child {
    border: none;
  }

  .file-box .file .img-box {
    width: 39px;
    height: 48.5px;
  }

  .file-box .file ul:nth-child(2) {
    padding-right: 30px;
    margin-left: 10px;
    width: calc(100% - 50px);
  }

  .file-box .file ul:nth-child(2) li:nth-child(2) {
    font-size: 12px;
  }

  .file-box .submittedFileName{
    word-wrap: break-word;
  }

  .logo-box {
    border-top: 1px solid #20985A;
    margin: 50px 0 40px;
  }

  .logo-box .logo {
    width: 78px;
    height: 78px;
    margin: 25px auto 10px;
  }

  .logo-box h4 {
    font-size: 16px;
    text-align: center;
    color: #000000;
  }
  .logo-box p{
    padding: 30px 10px;
    color: #000;
  }
  .logo-box .time{
    text-align: center;
    font-size: 14px;
    color: #000000;
  }

  .bottom ul {
    margin: 20px 0;
  }

  .bottom ul li:nth-child(1) {
    color: #000000;
  }

  .bottom p {
    margin-top: 15px;
  }

  .bottom .number {
    text-align: center;
    color: #000000;
    margin: 40px 0;
  }

  .bottom h6 span {
    color: #666666;
  }

  .bottom h5 {
    text-align: center;
  }

  .bottom .QR-code {
    width: 114px;
    height: 114px;
    margin: 30px auto;
    border: 1px solid;
  }

  .bottom h1 {
    text-align: center;
  }

  .archives .top {
    background: #205792;
    width: 100%;
    height: 150px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-top: 33px;
  }

  .archives .main .title {
    display: flex;
    justify-content: flex-start;
    height: 48px;
    align-items: center;
    background: #C7D5E4;
    color: #205792;
  }

  .archives .main .title .number {
    width: 48px;
    height: 48px;
    background: #205792;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    line-height: 48px;
    color: #FFFFFF;
  }

  .archives .main .title p {
    padding-left: 15px;
  }

  .archives .main .process {
    margin: 0 0 50px;
  }
  .process p{
    padding: 10px 0;
  }
  .archives .main .process .msg {
    color: #333333;
    padding: 20px 0;
  }

  .archives .signboard .down {
    color: #205792;
    display: block;
    margin-bottom: 25px;
  }

  .archives table {
    border-collapse: collapse;
    color: #000000;
    margin-bottom: 40px;
  }

  .archives table, table tr td, table tr th {
    border: 1px solid #ccc;
  }

  .archives .table th {
    font-weight: bold;
    background: #F0F2F5;
    font-size: 16px;
  }

  .archives .table th, .table td {
    width: 25%;
    padding: 12px 10px;
    text-align: center;
  }

  .archives .table td {
    font-size: 12px;
  }

  .archives .logo-box {
    border-top: 1px solid #205792;
    margin: 50px 0 40px;
  }

  .archives .project-name {
    margin: 30px 0;
    color:#000;
    font-weight: bold;
    font-size: 15px;
  }

  .archives .signboard {
    margin-bottom: 30px;
  }

  .archives .swiper {
    margin-top: 30px;
  }
</style>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>