<template>
  <div class="wrap register-wrap">
    <div class="banner">
      <img src="../assets/image/login-mini.png" alt="">
    </div>
    <div class="form">
      <div class="form-box">
        <el-row>
          <!--          <img src="../assets/image/logo-mini.png" alt="" class="logo" :style="{width: width}">-->
          <p class="logo-text">欢迎登录一二三环保平台</p>
        </el-row>
        <el-form ref="formBox" :model="form" :rules="rules" :inline-message="true" label-width="120px" class="el-form">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="公司名称" prop="companyName" :style="{'margin-bottom': bottom}">
                <el-input v-model="form.companyName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="营业执照号" prop="licenseNumber" :style="{'margin-bottom': bottom}">
                <el-input v-model="form.licenseNumber" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公司性质" prop="nature" :style="{'margin-bottom': bottom}">
                <el-input v-model="form.nature" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="经营范围" prop="businessScope" :style="{'margin-bottom': bottom}">
                <el-input v-model="form.businessScope" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="法人代表名称" prop="legalPerson" :style="{'margin-bottom': bottom}">
                <el-input v-model="form.legalPerson" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公司地址" prop="provinceValue" :style="{'margin-bottom': bottom}">
                <el-col :span="8">
                  <el-select v-model="form.provinceValue" @change="selectProvince(form.provinceValue)" clearable
                             placeholder="选择省">
                    <el-option
                        v-for="item in provinceData"
                        :key="item.id"
                        :label="item.fullName"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <el-select v-model="form.cityValue" @change="selectCity(form.cityValue)" clearable placeholder="选择市">
                    <el-option
                        v-for="item in cityData"
                        :key="item.id"
                        :label="item.fullName"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <el-select v-model="form.areaValue" clearable placeholder="选择区">
                    <el-option
                        v-for="item in areaData"
                        :key="item.id"
                        :label="item.fullName"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公司详细地址" prop="address" :style="{'margin-bottom': bottom}">
                <el-input v-model="form.address" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="联系电话" prop="phone" :style="{'margin-bottom': bottom}">
                <el-input v-model="form.phone" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="图形验证码" prop="img" :style="{'margin-bottom': bottom}" style="position: relative;">
                <el-input v-model="form.img" placeholder="请输入"></el-input>
                <div class="img-code">
                  <img :src="imageSrc" alt="" @click.stop="getCodeImage">
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="验证码" prop="code" :style="{'margin-bottom': bottom}" style="position: relative;">
                <el-input v-model="form.code" placeholder="请输入"></el-input>
                <div class="img-code btn">
                  <el-button size="mini" @click="getMessageCode" :disabled="disabled">
                    {{ disabled ? `剩余${count}S` : '获取验证码' }}
                  </el-button>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="密码" prop="password" :style="{'margin-bottom': bottom}">
                <el-input v-model="form.password" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item prop="license" label="营业执照照片">
              <div style="display: flex;">
                <el-upload
                    style="display: flex;width: 100px;height: 100px;border: 1px dashed #ddd;line-height: 50px;text-align: center;"
                    :action="action"
                    accept=".jpg,.jpeg,.png,.gif,.bmp,JPG,.JPEG,.PBG,.GIF"
                    :data="{bizType: 'REGISTER'}"
                    :show-file-list="false"
                    :on-success="onSuccess">
                  <img v-if="form.license" :src="form.license" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 30px;"></i>
                </el-upload>
                <div class="el-upload__text" style="margin: 15px 0 0 10px;height: 100px;"><p>
                  只能上传jpg/png文件，且不超过500kb</p></div>
              </div>
            </el-form-item>
          </el-row>
          <el-row>
            <el-button class="submit" @click="submit">注册</el-button>
            <p class="tip">已有账号？<span @click="toLogin">去登录！</span></p>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import FileApi from "@/api/file"
import LoginApi from '@/api/uc/login'
import verificationApi from '@/api/ep/verification'
import {randomNum} from '@/utils/index'
import CorpAccountApi from "@/api/ep/corpAccount"
import AreaApi from "@/api/common/area"

export default {
  name: 'register',
  data() {
    return {
      action: FileApi.singleUpload(),
      imageSrc: '',
      checkPhoneResult: '',
      form: {
        address: '',
        businessScope: '',
        companyName: '',
        legalPerson: '',
        license: '',
        licenseNumber: '',
        nature: '',
        password: '',
        img: '',
        code: '',
        phone: '',
        key: randomNum(24, 16),
        provinceValue: '',
        cityValue: '',
        areaValue: '',
      },
      width: 0,
      bottom: 0,
      disabled: false,
      count: 60,
      // 省市区数据
      provinceData: [],
      cityData: [],
      areaData: [],
      rules: {
        companyName: [
          {required: true, message: ' ', trigger: 'blur'},
        ],
        licenseNumber: [
          {required: true, message: ' ', trigger: 'blur'},
        ],
        nature: [
          {required: true, message: ' ', trigger: 'blur'},
        ],
        businessScope: [
          {required: true, message: ' ', trigger: 'blur'},
        ],
        legalPerson: [
          {required: true, message: ' ', trigger: 'blur'},
        ],
        provinceValue: [
          {required: true, message: ' ', trigger: 'change'}
        ],
        cityValue: [
          {required: true, message: ' ', trigger: 'change'}
        ],
        areaValue: [
          {required: true, message: ' ', trigger: 'change'}
        ],
        address: [
          {required: true, message: ' ', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: ' ', trigger: 'blur'}
        ],
        img: [
          {required: true, message: ' ', trigger: 'blur'}
        ],
        code: [
          {required: true, message: ' ', trigger: 'blur'}
        ],
        password: [
          {required: true, message: ' ', trigger: 'blur'}
        ],
        license: [
          {required: true, message: '请上传营业执照', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.width = (window.innerHeight / 1080) * 140 + 'px'
    if (window.innerHeight < 700) {
      this.bottom = 10 + 'px'
    }
    if (window.innerHeight < 800) {
      this.bottom = 20 + 'px'
    }
    if (window.innerHeight > 800) {
      this.bottom = 30 + 'px'
    }
    this.getCodeImage()
    this.getProvince()
  },
  components: {},
  methods: {
    // 获取省
    getProvince() {
      AreaApi.getProvince().then(res => {
        this.provinceData = res.data
      })
    },
    // 选中省
    selectProvince(e) {
      this.getCity(e)
    },
    // 获取市
    getCity(id) {
      AreaApi.getCity(id).then(res => {
        this.cityData = res.data
      })
    },
    // 选中市
    selectCity(e) {
      this.getArea(e)
    },
    // 获取区
    getArea(id) {
      AreaApi.getCity(id).then(res => {
        this.areaData = res.data
      })
    },
    //获取验证码
    getMessageCode() {
      if (!(this.form.phone && /^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.form.phone))) {
        this.$message.error('请输入正确格式的手机号')
        return
      }
      if (!this.form.img) {
        this.$message.error('请输入图形验证码')
        return
      }
      this.checkPhone()
    },
    checkPhone() {
      CorpAccountApi.chenckPhone(this.form.phone).then((res) => {
        if (!res.data) {
          this.$message.error('手机号已注册')
          return
        }
        this.disabled = true
        let params = {
          mobile: this.form.phone,
          type: 'REGISTER_USER',
          code: this.form.img,
          key: this.form.key
        }
        this.tt = setInterval(() => {
          if (this.count <= 0) {
            clearInterval(this.tt)
            this.disabled = false
            this.count = 60
          }
          this.count = this.count - 1
        }, 1000)


        verificationApi.send(params).then(res => {
          if (res.isSuccess) {
            this.$message.success('验证码已发送')
            return
          }
          clearInterval(this.tt)
          this.disabled = false
          this.count = 60
        })
      })
    },
    getCodeImage() {
      LoginApi.getCaptcha(this.form.key).then(res => {
        res = res.data
        if (res.byteLength <= 100) {
          this.$message({
            message: this.$t('tips.systemError'),
            type: 'error'
          })
        }

        return 'data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))
      }).then(res => {
        this.imageSrc = res
      }).catch(e => {
        if (e.toString().indexOf('429') !== -1) {
          this.$message({
            message: '请求次数太多',
            type: 'error'
          })
        } else {
          this.$message({
            message: '获取图形验证码失败',
            type: 'error'
          })
        }
      })
    },
    toLogin() {
      this.$router.push('/login')
    },
    //提交
    submit() {
      this.$refs['formBox'].validate((valid) => {
        if (valid) {
          let form = this.form
          form.province = this.form.provinceValue
          form.city = this.form.cityValue
          form.area = this.form.areaValue
          CorpAccountApi.save(form).then(res => {
            this.getCodeImage()
            if (!res.isSuccess) {
              return
            }
            this.$message.success('保存成功')
            this.$router.push('/')
          })
        }
      })
    },
    //上传成功
    onSuccess(res) {
      this.form['license'] = res.data.url
    }
  }
}
</script>

<style scoped lang="scss">
.img-code {
  position: absolute;
  top: 1px;
  right: 15px;
  height: calc(100% - 2px);

  img {
    max-height: 38px;
  }
}

.btn {
  top: -1px;
}

.avatar {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/deep/ .el-upload {
  display: block;
  width: 100%;
  line-height: 100px;
}

.wrap {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.banner,
.form {
  flex: 1;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.form {
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  display: block;
  width: 140px;
  margin: 0 auto;
}

.logo-text {
  margin-top: 18px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  line-height: 30px;
  color: #333333;
  text-align: center;
}

.submit {
  display: block;
  width: 100%;
  background-color: #359029;
  color: #fff;
  box-shadow: 0px 7px 22px rgba(103, 175, 94, 0.44);
}

.el-form {
  margin-top: 30px;
}

.form-box {
  max-width: 70%;
  padding: 0 5px;
  box-sizing: border-box;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

::-webkit-scrollbar-thumb { //滑块部分
  border-radius: 5px;
  background-color: #ffffff;
}

::-webkit-scrollbar-track { //轨道部分
  background: #FFFFFF;
  border-radius: 5px;
}

.tip {
  margin-top: 15px;
  height: 13px;
  color: #999999;
  text-align: center;
}

.tip span {
  color: #359029;
}

.tip span:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
<style lang="scss">
.el-col {
  height: 53px;
}

.register-wrap .el-input__inner {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #DCDFE6;
}

//.register-wrap .el-input__inner:focus {
//  border-bottom: 1px solid #359029;
//}

//.register-wrap .el-upload__tip {
//  margin-top: 10px;
//  line-height: 20px;
//}

//::v-deep .el-select {
//  width: 350px;
//}
//
//::v-deep .el-input__inner {
//  background-color: transparent;
//}
//
//::v-deep .el-select-dropdown__item {
//  color: #fff;
//}
//
//::v-deep .el-scrollbar,
//::v-deep .el-select-dropdown {
//  background-color: transparent !important;
//  color: #fff !important;
//}
//
//::v-deep .el-scrollbar__wrap,
//::v-deep .el-select-dropdown__list {
//  background-color: #0b1a37;
//  color: #fff !important;
//}
//
//::v-deep .el-select-dropdown__item.hover,
//.el-select-dropdown__item:hover {
//  background-color: rgba(0, 0, 0, 0.3);
//  color: #fff;
//}
</style>
