(function (draftWidth, n) {
  function getRem() {
    var html = document.querySelector("html");
    var w = document.documentElement.clientWidth;
    var rem = (w / draftWidth) * n;
    html.style.fontSize = rem + "px";
  }
  getRem();
  window.addEventListener("resize", function () {
    getRem();
  });
})(375, 10);
