import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'outletDevice/'

export default {
    /**
     * 分页
     * @param params
     * @returns {*}
     */
    search(params) {
        return http.post(PREFIX + `search`, params)
    },
    /**
     * 新增
     * @param params
     * @returns {*}
     */
    save(params) {
        return http.post(PREFIX + `save`, params)
    },
    /**
     * 删除
     * @param params
     * @returns {*}
     */
    delete(params) {
        return http.delete(PREFIX + `delete`, params);
    },
    /**
     * 设置密码
     * @param params
     * @returns {*}
     */
    setPassword(params) {
        return http.put(PREFIX + `setPassword`, params);
    },
  /**
   * 设置密码
   * @param params
   * @returns {*}
   */
  get(id) {
    return http.get(PREFIX + `${id}`);
  },

    /**
     * 查询排除项
     * @param params
     * @returns {*}
     */
    getUnusefulIds(params) {
        return http.post(PREFIX + `getUnusefulIds`, params)
    },
}
