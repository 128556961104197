<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`设置密码`"
  />
</template>

<script>
import OutletDeviceAPI from "@/api/ep/outletDevice"
import {SetPwdConfig} from './config.js'

export default {
  data() {
    return {
      visible: false,
      formConfig: SetPwdConfig.bind(this)(),
      ids: ''
    }
  },
  methods: {
    init(ids) {
      this.visible = true
      this.formConfig = SetPwdConfig.bind(this)()
      this.ids = ids
    },

    handleChange(key, value) {

    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      const params = {}
      params.ids = this.ids
      params.password = result.password
      OutletDeviceAPI.setPassword(params).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.visible = false
        this.$message.success('设置成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>

</style>
