//import { constantRoutes } from '@/router'
import LoginApi from '@/api/uc/login'
import hmStorage from '@/utils/hmStorage'

const state = {
  get routes() {
    const routes = hmStorage.getItem('ROUTES', '')
    return routes || []
  },
  set routes(routes) {
    routes && routes.length > 0 ? hmStorage.setItem('ROUTES', routes) : hmStorage.removeItem('ROUTES')
  },
  get permissions() {
    const routes = hmStorage.getItem('PERMISSIONS', '')
    return routes || []
  },
  set permissions(permissions) {
    permissions && permissions.length > 0 ? hmStorage.setItem('PERMISSIONS', permissions) : hmStorage.removeItem('PERMISSIONS')
  }
}

const mutations = {
  setRoutes: (state, routes) => {
    state.routes = constantRoutes.concat(routes)
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions
  }
}

const actions = {
  generateRoutes({ commit }) {
    return new Promise(resolve => {
      const enable = (typeof process.env.VUE_APP_CLOSE_ASYNC_ROUTER === 'string'
        ? process.env.VUE_APP_CLOSE_ASYNC_ROUTER.toLocaleLowerCase() === 'true' : process.env.VUE_APP_CLOSE_ASYNC_ROUTER)
      if (enable) {
        commit('setRoutes', [])
        resolve([])
        return
      }
      LoginApi.getRouter().then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        commit('setRoutes', data || [])
        resolve(data || [])
      })
    })
  },
  clear({ commit, state }) {
    state.routes = null
    state.permissions = null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
