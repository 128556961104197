import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'corpWaste/'

export default {
    /**
     * 保存
     * @param params
     * @returns {*}
     */
    save(params) {
        return http.post(PREFIX + `save`,params);
    },

    /**
     * 修改
     * @param params
     * @returns {*}
     */
    update(params) {
        return http.put(PREFIX + `update`,params);
    },
    /**
     * 列表
     * @param params
     * @returns {*}
     */
    getList(params) {
        return http.post(PREFIX + `search`,params);
    },
    /**
     * 列表
     * @param params
     * @returns {*}
     */
    getFileById(params) {
        return http.get(PREFIX + `getFileById`,params);
    },

    /**
     * 删除文件
     * @param params
     * @returns {*}
     */
    deleteFile(params) {
        return http.post(PREFIX + `deleteFile`, params || {})
    },

}
