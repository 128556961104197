<template>
  <div class="details-root">
    <Head ref="header"></Head>
    <div class="banner">
      <img :src="bannerPath">
    </div>
    <div class="details">
      <div class="details-wrap computer">
        <el-carousel  :interval="5000" arrow="always" height="380px" :autoplay="false">
          <el-carousel-item v-for="(item,index) in bannerData" :key="index">
            <div class="item-contain">
              <img :src="item">
              <div class="filter"></div>
              <div class="mark">
                <div class="sub">{{title}}</div>
                <div class="details-contain">
                  <div class="details">详细内容：</div>
                  <div class="text">{{summary}}</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="details-wrap phone">
        <div class="mark">
          <div class="sub">{{title}}</div>
          <div class="details-contain">
            <div class="details">详细内容：</div>
            <div class="text">{{summary}}</div>
          </div>
        </div>
        <el-carousel  :interval="5000" arrow="always" height="150px" :autoplay="false">
          <el-carousel-item v-for="(item,index) in bannerData" :key="index">
            <div class="item-contain">
              <img :src="item">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="case">
      <div class="case-wrap">
        <div class="case-nav">
          <div class="title">相关合作案例</div>
        </div>
        <div class="caseList">
          <div class="caseList-item" v-for="(item,index) in caseData" :key="index" @click="goCaseDetail(item)">
            <div class="sub">{{item.title}}</div>
            <div class="pic"><img :src="item.coverUrl"></div>
            <div class="type" v-if="item.categoryType.code==='WRFZ'">
              <div class="info">环保类型：</div>
              <div class="text">{{item.caseType}}</div>
            </div>
            <div class="process" v-if="item.categoryType.code==='WRFZ'">
              <div class="info">工艺流程：</div>
              <div class="text">{{item.subtitle}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Head from '@/components/head'
  import Footer from '@/components/footer'
import HomeCategoryApi from '@/api/ep/homeCategory'
  import HomeBannerApi from '@/api/ep/homeBanner'
  import HomeArticleApi from '@/api/ep/homeArticle'
  export default {
    name: "businessDetails",
    data() {
      return {
        id: '',
        code:'',
        title:'',
        content:'',
        summary:'',
        bannerPath:'',
        bannerData:[],
        caseData:[],
        caseList: [
          {
            sub: '广州市城市固体废弃物处理厂项目',
            pic: require('@/assets/image/case_1.png'),
            type: '市政污水',
            process: '预处理→SBR-CAST→高效沉淀→接触紫外消毒'
          },
          {
            sub: '顺德铁厂有机废气处理工程',
            pic: require('@/assets/image/case_2.png'),
            type: '市政污水',
            process: '预处理→SBR-CAST→高效沉淀→接触紫外消毒'
          },
          {
            sub: '广西铬污染土壤修复工程',
            pic: require('@/assets/image/case_3.png'),
            type: '市政污水',
            process: '预处理→SBR-CAST→高效沉淀→接触紫外消毒'
          },
        ]
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.code = this.$route.query.code
      this.getDetail()
      this.getBannerData()
      this.getBanner()
    },
    watch: {
      '$route'(val){
        this.id = val.query.id
        this.getDetail()
        this.getBanner()
      }
    },
    methods: {
      goCaseDetail(item){
        this.$router.push('/caseDetail?id=' + item.id)
      },
      //banner图数据
      getBannerData() {
        let params = {
          dictionary: 'BUSINESS'
        }
        HomeBannerApi.list(params).then((res) => {
          this.bannerPath =res.data[0].fileId
        })
      },
      //轮播
      getBanner(){
        HomeCategoryApi.get(this.id).then((res)=>{
          this.bannerData=res.data.figurelist
          this.title=res.data.title
          this.summary=res.data.summary
        })
      },
      getDetail() {
        let params={
          categoryId:this.id
        }
        HomeArticleApi.list(params).then((res) => {
          let data=res.data
          this.caseData=data
        })
      }
    },
    components: {Head, Footer}
  }
</script>

<style scoped>
  @media screen and (min-width: 900px){
    .banner img {
      width: 100%;
      height: 475px;
      object-fit: cover;
    }
    .phone{
      display: none;
    }
    .details-wrap {
      width: 1200px;
      margin: 0 auto;
      padding-top: 50px;
    }

    .details-wrap img {
      width: 100%;
      object-fit: cover;
      /*-webkit-filter: blur(10px); !* Chrome, Opera *!*/
      /*-moz-filter: blur(10px);*/
      /*-ms-filter: blur(10px);*/
      /*filter: blur(5px);*/
      /*filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false); !* IE6~IE9 *!*/
    }

    .item-contain {
      position: relative;
    }
    .filter{
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 40%;
      z-index: 3;
      background-color: rgba(238,239,241,0.5);
      backdrop-filter: blur(10px);
    }
    .item-contain .mark {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 40%;
      z-index: 4;
      background: rgba(0,0,0,.1);
    }
    .mark .sub {
      font-size: 20px;
      line-height: 26px;
      color: #FFFFFF;
      padding: 23px 20px 25px 37px;
    }

    .mark .details-contain {
      border-top: 1px dashed #ffffff;
      border-bottom: 1px dashed #ffffff;
      padding: 32px 20px 45px 37px;
      display: flex;
    }

    .mark .details-contain .details {
      font-size: 16px;
      line-height: 34px;
      color: #EEEEEE;
    }

    .mark .details-contain .text {
      font-size: 16px;
      line-height: 34px;
      color: #FFFFFF;
      flex: 1;
    }

    /*优秀案例*/
    .case-wrap {
      width: 1200px;
      margin: 0 auto;
    }

    .case-nav {
      width: 1200px;
      padding: 50px 0px 10px 0px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .case-nav .title {
      font-size: 24px;
      line-height: 32px;
      color: #000000;
    }

    .case .caseList {
      display: flex;
      flex-wrap: wrap;
    }

    .case .caseList .caseList-item {
      width: 340px;
      margin-right: 90px;
      margin-top: 20px;
      cursor: pointer;
    }

    .case .caseList .caseList-item:nth-child(3n+3) {
      margin-right: unset;
    }

    .case .caseList .caseList-item .sub {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
    }

    .case .caseList .caseList-item .pic {
      width: 340px;
      height: 284px;
      margin: 30px 0px;
    }

    .case .caseList .caseList-item .pic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .case .caseList-item .type,
    .case .caseList-item .process {
      display: flex;
    }

    .case .caseList-item .type .info,
    .case .caseList-item .process .info {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
    }

    .case .caseList-item .type .text,
    .case .caseList-item .process .text {
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      flex: 1;
    }
  }

  @media screen and (max-width: 900px){
    .banner img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
    .computer{
      display: none;
    }
    .details-wrap {
      width: 96%;
      margin: 0 auto;
      padding-top: 30px;
    }

    .details-wrap img {
      width: 100%;
      object-fit: cover;
      /*-webkit-filter: blur(10px); !* Chrome, Opera *!*/
      /*-moz-filter: blur(10px);*/
      /*-ms-filter: blur(10px);*/
      /*filter: blur(5px);*/
      /*filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false); !* IE6~IE9 *!*/
    }

    .item-contain {
    }
    .filter{
    }
    .mark {
    }
    .mark .sub {
      font-size: 20px;
      line-height: 26px;
      color: #333333;
    }

    .mark .details-contain {
      border-top: 1px dashed #ffffff;
      border-bottom: 1px dashed #dddddd;
      display: flex;
      padding: 10px 0px 15px;
    }

    .mark .details-contain .details {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }

    .mark .details-contain .text {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      flex: 1;
    }

    /*优秀案例*/
    .case-wrap {
      width: 98%;
      margin: 0 auto;
    }

    .case-nav {
      width: 100%;
      padding: 30px 0px 10px 0px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .case-nav .title {
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }
    .case .caseList {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .case .caseList .caseList-item {
      width: 90%;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .case .caseList .caseList-item .sub {
      font-size: 15px;
      line-height: 32px;
      color: #333333;
      text-align: center;
    }

    .case .caseList .caseList-item .pic {
      width: 100%;
      height: 150px;
      margin: 10px 0px;
    }

    .case .caseList .caseList-item .pic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .case .caseList-item .type,
    .case .caseList-item .process {
      display: flex;
    }

    .case .caseList-item .type .info,
    .case .caseList-item .process .info {
      font-size: 14px;
      line-height: 32px;
      color: #999999;
    }

    .case .caseList-item .type .text,
    .case .caseList-item .process .text {
      font-size: 14px;
      line-height: 32px;
      color: #666666;
      flex: 1;
    }
  }
</style>
