<template>
  <div class="components">
    <div class="content">
      <hm-search-form ref="searchForm" :data="searchConfig" size="normal" label-width="100"/>
      <div class="btns-box">
        <el-button type="primary" @click="add">新增生产设备</el-button>
        <el-button type="primary" @click="setPwd">设置密码</el-button>
      </div>
      <div style="margin-top: 20px;">
        <hm-table ref="table"
                  :stripe="true"
                  :border="true"
                  :header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}"
                  :api="listRequest"
                  :table-config="tableConfig"
                  :params="searchParams.params"
                  style="height:480px;"
        >
          <div slot="content" slot-scope="row">
            <el-image
                style="width: 100px; height: 100px"
                :src="row.data.content"
                :preview-src-list="[row.data.content]">
            </el-image>
          </div>
          <div slot="equipmentName" slot-scope="row">
            <div v-if="row.data.equipmentList">
              <span v-for="(item,index) in row.data.equipmentList"><span v-if="index>0">,</span>{{ item.name }}</span>
            </div>
          </div>
          <div slot="equipmentCode" slot-scope="row">
            <div v-if="row.data.equipmentList">
              <span v-for="(item,index) in row.data.equipmentList"><span v-if="index>0">,</span>{{ item.code }}</span>
            </div>
          </div>
          <div slot="qrCode" slot-scope="row">
            <el-image style="width: 100px; height: 100px" :src="row.data.qrCode"></el-image>
          </div>
          <div slot="operation" slot-scope="row">
            <el-button class="table-operation" type="text" @click="del(row.data.id)">删除</el-button>
            <el-button class="table-operation" type="text" @click="downQr(row.data)">下载二维码</el-button>
          </div>
        </hm-table>
      </div>
    </div>
    <add ref="addForm" @submitSuccess="submitSuccess"></add>
    <set-pwd ref="setPwdForm" @submitSuccess="submitSuccess"></set-pwd>
    <archives-code ref="archivesCode"></archives-code>
  </div>
</template>

<script>
import OutletDeviceAPI from "@/api/ep/outletDevice"
import {SearchConfig, TableConfig} from "./config"
import {mergeJson} from '@/utils/commons'
import Add from './add'
import SetPwd from './setPwd'
import ArchivesCode from './archivesCode'
export default {
  components: {Add, SetPwd,ArchivesCode},
  data() {
    return {
      listRequest: OutletDeviceAPI.search,
      searchConfig: SearchConfig.bind(this)(),
      tableConfig: TableConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {
          model: {
            referType: "EQUIPMENT"
          }
        },
        // table下拉条件
        tableParams: {},
      },

    }
  },
  methods: {
    add() {
      this.$refs.addForm.init()
    },
    setPwd() {
      let ids = this.$refs.table.getSelections()['ids']
      if (ids.length <= 0) {
        this.$message.error('至少选择一项')
        return false
      }
      this.$refs.setPwdForm.init(ids)
    },
    remove(data) {
      this.batchDelete(data.id)
    },
    del(id) {
      this.$confirm('此操作将删除生产设备, 是否继续?', '提示', {type: 'warning'}).then(() => {
        OutletDeviceAPI.delete({id: id}).then((res) => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
      })
    },
     downQr(data) {
       this.$refs.archivesCode.init(data.id)
    },
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        // 避免 200 from disk cache
        url = url + `?r=${Math.random()}`
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }
        xhr.send()
      })
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename)
      } else {
        const anchor = document.createElement('a')
        const body = document.querySelector('body')
        anchor.href = window.URL.createObjectURL(blob)
        anchor.download = filename

        anchor.style.display = 'none'
        body.appendChild(anchor)

        anchor.click()
        body.removeChild(anchor)

        window.URL.revokeObjectURL(anchor.href)
      }
    },
    handleSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.$refs.table.reload(params)
    }
    ,
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}

.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  width: 50px;
  height: 50px;
}
</style>
