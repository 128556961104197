<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`人员管理--${id?'编辑':'新增'}`"
  />
</template>

<script>
import CorpUserApi from "@/api/ep/corpUser"
import {EmployeeAddConfig, EmployeeEditConfig} from './config/employee.js'
import { mergeModelValue } from '@/utils/commons'

export default {
  data() {
    return {
      visible: false,
      formConfig: EmployeeAddConfig.bind(this)(),
      id: ''
    }
  },
  methods: {
    init(id) {
      this.id = id || ''
      this.visible = true
      this.formConfig = id ? EmployeeEditConfig.bind(this)() : EmployeeAddConfig.bind(this)()
      if (!id) {
        return
      }
      CorpUserApi.get(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        const model = this.formConfig.config.model
        data.status = data.status.code
        mergeModelValue(model, data)
      })
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      if (!this.id) {
        if(!result.password) {
          result.password = '123456'
        }
        CorpUserApi.save(result).then((res) => {
          if (!res.isSuccess) {
            return
          }

          const data = res.data
          this.visible = false
          this.$message.success('保存成功')
          this.$emit('submitSuccess', data)
        })

        return false
      }

      result.id = this.id
      CorpUserApi.update(result).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        this.visible = false
        this.$message.success('保存成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>

</style>
