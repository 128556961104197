<template>
  <div class="components">
    <div class="title">国字头账号管理</div>
    <div class="content">
      <hm-search-form ref="searchForm" :data="searchConfig" label-width="150" size="normal"/>
      <div class="btns-box">
        <el-button type="primary" @click="add" icon="el-icon-plus">新增官方平台账号</el-button>
        <el-button type="primary" @click="batchDelete()" icon="el-icon-delete">批量删除</el-button>
      </div>
      <div style="margin-top: 20px;">
        <hm-table ref="table"
                  :stripe="true"
                  :border="true"
                  :header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}"
                  :api="listRequest"
                  :table-config="tableConfig">
          <div slot="operation" slot-scope="row">
            <el-button class="table-operation" v-if="sso" type="text" @click="edit(row.data)">编辑</el-button>
            <el-button class="table-operation" v-if="sso" type="text" @click="remove(row.data)">删除</el-button>
            <el-button class="table-operation"  type="text" @click="upload(row.data)">下载附件</el-button>
          </div>
          <div slot="siteAddress" slot-scope="row">
            <a :href="row.data.siteAddress" class="link" target="_blank">{{ row.data.siteAddress }}</a>
          </div>
        </hm-table>
      </div>
    </div>
    <out-side-account-edit ref="form" @submitSuccess="submitSuccess"></out-side-account-edit>
  </div>
</template>

<script>
import CorpStateAccount from "@/api/ep/corpStateAccount"
import {OutSideAccountSearchConfig, OutSideAccountTableConfig} from "./config/outSideAccount"
import {downloadBlobFile, mergeJson} from '@/utils/commons'
import OutSideAccountEdit from "./outSideAccountEdit";
import FileAPi from '@/api/fs/file'
import UserApi from "@/api/uc/user"
export default {
  name: "outSideAccount",
  components: {OutSideAccountEdit},
  data() {
    return {
      listRequest: CorpStateAccount.search,
      searchConfig: OutSideAccountSearchConfig.bind(this)(),
      tableConfig: OutSideAccountTableConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {},
        // table下拉条件
        tableParams: {},
      },
      sso: this.$store.getters.sso
    }
  },
  mounted() {
  },
  methods: {
    edit(item) {
      this.$refs.form.init(item.id)
    },
    add() {
      this.$refs.form.init()
    },
    remove(data) {
      this.batchDelete(data.id)
    },
    batchDelete(id) {
      let ids;
      if (id) {
        ids = [id]
      } else {
        ids = this.$refs.table.getSelections()['ids']
      }
      if (ids.length <= 0) {
        this.$message.error('至少选择一项')
        return false
      }

      this.$confirm('此操作将永久删除, 是否继续?', '提示', {type: 'warning'}).then(() => {
        CorpStateAccount.batchDelete({ids: ids}).then((res) => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
      })
    },
    upload(data) {
      CorpStateAccount.getEnclosureId({id: data.id}).then(res => {
        if (!res.isSuccess) {
          return
        }
        const id = res.data;
        FileAPi.downloadFile({ids: [id]}).then((response) => {
          downloadBlobFile(response)
        })
      })
    },
    handleSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.$refs.table.reload(params)
    },
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.link:hover {
  color: #359029;
  text-decoration: underline;
}

.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}
</style>
