<template>
  <el-dialog
      :visible.sync="visible"
      :show-close="false"
      width="830px"
      :before-close="handleClose">
    <div class="contain">
      <div class="title">二维码下载</div>
      <div class="info" ref="imageWrapper">
        <div class="name"><span v-for="(item,index) in data.equipmentList" :key="index">{{item.name}}<i v-if="index!==data.equipmentList.length-1">、</i></span></div>
        <div class="code"><span v-for="(item,index) in data.equipmentList" :key="index">{{item.code}}<i v-if="index!==data.equipmentList.length-1">、</i></span></div>
        <div class="pic"><img :src="data.qrCode"></div>
      </div>
      <div class="btns">
        <div class="btn" @click="handleClose">取消</div>
        <div class="btn" @click="onCreateImage">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import OutletDeviceAPI from "@/api/ep/outletDevice"
  import html2canvas from "html2canvas"
  export default {
    name: "archivesCode",
    data(){
      return{
        visible:false,
        data:{}
      }
    },
    methods:{
      onCreateImage(){
        html2canvas(this.$refs.imageWrapper, {
          backgroundColor: '#606266',
          useCORS: true
        }).then(canvas => {
          let link = document.createElement("a");
          link.href = canvas.toDataURL();
          link.setAttribute("download","code.jpg");
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          this.$message.success('下载成功')
          this.handleClose()
        });
      },
      init(id){
        this.visible=true,
        this.getData(id)
      },
      getData(id){
        OutletDeviceAPI.get(id).then((res=>{
          this.data=res.data ||{}
        }))
      },
      handleClose(){
        this.visible=false
      }
    }
  }
</script>

<style scoped>
  /deep/.el-dialog{
    border-radius: 10px !important;
  }
  .contain{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title{
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
    text-align: center;
  }
.info{
  width: 308px;
  background: #FFFFFF;
  border: 1px solid #999999;
  opacity: 1;
  border-radius: 4px;
}

.info .name{
  font-size: 16px;
  color: #359029;
  text-align: center;
  padding: 25px 20px 20px;
}
.info .code{
  font-size: 16px;
  color: #999999;
  text-align: center;
  margin-bottom: 30px;
  padding: 0px 20px;
}
  .pic{
    text-align: center;
  }
  .pic img{
    width: 224px;
    height: 224px;
  }
  .btns{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
  .btn{
    width: 233px;
    height: 48px;
    border-radius: 24px;
    font-size: 18px;
    line-height: 48px;
    color: #FFFFFF;
    text-align: center;
    margin: 50px 25px 0px;
    cursor: pointer;
  }
  .btn:nth-child(1){
    background: #BEBEBE;
  }
  .btn:nth-child(2){
    background: #359029;
  }
</style>