import {CTX} from '@/api/api';
import http from '@/utils/http';

const PREFIX = CTX.FILE;

export default {

    /**
     * 分页
     * @param params
     * @returns {*}
     */
    filePage(params) {
        return http.post(PREFIX + `file/search`, params);
    },
    /**
     * 根据文件ID查询附件
     * @param params
     * @returns {*}
     */
    listByIds(params) {
        return http.get(PREFIX + `file/listByIds`, params);
    },

    /**
     * 分片上传
     */
    chunkUpload() {
        return process.env.VUE_APP_DOMAIN + PREFIX + `chunk/upload`;
    },

    /**
     * md5校验，校验后台文件是否存在同样的文件，如有文件后台直接复制，不需要重新上传
     * @param params
     * @returns {*}
     */
    checkMd5(params) {
        return http.post(PREFIX + `chunk/md5`, params);
    },

    /**
     * 分片合并
     * @param params
     * @returns {*}
     */
    chunkMerge(params) {
        return http.post(PREFIX + `chunk/merge`, params);
    },

    /**
     * 单文件上传
     * @returns {*}
     */
    singleUpload() {
        return process.env.VUE_APP_DOMAIN + PREFIX + `file/upload`;
    },

    /**
     * 单文件上传 formData 形式
     * @returns {*}
     */
    singleUploadFormData(formData) {
        return http.postUpload(PREFIX + `file/upload`, formData);
    },

    /**
     * 文件下载
     * @param params
     * @returns {*}
     */
    downloadFile(params) {
        return http.getDownload(PREFIX + `file/download`, params);
    },
    /**
     * 文件下载
     * @param params
     * @returns {*}
     */
    downloadByUrl(url) {
        return http.getDownload(PREFIX + `file/downloadByUrl`, {url:url});
    },
    /**
     * 文件下载与业务相关
     * @param params
     * @returns {*}
     */
    downloadBizFile(params) {
        return http.getDownload(PREFIX + `file/download/biz`, params);
    },

    /**
     * 文件删除
     * @param params
     * @returns {*}
     */
    batchDelete(params) {
        return http.delete(PREFIX + `file/batchDelete`, params);
    }
}
