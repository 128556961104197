<template>
  <hm-drawer-description
      :visible="visible"
      :title="title"
      size="600px"
      @button-event="buttonEvent"
  >
    <div slot="drawer-body">
      <hm-description
          :config="detailConfig"
          :column-number="1"
      >
        <div slot="dischargePortList" slot-scope="row" style="width: 100%">
          <div v-if="row.data.length !== 0" v-for="item in row.data"
               style="margin: 15px 0;border-bottom: 1px solid #cccccc;padding-bottom: 10px;width: 100%;line-height: 25px">
            <p>防治设施污染物种类:{{ item.portType.desc }}</p>
            <p>防治设施对应排放口编号:{{ item.code }}</p>
            <p>防治设施对应排放口名称:{{ item.name }}</p>
          </div>
          <div v-if="row.data.length === 0" style="margin: 15px 0;border-bottom: 1px solid #cccccc;padding-bottom: 10px;width: 100%;line-height: 25px">
            <p>防治设施污染物种类:</p>
            <p>防治设施对应排放口编号:</p>
            <p>防治设施对应排放口名称:</p>
          </div>
        </div>
      </hm-description>
    </div>
  </hm-drawer-description>
</template>
<script>
import ProtectiveApi from '@/api/ep/protective'
import {ProtectiveDetailConfig} from './config.js'
import {mergeModelValue} from '@/utils/commons'

export default {
  name: 'EquipmentDetail',
  data() {
    return {
      visible: false,
      title: '防治设施',
      detailConfig: ProtectiveDetailConfig.bind(this)()
    }
  },
  created() {

  },
  methods: {
    init(id) {
      this.visible = true

      this.getProtectiveData(id)

    },
    getProtectiveData(id) {
      ProtectiveApi.get(id).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        const model = this.detailConfig.config.model
        mergeModelValue(model, data)
      })
    },
    buttonEvent(type) {
      if (type === 'cancel') {
        this.visible = false
      }
    }
  }
}
</script>
