<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
		<el-form ref="formData" :model="formData" label-width="120px" label-position="right" style="width: 400px"
			status-icon :rules="rules">
			<div v-for="(item,index) in formData.data" :key="item.id">
				<el-form-item label="原辅材料名称:">
					<el-select v-model="item.assistCapacityId" :disabled="disabled" placeholder="请选择原辅材料名称" @change="changeClick(index)">
						<el-option v-for="(itemOne,indexOne) in optionsList" :key="itemOne.id"
							:label="itemOne.assistProductName" :value="itemOne.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用量:"  :rules="rules.number" :prop="`data[${index}].number`">
					<el-input-number v-model="item.number"  :disabled="disabled" :min="1" :max="10000" label="请输入用量">
					</el-input-number>
				</el-form-item>
				<el-form-item label="单位:">
					<el-input v-model="item.unit" disabled></el-input>
				</el-form-item>
			</div>
			<el-form-item label="日期"  prop="dateOne" v-if="!disabled">
				<el-date-picker v-model="formData.dateOne" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
					value-format="yyyy-MM-dd">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="日期:" v-if="disabled">
				<el-input v-model="recordCode" disabled></el-input>
			</el-form-item>
			<el-form-item style="display: flex;justify-content: flex-end;" v-if="!disabled">
				<el-button @click="handleClose">取消</el-button>
				<el-button type="primary" @click="onSubmit('formData')">确定</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import api from "@/api/pro/assistCapacity";
	export default {
		props: {
			// 弹窗标题
			title: {
				type: String,
				default: "",
			},
			// 显示弹窗
			dialogVisible: {
				type: Boolean,
				default: false,
			},
			recordCode: {
				type: [String, Number],
				default: 1
			},
			disabled:{
				type:Boolean,
				default:false
			}
		},
		components: {},
		watch: {
			dialogVisible: {
				immediate: true,
				handler(val) {
					if (val) {
						this.getData()
						this.getSelectFun()
					}
				}
			}
		},
		data() {
			return {
				rules: {
					number: [{
						required: true,
						message: '请输入用量',
						trigger: 'blur'
					}],
					dateOne:[
						{
							required: true,
							message: '请选择日期',
							trigger: 'change'
						}
					]
				},
				formData: {
					data: [],
					dateOne: ""
				},
				optionsList: [],
				
			};
		},

		created() {},

		mounted() {},

		methods: {
			changeClick(e) {
				this.optionsList.map(item => {
					if (item.id == this.formData.data[e].assistCapacityId) {
						this.$set(this.formData.data[e], 'unit', item.unit)
					}
				})
			},
			getSelectFun() {
				api.getSelect({
					model: {}
				}).then(res => {
					this.optionsList = res.data.records
				})
			},
			getData() {
				api.getListByRecordCode({
					recordCode: this.recordCode
				}).then(res => {
					res.data.forEach(item=>{
						item.number=item.dayNumber
					})
					this.formData.data = res.data

				})
			},
			handleClose() {
				this.$emit("handleClose");
			},
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.submitData();
					} else {
						return false;
					}
				});
			},
			// 提交表单数据
			async submitData() {
				let data = {
					assistCapacityRecords: this.formData.data,
					date: this.formData.dateOne,
					oldRecordCode: this.recordCode

				}
				let res = null;
				res = await api.update(data);

				if (res.code == 0) {
					this.$message({
						message:"修改成功",
						type: "success",
					});
					this.handleClose();
				} else {
					this.$message.error(res.message);
				}
			},
		},
	};
</script>
<style scoped>
</style>
