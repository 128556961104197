<template>
  <div class="card-main">
    <el-card class="box-card">
      <div slot="header">
        <span class="title">{{ title }}</span>
        <el-button
          v-has-permission="actions.addKey || ''"
          style="float: right; padding-top: 4px;"
          type="text"
          @click="addItem"
        >新增
        </el-button>
      </div>
      <div
        v-for="(item,index) in datas"
        :key="index"
        class="card-item"
        :class="selectIdx === index?'active':''"
        @click="selectItem(item,index)"
      >
        <span>{{ item[keyField] }}</span>
        <div class="card-opt">
          <el-button
            v-has-permission="actions.editKey || ''"
            type="primary"
            icon="el-icon-edit"
            circle
            @click.stop="editItem(item)"
          />
          <el-button
            v-has-permission="actions.deleteKey || ''"
            type="danger"
            icon="el-icon-delete"
            circle
            @click.stop="deleteItem(item)"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'HmCard',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      keyField: this.config.keyField,
      valueField: this.config.valueField,
      selectIdx: this.config.selectIdx || -1
    }
  },
  computed: {
    datas() {
      return this.config.data
    },
    actions() {
      return this.config.actions
    }
  },
  mounted() {
  },
  methods: {
    selectItem(item, index) {
      this.selectIdx = index
      this.config.onSelect && this.config.onSelect(item[this.valueField], item)
    },
    addItem() {
      this.config.onAdd && this.config.onAdd()
    },
    editItem(item) {
      this.config.onEdit && this.config.onEdit(item[this.valueField], item)
    },
    deleteItem(item) {
      this.config.onDelete && this.config.onDelete(item[this.valueField], item)
    }
  }
}
</script>

<style lang="scss">
  .card-main {
    padding-right: 20px;

    .card-item {
      font-size: 14px;
      margin-bottom: 18px;
      cursor: pointer;
    }

    .card-item:hover {
      color: #409eff;
    }

    .card-item.active {
      color: #409eff;
    }

    .box-card .title {
      font-size: 16px;
    }

    .el-card__header {
      padding: 6px 20px !important;
    }

    .card-item .card-opt {
      display: none;
    }

    .card-item .card-opt .el-button {
      padding: 4px;
    }

    .card-item:hover .card-opt {
      display: inline-block;
      float: right;
      margin-right: 8px;
    }
  }

</style>
