<template>
  <hm-dialog-form
      ref="form"
      label-width="150px"
      width="80%"
      :visible="visible"
      :data="formConfig"
      :title="`排污口电子化标示牌--${id?'编辑':'新增'}`"
  />
</template>

<script>
import SidnboardApi from "@/api/ep/sidnboard"
import { SignageBrandAddConfig } from './config/signageBrand'
import { mergeModelValue } from '@/utils/commons'

export default {
  name: 'signageBrandEdit',
  data() {
    return {
      visible: false,
      formConfig: SignageBrandAddConfig.bind(this)(),
      id: ''
    }
  },
  methods: {
    onformChange(k,v){
      if(k === 'pollutionType') {
        let formConfig = this.formConfig
        let item = formConfig.list[4]
        item.label = {
          "EXHAUSR": "废气污染",
          "SEWAGE": "污水排放",
          "RAIN": "雨水排放",
          "NOISE": "噪音排放",
          "DANGER": "危险品排放",
        }[v]
        this.formConfig.config.model.pollutionCategory = ''
        item.placeholder = `请输入${item.label}类别`
        this.$set(this.formConfig.list,4,item)
      }
    },
    init(id) {
      this.id = id || ''
      this.visible = true
      this.formConfig = SignageBrandAddConfig.bind(this)()
      if (!id) {
        return
      }
      SidnboardApi.get(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        const model = this.formConfig.config.model
        let formConfig = this.formConfig
        let item = formConfig.list[4]
        item.label = {
          "EXHAUSR": "废气污染",
          "SEWAGE": "污水排放",
          "RAIN": "雨水排放",
          "NOISE": "噪音排放",
          "DANGER": "危险品排放",
        }[data.pollutionType.code]

        formConfig.config.model.pollutionCategory = ''
        item.placeholder = `请输入${item.label}类别`
        this.$set(this.formConfig.list,4,item)
        data.pollutionType = data.pollutionType.code
        mergeModelValue(model, data)
      })
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      result.pollutionType = {code: result.pollutionType}
      if (!this.id) {
        SidnboardApi.save(result).then((res) => {
          if (!res.isSuccess) {
            return
          }
          const data = res.data
          this.visible = false
          this.$message.success('保存成功')
          this.$emit('submitSuccess', data)
        })

        return false
      }

      result.id = this.id
      SidnboardApi.update(result).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        this.visible = false
        this.$message.success('保存成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>

</style>
