<template>
  <div class="component-table">
    <div ref="tableFrontExtend" class="table-front-extend">
      <!--            表格顶部插槽-->
      <slot name="table-front-extend" />
    </div>
    <div ref="table" class="table">
      <el-table
              ref="elTable"
              v-loading="loading"
              :data="data"
              :stripe="stripe"
              :border="border"
              :max-height="tableHeight || currentTableHeight"
              :height="tableHeight || currentTableHeight"
              :size="size"
              :fit="fit"
              :show-header="showHeader"
              :highlight-current-row="highlightCurrentRow"
              :current-row-key="currentRowKey"
              :row-class-name="rowClassName"
              :row-style="rowStyle"
              :cell-class-name="cellClassName"
              :cell-style="cellStyle"
              :header-row-class-name="headerRowClassName"
              :header-row-style="headerRowStyle"
              :header-cell-class-name="headerCellClassName"
              :header-cell-style="headerCellStyle"
              :row-key="rowKey"
              :empty-text="emptyText"
              :default-expand-all="defaultExpandAll"
              :expand-row-keys="expandRowKeys"
              :default-sort="defaultSort"
              :tooltip-effect="tooltipEffect"
              :show-summary="showSummary"
              :sum-text="sumText"
              :summary-method="summaryMethod"
              :span-method="spanMethod"
              :select-on-indeterminate="selectOnIndeterminate"
              :indent="indent"
              :lazy="lazy"
              :load="load"
              :tree-props="treeProps"
              @selection-change="selectionChange"
              @row-click="rowClick"
              @filter-change="onSearch"
              @select="onSelect"
              @select-all="onSelect"
      >
        <template v-for="(column,columnIndex) in columns">
          <el-table-column
                  v-if="column.slotScope"
                  :key="columnIndex"
                  :type="column.type || undefined"
                  :index="column.index || undefined"
                  :column-key="column['column-key'] || undefined"
                  :label="column.label || undefined"
                  :prop="column.prop || undefined"
                  :width="column.width || undefined"
                  :min-width="column['min-width'] || undefined"
                  :fixed="column.fixed || undefined"
                  :render-header="column['render-header'] || undefined"
                  :sortable="column.sortable || false"
                  :sort-method="column['sort-method'] || undefined"
                  :sort-by="column['sort-by'] || undefined"
                  :sort-orders="column['sort-orders'] || ['ascending', 'descending', null]"
                  :resizable="column.resizable || true"
                  :formatter="column.formatter || undefined"
                  :show-overflow-tooltip="column['show-overflow-tooltip'] || true"
                  :align="column.align || 'left'"
                  :header-align="column['header-align'] || undefined"
                  :class-name="column['class-name'] || undefined"
                  :label-class-name="column['label-class-name'] || undefined"
                  :selectable="column.selectable || undefined"
                  :reserve-selection="column['reserve-selection'] || false"
                  :filter-placement="column['filter-placement'] || undefined"
                  :filter-multiple="column['filter-multiple'] || false"
                  :filter-method="column['filter-method'] || undefined"
                  :filtered-value="column['filtered-value'] || undefined"
                  :filters="filters[column['column-key']] || undefined"
          >
            <template slot-scope="scope">
              <slot :name="column.slotScope" :data="scope.row" />
            </template>
          </el-table-column>
          <el-table-column
                  v-else
                  :key="columnIndex"
                  :type="column.type || undefined"
                  :index="column.index || undefined"
                  :column-key="column['column-key'] || undefined"
                  :label="column.label || undefined"
                  :prop="column.prop || undefined"
                  :width="column.width || undefined"
                  :min-width="column['min-width'] || undefined"
                  :fixed="column.fixed || undefined"
                  :render-header="column['render-header'] || undefined"
                  :sortable="column.sortable || false"
                  :sort-method="column['sort-method'] || undefined"
                  :sort-by="column['sort-by'] || undefined"
                  :sort-orders="column['sort-orders'] || ['ascending', 'descending', null]"
                  :resizable="column.resizable || true"
                  :formatter="column.formatter || undefined"
                  :show-overflow-tooltip="column['show-overflow-tooltip'] || false"
                  :align="column.align || 'left'"
                  :header-align="column['header-align'] || undefined"
                  :class-name="column['class-name'] || undefined"
                  :label-class-name="column['label-class-name'] || undefined"
                  :selectable="column.selectable || undefined"
                  :reserve-selection="column['reserve-selection'] || false"
                  :filter-placement="column['filter-placement'] || undefined"
                  :filter-multiple="column['filter-multiple'] || false"
                  :filter-method="column['filter-method'] || undefined"
                  :filtered-value="column['filtered-value'] || undefined"
                  :filters="filters[column['column-key']] || undefined"
          />
        </template>

      </el-table>
    </div>
    <!-- 表格底部新增插槽-->
    <div ref="tableBackendExtend" class="table-backend-extend">
      <slot name="table-backend-extend" />
    </div>
    <!--        分页-->
    <div v-if="pagination" class="pagination-box">
      <el-pagination
              :current-page="pageIndex"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="sizeChange"
              @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HmTable',
    props: {
      dataFormatter: { //对数据进行格式化
        type: Boolean | Function,
        default: false
      },
      sizeField: {
        type: String,
        default: 'size'
      },
      pageIndexField: {
        type: String,
        default: 'current'
      },
      api: { // 表格内置的promise对象,用户获取数据，以及表格的一些内部操作
        type: Function,
        required: true
      },
      pagination: { // 是否有分页
        type: Boolean,
        default: true
      },
      params: {
        type: Object,
        default: () => {
          return {}
        }
      },
      tableConfig: {
        type: Object,
        default: () => {
          return {}
        }
      },
      // 封装elemenuUI table入参
      stripe: {
        type: Boolean,
        default: false
      },
      border: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: 'normal'
      },
      fit: {
        type: Boolean,
        default: true
      },
      showHeader: {
        type: Boolean,
        default: true
      },
      highlightCurrentRow: {
        type: Boolean,
        default: true
      },
      currentRowKey: {
        // eslint-disable-next-line vue/require-prop-type-constructor
        type: String | Number,
        default: undefined
      },
      rowClassName: {
        type: Function,
        default: undefined
      },
      rowStyle: {
        type: Function,
        default: undefined
      },
      cellClassName: {
        type: Function,
        default: undefined
      },
      cellStyle: {
        type: Function,
        default: undefined
      },
      headerRowClassName: {
        type: Function,
        default: undefined
      },
      headerRowStyle: {
        type: Function,
        default: undefined
      },
      headerCellClassName: {
        type: Function,
        default: undefined
      },
      headerCellStyle: {
        type: Function | Object,
        default: undefined
      },
      rowKey: {
        // eslint-disable-next-line vue/require-prop-type-constructor
        type: Function | String,
        default: undefined
      },
      emptyText: {
        type: String,
        default: '暂无数据'
      },
      defaultExpandAll: {
        type: Boolean,
        default: false
      },
      expandRowKeys: {
        type: Array,
        default: undefined
      },
      defaultSort: {
        type: Object,
        default: () => {
          return {
            order: 'ascending'
          }
        }
      },
      tooltipEffect: {
        type: String,
        default: 'dark'
      },
      showSummary: {
        type: Boolean,
        default: false
      },
      sumText: {
        type: String,
        default: '合计'
      },
      summaryMethod: {
        type: Function,
        default: undefined
      },
      spanMethod: {
        type: Function,
        default: undefined
      },
      selectOnIndeterminate: {
        type: Boolean,
        default: true
      },
      indent: {
        type: Number,
        default: 16
      },
      lazy: {
        type: Boolean,
        default: undefined
      },
      load: {
        type: Function,
        default: undefined
      },
      tableHeight: {
        type: Number | String,
        default: undefined
      },
      treeProps: {
        type: Object,
        default: () => {
          return { hasChildren: 'hasChildren', children: 'children' }
        }
      }
    },
    data() {
      return {
        maxHeight: 0,
        data: [],
        total: 0,
        extendParams: {}, // 获取扩展查询参数
        sections: [], // 选中行
        pageSize: 10,
        pageIndex: 1,
        loading: true,
        currentTableHeight: 50
      }
    },
    computed: {
      columns() {
        return this.tableConfig.list
      },
      filters() {
        return this.tableConfig.config ? (this.tableConfig.config.filters || {}) : {}
      },
      model() {
        return this.tableConfig.config ? (this.tableConfig.config.model || {}) : {}
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.currentTableHeight = window.innerHeight - this.$refs.elTable.$el.offsetTop - 50
        const node = this.$refs.elTable.$el
        const tableFrontExtendNode = this.$refs.tableFrontExtend
        const tableBackendExtendNode = this.$refs.tableBackendExtend
        var height = -10
        function getOffsetTop(node) {
          height += node.offsetTop
          if (node.offsetParent) {
            getOffsetTop(node.offsetParent, height)
          }
        }
        getOffsetTop(node)
        height && (this.currentTableHeight = window.innerHeight - height - tableFrontExtendNode.clientHeight - tableBackendExtendNode.clientHeight - 50 - 40)
        if (this.tableHeight > 400 || this.currentTableHeight > 400) {
          this.pageSize = 20
        }
        // 获取数据
        this.extendParams = this.params
        this.getTableData()
      })
    },
    updated() {
      this.$nextTick(() => {
        this.$refs['elTable'].doLayout()
      })
    },
    methods: {
      /**
       * 点击选中框
       */
      onSelect(rows) {
        this.$emit('onSelect', rows)
      },
      /**
       * 点击头部搜索
       */
      onSearch(filters) {
        for (const key in filters) {
          this.model[key] = filters[key].join(',')
        }

        const data = { model: {}, extend: {}}
        function handleKey(key, value, currentObj) {
          const index = key.indexOf('.')
          if (index < 0) {
            currentObj[key] = value
            return
          }

          const value1 = key.substr(0, index)
          const value2 = key.substr(index + 1)
          if (!Object.prototype.hasOwnProperty.call(currentObj, value1)) {
            currentObj[value1] = {}
          }

          handleKey(value2, value, currentObj[value1])
        }

        for (let key in this.model) {
          const value = this.model[key]
          if (key.indexOf('.') < 0) {
            data.model[key] = value
            continue
          }

          if (!key.startsWith('extend.')) {
            handleKey(key, value, data.model)
            continue
          }

          key = key.replace('extend.', '')
          handleKey(key, value, data.extend)
        }

        if (this.tableConfig.config) {
          this.tableConfig.config.onSearch && this.tableConfig.config.onSearch(data)
        }
      },
      /**
       * 获取表格请求参数
       */
      getParams() {
        const size = this.sizeField
        const index = this.pageIndexField
        const param = {}
        param[size] = this.pageSize
        param[index] = this.pageIndex
        return Object.assign(param, this.extendParams)
      },
      /**
       * 获取表格数据
       */
      getTableData() {
        this.loading = true
        if (!this.api) return
        this.api(this.getParams()).then((res) => {
          if (!res.isSuccess) {
            return
          }
          const data = res.data
          this.loading = false
          if(this.dataFormatter) {
            this.data = this.dataFormatter(data.records)
          }else {
            this.data = data.records
          }
          this.total = data.total || 0
          this.$nextTick(() => {
            this.$emit('onLoadSuccess', data.records)
          })
        })
      },
      /**
       * pageSize变化
       * @param val pageSize 条数
       */
      sizeChange(val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getTableData()
      },
      /**
       *  pageIndex 变化
       * @param val 当前第几页
       */
      currentChange(val) {
        this.pageIndex = val
        this.getTableData()
      },
      /**
       * 改变查询参数，重新加载数据
       */
      reload(param) {
        if (param) {
          this.extendParams = param
        }

        this.pageIndex = 1
        this.getTableData()
      },
      /**
       * 不改变参数，加载数据
       */
      refresh(isFirst = true) {
        if (isFirst) {
          this.pageIndex = 1
        }

        this.getTableData()
      },
      /**
       * 获取选中行
       * @param rows 选中行
       */
      selectionChange(rows) {
        this.sections = rows
        this.$emit('getSelectRows', rows)
      },
      /**
       * 获取选中行
       * @param row 选中行
       * @param column column
       * @param event
       */
      rowClick(row, column, event) {
        this.$emit('rowClick', row, column, event)
      },
      /**
       * 选中行
       * @param row 选中行
       * @param column column
       * @param event
       */
      toggleRowSelection(column, status) {
        this.$refs.elTable.toggleRowSelection(column, status)
      },
      /**
       * 获取选中行
       */
      getSelections() {
        const ids = []
        let rows = []
        if (this.sections.length === 0) {
          return { ids, rows }
        }
        rows = this.sections
        // 此处只是为了方便大部分取值为id的情况，如需要取其他值，开发者可从rows遍历中获取
        this.sections.forEach(item => {
          if (item.id) {
            ids.push(item.id)
          }
        })
        return { rows, ids }
      }
    }
  }
</script>

<style scoped>
  .component-table {
    height: 100%;
  }

  .pagination-box {
    padding-top: 6px;
    border-top: 1px solid #ddd;
    box-sizing: border-box;
  }
</style>
