import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.COMMON + 'parameter/'

export default {
    /**
     * 查询生产工艺描述
     * @returns {*}
     */
    getProcessRemark() {
        return http.get(PREFIX + `getPpRemark`)
    },
    /**
     * 新增生产工艺描述
     * @param params
     * @returns {*}
     */
    saveProcessRemark(params) {
        return http.post(PREFIX + `addPpRemark`, params);
    },
    /**
     * 编辑生产工艺描述
     * @param params
     * @returns {*}
     */
    updateProcessRemark(params) {
        return http.put(PREFIX + `updatePpRemark`, params);
    },
    /**
     * 查询排污口示意图
     * @returns {*}
     */
    getSignalImage() {
        return http.get(PREFIX + `getSignalImage`)
    },
    /**
     * 编辑排污口示意图
     * @param params
     * @returns {*}
     */
    saveSignalImage(params) {
        return http.post(PREFIX + `saveSignalImage`, params);
    },
}
