<template>
  <div class="information-root">
    <Head ref="header"></Head>
    <div class="banner">
      <img :src="bannerPath">
    </div>
    <div class="contain">
      <div class="comtain-wrap">
        <div class="title">信息公开</div>
        <div class="info" @click="goDetail(infoData)">
          <div class="info-l"><img :src="infoData.picPath"></div>
          <div class="info-r">
            <div class="time">{{infoData.day}}</div>
            <div class="name">{{infoData.title}}</div>
            <div class="text">{{infoData.summary}}
            </div>
          </div>
        </div>
        <div class="info-list">
          <div class="info-item info-item-1" v-for="(item,index) in pageInfo.list" :key="index" @click="goDetail(item)">
            <div class="sub">{{item.title}}</div>
            <div class="text">{{item.summary}}</div>
            <div class="time">{{item.day}}</div>
          </div>
        </div>
        <!--分页器-->
        <div class="pagination">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageInfo.pagenum"
              :page-sizes="[ 5, 10, 20, 50]"
              :page-size="pageInfo.pagesize"
              pager-count="5"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Head from '@/components/head'
  import Footer from '@/components/footer'
  import HomeArticleApi from '@/api/ep/homeArticle'
  import HomeBannerApi from '@/api/ep/homeBanner'
  export default {
    name: "caseDetail",
    data(){
      return{
        infoData:'',
        infoList:[],
        bannerPath:'',
        //2、分页信息
        pageInfo: {
          pagenum: 1,
          pagesize:10,
          total: 0,
          list: [],
          start: 0,
          end: 0,
          totalpage: 0
        }
      }
    },
    mounted() {
      this.getInfoData()
      this.getBannerData()
    },
    methods: {
      goDetail(item){
        this.$router.push('/infoDetail?id='+item.id)
      },
      //banner图数据
      getBannerData() {
        let params = {
          dictionary: 'INFORMATION'
        }
        HomeBannerApi.list(params).then((res) => {
          this.bannerPath =res.data[0].fileId
        })
      },
      getInfoData() {
        let params = {
           type:{
             code:'XXGK'
           }
        }
        HomeArticleApi.list(params).then((res) => {
          let data=res.data
          let list=[]
          data.forEach((item)=>{
            list.push({
              picPath:item.coverUrl,
              title:item.title,
              summary:item.summary,
              day:item.createTime.slice(5,10),
              id:item.id
            })
          })
          this.infoList=list
          this.infoData=this.infoList[0]
          this.getpages()
        })
      },
      //分页
      getpages(){
        //总条数
        this.pageInfo.total = this.infoList.length
        //总页数
        this.pageInfo.totalpage = Math.ceil(this.pageInfo.total / this.pageInfo.pagesize)
        //计算起点
        let start = (this.pageInfo.pagenum - 1) * this.pageInfo.pagesize
        //计算终点
        let end = start + this.pageInfo.pagesize
        if (end > this.pageInfo.total) {
          end = this.pageInfo.total
        }
        //截取数组元素
        let arr = this.infoList
        this.pageInfo.list = []
        for (let i = start; i < end; i++) {
          this.pageInfo.list.push(arr[i])
        }
      },
      //2、页码发生变化时，重新加载数据
      handleCurrentChange(pagenum) {
        this.pageInfo.pagenum = pagenum
        this.getInfoData()
      },
      //3、一页几条发生变化时，重新加载数据
      handleSizeChange(pagesize) {
        this.pageInfo.pagesize = pagesize
        this.getInfoData()
      },
    },
    components: {Head, Footer}
  }
</script>

<style scoped>
  @media screen and (min-width: 900px){
    .banner img {
      width: 100%;
      height: 475px;
      object-fit: cover;
    }

    .comtain-wrap {
      width: 1200px;
      margin: 0 auto;
    }

    .comtain-wrap .title {
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      padding: 55px 0px;
    }

    .info {
      display: flex;
      padding: 25px 25px;
      background: #FFFFFF;
      box-shadow: 0px 3px 18px rgba(91, 91, 91, 0.13);
      opacity: 1;
      border-radius: 8px;
    }

    .info .info-l {
      width: 538px;
      height: 273px;
      object-fit: cover;
    }

    .info .info-l img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .info .info-r {
      padding-left: 57px;
      flex: 1;
    }

    .info .info-r .time {
      font-size: 28px;
      font-family: DIN;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
      padding-top: 22px;
    }

    .info .info-r .name {
      font-size: 20px;
      line-height: 26px;
      color: #000000;
      padding: 36px 0px 10px 0px;
    }

    .info .info-r .text {
      font-size: 16px;
      line-height: 25px;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }

    .info-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .info-list .info-item {
      width: 574px;
      height: 232px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      position: relative;
      margin-top: 50px;
      cursor: pointer;
    }

    .info-list .info-item-1 .sub {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
      padding: 25px 17px 10px 17px;
    }

    .info-list .info-item-1 .text {
      font-size: 14px;
      line-height: 28px;
      color: #999999;
      padding: 0px 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }

    .info-list .info-item-1 .time {
      position: absolute;
      right: 25px;
      bottom: 20px;
      font-size: 28px;
      font-family: DIN;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
    }

    .info-list .info-item-2 {
      position: relative;
    }

    .info-list .info-item-2 img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .info-list .info-item-2 .sub {
      width: 100%;
      height: 58px;
      background-color: rgba(0, 0, 0, 0.52);
      position: absolute;
      bottom: 0px;
      left: 0px;
      line-height: 58px;
      font-size: 18px;
      color: #FFFFFF;
      text-indent: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .pagination {
      padding: 54px 0px 39px;
      text-align: center;
    }
  }
  @media screen and (max-width: 900px){
    .banner img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
    .comtain-wrap {
      width: 98%;
      margin: 0 auto;
    }

    .comtain-wrap .title {
      font-size: 20px;
      line-height: 32px;
      color: #000000;
      padding: 20px 0px;
    }

    .info {
      background: #FFFFFF;
      box-shadow: 0px 3px 18px rgba(91, 91, 91, 0.13);
      opacity: 1;
      border-radius: 10px;
      width: 96%;
      margin: 0px 2%;
    }

    .info .info-l {
      width: 100%;
      object-fit: cover;
    }

    .info .info-l img {
      width: 100%;
      height: auto;
    }

    .info .info-r {
      padding: 0px 20px;
    }

    .info .info-r .time {
      font-size: 24px;
      font-family: DIN;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
      padding-top: 10px;
    }

    .info .info-r .name {
      font-size: 18px;
      line-height: 26px;
      color: #000000;
      padding: 10px 0px 0px 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .info .info-r .text {
      padding-bottom: 10px;
      padding-top: 5px;
      font-size: 14px;
      line-height: 25px;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .info-list {
      width: 96%;
      margin: 0px 2%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .info-list .info-item {
      width: 100%;
      height: 115px;
      padding-bottom: 40px;
      background: #FFFFFF;
      position: relative;
      margin-top: 20px;
      cursor: pointer;
      box-shadow: 0px 3px 18px rgba(91, 91, 91, 0.13);
      opacity: 1;
      border-radius: 10px;
    }

    .info-list .info-item-1 .sub {
      font-size: 18px;
      line-height: 32px;
      color: #333333;
      padding: 25px 17px 0px 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .info-list .info-item-1 .text {
      font-size: 14px;
      line-height: 28px;
      color: #999999;
      padding: 0px 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .info-list .info-item-1 .time {
      position: absolute;
      right: 25px;
      bottom: 5px;
      font-size: 24px;
      font-family: DIN;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
    }

    .info-list .info-item-2 {
      position: relative;
    }

    .info-list .info-item-2 img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .info-list .info-item-2 .sub {
      width: 100%;
      height: 58px;
      background-color: rgba(0, 0, 0, 0.52);
      position: absolute;
      bottom: 0px;
      left: 0px;
      line-height: 58px;
      font-size: 18px;
      color: #FFFFFF;
      text-indent: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .pagination {
      padding: 54px 0px 39px;
      text-align: center;
    }
    /deep/ .el-pagination__jump{
      margin: 0px !important;
    }
    /deep/ .el-pagination__total{
      margin: 0px;
    }
    /deep/ .el-pagination__sizes{
      margin: 0px;
    }

    /deep/  .el-pagination button{
      padding: 0px 3px;
      min-width: 0px;
    }
    /deep/ .el-pager li{
      min-width: 0px;
    }
    /deep/ .el-pagination .el-select .el-input {
      width: 80px;
    }
  }
</style>
