<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="true"
      title="预览"
      width="80%"
      top="50px"
      :visible.sync="preview.visible"
    >
      <el-scrollbar>
        <div v-html="preview.context" />
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      preview: {
        visible: false,
        context: false
      }
    }
  },
  methods: {
    init(context) {
      this.preview.context = context
      this.preview.visible = true
    }
  }
}
</script>
