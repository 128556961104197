/* eslint-disable */
import axios from 'axios';
import Qs from 'qs';
import store from '../store'
import router from "../router"
import {Message, MessageBox} from 'element-ui'

import LoginApi from '@/api/uc/login'
const http = {};
var instance = axios.create({
    timeout: 50000,
    baseURL: process.env.VUE_APP_DOMAIN,
})

//添加请求拦截器
instance.interceptors.request.use(
    config => {
        let _config = config
        try {
            _config = queryRefreshToken(_config);
        } catch (e) {
            console.error(e)
        }

        return _config;
    },
    error => {
        return Promise.reject(error);
    }
)

//接口返回拦截器异常处理
instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
)
//get请求
http.get = function (url, data, config = {}) {
    return new Promise((resolve, reject) => {
        const publicParams = {
            ts: Date.now()
        }

        config.params = {...publicParams, ...(data || {})};
        config.paramsSerializer = (params) => {
            return Qs.stringify(params, {arrayFormat: 'repeat'});
        };

        instance.get(url, config)
            .then(response => {
                handleSuccess(response, resolve, config.custom);
            }).catch(e => {
            handleError(e, reject);
        })
    })
}

//post 请求
http.post = function (url, data, config = {}) {
    return new Promise((resolve, reject) => {
        instance.post(url, data, config).then(response => {
            handleSuccess(response, resolve, config.custom);
        }).catch(e => {
            handleError(e, reject)
        })
    })
};

//put 请求
http.put = function (url, options, config = {}) {
    return new Promise((resolve, reject) => {
        instance.put(url, options).then(response => {
            handleSuccess(response, resolve, config.custom);
        }).catch(e => {
            handleError(e, reject);
        })
    })
};

//delete 请求
http.delete = function (url, data, config = {}) {
    return new Promise((resolve, reject) => {
        //获取get请求 option
        const publicParams = {
            ts: Date.now()
        }

        config.params = {...publicParams, ...(data || {})};
        config.paramsSerializer = (params) => {
            return Qs.stringify(params, {arrayFormat: 'repeat'});
        };
        instance.delete(url, config).then(response => {
            handleSuccess(response, resolve, config.custom);
        }).catch(e => {
            handleError(e, reject);
        })
    })
};
//download请求 目前仅支持post请求
http.postDownload = function (url, data, config = {}) {
    return new Promise((resolve, reject) => {
        config.responseType = 'blob';
        instance.post(url, data, config).then(response => {
            handleSuccess(response, resolve, config.custom);
        }).catch(e => {
            handleError(e, reject);
        })
    })
};

//get请求 目前仅支持post请求
http.getDownload = function (url, data, config = {}) {
    return new Promise((resolve, reject) => {
        const publicParams = {
            ts: Date.now()
        }

        config.params = {...publicParams, ...(data || {})};
        config.paramsSerializer = (params) => {
            return Qs.stringify(params, {arrayFormat: 'repeat'});
        };

        config.responseType = 'blob';
        instance.get(url, config)
            .then(response => {
                handleSuccess(response, resolve, config.custom);
            }).catch(e => {
            handleError(e, reject);
        })
    })
};

//post 请求
http.postUpload = function (url, options) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_DOMAIN + url, options, {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: store.state.user.token
            },
            processData: false
        }).then(response => {
            handleSuccess(response, resolve);
        }).catch(e => {
            handleError(e, reject);
        })
    })
};

function handleError(error, reject) {
    if (error.code === 'ECONNABORTED') {
        Message.error('请求超时');
    } else if (error.response && error.response.data) {
        if (error.response.data.msg) {
            Message.error(error.response.data.msg);
        } else if (error.response.data.message) {
            Message.error(error.response.data.message);
        }
    } else if (error.message) {
        Message.error(error.message);
    }

    reject(error)
}

function handleSuccess(res, resolve, custom = {}) {
    let isAlert = custom['isAlert'] === undefined ? true : custom['isAlert'];
    if (res.data.isError) {
        // 未登录
        if (res.data.code === 40000 || res.data.code === 40001
            || res.data.code === 40002 || res.data.code === 40003
            || res.data.code === 40005 || res.data.code === 40008) {
            MessageBox.alert('登录已经失效，请重新登录', '提醒', {
                confirmButtonText: '确定',
                callback: () => {
                    store.dispatch('user/logout')
                    store.dispatch('permission/clear')
                    router.push('/login')
                }
            })
        } else if (isAlert) {
            Message.error(res.data.msg);
        }

        resolve(res.data);
        return;
    }

    if (res.data instanceof Blob) {
        resolve(res);
        return;
    }

    if (res.data instanceof ArrayBuffer) {
        resolve(res);
        return;
    }

    resolve(res.data);
}

async function queryRefreshToken(config) {
    let refreshToken = store.state.user.refreshToken;
    if (!refreshToken) {
        config.headers.common['Authorization'] = store.state.user.token;
        return config;
    }

    let userInfo = store.state.user.userInfo;
    if (!userInfo.expiration) {
        config.headers.common['Authorization'] = store.state.user.token;
        return config;
    }

    //差剩余5分钟内重新刷新一次
    //剩余分钟
    let syTime = (new Date(userInfo.expiration).getTime() - new Date().getTime()) / (1000 * 60);
    if (syTime > 5) {
        config.headers.common['Authorization'] = store.state.user.token;
        return config;
    }

    const result = await axios.post(process.env.VUE_APP_DOMAIN + LoginApi.refreshToken(), {
        grantType: 'refresh_token',
        refreshToken: refreshToken
    });

    if (result.status === 200 && result.data.data && result.data.data.token) {
        userInfo.expiration = result.data.data.expiration;
        store.commit('user/setUser', userInfo)
        store.commit('user/setToken', result.data.data.token)
        store.commit('user/setRefreshToken', result.data.data.refreshToken)
    }

    config.headers.common['Authorization'] = store.state.user.token;
    return config;
}


export default http;
