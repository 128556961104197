import DictionaryItemApi from '@/api/common/dictionaryItem'

export const convertEnum = obj => {
  const list = []
  if (obj) {
    for (const key in obj) {
      list.push({
        text: obj[key],
        value: key
      })
    }
  }
  return list
}

/**
 * 初始化字典
 * @param codes
 * @param callback
 */
export const initDicts = (codes, callback) => {
  if (typeof (codes) === 'string') {
    codes = [codes]
  }

  if (codes && codes.length > 0) {
    DictionaryItemApi.findCode({ codes: codes }).then(res => {
      if (!res.isSuccess) {
        return
      }

      const data = res.data
      const dicts = {}
      for (const code of codes) {
        dicts[code] = data[code]
      }

      callback && callback(dicts)
    })
  }
}

/**
 * 下载方法
 * @param response
 */
export const downloadBlobFile = (response) => {
  const res = response.data
  const type = res.type
  if (type.includes('application/json')) {
    const reader = new FileReader()
    reader.onload = e => {
      if (e.target.readyState === 2) {
        const data = JSON.parse(e.target.result)
        this.$message({
          message: data.msg,
          type: 'warning'
        })
      }
    }
    reader.readAsText(res)
  } else {
    const disposition = response.headers['content-disposition']
    let fileName = '下载文件.zip'
    if (disposition) {
      const respcds = disposition.split(';')
      for (let i = 0; i < respcds.length; i++) {
        const header = respcds[i]
        if (header !== null && header !== '') {
          const headerValue = header.split('=')
          if (headerValue !== null && headerValue.length > 0) {
            if (headerValue[0].trim().toLowerCase() === 'filename') {
              fileName = decodeURI(headerValue[1])
              break
            }
          }
        }
      }
    }
    // 处理引号
    if ((fileName.startsWith("'") || fileName.startsWith('"')) && (fileName.endsWith("'") || fileName.endsWith('"'))) {
      fileName = fileName.substring(1, fileName.length - 1)
    }

    const blob = new Blob([res])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
    window.URL.revokeObjectURL(link.href)
  }
}

/**
 * 下载图片
 */
export const downloadIamge = (imgsrc, name) =>{
  let image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function() {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    let a = document.createElement("a"); // 生成一个a元素
    let event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "photo"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}


/**
 * model 封装值
 * @param model model
 * @param object json值
 */
export const mergeModelValue = (model, object) => {
  for (const key1 in model) {
    const value = model[key1]
    const isJson = typeof (value) === 'object' && Object.prototype.toString.call(value).toLowerCase() == '[object object]' && !value.length
    if (!isJson) {
      model[key1] = Object.prototype.hasOwnProperty.call(object, key1) ? object[key1] : null
      continue
    }

    if(!object[key1]){
      continue
    }

    mergeModelValue(model[key1], object[key1])
  }

  return model
}

/**
 * 合并json，实现深度合并
 * @param sources 可变长度json对象
 */
export const mergeJson = (...sources) => {
  function isObj(x) {
    const type = typeof x
    return x !== null && (type === 'object' || type === 'function')
  }

  const hasOwnProperty = Object.prototype.hasOwnProperty
  const propIsEnumerable = Object.prototype.propertyIsEnumerable
  function assignKey(to, from, key) {
    const val = from[key]
    if (val === undefined || val === null) {
      return
    }

    if (hasOwnProperty.call(to, key)) {
      if (to[key] === undefined || to[key] === null) {
        throw new TypeError('Cannot convert undefined or null to object (' + key + ')')
      }
    }

    if (!hasOwnProperty.call(to, key) || !isObj(val)) {
      to[key] = val
    } else {
      to[key] = assign(Object(to[key]), from[key])
    }
  }

  function assign(to, from) {
    if (to === from) {
      return to
    }

    from = Object(from)
    for (const key in from) {
      if (hasOwnProperty.call(from, key)) {
        assignKey(to, from, key)
      }
    }

    if (Object.getOwnPropertySymbols) {
      const symbols = Object.getOwnPropertySymbols(from)
      symbols.forEach((item) => {
        if (propIsEnumerable.call(from, item)) {
          assignKey(to, from, item)
        }
      })
    }

    return to
  }
  const target = {}
  sources.forEach((item) => {
    assign(target, item)
  })

  return target
}
