<template>
  <div class="components">
    <div class="files-box" v-for="folder in CorpData">
      <div class="item">
        <div class="title">{{folder.dataName}}</div>
        <div class="list">
          <div class="cell-box" v-for="item in (folder.filelist || [])">
            <div class="cell">
              <div class="icon-box">
                <img src="../../assets/image/share-pdf.png" alt="">
              </div>
              <div class="file-box">
                <div class="name">
                  <div>{{item.submittedFileName.split(".")[0]}}</div>
                </div>
                <div class="size">{{getSize(item)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="files-box" v-if="CorpFile.length">
      <div class="item">
        <div class="title">文件列表</div>
        <div class="list">
          <div class="cell-box" v-for="item in (CorpFile || [])">
            <div class="cell">
              <div class="icon-box">
                <img src="../../assets/image/share-pdf.png" alt="">
              </div>
              <div class="file-box">
                <div class="name">
                  <div>{{item.submittedFileName.split(".")[0]}}</div>
                </div>
                <div class="size">{{getSize(item)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CorpShareApi from "@/api/ep/corpShare"
import Qs from "qs";
export default {
  name: "share",
  data() {
    return {
      CorpData: [],
      CorpFile: []
    }
  },
  created() {
    let params = Qs.parse(location.search.substring(1))
    let codes = []
    if(params.isSign) {
      return
    }
    if(params.dataIds) {
      codes.push([...JSON.parse(params.dataIds)])
    }
    if(params.fileIds) {
      codes.push([...JSON.parse(params.fileIds)])
    }
    this.getData({codes})
  },
  methods: {
    //获取文件大小
    getSize(item){
      if( (item.size / (1024*1024))> 1) {
        return (item.size / (1024*1024)).toFixed(2) + 'M'
      }
      if( (item.size / (1024))> 1) {
        return (item.size / (1024)).toFixed(2) + 'KB'
      }
      return (item.size).toFixed(2) + 'B'
    },
    getData(params){
      CorpShareApi.intoShare(params).then(res => {
        let data = res.data
        this.CorpData = data.CorpData || []
        this.CorpFile = data.CorpFile || []
      })
    }
  }
}
</script>

<style scoped lang="scss">
.components {
  padding: 40px 16px 20px 16px;
}

.title {
  position: relative;
  padding-left: 18px;
  height: 24px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
  text-stroke: 1 rgba(0, 0, 0, 0);

  &:before {
    position: absolute;
    top: 1px;
    left: 0;
    background-color: #359029;
    content: '';
    display: block;
    width: 4px;
    height: 21px;
    border-radius: 100%;

  }
}

.cell-box {
  padding: 20px 30px;
}

.list {
  display: flex;
  flex-wrap: wrap;

  .cell {
    display: flex;
    padding: 16px;
    width: 343px;
    height: 91px;
    background: #FFFFFF;
    box-shadow: 0px 7px 22px rgba(53, 144, 41, 0.14);
    opacity: 1;
    border-radius: 8px;
    box-sizing: border-box;

    .icon-box {
      flex: 0 0 1;
      width: 50px;
      height: 62px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .file-box {
      flex: 1;
      padding-left: 22px;

      .name {
        display: flex;
        align-items: center;
        white-space: nowrap;
        height: 40px;
        line-height: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
        text-stroke: 1 rgba(0, 0, 0, 0);
      }

      .size {
        margin-top: 5px;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #CCCCCC;
        -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
        text-stroke: 1 rgba(0, 0, 0, 0);
      }
    }
  }
}
</style>
