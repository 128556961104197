<template>
	<div class="components">
		<div class="title">原辅材料</div>
		<div class="content">
			<div style="display: flex; justify-content: space-between;margin-bottom:20px;">
				<div class="btns-box">
					<!-- <el-button type="primary" @click="add">新增原辅材料</el-button> -->
					<el-button type="primary" @click="batchDelete()">批量删除</el-button>
				</div>
				<hm-search-form ref="searchForm" :data="searchConfig" deriveShow label-width="150" size="normal" />
			</div>
			<div>
				<hm-table ref="table" :stripe="true" :border="true"
					:header-cell-style="{ backgroundColor: '#F5F7FA', color: '#696B6F' }" :api="listRequest"
					:table-config="tableConfig">
					<div slot="operation" slot-scope="row">
						<!-- <el-button class="table-operation " type="info" @click="viewClck(row.data)">导出</el-button> -->
						<el-button class="table-operation " type="success" @click="viewClck(row.data)">查看</el-button>
						<el-button class="table-operation table-operation-btn" @click="edit(row.data)">编辑</el-button>
						<el-button class="table-operation" type="danger" @click="remove(row.data)">删除</el-button>
					</div>
					<div slot="avatar" slot-scope="row">
						<el-avatar :key="row.data.qrCard" :src="row.data.qrCard" fit="fill">
						</el-avatar>
					</div>
				</hm-table>
			</div>
		</div>
		<edit-view :dialogVisible="dialogVisible" :disabled="disabled" :title="title" :recordCode="recordCode" @handleClose="handleClose"></edit-view>
		<ck-view :dialogVisible="dialogVisibleCk" :viewData="viewData" @handleViewClose="handleViewClose"></ck-view>
			
	</div>
</template>

<script>
	import CorpCardApi from "@/api/pro/assistCapacity";
	import {
		TableConfig,
		SearchConfig
	} from "./config";
	import {
		downloadBlobFile,
		mergeJson
	} from "@/utils/commons";
	import editView from "./edit.vue";
	import ckView from "./ckView.vue"
	export default {
		name: "assistMaterial",
		components: {
			editView,
			ckView
		},
		data() {
			return {
				listRequest: CorpCardApi.getList,
				tableConfig: TableConfig.bind(this)(),
				searchConfig: SearchConfig.bind(this)(),
				searchParams: {
					// 搜索条件
					params: {},
					// table下拉条件
					tableParams: {},
				},
				dialogVisible: false,
				title: "新增",
				flag:true,
				dialogVisibleCk:false,
				viewData:[],
				recordCode:"",
				disabled:false
			};
		},
		created() {
		},
		methods: {
			handleViewClose(){
				this.dialogVisibleCk=false
			},
			viewClck(item){
				this.recordCode=item.recordCode
				this.title = '查看原辅材料'
				this.dialogVisible = true;
				this.disabled=true
				return
				CorpCardApi.getListById({id:item.id}).then(res=>{
					this.viewData=res.data
					this.dialogVisibleCk=true
				})
			},
			getSelectFun() {
				CorpCardApi.getSelect().then(res => {
					this.optionsList=res.data.records
				})
			},
			handleClose() {
				this.dialogVisible = false;
				this.$refs.table.refresh();
			},
			edit(item) {
				this.disabled=false
				this.recordCode=item.recordCode
				this.title = '编辑原辅材料'
				this.dialogVisible = true;
				
			},
			add() {
				this.title = '新增原辅材料'
				this.dialogVisible = true;
				this.formData = {
					assistProductName: null,
					unit: null
				}
			},
			remove(data) {
				this.batchDelete(data.id);
			},
			batchDelete(id) {
				let ids;
				if (id) {
					ids = [id];
				} else {
					ids = this.$refs.table.getSelections().ids;
				}
				if (ids.length <= 0) {
					this.$message.error("至少选择一项");
					return false;
				}
				this.$confirm("此操作将永久删除, 是否继续?", "提示", {
					type: "warning",
				}).then(() => {
					CorpCardApi.batchDelete({
						ids: ids
					}).then((res) => {
						if (!res.isSuccess) {
							return;
						}
						this.$message.success("删除成功");
						this.$refs.table.refresh();
					});
				});
			},
			handleSearch(data) {
				this.searchParams.tableParams = data;
				const params = mergeJson(
					this.searchParams.params,
					this.searchParams.tableParams
				);
				this.$refs.table.reload(params);
			},
			submitSuccess() {
				this.$refs.table.refresh();
			},
			/*
						excel导出事件

						*/
			deriveBtnExcelClick() {

				if (this.flag) {
					this.flag = false
					const params = mergeJson(this.searchParams) || {}
					if (!params.extend) {
						params.extend = {}
					}
					params.extend.fileName = '原辅材料'
					CorpCardApi.exportExcel(params).then((data) => {
						downloadBlobFile(data)
					})
				}
				setTimeout(() => {
					this.flag = true
				}, 500)

			}
		},
	};
</script>

<style scoped>
	.components {
		height: 100%;
	}

	.title {
		font-size: 20px;
		font-weight: 400;
		color: #030303;
	}

	.content {
		padding: 20px;
		margin-top: 20px;
		background-color: #fff;
		height: calc(100% - 60px);
		border-radius: 5px;
		box-sizing: border-box;
	}

	.table-operation {
		margin: 0 5px;
	}

	.table-operation-btn {
		background-color: #2D8CF0;
		color: #fff;
	}
</style>
