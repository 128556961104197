<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`危废仓库厂区平面表上传`"
  />
</template>

<script>
import DischargePortAPI from "@/api/ep/dischargePortOne"
import {SignalImageConfigOne} from './config.js'
import {mergeModelValue} from '@/utils/commons'

export default {
  data() {
    return {
      visible: false,
      formConfig: SignalImageConfigOne.bind(this)(),
      id: ''
    }
  },
  methods: {
    init(id) {
      this.id = id || ''
      this.visible = true
      this.formConfig = SignalImageConfigOne.bind(this)()
      if (!id) {
        return
      }
      DischargePortAPI.get(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        const model = this.formConfig.config.model
        mergeModelValue(model, data)
      })
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      result.id = this.id
      DischargePortAPI.updateSignalImage(result).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        this.visible = false
        this.$message.success('编辑成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>
/deep/.el-form-item__label {
  width: 120px !important;
}

/deep/.el-form-item__content {
  margin-left: 120px !important;
}
</style>