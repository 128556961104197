import Regular from '@/utils/regular'
// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const EmployeeSearchConfig = function() {
  return {
    list: [
      {
        label: '员工名称',
        key: 'name',
        type: 'input',
        placeholder: '请输入员工名称'
      },
      {
        label: '联系方式',
        key: 'mobile',
        type: 'input',
        placeholder: '请输入联系方式'
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
      selects: {},
      model: {
        'name':'',
        'mobile':'',
      }
    }
  }
}

// 表格展示配置
export const EmployeeTableConfig = function() {
  return {
    list: [
      {
        type: 'selection',
        width: 60
      },
      {
        label: '编号',
        type: 'index',
        align: 'center',
        width: 60
      },
      {
        prop: 'name',
        align: 'center',
        label: '姓名',
      },
      {
        prop: 'mobile',
        align: 'center',
        label: '账号信息',
      },
      {
        prop: 'status.desc',
        align: 'center',
        width: '80px',
        label: '账户状态',
      },
      {
        prop: 'createTime',
        align: 'center',
        label: '录入时间',
      },
      {
        fixed: 'right',
        label: '操作',
        align: 'center',
        slotScope: 'operation',
        'min-width': 150
      }
    ]
  }
}

// 新增对象配置
export const EmployeeAddConfig = function () {
  return {
    list: [
      {
        label: '姓名',
        key: 'name',
        type: 'input',
        placeholder: '请输入姓名'
      },
      {
        label: '账号信息',
        key: 'mobile',
        type: 'input',
        placeholder: '请输入账户信息(手机号）'
      },
      {
        label: '账户密码',
        key: 'password',
        type: 'input',
        placeholder: '默认密码为123456'
      },
      {
        label: '账户状态',
        key: 'status',
        'active-value': 'ENABLE',
        'inactive-value': 'DISABLED',
        type: 'switch'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        'name':'',
        'mobile':'',
        'password':'',
        'status': 'ENABLE'
      },
      rules: {
        name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
        mobile: [{  required: true,pattern: Regular.MOBILE, message: '手机号格式不正确' }]
      },
      selects: {
      }
    }
  }
}

// 编辑对象配置
export const EmployeeEditConfig = function () {
  return {
    list: [
      {
        label: '姓名',
        key: 'name',
        type: 'input',
        placeholder: '请输入姓名'
      },
      {
        label: '账号信息',
        key: 'mobile',
        type: 'input',
        placeholder: '请输入账号信息(手机号）'
      },
      {
        label: '账户状态',
        key: 'status',
        'active-value': 'ENABLE',
        'inactive-value': 'DISABLED',
        type: 'switch'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        'name':'',
        'mobile':'',
        'password':'',
        'status': 'ENABLE'
      },
      rules: {
        name: [{required: true, message: '姓名', trigger: 'blur'}],
        mobile: [{  required: true,pattern: Regular.MOBILE, message: '手机号格式不正确' }]
      },
      selects: {
      }
    }
  }
}



