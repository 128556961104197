import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'image/'

export default {
  /**
   *
   * @param params
   * @returns {*}
   */
  list(params) {
    return http.post(PREFIX + `list`, params)
  },
}
