import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'optionRecord/'

export default {
    /**
     * 分页
     * @param params
     * @returns {*}
     */
    search(params) {
        return http.post(PREFIX + `search`, params)
    },

    /**
     * 导出excel
     * @param params
     * @returns {*}
     */
    exportExcel(params) {
        return http.postDownload(PREFIX + `exportOptionRecord`, params);
    }
}
