import { render, staticRenderFns } from "./mainBusiness.vue?vue&type=template&id=23631aee&scoped=true&"
import script from "./mainBusiness.vue?vue&type=script&lang=js&"
export * from "./mainBusiness.vue?vue&type=script&lang=js&"
import style0 from "./mainBusiness.vue?vue&type=style&index=0&id=23631aee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23631aee",
  null
  
)

export default component.exports