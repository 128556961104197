<template>
	<div class="components">
		<div class="title" style="margin-bottom: 10px">
			<span :class="tabIndex==0 ? 'tab-span' : ''" @click="tabClick(0)">排污口标示牌</span>
			<span :class="tabIndex==1 ? 'tab-span' : ''" @click="tabClick(1)">危险废物标示牌</span>
		</div>
		<div class="content" v-if="tabIndex==0">
			<hm-search-form ref="searchForm" :data="searchConfig" size="normal" label-width="120" />
			<div style="margin-top: 20px;">
				<hm-table ref="table" :tableHeight="500" :stripe="true" :border="true"
					:header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}" :api="listRequest"
					:table-config="tableConfig">
					<div slot="content" slot-scope="row">
						<el-image style="width: 100px; height: 100px" :src="row.data.content"
							:preview-src-list="[row.data.content]">
						</el-image>
					</div>
					<div slot="cover" slot-scope="row">
						<el-avatar :key="row.data.cover" :src="row.data.cover" shape="square" :size="50"></el-avatar>
					</div>
					<div slot="operation" slot-scope="row">
						<el-button class="table-operation" type="text" @click="edit(row.data)">编辑
						</el-button>
						<el-button class="table-operation" v-if="sso" type="text" @click="remove(row.data.id)">删除
						</el-button>
						<el-button class="table-operation" type="text" @click="detail(row.data)">详情</el-button>
						<el-button class="table-operation" type="text" @click="downQr(row.data)">下载二维码</el-button>
					</div>
					<div slot="equipment" slot-scope="row">
						<div v-if="row.data.equipments">
							<span v-for="(item,index) in row.data.equipments"><span
									v-if="index>0">,</span>{{ item.name }}</span>
						</div>
					</div>
					<div slot="protective" slot-scope="row">
						<div v-if="row.data.protectives">
							<span v-for="(item,index) in row.data.protectives"><span
									v-if="index>0">,</span>{{ item.name }}</span>
						</div>
					</div>
					<div slot="promission" slot-scope="row">
						<div v-if="row.data.promissions">
							<span v-for="(item,index) in row.data.promissions"><span
									v-if="index>0">,</span>{{ item.submittedFileName }}</span>
						</div>
					</div>
					<div slot="signalImage" slot-scope="row">
						<el-button class="table-operation" v-if="sso" type="text" @click="editSignalImage(row.data)">
							编辑
						</el-button>
					</div>
					<div slot="productProcess" slot-scope="row">
						<el-button class="table-operation" type="text" v-if="sso" @click="editProductProcess(row.data)">
							编辑
						</el-button>
					</div>
					<div slot="checkFile" slot-scope="row">
						<el-button class="table-operation" type="text" v-if="sso" @click="editCheckFile(row.data)">
							编辑
						</el-button>
					</div>
				</hm-table>
			</div>
		</div>

		<!-- 危险废物标识牌 -->
		<div class="content" v-if="tabIndex==1">
			<hm-search-form ref="searchForm" :data="searchConfigOne" size="normal" label-width="120" />
			<div style="margin-top: 20px;">
				<hm-table ref="tableOne" :tableHeight="500" :stripe="true" :border="true"
					:header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}" :api="listRequestONE"
					:table-config="tableConfigOne">
					<div slot="name" slot-scope="row">
						{{row.data.warehouse.name}}
					</div>
					<div slot="code" slot-scope="row">
						{{row.data.warehouse.code}}
					</div>
					<div slot="wareCategory" slot-scope="row">
						{{row.data.warehouse.wareCategory}}
					</div>
					<div slot="operation" slot-scope="row">
						<el-button class="table-operation" type="text" @click="editWfClick(row.data)">编辑
						</el-button>
						<el-button class="table-operation" type="text" @click="removeOne(row.data.id)">删除
						</el-button>
						<el-button class="table-operation" type="text" @click="detailOne(row.data)">详情</el-button>
						<el-button class="table-operation" type="text" @click="downQrOne(row.data)">下载二维码</el-button>
					</div>
					<!-- 厂区平面图 -->
					<div slot="wareHouseAddressImage" slot-scope="row">
						<el-button class="table-operation" type="text" v-if="sso" @click="editSignalImageOne(row.data)">
							编辑
						</el-button>
					</div>
					<div slot="linkName" slot-scope="row">
						<el-button class="table-operation" type="text" v-if="sso" @click="editName(row.data,0)">
							编辑
						</el-button>
					</div>
					<div slot="linkPhone" slot-scope="row">
						<el-button class="table-operation" type="text" v-if="sso" @click="editName(row.data,1)">
							编辑
						</el-button>
					</div>
				</hm-table>
			</div>
		</div>
		<signal-image-upload ref="signalImageForm" @submitSuccess="submitSuccess"></signal-image-upload>
		<product-pocess-edit ref="productPocessForm" @submitSuccess="submitSuccess"></product-pocess-edit>
		<check-file-edit ref="checkFileForm" @submitSuccess="submitSuccess"></check-file-edit>
		<edit ref="edit" @submitSuccess="submitSuccess"></edit>
		<detail ref="detail"></detail>
		<archives-code ref="archivesCode"></archives-code>
		<archives-code-one ref="archivesCodeOne"></archives-code-one>
		<pmt-view ref="pmtImgeForm" @submitSuccess="submitSuccessOne"></pmt-view>
		<!-- 修改危险废物标示牌的名称和电话 -->
		<el-dialog :title="editTitle" :visible.sync="editDialogVisible" width="30%" :before-close="handleCloseEdit">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
				<el-form-item label="仓库负责人名称" prop="linkName" v-if="editIndex==0">
					<el-input v-model="ruleForm.linkName"></el-input>
				</el-form-item>
				<el-form-item label="仓库负责人电话" prop="linkPhone" v-if="editIndex==1">
					<el-input v-model="ruleForm.linkPhone"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseEdit">取 消</el-button>
				<el-button type="primary" @click="editSubmit('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
		
		
		<!-- 危险废物编辑 -->
		<wf-edit :dialogVisible="wfDialogVisible" :id="wfId" @handleClose="wfHandleClose"></wf-edit>
		<!-- 危废详情 -->
		<deatil-one ref="detailOne"></deatil-one>

	</div>
</template>

<script>
	import DischargePortAPI from "@/api/ep/dischargePort"
	import DischargePortAPIONE from "@/api/ep/dischargePortOne"
	import {
		SearchConfig,
		TableConfig,
		SearchConfigOne,
		TableConfigOne
	} from "./config"
	import {
		mergeJson
	} from '@/utils/commons'
	import SignalImageUpload from "./signalImageUpload"
	import ProductPocessEdit from "./productPocessEdit"
	import CheckFileEdit from "./checkFileUpload"
	import pmtView from "./pmtView"
	import Edit from './edit'
	import Detail from './detail'
	import ArchivesCode from './archivesCode'
	import ArchivesCodeOne from './archivesCodeOne'
	import wfEdit from "./wfEdit"
	import deatilOne from "./detailOne.vue"
	import UserApi from "@/api/uc/user"

	export default {
		components: {
			SignalImageUpload,
			ProductPocessEdit,
			CheckFileEdit,
			Edit,
			Detail,
			ArchivesCode,
			pmtView,
			ArchivesCodeOne,
			wfEdit,
			deatilOne
		},
		data() {
			return {
				listRequest: DischargePortAPI.search,
				searchConfig: SearchConfig.bind(this)(),
				tableConfig: TableConfig.bind(this)(),
				searchParams: {
					// 搜索条件
					params: {},
					// table下拉条件
					tableParams: {},
				},
				sso: this.$store.getters.sso,
				tabIndex: 0,
				// 危险废物
				searchConfigOne: SearchConfigOne.bind(this)(),
				tableConfigOne: TableConfigOne.bind(this)(),
				listRequestONE: DischargePortAPIONE.search,
				searchParamsOne: {
					// 搜索条件
					params: {},
					// table下拉条件
					tableParams: {},
				},
				editDialogVisible: false,
				ruleForm: {},
				rules: {
					linkName: [{
						required: true,
						message: '请输入联系人姓名',
						trigger: 'blur'
					}],
					linkPhone: [{
						required: true,
						message: '请输入联系人电话',
						trigger: 'blur'
					}]
				},
				editTitle: "编辑仓库负责人名称",
				editIndex: 0,
				wfDialogVisible:false,
				wfId:""
				
			}
		},
		methods: {
			editWfClick(item){
				this.wfDialogVisible=true
				this.wfId=item.id
			},
			downQr(data) {
				this.$refs.archivesCode.init(data.id)
			},
			downQrOne(data){
				this.$refs.archivesCodeOne.init(data.id)
			},
			detail(item) {
				this.$refs.detail.init(item.id)
			},
			detailOne(item){
				this.$refs.detailOne.init(item.id)
			},
			edit(item) {
				this.$refs.edit.init(item)
			},
			editSignalImage(item) {
				this.$refs.signalImageForm.init(item.id)
			},
			editProductProcess(item) {
				this.$refs.productPocessForm.init(item.id)
			},
			editCheckFile(item) {
				this.$refs.checkFileForm.init(item.id)
			},
			editSignalImageOne(item) {
				this.$refs.pmtImgeForm.init(item.id)
			},



			remove(id) {
				this.$confirm('此操作将永久删除, 是否继续?', '提示', {
					type: 'warning'
				}).then(() => {
					DischargePortAPI.remove({
						id: id
					}).then((res) => {
						if (!res.isSuccess) {
							return
						}

						this.$message.success('删除成功')
						this.$refs.table.refresh()
					})
				})
			},
			removeOne(id){
				this.$confirm('此操作将永久删除, 是否继续?', '提示', {
					type: 'warning'
				}).then(() => {
					DischargePortAPIONE.remove({
						id: id
					}).then((res) => {
						if (!res.isSuccess) {
							return
						}
				
						this.$message.success('删除成功')
						this.$refs.tableOne.refresh()
					})
				})
			},
			handleSearch(data) {
				this.searchParams.tableParams = data
				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.$refs.table.reload(params)
			},
			handleSearchOne(data) {
				this.searchParamsOne.tableParams = data
				const params = mergeJson(this.searchParamsOne.params, this.searchParamsOne.tableParams)
				this.$refs.tableOne.reload(params)
			},
			submitSuccess() {
				this.$refs.table.refresh()
			},
			submitSuccessOne() {
				this.$refs.tableOne.refresh()
			},
			editName(item, index) {
				this.editIndex = index
				this.ruleForm.id = item.warehouse.id
				if (index == 0) {
					this.editTitle = '编辑仓库负责人名称'
					this.$set(this.ruleForm,'linkName',item.warehouse.linkName)
				} else {
					this.editTitle = '编辑仓库负责人电话'
					this.$set(this.ruleForm,'linkPhone',item.warehouse.linkPhone)
				}
				this.editDialogVisible = true
			},
			tabClick(index) {
				if (this.tabIndex == index) return
				this.tabIndex = index
				if (index == 0) {
					this.$nextTick(() => {
						this.$refs.table.refresh()
					})
				} else {
					this.$nextTick(() => {
						this.$refs.tableOne.refresh()
					})

				}
			},
			editSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.submitData()
						
					}
				});
			},
		async submitData(){
			
			
			let res = null;
			if(this.editIndex==0){
				res=await DischargePortAPIONE.updateName(this.ruleForm)
			}else{
				res=await DischargePortAPIONE.updatePhone(this.ruleForm)
			}
			if (res.code == 0) {
				this.$message({
					message:"修改成功",
					type: "success",
				});
				this.handleCloseEdit();
			} else {
				this.$message.error(res.message);
			}
			
				
			},
			handleCloseEdit(){
				this.ruleForm={}
				this.editDialogVisible=false
				
				this.$refs.tableOne.refresh()
			},
			wfHandleClose(){
				this.wfDialogVisible=false
				this.$refs.tableOne.refresh()
			}
		}
	}
</script>

<style scoped>
	/deep/ .el-range-editor.is-active:hover {
		border-color: #359029 !important;
	}

	/deep/ .el-range-editor.is-active {
		border-color: #359029 !important;
	}

	.el-range-editor.is-active .components {
		height: 100%;
	}

	.title {
		font-size: 20px;
		font-weight: 400;
		color: #666;
		cursor: pointer;
	}

	.title span {
		display: inline-block;
		margin-right: 20px;
	}

	.tab-span {
		color: #42AB34;
	}

	.content {
		background-color: #fff;
		height: calc(100% - 100px);
		border-radius: 5px;
		padding: 20px;
	}

	.avatar-uploader {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px dashed #dddddd;
		width: 50px;
		height: 50px;
	}
</style>
