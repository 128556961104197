<template>
	<el-dialog :visible.sync="visible" :show-close="false" width="830px" :before-close="handleClose">
		<div class="contain">
			<div class="title">二维码下载</div>
			<div class="info" ref="imageWrapper">
				<div class="info-top">
					<div style="overflow: hidden;width: 135px;height: 265px">
						<div class="info-top-l">
							<div class="title">
								<h2>危险废物仓库</h2>
							</div>
							<div class="item">
								<div class="sub">单位名称：</div>
								<div class="sub-info">{{data.corpName }}</div>
							</div>
							<div class="item">
								<div class="sub">贮存场所编号：</div>
								<div class="sub-info">{{ data.warehouse.code }}</div>
							</div>
							<div class="item">
								<div class="sub">主要贮存种类：<br />{{data.warehouse.wareCategory}}</div>
							</div>
							<div class="bottom">
								<p style="padding-top: 5px">生态环境部监制</p>
							</div>
						</div>
					</div>
					<div class="info-top-r"><img src="../../../assets/image/1.png"></div>
				</div>
				<div class="info-m">
					<!-- <div class="info-m-left">
            <img style="width: 50px;height: 50px;border-radius: 50%;object-fit: cover" :src="data.corpAccount.logo">
          </div> -->
					<div class="info-m-right">
						<div class="company-1">{{data.companyName }}危废贮存标识</div>
						<div class="company-2">安徽一二三环保科技有限公司监制</div>
					</div>
				</div>
				<div class="info-b">
					<div class="info-b-l">
						<div class="cell"> 贮存间名称：{{ data.warehouse.name }}</div>
						<div class="cell"> 贮存间编号：{{ data.warehouse.code }}</div>
						<div class="cell"> 责任人：{{ data.warehouse.linkName }}</div>
						<div class="cell"> 联系方式：{{ data.warehouse.linkPhone }}</div>
					</div>
					<div class="info-b-r"><img :src="data.qrCode"></div>
				</div>
				<div class="text">绿水青山就是金山银山</div>
			</div>
			<div class="btns">
				<div class="btn" @click="handleClose">取消</div>
				<div class="btn" @click="onCreateImage">确定</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import DischargePortApi from "@/api/ep/dischargePortOne"
	import html2canvas from "html2canvas"

	export default {
		name: "archivesCode",
		data() {
			return {
				visible: false,
				data: {}
			}
		},
		methods: {
			onCreateImage() {
				const loading = this.$loading({
					lock: true,
					text: '生成中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				html2canvas(this.$refs.imageWrapper, {
					backgroundColor: '#fff',
					scale: 2,
					useCORS: true
				}).then(canvas => {
					let link = document.createElement("a");
					link.href = canvas.toDataURL();
					link.setAttribute("download", "危险废物标示牌.png");
					link.style.display = "none";
					document.body.appendChild(link);
					link.click();
					loading.close();
					this.$message.success('下载成功')
					this.handleClose()
				});
			},
			init(id) {
				this.visible = true
				this.getData(id)
			},
			getData(id) {
				DischargePortApi.getQrcode({
					id
				}).then((res => {
					console.log(res)
					this.data = res.data
				}))
			},
			handleClose() {
				this.visible = false
			}
		}
	}
</script>

<style scoped>
	/deep/ .el-dialog {
		background-color: #fff !important;
		border-radius: 10px !important;
	}

	.bor {
		border: 2px solid #FCDB00 !important;
	}

	.col {
		background-color: #FCDB00 !important;
	}

	.fot {
		color: #000 !important;
	}

	.contain {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.title {
		font-size: 30px;
		color: #000000;
		margin-bottom: 47px;
		display: flex;
		align-items: center;
	}

	.title h2 {
		font-weight: 700;
		margin: 0 auto;
		padding-bottom: 15px;
		font-size: 18px;
	}

	.info {
		width: 433px;
		box-sizing: border-box;
		border: 2px solid #fcdb00;
	}

	.info-top {
		display: flex;
		padding: 10px;
		justify-content: space-between;
	}

	.info-top-l {
		margin-right: 10px;
		background-color: #fcdb00;
		padding-top: 10px;
		padding-right: 14px;
		box-sizing: border-box;
		width: 160px;
		transform: scale(.9);
		transform-origin: 0 0;
		height: 293.4px;

		position: relative;
	}

	.info-top-l .title {
		padding-top: 5px;
		font-size: 14px;
		margin-bottom: 5px;
		color: #000000;
		text-align: left;
		padding-left: 7px;
	}

	.info-top-l .item {
		/*margin: 0 auto;*/
		font-size: 12px;
		color: #000000;
		font-weight: 700;
		line-height: 20px;
		margin-bottom: 5px;
		/*  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;*/
		padding-left: 7px;
	}

	.info-top-l .item .sub {
		font-size: 12px;
	}

	.info-top-l .item .sub-info,
	.info-top-l .item .sub {
		line-height: 20px;
	}

	.info-top-l .item .sub-info,
	.sub span {
		font-size: 13px;
	}

	.info-top-l .sub-info-3 {
		overflow: hidden;
		/* text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;*/
	}

	.info-top-l p {
		font-weight: 700;
		position: absolute;
		bottom: 3px;
		left: -10px;
		width: 100%;
		font-size: 14px;
		color: #000000;
		/*padding-right: 10px;*/
		box-sizing: border-box;
		padding-left: 7px;
		padding-bottom: 15px;
		text-align: center;
		/*margin: 0 auto;*/

	}

	.info-top-r {
		/*height: auto;*/
		width: 263.5px;
		height: 263.5px;
	}

	.info-top-r img {
		/*width: 207px;*/
		width: 100%;
		height: 100%;
	}

	.info-m {
		padding: 15px 20px 0px;
		margin: 0 20px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #ccc;
	}

	.info-m .info-m-left {
		flex: 1;
		padding-right: 20px;
	}

	.info-m-left img {
		width: 100%;
	}

	.info-m .info-m-right {
		flex: 7;
		boder-bottom: 1px solid #CCC;
	}

	.info-m .company-1 {
		font-size: 15px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 10px;
	}

	.info-m .company-2 {
		font-size: 12px;
		color: #000000;

		padding-bottom: 10px;
	}

	.info-b {
		display: flex;
		justify-content: space-between;
		padding: 26px 20px 0px;
	}

	.info-b .info-b-l {
		flex: 1;

	}

	.info-b .cell {
		font-size: 14px;
		color: #000000;
		line-height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		margin-bottom: 10px;
	}

	.info-b .info-b-r {
		width: 130px;
		height: 130px;
		margin-top: -10px;
	}

	.info-b-r img {
		width: 100%;
		height: 100%;
	}

	.text {
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		color: #000000;
		padding: 20px;
	}

	.btns {
		display: flex;
		align-items: center;
		padding-bottom: 20px;
	}

	.btn {
		width: 233px;
		height: 48px;
		border-radius: 24px;
		font-size: 18px;
		line-height: 48px;
		color: #FFFFFF;
		text-align: center;
		margin: 50px 25px 0px;
		cursor: pointer;
	}

	.btn:nth-child(1) {
		background: #BEBEBE;
	}

	.btn:nth-child(2) {
		background: #fcdb00;
	}
</style>
