import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import VueClipboard from 'vue-clipboard2'
import './utils/rem'
Vue.use(VueClipboard)

Vue.use(ElementUI);
Vue.config.productionTip = false

/* eslint-disable no-new */
/*css初始化*/
import '@/assets/css/base.css'
import '@/assets/css/font.css'
/*百度地图引入*/
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '4LFCi3nx9p05XyFh3y8XehBVcOY0GI5V'    //这个地方是官方提供的ak密钥
})
import * as filters from '@/utils/filters'
import HmDescription from '@/components/hm-description/hm-description'
import HmDialogForm from '@/components/hm-dialog-form/hm-dialog-form'
import HmDialog from '@/components/hm-dialog/hm-dialog'
import HmDrawerDescription from '@/components/hm-drawer-description/index'
import HmDrawerTabs from '@/components/hm-drawer-tabs/index'
import HmForm from '@/components/hm-form/hm-form'
import HmTable from '@/components/hm-table/hm-table'
import HmSearchForm from '@/components/hm-search-form/hm-search-form'
import HmChunkUpload from '@/components/hm-chunk-upload/index'
import HmPreviewExcel from '@/components/hm-preview-excel/hm-preview-excel'
import HmTreeInput from '@/components/hm-tree-input/hm-tree-input'
import HmEditor from '@/components/hm-editor/index'
import HmImport from '@/components/hm-import/index'
import HmCard from '@/components/hm-card/index'
import HmIcon from '@/components/hm-icon/index'
import HmPictureCard from '@/components/hm-picture-card/index'

Vue.component('hm-description', HmDescription)
Vue.component('hm-dialog', HmDialog)
Vue.component('hm-dialog-form', HmDialogForm)
Vue.component('hm-drawer-description', HmDrawerDescription)
Vue.component('hm-drawer-tabs', HmDrawerTabs)
Vue.component('hm-form', HmForm)
Vue.component('hm-table', HmTable)
Vue.component('hm-search-form', HmSearchForm)
Vue.component('hm-chunk-upload', HmChunkUpload)
Vue.component('hm-preview-excel', HmPreviewExcel)
Vue.component('hm-tree-input', HmTreeInput)
Vue.component('hm-editor', HmEditor)
Vue.component('hm-import', HmImport)
Vue.component('hm-card', HmCard)
Vue.component('hm-icon', HmIcon)
Vue.component('hm-picture-card', HmPictureCard)




new Vue({
  el: '#app',
  router,
  store,
  render: h=>h(App),
}).$mount("#app")
