<template>
	<div class="page-wrap" style="padding: 20px;background-color: #fff">
		<hm-search-form ref="searchForm" :data="searchConfig" deriveShow size="normal" label-width="120" />
		<div class="component-table">
			<div class="table-box" style="height: calc(100% - 112px); margin-top: 10px;">
				<el-table :data="tableData" height="500" style="width: 100%;" align="center" ref="table"
					:row-style="{height: '80px'}">
					<el-table-column type="index" label="序号" :index="getSerialNum" align="center">
					</el-table-column>
					<el-table-column prop="name" label="生产设备名称" width="200px" align="center">
						<template slot-scope="scope">
							<span style="cursor: pointer;color: #359029"
								@click="getDetails(scope.row.id)">{{ scope.row.name }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="code" label="生产设备编号" width="150px" align="center">
					</el-table-column>
					<el-table-column prop="workmanship" label="生产设备工艺" align="center">
					</el-table-column>
					<el-table-column label="生产设备参数" align="center" width="600px">
						<el-table-column prop="parameter" label="参数名称" width="150px" align="center">
						</el-table-column>
						<el-table-column prop="measure" label="计量单位" width="150px" align="center">
						</el-table-column>
						<el-table-column prop="design" label="设计值" width="150px" align="center">
						</el-table-column>
						<el-table-column prop="other" label="其他设施参数信息" width="150px" align="center">
						</el-table-column>
					</el-table-column>
				</el-table>
			</div>

			<!-- 表格底部新增插槽-->
			<div ref="tableBackendExtend" class="table-backend-extend">
				<slot name="table-backend-extend" />
			</div>
			<!--        分页-->
			<div class="pagination-box">
				<el-pagination :current-page="searchParams.tableParams.current" :page-sizes="[10, 20, 50, 100]"
					:page-size="searchParams.tableParams.size" layout="total, sizes, prev, pager, next, jumper"
					:total="searchParams.tableParams.total" @size-change="sizeChange" @current-change="currentChange" />
			</div>
		</div>
		<equipment-detail ref="equipmentDetail" />
	</div>
</template>

<script>
	import Product from '@/api/ep/equipment'
	import {
		ProductSearchConfig
	} from "./config"
	import EquipmentDetail from './detail'
	import {downloadBlobFile, mergeJson} from '@/utils/commons'

	export default {
		components: {
			EquipmentDetail,
		},
		data() {
			return {
				tableData: [],
				searchConfig: ProductSearchConfig.bind(this)(),
				searchParams: {
					// 搜索条件
					params: {
						model: {
							corpId: ''
						}
					},
					// table下拉条件
					tableParams: {
						size: 10,
						current: 1,
						total: 0
					}
				},
				serialNum: 1,
				sections: [],
				corpId: '',
				flag: true,

			}
		},
		computed: {
			getSerialNum() {
				return this.serialNum++
			}
		},
		mounted() {
			this.corpId = localStorage.corpId ? localStorage.corpId : this.$route.params.corpId
			const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
			this.getData(params)
		},
		methods: {
			getData(params) {
				params.model.corpId = this.corpId
				Product.search(params).then(res => {
					this.tableData = res.data.records
					const tableParams = this.searchParams.tableParams
					tableParams.current = res.data.current
					tableParams.total = res.data.total
					tableParams.size = res.data.size
				})
			},
			getDetails(id) {
				this.$refs.equipmentDetail.init(id, 'product')
			},
			handleSearch(data) {
				console.log('触发', data)
				this.searchParams.params = data
				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.getData(params)
			},
			handleFilterSearch(data) {
				this.searchParams.tableParams = data
				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.getData(params)
			},
			/**
			 * 不改变参数，加载数据
			 */
			refresh(isFirst = true) {
				if (isFirst) {
					this.searchParams.tableParams.current = 1
				}

				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.getData(params)
			},
			/**
			 * pageSize变化
			 * @param val pageSize 条数
			 */
			sizeChange(val) {
				this.searchParams.tableParams.size = val
				this.searchParams.tableParams.current = 1
				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.getData(params)
			},
			/**
			 *  pageIndex 变化
			 * @param val 当前第几页
			 */
			currentChange(val) {
				this.searchParams.tableParams.current = val
				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.getData(params)
			},
			/* 
			excel导出事件
			
			*/
			deriveBtnExcelClick() {
				if (this.flag) {
					this.flag = false
					const params = mergeJson(this.searchParams) || {}
					if (!params.extend) {
						params.extend = {}
					}
					params.extend.fileName = '生产设备'
					Product.exportExcel(params).then((data) => {
						downloadBlobFile(data)
					})
				}
				setTimeout(() => {
					this.flag = true
				}, 500)
				
			}

		}
	}
</script>

<style scoped>
	.pagination-box {
		padding-top: 6px;
		border-top: 1px solid #ddd;
		box-sizing: border-box;
	}

	.component-table {
		height: 100%;
		margin-top: 20px;
	}
</style>
