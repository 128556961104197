<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`添加近期检测报告`"
  />
</template>

<script>
import DischargePortAPI from "@/api/ep/dischargePort"
import {CheckFileConfig} from './config.js'
import {mergeModelValue} from '@/utils/commons'

export default {
  data() {
    return {
      visible: false,
      formConfig: CheckFileConfig.bind(this)(),
      id: ''
    }
  },
  methods: {
    init(id) {
      this.id = id || ''
      this.visible = true
      this.formConfig = CheckFileConfig.bind(this)()
      if (!id) {
        return
      }
      this.checkFileList()
      this.get(id)
    },
    checkFileList() {
      DischargePortAPI.checkFileList().then((res) => {
        if (!res.isSuccess) {
          return
        }
        this.formConfig.config.selects['checkFileId'] = res.data.map(item => {
          let obj = {}
          obj.value = item.id
          obj.text = `${item.submittedFileName}`
          return obj
        })
      })
    },
    get(id) {
      DischargePortAPI.get(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        if (res.data.checkFileId === ''){
          data.checkFileId = ''
        }else {
          data.checkFileId = data.checkFileId.split(",")
        }
        const model = this.formConfig.config.model
        mergeModelValue(model, data)
      })
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      let params = {};
      params.checkFileId = result.checkFileId.join(",") || null;
      params.id = this.id
      DischargePortAPI.updateCheckFile(params).then((res) => {
        if (!res.isSuccess) {
          return
        }

        const data = res.data
        this.visible = false
        this.$message.success('编辑成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>
/deep/.el-form-item__label {
  width: 120px !important;
}

/deep/.el-form-item__content {
  margin-left: 120px !important;
}
</style>