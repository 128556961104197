<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" width="700px" :before-close="handleClose">
		<el-form label-width="120px" label-position="right" style="width: 600px" status-icon>
			<div v-for="item in viewData" :key="item.id" style="display: flex;">
				<el-form-item label="时间:">
					<el-input v-model="item.createTime" disabled></el-input>
				</el-form-item>
				<el-form-item label="用量:">
					<el-input v-model="item.number" disabled></el-input>
				</el-form-item>
			</div>
			<el-form-item style="display: flex;justify-content: flex-end;">
				<el-button type="primary" @click="handleClose">确定</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			// 弹窗标题
			title: {
				type: String,
				default: "查看用量记录",
			},
			// 显示弹窗
			dialogVisible: {
				type: Boolean,
				default: false,
			},
			viewData: {
				type: Array,
				default: function() {
					return {};
				},
			},
		},
		components: {},

		data() {
			return {};
		},

		created() {},

		mounted() {},

		methods: {
			handleClose() {
				this.$emit("handleViewClose");
			}
		},
	};
</script>
<style scoped>
</style>
