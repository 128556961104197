import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.COMMON + 'area/'

export default {

  /**
     * 保存
     * @param params
     * @returns {*}
     */
  save(params) {
    return http.post(PREFIX + `save`, params)
  },

  /**
     * 修改
     * @param params
     * @returns {*}
     */
  update(params) {
    return http.put(PREFIX + `update`, params)
  },

  /**
     * 批量删除
     * @param params
     * @returns {*}
     */
  batchDelete(params) {
    return http.delete(PREFIX + `batchDelete`, params)
  },

  /**
     * tree 数据
     * @returns {*}
     */
  tree() {
    return http.get(PREFIX + `tree`)
  },

  /**
     * 查询结果
     * @param id
     * @returns {*}
     */
  get(id) {
    return http.get(PREFIX + `${id}`)
  },

  /**
   * 获取省
   * @returns {*}
   */
  getProvince() {
    return http.get(PREFIX + 'getProvince')
  },

  /**
   * 获取市/区
   * @returns {*}
   */
  getCity(id) {
    return http.get(PREFIX + 'getCity?parentId=' + `${id}`)
  },
}
