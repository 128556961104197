<template>
  <div class="components">
    <div class="title">企业危废详情</div>
    <div class="page">
      <el-tabs v-model="activeName">
        <el-tab-pane label="危废产生情况" name="first"></el-tab-pane>
        <el-tab-pane label="危废仓库建设情况" name="second"></el-tab-pane>
      </el-tabs>
      <div class="tabs">
        <HazardousWaste v-if="activeName==='first'"></HazardousWaste>
        <WareHouse v-if="activeName==='second'"></WareHouse>
      </div>
    </div>
  </div>
</template>

<script>
import HazardousWaste from './hazardousWaste/index'
import WareHouse from './wareHouse/index'
import UserApi from '@/api/uc/user'

export default {
  components: {HazardousWaste, WareHouse},
  name: "index",
  data() {
    return {
      activeName: "first",
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<style scoped>
.page{
  position: relative;
  background-color: #fff;
  padding: 20px;
  height: calc(100vh - 210px);
  overflow: auto;
}
.title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #030303;
}
</style>
<style>
.el-tabs__nav-wrap::after {
  height: 4px;
  margin-top: 15px;
}
.el-tabs__item {
  color: #999;
  margin-bottom: 10px;
}
.el-tabs__item:hover{
  color: #359029;
}
.el-tabs__item.is-active{
  color: #359029;
}
.el-tabs__active-bar{
  background: #359029;
}
/*.table-box{*/
/*  height: 200px !important;*/
/*  overflow: auto;*/
/*}*/
</style>

