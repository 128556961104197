<template>
  <div class="components">
    <div class="content">
      <div class="operation-box">
        <div>
          <el-button type="info" icon="el-icon-plus" @click="add">创建模块</el-button>
          <el-button type="info" icon="el-icon-export" :disabled="!isEditStatus" v-if="false">批量导出</el-button>
          <el-button type="info" icon="el-icon-share" @click="batchDelete" :disabled="!isEditStatus">批量删除</el-button>
          <el-button type="info" :icon="isEditStatus ? 'el-icon-check' : 'el-icon-edit'"
                     @click="isEditStatus = !isEditStatus">{{ isEditStatus ? '完成' : '编辑' }}
          </el-button>
        </div>
        <div class="search-box">
          <el-form :inline="true" :show-message="false">
            <el-form-item label="生产设备模板" style="margin-bottom: 0">
              <el-input placeholder="生产设备模板检索" v-model="params.standingName" clearable></el-input>
            </el-form-item>
            <el-form-item style="margin-bottom: 0">
              <el-button type="primary" @click="getData">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="list">
        <div class="item-box" v-for="(item,index) in list" :style="getColumns()">
          <div class="item">

            <div class="select-box"
                 @click.stop="chooseRow(index)"
                 v-if="isEditStatus"
                 :class="{'select-box-active': item.checked}"></div>
            <div class="file-box">
              <img src="../../../../assets/image/icon-excel.png" alt="">
            </div>
            <div class="file-info">
              <div class="file-name">
                <p class="file-label">{{ item.standingName }}</p>
              </div>
              <div class="btn-box">
                <a href="javascript:;" :class="item.status && item.status.code === 'ENABLE' ? '' : 'btn-disable'"
                   @click="handleEnable(item)">{{ item.status && item.status.code === 'ENABLE' ? '已启用' : '已禁用' }}</a>
<!--                <a href="javascript:;" @click.stop="edit(item)" v-if="false">查看数据</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="创建生产设备模板"
        :visible.sync="formVisible"
        top="10vh"
        @close="cancel"
        width="600">
      <el-form ref="form" :model="templateForm" :inline="true" :rules="rules">
        <el-form-item label="模板名称">
          <el-input v-model="templateForm.standingName" placeholder="请输入模板名称"></el-input>
        </el-form-item>
        <el-form-item label="是否禁用">
          <el-switch
              v-model="templateForm.status"
              active-value="ENABLE"
              inactive-value="DISABLE"
          ></el-switch>
        </el-form-item>
      </el-form>


      <table class="drag-box" border="0" cellspacing="0">
        <tr>
          <th width="60" align="center">拖拽</th>
          <th width="60" align="center">序号</th>
          <th align="center">表头</th>
          <th align="center" v-if="false">字段</th>
          <th align="center">类型</th>
          <th align="center">操作</th>
        </tr>
        <draggable v-model="data" element="tbody" handle=".move">
          <tr v-for="(item,index) in data">
            <td align="center"><span class="iconfont iconyidong move" style="font-size: 20px;cursor: move"></span></td>
            <td align="center">{{ index + 1 }}</td>
            <td align="center">
              <el-input placeholder="输入表头" v-model="data[index] && data[index]['name']"></el-input>
            </td>
            <td align="center" v-if="false">
              <el-input placeholder="输入字段" v-model="data[index] && data[index]['field']"></el-input>
            </td>
            <td align="center">
              <el-select v-model="data[index] && data[index]['type']">
                <el-option
                    label="日期"
                    value="DATE">
                </el-option>
                <el-option
                    label="输入框"
                    value="INPUT">
                </el-option>
              </el-select>
            </td>
            <td align="center">
              <el-button size="mini" type="primary" icon="el-icon-plus" @click="addTableRow">添加</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" v-if="index != 0"
                         @click="removeTableRow(index)">删除
              </el-button>
            </td>
          </tr>
        </draggable>
      </table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import StandingBookDataApi from "@/api/ep/standingBook"
import FileApi from "@/api/file"
import draggable from "vuedraggable"

export default {
  name: "Files",
  data() {
    return {
      rules: {
        standingName: [{required: true, message: '模板名称', trigger: 'blur'}]
      },
      headers: {
        Authorization: this.$store.state.user.token
      },
      action: FileApi.singleUpload(),
      search_form: {name: ''},
      visible: false,
      file: null,
      list: [],
      folder: null,
      formVisible: false,
      isEditStatus: false,
      templateForm: {
        standingName: '',
        standingType: 'PRODUCTION',
        status: 'ENABLE',
        fields: '',
        type: ' MODEL',
        fieldsType: '',
        headersName: ''
      },
      data: [
        {}
      ],
      params: {standingType: 'PRODUCTION', status: 'ENABLE', type: 'MODEL',standingName: ''},
      width: 0
    }
  },
  created() {
    this.getData()
    this.width = window.innerWidth
    window.addEventListener('resize', this.resizeWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWidth)
  },
  watch: {
    width() {
      this.resizeWidth()
    }
  },
  components: {draggable},
  methods: {
    resizeWidth() {
      this.width = window.innerWidth
    },
    //启用
    handleEnable(item) {
      let params = {
        fileId: item.id,
        status: item.status.code === 'ENABLE' ? 'DISABLED' : 'ENABLE'
      }
      StandingBookDataApi.updateStatus(params).then(res => {
        if (res.isSuccess) {
          this.$message.success('更改成功')
          this.getData()
          return
        }
        this.$message.error(res.message)
      })
    },
    //加一行
    addTableRow() {
      this.data.push({})
    },
    //少一行
    removeTableRow(index) {
      this.data.splice(index, 1)
    },
    //计算一行防几个
    getColumns() {
      let width = this.width
      return width < 1440 ? 'width: 33.33%;min-width: 330px;' : width < 1660 ? 'width: 25%;min-width: 330px;' : 'width: 20%;min-width: 330px;'
    },
    batchDelete() {
      let ids = this.list.filter(item => {
        return item.checked
      }).map(item => {
        return item.id
      })
      if (ids.length <= 0) {
        this.$message.error('至少选择一项')
        return false
      }
      this.$confirm('此操作将永久删除台账模板！, 是否继续?', '提示', {type: 'warning'}).then(() => {
        StandingBookDataApi.deleteModel({ids: ids.join(",")}).then((res) => {
          if (!res.isSuccess) {
            return
          }
          this.$message.success('删除成功')
          this.getData()
        })
      })
    },
    //选中
    chooseRow(index) {
      let item = this.list[index]
      item.checked = !item.checked
      this.$set(this.list, index, item)
    },
    back() {
      this.$emit('back')
    },
    getData() {
      StandingBookDataApi.list(this.params).then(res => {
        this.list = res.data || []
      })
    },
    //添加文件夹
    add() {
      this.formVisible = true
    },
    //添加文件夹
    edit(item) {
      this.file = item
      this.form['neoname'] = item.submittedFileName.split(".")[0]
      this.form['file'] = res.data.id
      this.formVisible = true
    },
    //取消
    cancel() {
      this.formVisible = false
      this.file = null
      this.form = {
        id: '',
        file: '',
        neoname: ''
      }
      this.templateForm = {
        standingName: '',
        standingType: 'PRODUCTION',
        status: 'ENABLE',
        fields: '',
        type: ' MODEL',
        fieldsType: '',
        headersName: ''
      }
      this.data = [
        {}
      ]
    },
    //提交
    submit() {
      if (!this.templateForm.standingName) {
        this.$message.error(`请输入模板名称`);
        return
      }
      let flag = true, index = 0, fields = [], fieldsType = [], headersName = []
      let data = this.data.map((item, index) => {
        flag = item.name  && item.type
        if (flag) index++
        fields.push(`field_${index}`)
        fieldsType.push(item.type)
        headersName.push(item.name)
      })
      if (!flag) {
        this.$message.error(`请补充${index + 1}行数据`);
        return
      }
      this.templateForm.fields = fields.join(",")
      this.templateForm.fieldsType = fieldsType.join(",")
      this.templateForm.headersName = headersName.join(",")
      StandingBookDataApi.createModel(this.templateForm).then(res => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.cancel()
        this.$message.success('保存成功');
        this.$emit("onSuccess", 'template')
        this.getData()
        this.data = [{}]
        this.templateForm.standingName = ''
      })
    },
    //上传成功
    onSuccess(res) {
      this.file = res.data
      this.form['neoname'] = res.data.submittedFileName.split(".")[0]
      this.form['file'] = res.data.id
    },
  }
}
</script>

<style scoped lang="scss">
.components {
  height: 100%;
}

.breadcrumb-label {
  cursor: pointer;

  &:hover {
    color: #359029;
  }
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.breadcrumb {
  & > span:last-child {
    color: #359029;
  }

  .icon {
    margin: 0 10px;
  }
}

.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 1px dashed #ddd;
}

.content {
  padding: 20px 0;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}

.operation-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-box {
  display: flex;
  align-items: center;
}

.list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  .item-box {
    padding: 10px;
    box-sizing: border-box;
  }

  .item {
    position: relative;
    display: flex;
    padding: 10px;
    height: 140px;
    box-shadow: 6px 6px 28px rgba(194, 194, 194, 0.34);
    border-radius: 20px;
    box-sizing: border-box;

    .file-box {
      padding-top: 10px;
      flex: 0 0 auto;
      width: 100px;
      padding-left: 10px;

      img {
        display: block;
        width: 73px;
      }
    }

    .file-info {
      flex: 1;
      flex-wrap: wrap;
    }
  }

  .file-name {
    display: flex;
    align-items: center;
    height: 40px;
    width: 126px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;

    .file-label {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
  }

  .btn-box {
    margin-top: 27px;
    display: flex;

    & > a {
      padding: 0 15px;
      display: block;
      height: 30px;
      line-height: 34px;
      text-align: center;
      border-radius: 30px;
      border: 1px solid #359029;
      color: #359029;

      &:last-child {
        margin-left: 10px;
      }
    }

    .btn-disable {
      color: #FF0000;
      border: 1px solid #FF0000;
    }
  }

  .select-box {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;
    border: 1px solid #359029;
    border-radius: 100%;
  }

  .select-box-active {
    background-color: #359029;
    color: #fff;
    border: 1px solid #359029;

    &:after {
      display: block;
      content: '\e63b';
      font-size: 14px;
      color: #fff;
      font-family: "iconfont" !important;
    }
  }
}

.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  width: 80px;
  height: 80px;
}

.upload-tip {
  font-size: 13px;
  color: #999999;
}

.introduce {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}

.drag-box {
  width: 100%;

  td, th {
    border-bottom: 1px solid #dfe6ec;
    user-select: none;
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
  }
}


</style>
