// 表格展示配置
export const TableConfig = function () {
    return {
        list: [
            {
                prop: 'title',
                align: 'center',
                label: '生产工艺标题'
            },
            {
                prop: 'image',
                align: 'center',
                label: '生产工艺流程图',
                slotScope: 'image',
            },
            {
                prop: 'createTime',
                align: 'center',
                label: '创建时间',
            },
            {
                fixed: 'right',
                label: '操作',
                align: 'center',
                slotScope: 'operation',
                'min-width': 100
            }
        ]
    }
}

// 新增配置
export const AddConfig = function () {
    return {
        list: [
            {
                label: '生产工艺标题',
                key: 'title',
                type: 'input'
            },
            {
                label: '图片',
                key: 'image',
                type: 'avatar'
            },
        ],
        config: {
            onSubmit: data => {
                this.handleSubmit(data)
            },
            onCancel: () => {
                this.handleCancel()
            },
            model: {
                'title': '',
                'image': ''
            },
            rules: {
                signalImage: [{required: true, message: '请上传排污口示意图 ', trigger: 'blur'}],
            },
            selects: {}
        }
    }
}

// 编辑配置
export const EditConfig = function () {
    return {
        list: [
            {
                label: '生产工艺标题',
                key: 'title',
                type: 'input'
            },
            {
                label: '图片',
                key: 'image',
                type: 'avatar'
            },
        ],
        config: {
            onSubmit: data => {
                this.handleSubmit(data)
            },
            onCancel: () => {
                this.handleCancel()
            },
            model: {
                'title': '',
                'image': ''
            },
            rules: {
                signalImage: [{required: true, message: '请上传排污口示意图 ', trigger: 'blur'}],
            },
            selects: {}
        }
    }
}

export const EditRemarkConfig = function () {
    return {
        list: [
            {
                label: '生产工艺描述',
                key: 'describe',
                type: 'textarea'
            },
        ],
        config: {
            onSubmit: data => {
                this.handleSubmit(data)
            },
            onCancel: () => {
                this.handleCancel()
            },
            model: {
                describe: ''
            }
        }
    }
}