import hmStorage from '@/utils/hmStorage'

const user = {
  namespaced: true,
  state: {
    get token() {
      const token = hmStorage.getItem('TOKEN', '')
      if (token) {
        return 'Bearer ' + token
      }

      return process.env.VUE_APP_TOKEN ? 'Bearer ' + process.env.VUE_APP_TOKEN : token
    },
    set token(token) {
      token ? hmStorage.setItem('TOKEN', token) : hmStorage.removeItem('TOKEN')
    },
    get refreshToken() {
      return hmStorage.getItem('REFRESH_TOKEN', '')
    },
    set refreshToken(refreshToken) {
      refreshToken ? hmStorage.setItem('REFRESH_TOKEN', refreshToken) : hmStorage.removeItem('REFRESH_TOKEN')
    },
    get userInfo() {
      const userInfo = hmStorage.getItem('USER_INFO', '')
      if (!userInfo) {
        return {}
      }

      return userInfo
    },
    set userInfo(data) {
      data ? hmStorage.setItem('USER_INFO', data) : hmStorage.removeItem('USER_INFO')
    },
    get sso() {
      return hmStorage.getItem('SSO', '')
    },
    set sso(sso) {
      sso ? hmStorage.setItem('SSO', sso) : hmStorage.removeItem('SSO')
    },
    avatar: ''
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
    },
    setUser(state, userInfo) {
      state.userInfo = userInfo
    },
    setAvatar(state, avatar) {
      state.avatar = avatar
    },
    setSso(state, sso) {
      state.sso = sso
    }
  },
  actions: {
    logout({ commit, state }) {
      state.token = null
      state.refreshToken = null
      state.userInfo = null
      state.sso = null
    },
    updateAvatar({ commit, state }, avatar) {
      state.avatar = avatar
    },
    updateInfo({ commit, state }, user) {
      const userInfo = state.userInfo
      userInfo.name = user.name
      state.userInfo = userInfo
    }
  }
}
export default user
