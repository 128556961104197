// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const SignageBrandSearchConfig = function() {
  return {
    list: [
      {
        label: '编号',
        key: 'number',
        type: 'input',
        placeholder: '请输入编号'
      },
      {
        label: '主要污染物',
        key: 'pollutants',
        type: 'input',
        placeholder: '请输入主要污染物'
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
      selects: {},
      model: {
        'number':'',
        'unit':'',
        'pollutants':'',
      }
    }
  }
}

// 表格展示配置
export const SignageBrandTableConfig = function() {
  return {
    list: [
      {
        type: 'selection',
        align: "center",
        width: 60
      },
      {
        label: '序号',
        align: "center",
        type: 'index',
        width: 60
      },
      {
        prop: 'number',
        align: 'center',
        label: '排污口编号',
      },
      {
        prop: 'company',
        align: 'center',
        label: '单位',
      },
      {
        prop: 'pollutants',
        label: '主要污染物',
      },
      {
        prop: 'pollutionType.desc',
        label: '排污类别',
      },
      {
        prop: 'cover',
        label: '类别封面',
        slotScope: 'cover'
      },
      {
        prop: 'unit',
        align: 'center',
        label: '监制单位',
      },
      {
        prop: 'createTime',
        align: 'center',
        label: '创建时间',
      },
      {
        fixed: 'right',
        label: '操作',
        align: 'center',
        slotScope: 'operation',
        'min-width': 100
      }
    ]
  }
}

// 新增对象配置
export const SignageBrandAddConfig = function () {
  return {
    list: [
      {
        label: '排放编号',
        key: 'number',
        type: 'input',
        span:12,
        placeholder: '请输入排放编号'
      },
      {
        label: '主要污染物',
        key: 'pollutants',
        type: 'input',
        span:12,
        placeholder: '请输入主要污染物'
      },
      {
        label: '污染类别',
        key: 'pollutionType',
        type: 'select',
        span:12,
        placeholder: '请选择'
      },
      {
        label: '监制单位',
        key: 'unit',
        span:12,
        type: 'input',
        disabled: true,
        placeholder: '请输入监制单位'
      },
      {
        label: '废气污染',
        key: 'pollutionCategory',
        span:12,
        type: 'input',
        placeholder: '请输入废气污染类别'
      },
      {
        label: '处理工艺',
        key: 'treatmentProcess',
        span:12,
        type: 'input',
        placeholder: '请输入处理工艺'
      },
      {
        label: '二维码内容 ',
        key: 'content',
        type: 'editor',
        placeholder: '请输入监制单位'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      onChange:(k,v) =>{
        this.onformChange(k,v)
      },
      model: {
        'number':'',
        'pollutants':'',
        'pollutionType':'EXHAUSR',
        'unit':'安徽一二三环保科技有限公司',
        'content':'',
        'treatmentProcess':'',
        'pollutionCategory':'',
      },
      rules: {
        company: [{required: true, message: '项目名称', trigger: 'blur'}]
      },
      selects: {
        pollutionType:[{text: '废气污染', value: 'EXHAUSR'},{text: '污水排放', value: 'SEWAGE'},{text: '雨水排放', value: 'RAIN'},{text: '噪音排放', value: 'NOISE'},{text: '危险品排放', value: 'DANGER'}]
      }
    }
  }
}
