import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'selfTestReport/'

export default {
  /**
   * 分页
   * @param params
   * @returns {*}
   */
  searchSelfTestReport(params) {
    return http.post(PREFIX + `searchSelfTestReport`, params)
  },
  /**
   * 查询结果
   * @param id
   * @returns {*}
   */
  get(id) {
    return http.get(PREFIX + `${id}`);
  },
  /**
   * 保存
   * @param params
   * @returns {*}
   */
  save(params) {
    return http.post(PREFIX + `save`, params);
  },

  /**
   * 修改
   * @param params
   * @returns {*}
   */
  update(params) {
    return http.put(PREFIX + `update`, params);
  },

  /**
   * 批量删除
   * @param params
   * @returns {*}
   */
  batchDelete(params) {
    return http.delete(PREFIX + `batchDelete`, params);
  },

  /**
   * 查询列表
   * @param params
   * @returns {*}
   */
  list(params) {
    return http.post(PREFIX + `list`, params || {})
  },
  /**
   * 获取编辑内容
   * @param params
   * @returns {*}
   */
  getSelfTestReportById(id) {
    return http.get(PREFIX + `getSelfTestReportById/` + `${id}`)
  },
  /**
   * 编辑
   * @param params
   * @returns {*}
   */
  updateSelfTestReport(params) {
    return http.put(PREFIX + `updateSelfTestReport`, params)
  },
  /**
   * 导出excel
   * @param params
   * @returns {*}
   */
  exportExcel(params) {
    return http.postDownload(PREFIX + `export`, params);
  },
}
