<template>
  <div class="description-main">
    <table>
      <tr v-for="(tr,trIndex) in trs" :key="trIndex">
        <td v-for="(td,tdIndex) in tr" :key="tdIndex"
            :colspan="td.span?td.span:(td.type === 'video' || td.type === 'editor' || td.type === 'images'|| td.type === 'tags'|| td.type === 'rate'?columnNumber:1)">
          <template
              v-if="td.type !== 'video' && td.type !== 'editor' && td.type !== 'images' && td.type !== 'tags' && td.type !== 'rate' && !td.slotScope">
            <div :class="layout === 'vertical' ? 'vertical' : 'horizontal'">
              <span class="label">{{ td.label }}:</span>
              <span class="value">{{ renderValue(td.key) }}{{ td.suffix || '' }}</span>
            </div>
          </template>

          <template v-if="td.type === 'video'">
            <div :class="layout === 'vertical' ? 'vertical' : 'horizontal'">
              <span class="label">{{ td.label }}:</span>
              <span class="value">
              <ele-upload-video
                  style="margin: 50px"
                  action=""
                  :value="renderValue(td.key)"
              />
              </span>
            </div>
          </template>

          <template v-if="td.type === 'rate'">
            <div :class="layout === 'vertical' ? 'vertical' : 'horizontal'">
              <span class="label">{{ td.label }}:</span>
              <span class="value">
                <el-rate
                    style="margin-top: 5px;"
                    v-if="td.type === 'rate'"
                    :value="renderValue(td.key,td.type)"
                    :disabled="true"
                >
              </el-rate>
              </span>
            </div>
          </template>
          <template v-if="td.type === 'editor'">
            <div :class="layout === 'vertical' ? 'vertical' : 'horizontal'">
              <span class="label">{{ td.label }}:</span>
              <span class="value">
                 <hm-editor :value="renderValue(td.key)" :preview="true"/>
              </span>
            </div>
          </template>
          <template v-if="td.type === 'images'">
            <div :class="layout === 'vertical' ? 'vertical' : 'horizontal'">
              <span class="label">{{ td.label }}:</span>
              <span class="value">
                 <el-upload
                     list-type="picture-card"
                     action=""
                     :on-preview="handlePictureCardPreview"
                     :file-list="images[td.key]">
              </el-upload>
              </span>
            </div>
          </template>
          <template v-if="td.type === 'tags'">
            <div :class="layout === 'vertical' ? 'vertical' : 'horizontal'">
              <span class="label">{{ td.label }}:</span>
              <span class="value">
                 <el-tag
                     :key="tag"
                     v-for="tag in (renderValue(td.key)?renderValue(td.key).split(','):[])"
                     :disable-transitions="false">
                  {{tag}}
                </el-tag>
              </span>
            </div>
          </template>
          <template v-if="td.slotScope">
            <div :class="layout === 'vertical' ? 'vertical' : 'horizontal'">
              <span class="label">{{ td.label }}:</span>
              <slot :name="td.slotScope" :column="td" :data="renderValue(td.key)">
                <span class="value">{{ renderValue(td.key) }}</span>
              </slot>
            </div>
          </template>
        </td>
      </tr>
    </table>

    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
  import EleUploadVideo from "vue-ele-upload-video"

  /**
   * 描述组件
   * type: 默认 horizontal，  horizontal|vertical
   *
   * columnNumber: 默认展示3
   *
   * columns: Array 默认null
   * column 属性
   * label: 标签
   * value: 值
   * span:  colspan 列数
   * type: 默认 horizontal ,horizontal|vertical 设置单个单元格的样式
   *
   * slot: slotScope 支持单个单个格的插槽展示
   */
  export default {
    components: {
      EleUploadVideo
    },
    name: 'HmDescription',
    props: {
      layout: {
        type: String,
        default: 'horizontal'
      },
      columnNumber: {
        type: Number,
        default: 3
      },
      config: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        columns: this.config.list,
        model: this.config.config.model,
        dialogImageUrl: '',
        dialogVisible: false,
      }
    },
    computed: {
      trs() { // 计算trs排列方式
        const arr = [[]]
        let cols = 0 // 当前列索引
        let count = 0
        const columns = this.columns
        for (let i = 0; i < columns.length; i++) {
          count += Number(columns[i].span ? columns[i].span :
            ((columns[i].type === 'video' || columns[i].type === 'editor' || columns[i].type === 'images' || columns[i].type === 'rate' || columns[i].type === 'tags') ? this.columnNumber : 1))
          if (count > this.columnNumber) {
            count = count - this.columnNumber
            cols++

          }

          if (!arr[cols]) {
            arr.push([])
          }
          arr[cols].push(columns[i])
          if (count !== 0 && count >= this.columnNumber) {
            count = 0
            cols++
          }
        }

        return arr
      },
      //多图片临时存储
      images() {
        let images = {};
        this.columns.forEach((item) => {
          if (item.type !== 'images') {
            return true
          }

          if (!this.model[item.key]) {
            images[item.key] = [];
            return true
          }

          let urls = [];
          this.model[item.key].split(',').forEach((item) => {
            urls.push({url: item})
          })

          images[item.key] = urls;
        })

        return images;
      },
    },
    mounted() {
    },
    methods: {
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      renderValue(key, type) {
        const keys = key.split('.')
        let value = this.model[keys[0]]
        for (let index = 1; index < keys.length; index++) {
          if (value) {
            value = value[keys[index]]
          }

        }

        return value || ((type && type === 'rate') ? 0 : '')
      }
    }
  }
</script>

<style>
  .description-main .ele-upload-video {
    margin: 0 !important;
  }

  .description-main .ele-upload-video_mask__item .el-icon-delete,
  .description-main .el-upload--picture-card,
  .description-main .el-upload-list__item-status-label,
  .description-main .el-upload-list--picture-card .el-icon-close,
  .description-main .el-upload-list--picture-card .el-icon-close-tip,
  .description-main .el-upload-list__item-delete {
    display: none !important;
  }

</style>

<style scoped>
  .description-main table {
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
  }

  table td {
    padding: 10px 20px;
    vertical-align: top;
  }

  .horizontal {
    display: flex;
  }

  .label {
    color: #999;
  }

  .value {
    color: #000;
  }

  .vertical .label,
  .vertical .value {
    display: block;
  }

  .vertical .value {
    margin-top: 5px;
  }

  .horizontal .label {
    flex: 0 0 auto;
    text-align: right;
  }

  .horizontal .value {
    margin-left: 10px;
    flex-grow: 1;
    width: 0;
    line-height: 20px;
  }
</style>
