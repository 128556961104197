<template>
  <div class="page">
    <div class="topBtn">
      <el-button type="primary" @click="detail">企业电子环保档案预览</el-button>
      <el-button type="primary" @click="handleUpload">下载环保档案二维码</el-button>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="厂区平面图" name="first"></el-tab-pane>
      <el-tab-pane label="生产工艺流程" name="second"></el-tab-pane>
      <el-tab-pane label="企业现有环保档案" name="third"></el-tab-pane>
      <el-tab-pane label="企业现场照片" name="fourth"></el-tab-pane>
    </el-tabs>
    <div class="tabs">
      <ProduceProcess v-if="activeName==='second'"></ProduceProcess>
      <Archives v-if="activeName === 'third'"></Archives>
      <sketch-map v-if="activeName==='first'"></sketch-map>
      <scene-photo v-if="activeName==='fourth'"></scene-photo>
    </div>
    <detail ref="detail"></detail>
    <archives-code ref="archivesCode"></archives-code>
  </div>
</template>

<script>
import ScenePhoto from './scenePhoto/index'
import ProduceProcess from './produceProcess/index'
import Archives from './archives/index'
import SketchMap from './sketchMap/index'
import Detail from './detail'
import ArchivesCode from './archivesCode'
import UserApi from '@/api/uc/user'

export default {
  components: {ScenePhoto, ProduceProcess, Archives, SketchMap, Detail,ArchivesCode},
  name: "index",
  data() {
    return {
      activeName: "first",
      corpId:''
    }
  },
  mounted() {
    this.getUser()
  },
  methods:{
    detail(){
      this.$refs.detail.init(this.corpId)
    },
    handleUpload(){
      this.$refs.archivesCode.init()
    },
    getUser(){
      UserApi.getMine().then(res =>{
        console.log(res)
        this.corpId = res.data.corpId
      })
    },
  }
}
</script>

<style scoped>
.page{
  position: relative;
  background-color: #fff;
  padding: 20px;
  height: calc(100vh - 160px);
  }
.topBtn{
  z-index: 9;
  position: absolute;
  width: 400px;
  display: flex;
  justify-content: space-between;
  top: 20px;
  right: 30px;
}
</style>
<style>
.el-tabs__nav-wrap::after {
  height: 4px;
  margin-top: 15px;
}
.el-tabs__item {
  color: #999;
  margin-bottom: 10px;
}
.el-tabs__item:hover{
  color: #359029;
}
.el-tabs__item.is-active{
  color: #359029;
}
.el-tabs__active-bar{
  background: #359029;
}
</style>

