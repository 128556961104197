import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/signboard',
      name: 'signboard',
      component: () => import('@/view/userCenter/signboard/index'),
    },
    {
      path: '/preview',
      name: 'preview',
      component: () => import('@/view/userCenter/preview/index'),
    },
    {
      path: '/',
      name: 'index',
      component: () => import('@/view/index')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/view/website/about')
    },
    {
      path: '/mainBusiness',
      name: 'mainBusiness',
      component: () => import('@/view/website/mainBusiness')
    },
    {
      path: '/businessDetails',
      name: 'businessDetails',
      component: () => import('@/view/website/businessDetails')
    },
    {
      path: '/case',
      name: 'case',
      component: () => import('@/view/website/case')
    },
    {
      path: '/caseDetail',
      name: 'caseDetail',
      component: () => import('@/view/website/caseDetail')
    },
    {
      path: '/information',
      name: 'information',
      component: () => import('@/view/website/information')
    },
    {
      path: '/infoDetail',
      name: 'infoDetail',
      component: () => import('@/view/website/infoDetail')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/view/website/contact')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/view/register')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/view/login')
    },
    {
      path: '/changePwd',
      name: 'changePwd',
      component: () => import('@/view/changePwd')
    },
    {
      path: '/layout',
      name: 'layout',
      component: () => import('@/view/layout'),
      children: [
        {
          path: '/userCenter/account',
          name: 'account',
          component: () => import('@/view/userCenter/account'),
        },
        {
          path: '/userCenter/outSideAccount',
          name: 'outSizeAccount',
          component: () => import('@/view/userCenter/outSideAccount'),
        },
        {
          path: '/userCenter/employee',
          name: 'employee',
          component: () => import('@/view/userCenter/employee'),
        },
        {
          path: '/userCenter/signageBrand',
          name: 'signageBrand',
          component: () => import('@/view/userCenter/signageBrand'),
        },
        {
          path: '/userCenter/companyFolder',
          name: 'companyFolder',
          component: () => import('@/view/userCenter/companyFolder'),
        },
        {
          path: '/userCenter/businessCard',
          name: 'businessCard',
          component: () => import('@/view/userCenter/businessCard'),
        },
        {
          path: '/share',
          name: 'share',
          meta: {fullscreen: true},
          component: () => import('@/view/userCenter/share'),
        },
        {
          path: '/standing/environment',
          name: 'standingEnvironment',
          component: () => import('@/view/userCenter/standing/environment')
        },
        {
          path: '/standing/production',
          name: 'standingProduction',
          component: () => import('@/view/userCenter/standing/production'),
        },
        {
          path: '/standing/danger',
          name: 'standingDanger',
          component: () => import('@/view/userCenter/standing/danger'),
        },
        {
          path: '/userCenter/selfCheckReport',
          name: 'selfCheckReport',
          component: () => import('@/view/userCenter/selfCheckReport/index'),
        },
        {
          path: '/standing/equipmentRecoed',
          name: 'equipmentRecoed',
          component: () => import('@/view/userCenter/standing/equipmentRecoed'),
        },
        {
          path: '/standing/protectiveRecoed',
          name: 'protectiveRecoed',
          component: () => import('@/view/userCenter/standing/protectiveRecoed'),
        },
        {
          path: '/dischargePort',
          name: 'dischargePort',
          component: () => import('@/view/userCenter/dischargePort/dischargePort'),
        },
        {
          path: '/userCenter/ware',
          name: 'ware',
          component: () => import('@/view/userCenter/ware/index'),
        },
        {
          path: '/userCenter/protectionArchives',
          name: 'protectionArchives',
          component: () => import('@/view/userCenter/protectionArchives/index'),
        },
        {
          path: '/userCenter/sewageOutletEquipment',
          name: 'sewageOutletEquipment',
          component: () => import('@/view/userCenter/sewageOutletEquipment/index'),
        },
        {
          path: '/userCenter/enterpriseEquipment',
          name: 'enterpriseEquipment',
          component: () => import('@/view/userCenter/enterpriseEquipment/index'),
        },
        {
          path: '/userCenter/assistMaterial',
          name: 'assistMaterial',
          component: () => import('@/view/userCenter/assistMaterial/index'),
        },{
          path: '/userCenter/mainMaterial',
          name: 'mainMaterial',
          component: () => import('@/view/userCenter/mainMaterial/index'),
        },
      ]
    }
  ]
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
