<template>
  <el-dialog
      title="编辑"
      :visible.sync="visible"
      width="40%"
      center
      :close-on-click-modal="false"
      :before-close="handleClose">
    <div class="container" v-if="visible">
      <div>
        <div class="title">排放口详情</div>
        <div class="number">
          <label>排污口编号</label>
          <div class="number_box">
            <div>{{code}}</div>
            <input placeholder="请输入排污口编号" :value="number"/>
          </div>
        </div>
        <div class="line no_border">
          <label>*生产设备</label>
          <div class="production">
            <div class="label_box">
              <div class="label" v-for="(item,index) in equipmentItem" :key="index">
                {{item.name}}
                <i class="el-icon-close" @click="deleteEquipmentItem(index)"></i>
              </div>
            </div>
            <div class="input_box">
              <el-input
                  placeholder="请输入设备检索"
                  v-model="equipmentSearch"
                  @change="getEquipmentList"
                  @focus="getEquipmentList"
                  clearable>
              </el-input>
              <span class="clear" @click="clearEquipmentList">确认</span>
            </div>
            <div class="menu">
                <div class="check__label" v-for="(item,index) in equipmentData" :key="index">
                  <div class="check__hd">
                    <el-checkbox :label="item" v-model="item.checked" @change="selectEquipment(index)">{{item.name}}</el-checkbox>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="line no_border">
          <label>*防治设施</label>
          <div class="production">
            <div class="label_box">
              <div class="label" v-for="(item, index) in facilitiesItem">
                {{item.name}}
                <i class="el-icon-close" @click="deleteFacilitiesItem(index)"></i>
              </div>
            </div>
            <div class="input_box">
              <el-input
                  placeholder="请输入防治设施检索"
                  v-model="facilitiesSearch"
                  @change="getFacilityList"
                  @focus="getFacilityList"
                  clearable>
              </el-input>
              <span class="clear" @click="clearFacilitiesList">确认</span>
            </div>
            <div class="menu">
                <div class="check__label" v-for="(item, index) in facilitiesData" :key="index">
                  <div class="check__hd">
                    <el-checkbox :label="item" v-model="item.checked" @change="selectFacilities(index)">{{item.name}}</el-checkbox>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="line">
          <label>*排放口名称</label>
          <div>
            <input placeholder="请输入" v-model="dischargePortInfo.dischargePortData && dischargePortInfo.dischargePortData.name"/>
          </div>
        </div>
        <div class="line flex type">
          <label>排放口类型</label>
          <div>{{ dischargePortInfo.dischargePortData && dischargePortInfo.dischargePortData.portType.desc }}</div>
        </div>
      </div>
      <div>
        <div class="title in_start">排放口参数</div>
        <div class="size">
          <label>排放口尺寸</label>
          <div class="size_box">
            <div class="size_inner">
              <label>高度:</label>
              <input placeholder="请输入" v-model="dischargePortInfo.dischargePortData && dischargePortInfo.dischargePortData.portHeight"/>
              <label>m</label>
            </div>
            <div class="size_inner">
              <label>内径:</label>
              <input placeholder="请输入" v-model="dischargePortInfo.dischargePortData && dischargePortInfo.dischargePortData.portInradium"/>
              <label>m</label>
            </div>
          </div>
        </div>
        <div class="line">
          <label>*污染源生产工序</label>
          <div>
            <input placeholder="请输入" v-model="dischargePortInfo.dischargePortData && dischargePortInfo.dischargePortData.pollutionProductProcess"/>
          </div>
        </div>
        <div class="line">
          <label>*前段治理工艺</label>
          <div>
            <input placeholder="请输入" v-model="dischargePortInfo.dischargePortData && dischargePortInfo.dischargePortData.treatmentProcess"/>
          </div>
        </div>
      </div>
      <div v-for="(item,index) in (dischargePortInfo.dischargePortData && dischargePortInfo.dischargePortData.dischargePortCpList)">
        <div class="title in_start">特征污染物 <i class="el-icon-close" @click="deteleContaminants(item)"></i></div>
        <div class="line">
          <label>*特征污染物</label>
          <div>
            <input placeholder="请输入" v-model="item.name"/>
          </div>
        </div>
        <div class="line">
          <label>执行标准</label>
          <div>
            <input placeholder="请输入" v-model="item.executiveStandard"/>
          </div>
        </div>
        <div class="line no_border">
          <label>限制数据</label>
          <br>
          <div class="line-l">
          <div class="line">
            <input placeholder="请输入具体数值" v-model="item.limitData"/>
          </div>
          <el-select v-model="item.limitUnit.data" @change="(val) => changeLimitUnit(index,val)" placeholder="请选择限制单位">
            <el-option
                v-for="item in restrictedData"
                :key="item.code"
                :label="item.name"
                :value="item.code">
            </el-option>
          </el-select>
        </div>
        </div>
<!--        <div class="line">-->
<!--          <input placeholder="请输入具体数值" v-model="item.limitData"/>-->
<!--        </div>-->
        <div class="line" v-if="item.limitUnit.key === 'OTHER'">
          <input placeholder="请输入数据单位" v-model="item.limitOtherUnit"/>
        </div>
        <div class="line no_border">
          <label>监测频次</label>
          <br>
          <el-select v-model="item.monitorUnit.data" @change="(val) => changeMonitorUnit(index,val)" placeholder="请选择限制单位">
            <el-option
                v-for="item in monitoringFrequency"
                :key="item.code"
                :label="item.name"
                :value="item.code">
            </el-option>
          </el-select>
        </div>
<!--        <div class="line" v-if="item.monitorUnit.key === 'OTHER'">-->
<!--          <input placeholder="请输入具体数值" v-model="item.monitorData"/>-->
<!--        </div>-->
        <div class="line" v-if="item.monitorUnit.key === 'OTHER'">
          <input placeholder="请输入数据单位" v-model="item.monitorOtherUnit"/>
        </div>
      </div>
      <div class="btn add" @click="addContaminantCharacteristics">新增特征污染物</div>
      <div>
        <div class="title">对应排污口照片</div>
        <div class="upload">
          <el-upload
              list-type="picture-card"
              :action="uploadAction"
              :data="{bizType: 'DISCHARGE_PORT_IMAGE'}"
              :headers="headers"
              :on-remove="handleImageRemove"
              :on-success="onImageUploadSuccess"
              :file-list="images">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <div class="btn_group">
        <div class="cancel" @click="handleClose">取消</div>
        <div class="submit" @click="submit">保存</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import FileApi from '@/api/fs/file'
  import DischargePortAPI from "@/api/ep/dischargePort"
  import ProtectiveApi from "@/api/ep/protective"
  import EquipmentApi from "@/api/ep/equipment"
  import DictionaryItemApi from "@/api/common/dictionaryItem"

  export default {
    name: "edit",
    data() {
      return {
        // 排放口id
        id: '',
        // 企业id
        corpId: '',
        // 排放口id
        portId: '',
        visible: false,
        // 排放口信息
        dischargePortInfo: {},
        // 排污口编码
        code: '',
        number: '',
        // 设备数据
        equipmentData: [],
        // 选中生产设备
        equipmentItem: [],
        // 设施数据
        facilitiesData: [],
        // 选中设施
        facilitiesItem: [],
        //图片
        images: [],
        // 排放口状态
        portStatus: '',
        // 特征污染物列表
        contaminantsList: [],
        // 限制数据
        restrictedData: [],
        // 监测频次
        monitoringFrequency: [],
        // 设备搜索
        equipmentSearch: '',
        // 设施搜索
        facilitiesSearch: '',
        // 排污口示意图
        signalImage: '',
        // 生产工艺流程
        productProcessId: '',
        productProcessRemark: '',
        // 近期检测报告
        checkFileId: '',
        uploadAction: FileApi.singleUpload.bind(this)(),
        headers: {
          Authorization: this.$store.state.user.token
        },
      }
    },
    watch: {
      visible: function (newVal, oldVal) {
        if (!newVal) {
          this.equipmentData = []
          this.facilitiesData = []
          this.equipmentItem = []
          this.facilitiesItem = []
          this.equipmentSearch = ''
          this.facilitiesSearch = ''
        }
      }
    },
    methods: {
      handleImageRemove(file, fileList) {
        this.images = fileList
      },
      /**
       * 多图片上传成功
       */
      onImageUploadSuccess(e) {
        let files = this.images
        files.push({url: e.data.url})
      },
      init(item) {
        this.id = item.id
        this.corpId = item.corpId
        this.visible = true
        this.getDischargePortInfo(this.id)
      },
      handleClose() {
        this.visible = false
      },
      //获取污染物
      getContaminatedItem() {
        return {
          portId: this.id,
          name: '',
          executiveStandard: '',
          limitUnit: {key: "",data:''},
          limitData: '',
          limitOtherUnit: '',
          monitorUnit: {key: "",data:''},
          monitorData: '',
          monitorOtherUnit: '',
        }
      },
      // 获取排放口信息
      getDischargePortInfo(id) {
        DischargePortAPI.getDetail(id).then(res => {
          this.dischargePortInfo = res.data
          this.code = res.data.dischargePortData.code.substring(0, 2)
          this.number = res.data.dischargePortData.code.slice(2)
          this.equipmentItem = res.data.dischargePortData.equipments || []
          this.facilitiesItem = res.data.dischargePortData.protectives || []
          if(res.data.dischargePortData.image){
            let urls = [];
            res.data.dischargePortData.image.split(',').forEach((item) => {
              urls.push({url: item})
            })

            this.images = urls
          }

          this.getDataDictionary()
        })
      },
      // 获取设备列表
      getEquipmentList() {
        let params = {
          corpId: this.corpId,
          name: this.equipmentSearch
        }
        EquipmentApi.equipmentList(params).then(res => {
          let checkedIds = [];
          this.equipmentItem.forEach((item)=>{
            checkedIds.push(item.id)
          })

          res.data.forEach((item)=>{
            item.checked = checkedIds.indexOf(item.id)>=0
          })

          this.equipmentData = res.data
        })
      },
      // 选中设备
      selectEquipment(index){
        let equipmentData = this.equipmentData[index]
        if(equipmentData.checked){
          this.equipmentItem.push(equipmentData);
          return false
        }

        let currentIdx = -1;
        this.equipmentItem.forEach((item, idx) => {
          if (item.id === equipmentData.id) {
            currentIdx = idx
            return false
          }
        })

        if (currentIdx >= 0) {
          this.equipmentItem.splice(currentIdx,1)
        }
      },
      // 清除设备列表
      clearEquipmentList(){
        this.equipmentData = []
      },
      // 获取设施列表
      getFacilityList() {
        let params = {
          corpId: this.corpId,
          name: this.facilitiesSearch
        }
        ProtectiveApi.protectiveList(params).then(res => {
          let checkedIds = [];
          this.facilitiesItem.forEach((item) => {
            checkedIds.push(item.id)
          })

          res.data.forEach(item => {
            item.checked = checkedIds.indexOf(item.id) >=0
          })

          this.facilitiesData = res.data
        })
      },
      // 选中设施
      selectFacilities(index){
        let facilitiesData = this.facilitiesData[index]
        if (facilitiesData.checked){
          this.facilitiesItem.push(facilitiesData);
          return false
        }

        let currentIdx = -1;
        this.facilitiesItem.forEach((item, idx) => {
          if (item.id === facilitiesData.id){
            currentIdx = idx
            return false
          }
        })

        if (currentIdx >= 0){
          this.facilitiesItem.splice(currentIdx, 1)
        }
      },
      // 清除设备列表
      clearFacilitiesList(){
        this.facilitiesData = []
      },
      // 获取数据字典
      getDataDictionary() {
        let params = {
          code: "LIMIT_UNIT",
          dictionaryId: "9499b730a3d3045ac190f1f2089e6ee7"
        }
        let data = {
          code: "MONITOR_UNIT",
          dictionaryId: "e89b4acdff635a1ed8f3c966fa5c7e28"
        }
        DictionaryItemApi.getByParentCode(params).then(res => {
          this.restrictedData = res.data
        })
        DictionaryItemApi.getByParentCode(data).then(res => {
          this.monitoringFrequency = res.data
        })
      },
      // 选中限制单位
      changeLimitUnit(index, val){
        this.dischargePortInfo.dischargePortData.dischargePortCpList[index].limitUnit.key = val
      },
      // 选中限制单位
      changeMonitorUnit(index, val){
        this.dischargePortInfo.dischargePortData.dischargePortCpList[index].monitorUnit.key = val
      },
      // 添加污染物特性
      addContaminantCharacteristics() {
        let dischargePortCpItem = this.getContaminatedItem()
        let dischargePortCpList = this.dischargePortInfo.dischargePortData.dischargePortCpList
        dischargePortCpList.push(dischargePortCpItem)
        for (let i = 0; i < dischargePortCpList.length; i++) {
          dischargePortCpList[i].showOrder = i
        }
        this.dischargePortInfo.dischargePortData.dischargePortCpList = dischargePortCpList
      },
      // 删除特征污染物
      deteleContaminants(data) {
        let dischargePortCpList = this.dischargePortInfo.dischargePortData.dischargePortCpList
        dischargePortCpList.forEach((item, index) => {
          if (data.showOrder === item.showOrder) {
            dischargePortCpList.splice(index, 1)
          }
        })
        this.dischargePortInfo.dischargePortData.dischargePortCpList = dischargePortCpList
      },
      // 删除选中生产设备
      deleteEquipmentItem(index) {
        let equipmentItem = this.equipmentItem[index];
        this.equipmentItem.splice(index, 1)
        this.equipmentData.forEach((item)=>{
          if(item.id === equipmentItem.id){
            item.checked =  false
            return false
          }
        })
      },
      // 删除选中防治设施
      deleteFacilitiesItem(index) {
        let facilitiesItem = this.facilitiesItem[index];
        this.facilitiesItem.splice(index, 1)
        this.facilitiesData.forEach(item => {
          if (item.id === facilitiesItem.id){
            item.checked = false
            return false
          }
        })
      },
      // 提交
      submit() {
        let equipmentIdS = [], protectiveIds = []
        this.equipmentItem.forEach(item => {
          equipmentIdS.push(item.id)
        })
        this.facilitiesItem.forEach(item => {
          protectiveIds.push(item.id)
        })

        let urls = []
        if (this.images.length) {

          this.images.forEach((item) => {
            urls.push(item.url)
          })
        }

        let params = {
          checkFileId: this.dischargePortInfo.dischargePortData.checkFileId,//监测文件ID
          code: this.code + this.number,
          corpId: this.corpId,//企业id
          dischargePortCpList: this.dischargePortInfo.dischargePortData.dischargePortCpList,
          equipmentIds: equipmentIdS.join(','),
          id: this.id,
          image: urls.join(','),
          name: this.dischargePortInfo.dischargePortData.name,
          pollutionProductProcess: this.dischargePortInfo.dischargePortData.pollutionProductProcess,
          portHeight: this.dischargePortInfo.dischargePortData.portHeight,
          portInradium: this.dischargePortInfo.dischargePortData.portInradium,
          portStatus: "COMPLETED",//排放口状态
          portType: this.dischargePortInfo.dischargePortData.portType.code,
          productProcessId: this.dischargePortInfo.dischargePortData.productProcessId,//生产工艺流程ID
          productProcessRemark: this.dischargePortInfo.dischargePortData.productProcessRemark,//生产工艺流程ID
          protectiveIds: protectiveIds.join(','),
          signalImage: this.dischargePortInfo.dischargePortData.signalImage,
          treatmentProcess: this.dischargePortInfo.dischargePortData.treatmentProcess
        }

        DischargePortAPI.update(params).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.handleClose()
          }
        })
      },
    }
  }
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;
  color: #000000;
}
.line-l{
  display: flex;
  align-items: center;
}
.line-l .line{
  flex:1;
  padding: 15px 10px;
}
.line-l .el-select{
  flex: 1;
}
.container{
  padding: 15px;
  margin: 0 auto;
  border: 1px solid #EEEEEE;
  width: 375px;
}
.title{
  position: relative;
  color: #359029;
  border-left: 4px solid #359029;
  height: 20px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.title i{
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 24px;
}
.line{
  margin-bottom: 15px;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
  padding-bottom: 10px;
}
.line .label_box{
  display: flex;
  flex-wrap: wrap;
  font-size: 28px;
  color: #666666;
}
.line div{
  margin-top: 10px;
}
.line .label_box .label{
  position: relative;
  margin: 0 10px;
}
.line text{
  font-size: 14px;
}
.line .input_box{
  border: 1px solid #359029;
  border-radius: 6px;
  /*padding: 5px 7px;*/
  box-sizing: border-box;
  margin-top: 10px;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.clear{
  color: #359029;
  font-size: 16px;
  display: block;
  width: 40px;
  cursor: pointer;
}
.line label{
  margin-bottom: 10px;
}
.line .menu{
  width: 100%;
  box-shadow: #dedede 0 0 15px 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
  top: 0;
  padding: 0 15px;
  box-sizing: border-box;
  max-height: 200px;
  overflow-y: auto;
}
.flex{
  display: flex;
  align-items: center;
}
.type{
  justify-content: space-between;
}
.type label,.type div{
  margin: 0;
}
.no_border{
  border: none;
}
.no_border div{
  margin: 0;
}
.in_start{
  margin-top: 25px;
}
.number_box{
  margin: 15px 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.number_box div{
  color: #359029;
  border: 1px solid #359029;
  border-radius: 6px;
  height: 34px;
  padding: 0 5px;
  line-height: 34px;
  margin-right: 10px;
  max-width: 50px;
}
.number_box input{
  border-bottom: 1px solid #EEEEEE;
  height: 34px;
  width: calc(100% - 50px);
}
input{
  font-size: 14px;
  color: #575757;
  width: 100%;
}
.size{
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 15px;
}
.size_box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.size_box .size_inner{
  width: 45%;
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.size_box .size_inner input{
  box-sizing: border-box;
  border: 1px solid #359029;
  padding: 0 15px;
  border-radius: 10px;
  width: 80px;
  height: 39px;
  margin: 0 15px 0 8px;
}
.add{
  font-size: 14px;
  color: #359029;
  width: 222px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #359029;
  margin: 25px auto 25px;
  cursor: pointer;
}
.btn_group{
  display: flex;
  justify-content: space-between;
  margin: 25px 0 40px;
}
.btn_group div{
  width: 157px;
  height: 44px;
  border-radius: 22px;
  text-align: center;
  line-height: 44px;
}
.cancel{
  background: #fff;
  color: #359029;
  border: 1px solid #359029;
}
.submit{
  color: #FFCC2D;
  background: #359029;
}
input::-webkit-input-placeholder{
  color: #c7c7c7;
}
.el-select{
  width: 100%;
  margin-top: 10px !important;
}
</style>
<style>

.el-input--suffix .el-input__inner{
  font-size: 14px;
  color: #c7c7c7;
}
.el-select-dropdown__item{
  padding: 0 20px;
}
i{
  cursor: pointer;
}
.line .input_box .el-input__inner{
  border: none;
}

</style>