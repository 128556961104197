<template>
  <editor
    v-model="myValue"
    :init="tinymceConfig"
    :disabled="disabled"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import tinymce from 'tinymce/tinymce'
import plugins from './config/plugins'
import toolbar from './config/toolbar'
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/print'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/tabfocus'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import FileApi from '@/api/fs/file'

export default {
  name: 'HmEditor',
  components: { Editor },
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      myValue: this.value,
      tinymceConfig: {
        language_url: `${process.env.BASE_URL === '/' ? '' : process.env.BASE_URL}/tinymce/langs/zh_CN.js`,
        language: 'zh_CN',
        skin_url: `${process.env.BASE_URL === '/' ? '' : process.env.BASE_URL}/tinymce/skins/ui/oxide`,
        height: this.height,
        plugins: this.preview ? 'preview' : plugins,
        toolbar: this.preview ? 'preview' : toolbar,
        branding: false,
        relative_urls: false,
        remove_script_host: false,
        elementpath: false,
        images_upload_handler: this.imageUploadHandler
      }
    }
  },
  watch: {
    value(newVal) {
      this.myValue = newVal
    },
    myValue(newValue) {
      this.$emit('input', newValue)
    }
  },
  methods: {
    imageUploadHandler(blobInfo, success, failure, progress) {
      progress(0)
      const formData = new FormData()
      formData.append('file', blobInfo.blob())
      formData.append('bizType', 'HM_EDIT')
      FileApi.singleUploadFormData(formData).then((res) => {
        if (res.isSuccess) {
          success(res.data.url)
          progress(100)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
