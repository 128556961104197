import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'homeArticle/'

export default {
  /**
   * 主营业务
   * @param params
   * @returns {*}
   */
  search(params) {
    return http.post(PREFIX + `search`, params)
  },
  /**
   * 查询结果
   * @param id
   * @returns {*}
   */
  get(id) {
    return http.get(PREFIX + `${id}`);
  },
  /**
   * 优秀案例
   * @param params
   * @returns {*}
   */
  tree(params) {
    return http.get(PREFIX + `tree`, params)
  },
  /**
   *
   * @param params
   * @returns {*}
   */
  list(params) {
    return http.post(PREFIX + `list`, params)
  },
  /**
   * 优秀案例
   * @param params
   * @returns {*}
   */
  getarticlelist(params) {
    return http.get(PREFIX + `getarticlelist`, params)
  },
}
