<template>
  <div class="contact-root">
    <Head ref="header"></Head>
    <div class="banner">
      <!--      <img src="@/assets/image/contact.png">-->
      <img :src="bannerPath">
    </div>
    <div class="contact">
      <div class="contact-wrap">
        <div class="title">联系我们</div>
        <div class="contactList">
          <div class="contain-item">
            <div class="sub">公司地址</div>
            <p>{{contactData.address}}</p>
            <p>{{contactData.suAddress}}</p>
          </div>
          <div class="contain-item">
            <div class="sub">联系方式</div>
            <p>联系电话：{{contactData.mobile}}</p>
            <p>联系手机：{{contactData.phone}}</p>
            <p>E-mail：{{contactData.email}}</p>
          </div>
          <div class="contain-item">
            <div class="sub">其他</div>
            <div class="wx-qq">
              <div class="wx">
                <img src="@/assets/image/wx.png">
                <div class="code">
                  <img :src="contactData.wxCode">
                </div>
              </div>
              <div class="qq">
                <img src="@/assets/image/qq.png">
                <div class="code">
                  <img :src="contactData.customerCode">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="allmap" class="Map">
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Head from '@/components/head'
  import Footer from '@/components/footer'
  import HomeBannerApi from '@/api/ep/homeBanner'
  import HomeAboutApi from '@/api/ep/homeAbout'

  export default {
    name: "contact",
    data() {
      return {
        bannerPath: '',
        contactData: [],
        point: '',
        marker: '',
        coordinates: [],
        lotude:31.02721,
        latude: 118.743408
      }
    },
    components: {Head, Footer},
    mounted: function () {
      this.getContactData()
      this.getBannerData()
    },
    methods: {
      //banner图数据
      getBannerData() {
        let params = {
          dictionary: 'CONTACTUS'
        }
        HomeBannerApi.list(params).then((res) => {
          this.bannerPath = res.data[0].fileId
        })
      },
      getContactData() {
        let params = {}
        HomeAboutApi.getInfo(params).then((res) => {
          let data = res.data
          this.contactData = data
          this.lotude= data.suLotude
          this.latude= data.suLatude
          let list=[]
          list.push(
              {
                x: data.lotude,
                y: data.latude,
                name:data.address.slice(0,4)
              },
              {
                x: data.suLotude,
                y: data.suLatude,
                name:data.suAddress.slice(0,4)
              }
          )

          this.coordinates=list

          var map = new BMap.Map("allmap");   //初始化map, 绑定id=allmap
          var point = new BMap.Point(this.lotude,this.latude);   // 初始化point, 给定一个默认x,y值
          map.centerAndZoom(point, 11);        // 将point点放入map中，展示在页面中心展示，10=缩放程度
          map.enableScrollWheelZoom();         // 开启滚动鼠标滑轮
          // 如有多个point去展示，可根据后端接口传入为主
          /*118.74808636501658,30.939139177624554*/
          list.forEach((e, i) => {
            // 创建point, 将x,y值传入
            let pointNumber = new BMap.Point(parseFloat(e.x), parseFloat(e.y))
            // 创建信息窗口对象
            let infoWindow = new BMap.InfoWindow(" ", {
              height: 60,     // 信息窗口高度
              title: "<div style='padding-top: 15px'>"+e.name+"</div>" // 信息窗口标题
            });
            // 将data中的name加入地图中
            var label = new BMap.Label(e.name, {
              offset: new BMap.Size(25, 5)
            });
            label.setStyle({
              border:'none',
              top:"-5px",
              padding:'10px 15px',
              fontSize : "15px",
              height : "20px",
              maxWidth:"none",

            });
            markerFun(pointNumber, infoWindow, label)
          })
          function markerFun(points, infoWindows, label) {
            let markers = new BMap.Marker(points);
            map.addOverlay(markers);  // 将标注添加到地图中
            markers.setLabel(label);  // 将data中的name添加到地图中
//            // 标注的点击事件
//            markers.addEventListener("click", function (event) {
//              map.openInfoWindow(infoWindows, points);//参数：窗口、点  根据点击的点出现对应的窗口
//            });
          }



        })
      },
    },
  }
</script>

<style scoped>
  @media screen and (min-width: 900px){
    .BMapLabel{
      border: none!important;
    }
    .banner img {
      width: 100%;
      height: 475px;
      object-fit: cover;
    }

    .contact-wrap {
      width: 1200px;
      margin: 0 auto;
      padding-top: 55px;
    }

    .contact-wrap .title {
      font-size: 24px;
      line-height: 32px;
      color: #000000;
    }

    .contactList {
      padding-top: 47px;
      padding-bottom: 70px;
      display: flex;
      justify-content: space-between;
    }

    .contactList .contain-item {
      width: 290px;
    }

    .contactList .contain-item .sub {
      font-size: 16px;
      line-height: 32px;
      color: #000000;
      padding-bottom: 20px;
      border-bottom: 1px solid #CCCCCC;
      margin-bottom: 20px;
    }

    .contactList .contain-item p {
      font-size: 14px;
      line-height: 20px;
      color: #999999;
      margin-bottom: 5px;
    }

    .contactList .contain-item .wx-qq {
      display: flex;
    }

    .contactList .contain-item .wx,
    .contactList .contain-item .qq {
      width: 56px;
      height: 56px;
      border: 1px solid #cccccc;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      position: relative;
    }

    .contactList .contain-item .wx-qq .code {
      position: absolute;
      top: 50%;
      left: 75px;
      transform: translateY(-50%);
      width: 140px;
      height: 140px;
      padding: 10px;
      background: #FFFFFF;
      border: 1px solid #707070;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      display: none;
    }

    .contactList .contain-item .wx:hover .code {
      display: block;
    }

    .contactList .contain-item .qq:hover .code {
      display: block;
    }

    .contactList .contain-item .wx:hover {
      border: 1px solid #359029;
    }

    .contactList .contain-item .qq:hover {
      border: 1px solid #359029;
    }

    .contactList .contain-item .wx-qq .code img {
      width: 140px;
      height: 140px;
    }

    .Map {
      height: 364px;
      width: 100%;
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 900px){
    .BMapLabel{
      border: none!important;
    }
    .banner img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    .contact-wrap {
      width: 96%;
      margin: 0 auto;
      padding-top: 20px;
    }

    .contact-wrap .title {
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }

    .contactList {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .contactList .contain-item {
      width: 100%;
      margin-bottom: 15px;
    }

    .contactList .contain-item .sub {
      font-size: 16px;
      line-height: 32px;
      color: #000000;
      border-bottom: 1px solid #CCCCCC;
      margin-bottom: 10px;
    }

    .contactList .contain-item p {
      font-size: 14px;
      line-height: 20px;
      color: #999999;
      margin-bottom: 5px;
    }

    .contactList .contain-item .wx-qq {
      display: flex;
    }

    .contactList .contain-item .wx,
    .contactList .contain-item .qq {
      width: 56px;
      height: 56px;
      border: 1px solid #cccccc;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      position: relative;
    }

    .contactList .contain-item .wx-qq .code {
      position: absolute;
      top: 50%;
      left: 75px;
      transform: translateY(-50%);
      width: 140px;
      height: 140px;
      padding: 10px;
      background: #FFFFFF;
      border: 1px solid #707070;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      display: none;
    }

    .contactList .contain-item .wx:hover .code {
      display: block;
    }

    .contactList .contain-item .qq:hover .code {
      display: block;
    }

    .contactList .contain-item .wx:hover {
      border: 1px solid #359029;
    }

    .contactList .contain-item .qq:hover {
      border: 1px solid #359029;
    }

    .contactList .contain-item .wx-qq .code img {
      width: 140px;
      height: 140px;
    }
    .Map {
      height: 360px;
      width: 100%;
      margin-bottom: 30px;
    }
  }
</style>
