import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'homeBanner/'

export default {
  /**
   * banner图数据
   * @param params
   * @returns {*}
   */
  search(params) {
    return http.get(PREFIX + params['fieldName'] + "/" + params['fieldValue'])
  },
  list(params) {
    return http.post(PREFIX + `list`, params)
  },
}
