<template>
    <div class="components" v-if="visible">
        <div class="title breadcrumb">
            <span @click="back" class="breadcrumb-label">企业资料管理</span>
            <template v-if="isShow">
                <span class="el-icon-d-arrow-right icon"></span>
                <span @click="backChild" class="breadcrumb-label">{{ dataName || '' }}</span>
            </template>
            <span class="el-icon-d-arrow-right icon"></span>
            <span class="breadcrumb-label">{{ folder.dataName || '' }}</span>
        </div>
        <div class="content">
            <div class="operation-box">
                <div>
                    <el-button type="info" icon="el-iconn-puls" @click="add">新增</el-button>
                    <el-button type="info" icon="el-icon-share" @click="batchDelete">批量删除</el-button>
                    <el-button type="info" icon="el-icon-share" @click="batchDownFile" :loading="loading">批量下载<span
                            v-if="loading">（下载中）</span></el-button>
                    <el-button type="info" :icon="isEditStatus ? 'el-icon-check' : 'el-icon-edit'"
                               @click="handleSuccess">{{ isEditStatus ? '完成' : '编辑' }}
                    </el-button>
                </div>
                <div class="search-box">
                    <el-form :inline="true" :show-message="false">
                        <el-form-item :label="folder.dataName=='危险废物处置单位'?'处置单位名称':'企业资料名称'" style="margin-bottom: 0">
                            <el-input v-model="search_form.dataName" placeholder="企业资料名称检索"></el-input>
                        </el-form-item>
                        <el-form-item style="margin-bottom: 0">
                            <el-button type="primary" @click="getData">搜索</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <div class="list" v-if="folder.dataName=='危险废物处置单位'">
                <div class="item-box" v-for="(item,index) in list1" :key="index">
                    <div class="item copy-item" :class="activeIndex==index ? 'copy-item-ax' : ''">
                        <div class="copy-left" @click="activbeClick(index)">
                            <i :class="activeIndex==index ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                            <span class="copy-name">{{ item.name }}</span>
                        </div>
                        <div class="btn-box">
                            <a href="javascript:;" @click.stop="share(item)">分享</a>
                            <a href="javascript:;" @click.stop="editWF(item)">编辑</a>
                        </div>
                    </div>
                    <div class="copy-chilkd" v-show="activeIndex==index">
                        <div class="chikd-item">
                            <img :src="getImage(item.hmFile)" alt="">
                            <div>{{item.hmFile.submittedFileName}}</div>
                        </div>
                        <div class="chikd-item">
                            <img :src="getImage(item.hmAgreementFile)" alt="">
                            <div>{{item.hmAgreementFile.submittedFileName}}</div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="list" v-else>
                <div class="item-box" v-for="(item,index) in list">
                    <div class="item">
                        <div class="select-box" @click.stop="chooseRow(index)" v-if="isEditStatus"
                             :class="{'select-box-active': item.checked}"></div>
                        <div class="file-box">
                            <img :src="getImage(item)" alt="">
                        </div>
                        <div class="file-info">
                            <div class="file-name">
                                <p class="file-label">
                                    {{ item.submittedFileName.substring(0,item.submittedFileName.lastIndexOf(".")) }}
                                </p>
                            </div>
                        </div>
                        <div class="btn-box">
                            <a href="javascript:;" @click.stop="share(item)">分享</a>
                            <a href="javascript:;" @click.stop="edit(item)">编辑</a>
                        </div>
                    </div>
                </div>
            </div>

            <!--    分页-->
            <el-pagination background layout="prev, pager, next" :total="params.total" @current-change="currentChange"
                           style="text-align: center;margin-top: 100px">
            </el-pagination>
        </div>


        <el-dialog title="新增企业文件" :visible.sync="formVisible" top="10vh" width="70%">
            <el-form :model="form" label-width="150px">
                <el-row>
                    <el-col>
                        <el-form-item label="文件名称">
                            <el-input v-model="form.neoname" placeholder="请输入文件名称或直接上传文件后，自动带入名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="文件上传">
                            <el-upload class="upload-box" accept=".pdf,.xlsx,.xls,.doc,.docx,.jpg,.jpeg,.png"
                                       :headers="headers" :data="{bizType: 'Files'}" :on-success="(e) => onSuccess(e)"
                                       :before-upload="(e)=>onBeforeUpload(e)" :on-progress="onProgress"
                                       :show-file-list="false" :action="action">
                                <img v-if="file && file.url" :src="getImage(file)" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 20px;"></i>
                            </el-upload>
                            <div class="upload-tip">请上传文件、文件类型为word、pdf、excel、jpg、png</div>
                            <el-progress :percentage="uploadPercentage"
                                         v-if="uploadPercentage>0 && uploadPercentage<100"></el-progress>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="新增危废单位" :visible.sync="formVisible1" top="10vh" width="70%">
            <el-form :model="form1" label-width="160px">
                <el-row>
                    <el-col>
                        <el-form-item label="处置单位名称">
                            <el-input v-model="form1.name" placeholder="请输入处置单位名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="处置单位营业许可编号">
                            <el-input v-model="form1.businessNumber" placeholder="请输入处置单位营业许可编号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="相关资质文件">
                            <el-upload class="upload-box" accept=".pdf,.xlsx,.xls,.doc,.docx,.jpg,.jpeg,.png"
                                       :headers="headers" :data="{bizType: 'Files'}" :on-success="(e) => onSuccess1(e)"
                                       :before-upload="(e)=>onBeforeUpload(e,1)" :on-progress="onProgress"
                                       :show-file-list="false" :action="action">
                                <img v-if="file && file.url" :src="getImage(file)" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 20px;"></i>
                            </el-upload>
							<div v-if="file && file.url">{{file.submittedFileName}}</div>
							<div class="upload-tip" v-else>请上传相关资质文件、文件类型为word、pdf、excel、jpg、png</div>
                            <el-progress :percentage="uploadPercentage"
                                         v-if="uploadPercentage>0 && uploadPercentage<100 && uploadIndex==1"></el-progress>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="危废处置协议">
                            <el-upload class="upload-box" accept=".pdf,.xlsx,.xls,.doc,.docx,.jpg,.jpeg,.png"
                                       :headers="headers" :data="{bizType: 'Files'}" :on-success="(e) => onSuccess2(e)"
                                       :before-upload="(e)=>onBeforeUpload(e,2)" :on-progress="onProgress"
                                       :show-file-list="false" :action="action">
                                <img v-if="agreementFile && agreementFile.url" :src="getImage(agreementFile)"
                                     class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 20px;"></i>
                            </el-upload>
							<div v-if="agreementFile && agreementFile.url">{{agreementFile.submittedFileName}}</div>
                            <div class="upload-tip" v-else>请上传相关危废处置协议文件、文件类型为word、pdf、excel、jpg、png</div>
                            <el-progress :percentage="uploadPercentage"
                                         v-if="uploadPercentage>0 && uploadPercentage<100 && uploadIndex==2"></el-progress>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel1">取 消</el-button>
                <el-button type="primary" @click="submit1">确 定</el-button>
            </div>
        </el-dialog>
        <share-dialog ref="share"/>
    </div>
</template>

<script>
    import CorpWasteApi from "@/api/ep/CorpWaste"
    import CorpDataApi from "@/api/ep/corpData"
    import CorpShareApi from "@/api/ep/corpShare"
    import FileApi from "@/api/file"
    import ShareDialog from "./share";
    import {
        downloadBlobFile
    } from "@/utils/commons"

    export default {
        name: "Files",
        components: {
            ShareDialog
        },
        data() {
            return {
                loading: false,
                iconWord: require("@/assets/image/icon/icon-word.png"),
                iconExcel: require("@/assets/image/icon/icon-excel.png"),
                iconPDF: require("@/assets/image/icon/icon-pdf.png"),
                iconPNG: require("@/assets/image/icon/icon_png.png"),
                iconJPG: require("@/assets/image/icon/icon_jpg.png"),
                headers: {
                    Authorization: this.$store.state.user.token
                },
                action: FileApi.singleUpload(),
                search_form: {
                    dataName: ''
                },
                visible: false,
                form: {
                    name: '',
                    neoname: '',
                    file: '',
                    id: '',
                },
                form1: {
                    id: '',
                    name: '',
                    businessNumber: '',
                    file: '',
                    id: '',
                    agreementFile: ""
                },
                file: null,
                agreementFile: null,
                list: [],
                list1: [],
                folder: null,
                formVisible: false,
                formVisible1: false,
                isEditStatus: false,
                isEditFileStatus: false,
                uploadPercentage: 0,
                dataName: '',
                isShow: false,
                params: {
                    model: {},
                    total: 0,
                    size: 10,
                    current: 1
                },
                activeIndex: -1,
                uploadIndex: 0
            }
        },
        methods: {
            activbeClick(index) {
                if (this.activeIndex == index) {
                    this.activeIndex = -1
                    return
                }
                this.activeIndex = index
            },
            getImage(file) {
                if (['xlsx', 'XLSX', 'xls', 'XLS'].includes(file.ext)) return this.iconExcel
                if (['doc', 'docx', 'DOC', 'DOCX'].includes(file.ext)) return this.iconWord
                if (['doc', 'docx', 'DOC', 'DOCX'].includes(file.ext)) return this.iconWord
                if (['png', 'PNG'].includes(file.ext)) return this.iconPNG
                if (['jpg', 'JPG'].includes(file.ext)) return this.iconJPG
                return this.iconPDF
            },
            //设置分享
            share(item) {
                let ids = [item.id]
                let params = {
                    fileIds: ids,
                    dataIds: []
                }
                CorpShareApi.getShare(params).then(res => {
                    let data = res.data
                    this.$refs.share.init(data)
                })

            },
            currentChange(val) {
                this.params.current = val
                this.getData(this.params)
            },
            batchDelete() {
                if (this.folder.dataName == "危险废物处置单位") {
                    let ids = []
                    this.list1.forEach((item) => {
                        if (item.checked) {
                            ids.push(item.file)
                        }
                    })
                    if (ids.length <= 0) {
                        this.$message.warning('至少选择一项')
                        return false
                    }
                    this.$confirm('此操作将永久删除文件吗？, 是否继续?', '提示', {
                        type: 'warning'
                    }).then(() => {
                        CorpWasteApi.deleteFile({
                            file: ids.join(","),
                            id: this.folder.id
                        }).then((res) => {
                            if (!res.isSuccess) {
                                return
                            }
                            this.$message.success('删除成功')
                            this.getData()
                        })
                    })

                } else {
                    let ids = []
                    this.list.forEach((item) => {
                        if (item.checked) {
                            ids.push(item.id)
                        }
                    })
                    if (ids.length <= 0) {
                        this.$message.warning('至少选择一项')
                        return false
                    }
                    this.$confirm('此操作将永久删除文件吗？, 是否继续?', '提示', {
                        type: 'warning'
                    }).then(() => {
                        CorpDataApi.deleteFile({
                            file: ids.join(","),
                            id: this.folder.id
                        }).then((res) => {
                            if (!res.isSuccess) {
                                return
                            }
                            this.$message.success('删除成功')
                            this.getData()
                        })
                    })
                }
            },
            handleSuccess() {
                this.isEditStatus = !this.isEditStatus
                this.list.forEach(item => {
                    item.checked = false
                })
                this.list1.forEach(item => {
                    item.checked = false
                })
            },
            batchDownFile() {
                let ids = []
                this.list.forEach((item) => {
                    if (item.checked) {
                        ids.push(item.id)
                    }

                })

                if (!ids.length) {
                    this.$message.warning('至少选择一项下载')
                    return
                }

                this.loading = true
                FileApi.downloadFile({
                    ids: ids
                }).then(res => {
                    this.loading = false
                    downloadBlobFile(res)
                }).catch(() => {
                    this.loading = false
                })
            },
            //选中
            chooseRow(index) {
                let item = this.list[index]
                item.checked = !item.checked
                this.$set(this.list, index, item)
            }, //选中
            chooseRow1(index) {
                let item = this.list1[index]
                item.checked = !item.checked
                this.$set(this.list1, index, item)
            },
            back() {
                this.visible = false
                this.$emit('back')
            },
            backChild() {
                this.visible = false
                this.$emit('back', {
                    dataName: this.dataName,
                    parentId: this.folder.parentId
                })
            },
            init(item, dataName) {
                this.isShow = false
                this.visible = true
                this.folder = item
                this.form.id = item.id
                this.dataName = dataName

                if (this.dataName) {
                    this.isShow = true
                }
                this.getData()
            },
            getData() {

                if (this.folder.dataName == "危险废物处置单位") {
                    this.params.model.id = this.folder.id
                    this.params.model.name = this.search_form.dataName
                    CorpWasteApi.getList(this.params).then(res => {
                        if (!res.isSuccess) {
                            return
                        }
                        this.params.total = res.data.total
                        this.params.size = res.data.size
                        this.params.current = res.data.current
                        this.list1 = res.data.records
                    })
                } else {
                    this.params.model.id = this.folder.id
                    this.params.model.dataName = this.search_form.dataName
                    CorpDataApi.getFiles(this.params).then(res => {
                        if (!res.isSuccess) {
                            return
                        }
                        this.params.total = res.data.total
                        this.params.size = res.data.size
                        this.params.current = res.data.current
                        this.list = res.data.records
                    })
                }
            },
            //添加文件夹
            add() {
                if (this.folder.dataName == "危险废物处置单位") {
                    this.formVisible1 = true
                } else {
                    this.formVisible = true
                }
            },
            //添加文件夹
            edit(item) {
                this.isEditFileStatus = true
                this.file = item
                this.form['neoname'] = item.submittedFileName.split(".")[0]
                this.form['fileId'] = item.id
                this.form['file'] = item.id
                this.formVisible = true
            },
            //编辑危废文件夹
            editWF(item) {
                this.isEditFileStatus = true
                var data = {
                    fileId: item.file
                }
                // CorpWasteApi.getFileById(data).then(res=>{
                this.file = item.hmFile
                this.agreementFile = item.hmAgreementFile
                this.form1['id'] = item.id
                this.form1['file'] = item.file
                this.form1['agreementFile'] = item.agreementFile
                this.form1['name'] = item.name
                this.form1['businessNumber'] = item.businessNumber
                this.formVisible1 = true
                // })


            },
            //取消
            cancel() {
                this.formVisible = false
                this.isEditFileStatus = false
                this.file = null
                this.agreementFile = null
                this.form = {
                    file: '',
                    neoname: '',
                    id: this.folder.id
                }
            },

            //取消
            cancel1() {
                this.formVisible1 = false
                this.isEditFileStatus = false
                this.file = null
                this.agreementFile = null
                this.form1 = {
                    id: '',
                    file: '',
                    name: '',
                    businessNumber: '',
                    id: this.folder.id,
                    agreementFile: ""
                }
            },
            //提交
            submit() {
                if (!this.isEditFileStatus) {
                    CorpDataApi.uploadfiles(this.form).then(res => {
                        if (!res.isSuccess) {
                            return
                        }
                        const data = res.data
                        this.cancel()
                        this.$message.success('保存成功')
                        this.getData()
                    })
                    return
                }

                CorpDataApi.changeFile(this.form).then(res => {
                    if (!res.isSuccess) {
                        return
                    }
                    const data = res.data
                    this.cancel()
                    this.$message.success('保存成功')
                    this.getData()
                })
            },
            //提交
            submit1() {
                if (!this.isEditFileStatus) {
                    CorpWasteApi.save(this.form1).then(res => {
                        if (!res.isSuccess) {
                            return
                        }
                        const data = res.data
                        this.cancel1()
                        this.$message.success('保存成功')
                        this.getData()
                    })
                    return
                }

                CorpWasteApi.update(this.form1).then(res => {
                    if (!res.isSuccess) {
                        return
                    }
                    const data = res.data
                    this.cancel1()
                    this.$message.success('保存成功')
                    this.getData()
                })
            },
            //上传成功
            onSuccess(res) {
                this.file = res.data
                let index = res.data.submittedFileName.lastIndexOf(".")
                this.form['neoname'] = res.data.submittedFileName.substring(0, index)
                this.form['file'] = res.data.id
                this.uploadPercentage = 0
            },
            //上传成功
            onSuccess1(res) {
                this.file = res.data
                this.form1['file'] = res.data.id
                this.uploadPercentage = 0
            },
            onSuccess2(res) {
                this.agreementFile = res.data
                this.form1['agreementFile'] = res.data.id
                this.uploadPercentage = 0
            },
            onBeforeUpload(e, index) {
                this.uploadIndex = index
                let fileName = e.name
                fileName = fileName.toLowerCase()
                if (!fileName.endsWith('pdf') && !fileName.endsWith('xlsx') &&
                    !fileName.endsWith('xls') && !fileName.endsWith('doc') && !fileName.endsWith('docx') &&
                    !fileName.endsWith('jpg') && !fileName.endsWith('png')) {
                    this.$message.error('文件格式不正确')
                    return false
                }

                return true
            },
            onProgress(e) {
                this.uploadPercentage = e.percent
                return true
            },
        }
    }
</script>

<style scoped lang="scss">
    * {
        box-sizing: border-box;
    }

    .components {
        height: 100%;
    }

    .breadcrumb-label {
        cursor: pointer;

        &:hover {
            color: #359029;
        }
    }

    .title {
        font-size: 20px;
        font-weight: 400;
        color: #030303;
    }

    .breadcrumb {
        & > span:last-child {
            color: #359029;
        }

        .icon {
            margin: 0 10px;
        }
    }

    .upload-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border: 1px dashed #ddd;

        img {
            display: block;
            object-fit: cover;
            margin: 0 auto;
            max-height: 80px;
        }
    }

    .content {
        padding: 20px;
        margin-top: 20px;
        background-color: #fff;
        min-height: calc(100% - 60px);
        border-radius: 5px;
        box-sizing: border-box;
    }

    .operation-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .search-box {
        display: flex;
        align-items: center;
    }

    .list {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .item-box {
            width: 100%;
            padding: 10px;
        }

        .item {
            position: relative;
            display: flex;
            align-items: center;
            padding: 10px;
            width: 95%;
            height: 140px;
            box-shadow: 6px 6px 28px rgba(194, 194, 194, 0.34);
            border-radius: 20px;
            box-sizing: border-box;

            .file-box {
                padding-top: 10px;
                flex: 0 0 auto;
                width: 100px;
                padding-left: 10px;

                img {
                    display: block;
                    width: 73px;
                }
            }

            .file-info {
                flex: 1;
                flex-wrap: wrap;
            }
        }

        .copy-item {
            justify-content: space-between;
            height: 90px;
            padding-left: 30px;

            .copy-left {
                cursor: pointer;
            }

            .copy-name {
                display: inline-block;
                margin-left: 10px;
                color: #333333;
                font-size: 18px;
            }

            i {
                font-size: 20px;
            }
        }

        .copy-item-ax {
            border-radius: 20px 20px 0 0;
        }

        .copy-chilkd {
            background: #F8F8F8;
            padding-left: 94px;
            width: 95%;

            .chikd-item {
                display: flex;
                align-items: center;
                height: 80px;
                width: 100%;

                img {
                    width: 40px;
                    height: 50px;
                    margin-right: 6px;
                }
            }
        }

        .file-name {
            display: flex;
            align-items: center;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;

            .file-label {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
        }

        .btn-box {
            margin: 20px 30px 0 0;
            display: flex;

            & > a {
                width: 65px;
                display: block;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 30px;
                border: 1px solid #359029;
                color: #359029;

                &:last-child {
                    margin-left: 10px;
                }
            }
        }

        .select-box {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 15px;
            height: 15px;
            border: 1px solid #359029;
            border-radius: 100%;
        }

        .select-box-active {
            background-color: #359029;
            color: #fff;
            border: 1px solid #359029;

            &:after {
                display: block;
                content: '\e63b';
                font-size: 14px;
                color: #fff;
                font-family: "iconfont" !important;
            }
        }
    }

    .avatar-uploader {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #dddddd;
        width: 80px;
        height: 80px;
    }

    .upload-tip {
        font-size: 13px;
        color: #999999;
    }

    .introduce {
        font-size: 18px;
        margin-left: 10px;
        cursor: pointer;
    }
</style>
