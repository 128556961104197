const hmStorage = {
  setItem(key, value) {
    const projectName = process.env.VUE_APP_PROJECT_NAME
    localStorage.setItem(projectName + '_' + key, JSON.stringify(value))
  },
  getItem(key, defaultValue = {}) {
    const projectName = process.env.VUE_APP_PROJECT_NAME
    try {
      const value = localStorage.getItem(projectName + '_' + key)
      return value ? JSON.parse(value) : (defaultValue || null)
    } catch (err) {
      return defaultValue
    }
  },
  removeItem(key) {
    const projectName = process.env.VUE_APP_PROJECT_NAME
    localStorage.removeItem(projectName + '_' + key)
  },
  clear() {
    const projectName = process.env.VUE_APP_PROJECT_NAME
    for (const key in localStorage) {
      if (key.indexOf(projectName) !== -1) {
        localStorage.removeItem(key)
      }
    }
  }
}

export default hmStorage
