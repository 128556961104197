import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'wareHouse/'

export default {
    /**
     * 分页
     * @param params
     * @returns {*}
     */
    search(params) {
        return http.post(PREFIX + `search`, params)
    },

    /**
     * 导出excel
     * @param params
     * @returns {*}
     */
    exportExcel(params) {
        return http.postDownload(PREFIX + `export`, params);
    },
    /**
     * 查询结果
     * @param id
     * @returns {*}
     */
    get(id) {
        return http.get(PREFIX + `${id}`);
    },
}
