// 组件参数数据
export const componentsData = {}

// 防治设施搜索表单配置
export const ProtectiveSearchConfig = function () {
    return {
        list: [
            {
                label: '名称',
                key: 'name',
                type: 'input',
                placeholder: '请输入防治设施名称'
            },
            {
                label: '编号',
                key: 'code',
                type: 'input',
                placeholder: '请输入防治设施编号'
            },
            {
                label: '污染物种类',
                key: 'portType',
                type: 'input',
                placeholder: '请输入污染物种类'
            }
        ],
        config: {
            onSearch: data => {
                this.handleSearch(data)
            },
            deriveBtn: data => {
                this.deriveBtnExcelClick(data)
              },
            selects: {},
            model: {
                corpId: ''
            }
        }
    }
}

// 防护设施表格展示配置
export const ProtectiveTableConfig = function () {
    return {
        list: [
            {
                label: '序号',
                prop: 'serialNum',
                align: 'center',
            },
            {
                label: '防治设施名称',
                prop: 'name',
                align: 'center',
                slotScope: 'name',
            },
            {
                label: '防治设施编号',
                align: 'center',
                prop: 'code'
            },
            {
                label: '防治设施工艺',
                align: 'center',
                prop: 'workmanship'
            },
            {
                label: '防治设施设计处理效率(%)',
                align: 'center',
                prop: 'processEfficiency'
            },
            {
                label: '防治设施对应产污设备编号',
                align: 'center',
                prop: 'equipment.data.code'
            },
            {
                label: '防治设施对应产污设备名称',
                align: 'center',
                prop: 'equipment.data.name'
            },
            {
                label: '防治设施污染物种类',
                align: 'center',
                prop: 'portType'
            },
            {
                label: '防治设施对应排放口编号',
                align: 'center',
                prop: 'portCode'
            },
            {
                label: '防治设施对应排放口名称',
                align: 'center',
                prop: 'portName'
            }
        ],
        config: {
            filters: {},
            model: {},
            onSearch: data => {
                this.handleFilterSearch(data)
            }
        }
    }
}

// 防治设施预览配置
export const ProtectiveDetailConfig = function () {
    return {
        list: [
            {
                label: '防治设施名称',
                key: 'name',
            },
            {
                label: '防治设施编号',
                key: 'code'
            },
            {
                label: '防治设施工艺',
                key: 'workmanship'
            },
            {
                label: '防治设施设计处理效率(%)',
                key: 'processEfficiency'
            },
            {
                label: '防治设施对应产污设备编号',
                key: 'equipment.data.code',
                slotScope: 'equipmentCode'
            },
            {
                label: '防治设施对应产污设备名称',
                key: 'equipment.data.name',
            },
            {
                label: '关联排污口信息',
                key: 'dischargePortList',
                slotScope: 'dischargePortList'
            },
            {
                label: '防治设施现场照片',
                key: 'sceneImage',
                type: 'images'
            },
        ],
        config: {
            model: {
                name: '',
                code: '',
                workmanship: '',
                processEfficiency: '',
                equipmentCode: '',
                equipmentName: '',
                portType: '',
                portCode: '',
                portName: '',
                sceneImage: '',
                equipment: '',
                dischargePortList: []
            }
        }
    }
}


