<template>
  <div class="components">
    <div class="content">
      <div class="edit">
        <div class="edit-contaion" v-html="remark"></div>
        <el-button v-if="sso" style="height: 30px;" size="mini" type="primary" @click="editRemark()">编辑</el-button>
      </div>
      <div class="btns-box" v-if="sso">
        <el-button type="primary" @click="add">新增</el-button>
      </div>
      <div style="margin-top: 20px;">
        <hm-table ref="table"
                  :stripe="true"
                  :border="true"
                  :header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}"
                  :api="listRequest"
                  :table-config="tableConfig"
                  :params="searchParams.params">
          <div slot="image" slot-scope="row">
            <el-image
                style="width: 100px; height: 100px"
                :src="row.data.image"
                :preview-src-list="[row.data.image]">
            </el-image>
          </div>
          <div slot="operation" slot-scope="row">
            <el-button class="table-operation" v-if="sso" type="text" @click="update(row.data.id)">编辑</el-button>
            <el-button class="table-operation" v-if="sso" type="text" @click="remove(row.data.id)">删除</el-button>
          </div>
        </hm-table>
      </div>
    </div>
    <edit ref="editForm" @submitSuccess="submitSuccess"></edit>
    <edit-remark ref="editRemarkForm" @submitSuccess="getRemark"></edit-remark>
  </div>
</template>

<script>
import ParameterAPI from "@/api/common/parameter"
import UserApi from "@/api/uc/user"
import ProductProcessAPI from "@/api/ep/productProcess"
import {TableConfig} from "./config"
import {mergeJson} from '@/utils/commons'
import Edit from './edit'
import EditRemark from './editRemark'

export default {
  components: {Edit, EditRemark},
  data() {
    return {
      listRequest: ProductProcessAPI.search,
      tableConfig: TableConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {},
        // table下拉条件
        tableParams: {},
      },
      remark:'',
      sso: this.$store.getters.sso
    }
  },
  created() {
    this.getRemark()
  },
  methods: {
    add() {
      this.$refs.editForm.init()
    },
    getRemark() {
      ParameterAPI.getProcessRemark().then((res) => {
        if (!res.isSuccess) {
          return
        }
        this.remark = res.data.value
      })
    },
    update(id) {
      this.$refs.editForm.init(id)
    },
    editRemark(id) {
      this.$refs.editRemarkForm.init(id)
    },
    remove(id) {
      this.$confirm('是否确认删除?', '提示', {type: 'warning'}).then(() => {
        ProductProcessAPI.remove({id: id}).then((res) => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
      })
    },
    handleSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.$refs.table.reload(params)
    }
    ,
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}

.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  width: 50px;
  height: 50px;
}

.edit {
  display: flex;
  justify-content: space-between;
  height: 100px;
  border: 1px solid #dddddd;
  padding: 20px;
  margin-bottom: 15px;
}

.edit-contaion {
  width: 70%;
}
</style>
