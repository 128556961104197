<template>
  <el-dialog
      title="查看标识牌"
      :visible.sync="visible"
      width="40%"
      @close="visible = false">
    <div class="box" ref="box">
      <div class="box-news">
        <img class="pai" :src="data.image" crossorigin="anonymous"></img>
        <div class="news">
          <div class="news-title">
            <div>{{ data.pollutionType && data.pollutionType.desc }}口</div>
            <div>生态环境部监制</div>
          </div>
          <div class="news-txt">单位名称：{{ data.company }}</div>
          <div class="news-txt">排放口编号：{{ data.number }}</div>
          <div class="news-txt">主要污染物：{{ data.pollutants }}</div>
        </div>
      </div>
      <div class="abeling">
        <div class="abeling-box">
          <!--      <div class="sign"><image src="../../images/logo.png"></image></div>-->
          <div class="sign">
            <img :src="data.corpLogo" crossorigin="anonymous"></img>
          </div>
          <!--<div>标示牌</div>-->
        </div>
        <div class="abeling-txt">
          <div>{{ data.company }}排污口电子标示</div>
          <div>{{ data.unit }}监制</div>
        </div>
      </div>
      <div class="explain">
        <div class="explain-title">{{ data.pollutionType && data.pollutionType.desc }}</div>
        <div class="explain-content">
          <div class="content-txt">
            <div class="text-words">
              <div>编号：</div>
              <div>{{ data.number }}</div>
            </div>
            <div class="text-words">
              <div>{{ data.pollutionType && data.pollutionType.desc }}：</div>
              <div>{{ data.pollutionCategory }}</div>
            </div>
            <div class="text-words">
              <div>处理工艺：</div>
              <div>{{ data.treatmentProcess }}</div>
            </div>
            <!-- <div class="banner">绿水金山就是金山银山</div> -->
          </div>
          <img :src="data.qrCard" crossorigin="anonymous"></img>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="visible = false">关 闭</el-button>
    <el-button type="primary" @click="download">下 载</el-button>
  </span>
  </el-dialog>
</template>

<script>
import html2canvas from "html2canvas"

export default {
  name: 'signageBrandCard',
  data() {
    return {
      data: {},
      visible: false
    }
  },
  methods: {
    downloadFile(content, fileName) { //下载base64图片
      var base64ToBlob = function (code) {
        let parts = code.split(';base64,');
        let contentType = parts[0].split(':')[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {
          type: contentType
        });
      };
      let aLink = document.createElement('a');
      let blob = base64ToBlob(content); //new Blob([content]);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    },
    download() {
      setTimeout(()=>{
        html2canvas(this.$refs.box).then(canvas => {
          let dataURL = canvas.toDataURL("image/png");
          this.downloadFile(dataURL, `${this.data.company}排污口电子标示`)
          if (dataURL !== "") {
            this.visible = false;
          }
        });
      },500)
    },
    init(data) {
      this.visible = true
      this.data = data
      this.setValue(this.data.image,base64 => {
        this.data['image'] = base64
      })

      this.setValue(this.data.corpLogo,base64 => {
        this.data['corpLogo'] = base64
      })

      this.setValue(this.data.qrCard,base64 => {
        this.data['qrCard'] = base64
      })


    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");  // 可选其他值 image/jpeg
      return dataURL;
    },
    setValue(src,cb){
      let that
      var image = new Image();
      image.src = src + '?v=' + Math.random(); // 处理缓存
      image.crossOrigin = "*";  // 支持跨域图片
      image.onload = ()=>{
        var base64 = this.getBase64Image(image);
        cb && cb(base64);
      }
    }
  }
}
</script>

<style scoped>
/* pages/signs/signs.wxss */
.box {
  margin: 0 auto;
  width: 375px;
  box-shadow: 0px 3px 6px rgba(172, 172, 172, 0.16);
  box-sizing: border-box;
}

.box-news {
  border-radius: 8px 8px 0px 0px;
  display: flex;
  padding: 20px 10px;
  background-color: #359029;
}

.box-news .pai {
  width: 80px;
  height: 80px;
  margin-right: 17px;
}

.box-news .news {
  flex: 1;
}

.box-news .news .news-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-news .news .news-title div:nth-child(1) {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 35px;
  color: #FFFFFF;
}

.box-news .news .news-title div:nth-child(2) {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 35px;
  color: #8ED685;
}

.news-txt {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 25px;
  color: #FFFFFF;
}

.abeling {
  display: flex;
  margin: 0 9px;
  margin-top: 28px;
  padding-bottom: 18px;
  border-bottom: 1px solid #7B7B7B;
}

.abeling-box {
  margin-right: 10px;
}

.abeling-box .sign img {
  width: 46px;
  height: 46px;
  border-radius: 100%;
}

.abeling-box view:nth-child(1) {
  margin-top: 13px;
}

.abeling-box view:nth-child(1) text {
  font-size: 25px;
  padding: 10px 10px;
  line-height: 40px;
  color: #999999;
}

.abeling-box view:nth-child(2) {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 15px;
  color: #FFFFFF;
  background-color: black;
  text-align: center;
}

.abeling-txt view:nth-child(1) {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 35px;
  color: #000000;
}

.abeling-txt view:nth-child(2) {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 25px;
  color: #666666;
}

.explain {
  margin-top: 16px;
  padding: 0 9px;
  padding-bottom: 19px;
}

.explain .explain-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 35px;
  color: #000000;
}

.explain img {
  width: 112px;
  height: 112px;
  margin-top: 15px;
}

.explain-content {
  display: flex;
  justify-content: space-between;
}

.content-txt .text-words {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.content-txt .text-words view:nth-child(1) {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  flex: 0 0 auto;
  width: 70px;
  align-self: flex-start;
}

.content-txt .text-words view:nth-child(2) {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.5em;
  color: #000000;
}

.banner {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 35px;
  color: #000000;
}
</style>
