<template>
	<div class="page-wrap" style="padding: 20px;background-color: #fff">
		<hm-search-form ref="searchForm" :data="searchConfig" deriveShow size="normal" label-width="120" />
		<div class="table-box" style="height: calc(100% - 112px); ">
			<hm-table ref="table" :api="listRequest" :table-config="tableConfig" :params="searchParams.params"
				style="height: 480px;margin-top: 15px">
				<div slot="name" slot-scope="row">
					<span style="cursor: pointer;color: #359029"
						@click="getDetails(row.data.id)">{{ row.data.name }}</span>
				</div>
			</hm-table>
		</div>
		<equipment-detail ref="equipmentDetail" />
	</div>
</template>

<script>
	import Protective from '@/api/ep/protective'
	import EquipmentDetail from './detail'
	import {
		ProtectiveSearchConfig,
		ProtectiveTableConfig
	} from './config.js'
	import {
		downloadBlobFile,
		mergeJson
	} from '@/utils/commons'

	export default {
		components: {
			EquipmentDetail,
		},
		data() {
			return {
				listRequest: Protective.search,
				tableConfig: ProtectiveTableConfig.bind(this)(),
				searchConfig: ProtectiveSearchConfig.bind(this)(),
				searchParams: {
					// 搜索条件
					params: {
						model: {
							corpId: ''
						}
					},
					// table下拉条件
					tableParams: {}
				},
				serialNum: 1,
				corpId: ''
			}
		},
		mounted() {
			this.corpId = localStorage.corpId ? localStorage.corpId : this.$route.params.corpId
			this.searchParams.params.model.corpId = this.corpId
		},
		methods: {
			getDetails(id) {
				this.$refs.equipmentDetail.init(id, 'protective')
			},
			handleSearch(data) {
				console.log(data)
				data.model.corpId = this.corpId
				this.searchParams.params = data
				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.$refs.table.reload(params)
			},
			handleFilterSearch(data) {
				data.model.corpId = this.corpId
				this.searchParams.tableParams = data
				const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
				this.$refs.table.reload(params)
			},
			/* 
    excel导出事件
    
    */
			deriveBtnExcelClick() {
				if (this.flag) {
					this.flag = false
					const params = mergeJson(this.searchParams) || {}
					
					if (!params.extend) {
						params.extend = {}
					}
					params.extend.fileName = '防治设施'
					Protective.exportExcel(params).then((data) => {
						downloadBlobFile(data)
					})
				}
				setTimeout(() => {
					this.flag = true
				}, 500)
			}
		}
	}
</script>

<style scoped>

</style>
