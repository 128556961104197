<template>
  <div class="page-wrap">
    <div style="display: flex;align-items: end">
      <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :headers="headers"
          :show-file-list="false"
          :data="{bizType:'',bizId: ''}"
          :disabled="!sso"
          :on-success="handleAvatarSuccess">
        <img v-if="describe!==''" :src="describe" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
import FileApi from '@/api/fs/file'
import ParameterAPI from "@/api/common/parameter"

export default {
  data() {
    return {
      uploadAction: FileApi.singleUpload.bind(this)(),
      headers: {
        Authorization: this.$store.state.user.token
      },
      describe: "",
      sso: this.$store.getters.sso
    }
  },
  created() {
    this.getData()
  },

  methods: {
    getData() {
      ParameterAPI.getSignalImage().then((res) => {
        if (!res.isSuccess) {
          return
        }
        this.describe = res.data || ''
      })
    },
    handleAvatarSuccess(res, file) {
      this.describe = res.data.url || ''
      this.handleSubmit()
    },
    handleSubmit() {
      ParameterAPI.saveSignalImage({describe: this.describe}).then((res) => {
        if (!res.isSuccess) {
          return
        }
        this.visible = false
        this.$message.success('保存成功')
        this.$emit('submitSuccess')
      })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .el-upload {
  border: 1px dashed #d9d9d9 !important;
  width: 178px !important;
  height: 178px !important;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>