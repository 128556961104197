<template>
  <div class="components">
    <div class="content">
      <hm-search-form ref="searchForm" :data="searchConfig" size="normal" label-width="120"/>
      <div class="btns-box">
        <el-button type="primary" @click="exportExcel" :disabled="!flag">导出</el-button>
      </div>
      <div style="margin-top: 20px;">
        <hm-table ref="table"
                  :stripe="true"
                  :border="true"
                  :header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}"
                  :api="listRequest"
                  :table-config="tableConfig"
                  style="height: 500px"
        >
          <div slot="name" slot-scope="row">
            <el-link type="primary" :underline="false" @click="onView(row.data.id)">{{ row.data.name }}</el-link>
          </div>
        </hm-table>
      </div>
    </div>
    <detail ref="detailForm" @submitSuccess="submitSuccess"></detail>
  </div>
</template>

<script>
import WareHouseAPI from "@/api/ep/wareHouse"
import {SearchConfig, TableConfig} from "./config"
import {downloadBlobFile, mergeJson} from '@/utils/commons'
import Detail from './detail'

export default {
  components: {Detail},
  data() {
    return {
      listRequest: WareHouseAPI.search,
      searchConfig: SearchConfig.bind(this)(),
      tableConfig: TableConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {},
        // table下拉条件
        tableParams: {},
      },
      flag:true
    }
  },
  methods: {
    onView(id) {
      this.$refs.detailForm.init(id)
    },
    exportExcel() {
      if (this.flag){
        this.flag = false
        const params = mergeJson(this.searchParams.params, this.searchParams.tableParams) || {}
        if (!params.extend) {
          params.extend = {}
        }
        params.extend.fileName = '危险品仓库建设情况'
        WareHouseAPI.exportExcel(params).then((data) => {
          downloadBlobFile(data)
        })
      }
      setTimeout(()=> {
        this.flag = true
      },500)
    },

    handleSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.$refs.table.reload(params)
    },
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}

.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dddddd;
  width: 50px;
  height: 50px;
}
</style>
