<template>
  <el-dialog
      :visible.sync="visible"
      :show-close="false"
      width="830px"
      :before-close="handleClose">
    <div class="contain">
      <div class="title">二维码下载</div>
      <div class="info" ref="imageWrapper" :class="data.portType.code === 'DANGER'?'bor':''">
        <div class="info-top">
          <div style="overflow: hidden;width: 135px;height: 265px">
            <div class="info-top-l" :class="data.portType.code === 'DANGER'?'col':''">
              <div class="title" :class="data.portType.code === 'DANGER'?'fot':''" >
                <h2>{{ data.portType && data.portType.desc }}</h2>
              </div>
              <div class="item" :class="data.portType.code === 'DANGER'?'fot':''">
                <div class="sub">单位名称：</div>
                <div class="sub-info">{{ data.corpAccount && data.corpAccount.companyName }}</div>
              </div>
              <div class="item" :class="data.portType.code === 'DANGER'?'fot':''">
                <div class="sub">排放口编号：</div>
                <div class="sub-info">{{ data.code }}</div>
              </div>
              <div class="item" :class="data.portType.code === 'DANGER'?'fot':''">
                <div class="sub">主要污染物：<br /><span
                    v-for="(item,index) in data.dischargePortCpList">{{ item.name }}<i
                    v-if="index!==data.dischargePortCpList.length-1">、</i></span></div>
              </div>
              <div class="bottom" ><p style="padding-top: 5px" :class="data.portType.code === 'DANGER'?'fot':''">生态环境部监制</p></div>
            </div>
          </div>
          <div class="info-top-r"><img :src="data.cover"></div>
        </div>
        <div class="info-m">
          <div class="info-m-left">
            <img style="width: 50px;height: 50px;border-radius: 50%;object-fit: cover" :src="data.corpAccount.logo">
          </div>
          <div class="info-m-right">
            <div class="company-1">{{ data.corpAccount && data.corpAccount.companyName }}排污口标识</div>
            <div class="company-2">安徽一二三环保科技有限公司监制</div>
          </div>
        </div>
        <div class="info-b">
          <div class="info-b-l">
            <div class="cell"> 排放口名称：{{ data.name }}</div>
            <div class="cell"> 排放口编号：{{ data.code }}</div>
            <div class="cell"> 生产工序：{{ data.pollutionProductProcess }}</div>
            <div class="cell"> 治理工艺：{{ data.treatmentProcess }}</div>
          </div>
          <div class="info-b-r"><img :src="data.qrCode"></div>
        </div>
        <div class="text">绿水青山就是金山银山</div>
      </div>
      <div class="btns">
        <div class="btn" @click="handleClose">取消</div>
        <div class="btn" @click="onCreateImage">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import DischargePortApi from "@/api/ep/dischargePort"
import html2canvas from "html2canvas"

export default {
  name: "archivesCode",
  data() {
    return {
      visible: false,
      data: {}
    }
  },
  methods: {
    onCreateImage() {
      html2canvas(this.$refs.imageWrapper, {
        backgroundColor: '#fff',
        scale: 2,
        useCORS: true
      }).then(canvas => {
        let link = document.createElement("a");
        link.href = canvas.toDataURL();
        link.setAttribute("download", "排污口标识牌.png");
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        this.$message.success('下载成功')
        this.handleClose()
      });
    },
    init(id) {
      this.visible = true
      this.getData(id)
    },
    getData(id) {
      DischargePortApi.get(id).then((res => {
        console.log(res)
        this.data = res.data
      }))
    },
    handleClose() {
      this.visible = false
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  background-color: #fff !important;
  border-radius: 10px !important;
}
.bor{
  border: 2px solid #FCDB00 !important;
}
.col{
  background-color: #FCDB00 !important;
}
.fot{
  color: #000 !important;
}
.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 30px;
  color: #000000;
  margin-bottom: 47px;
  display: flex;
  align-items: center;
}
.title h2{
  font-weight: 700;
  margin: 0 auto;
  padding-bottom: 15px;
  font-size: 18px;
}

.info {
  width: 433px;
  box-sizing:border-box;
  border: 2px solid #009944;
}

.info-top {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.info-top-l {
  margin-right: 10px;
  background-color: #009944;
  padding-top: 10px;
  padding-right: 14px;
  box-sizing: border-box;
  width: 160px;
  transform: scale(.9);
  transform-origin: 0 0;
  height: 293.4px;

  position: relative;
}

.info-top-l .title {
  padding-top: 5px;
  font-size: 14px;
  margin-bottom: 5px;
  color: #ffffff;
  text-align: left;
  padding-left: 7px;
}

.info-top-l .item {
  /*margin: 0 auto;*/
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  margin-bottom: 5px;
  /*  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;*/
  padding-left: 7px;
}
.info-top-l .item .sub{
  font-size: 12px;
}
.info-top-l .item .sub-info,
.info-top-l .item .sub {
  line-height: 20px;
}
.info-top-l .item .sub-info,
.sub span{
  font-size: 13px;
}
.info-top-l .sub-info-3 {
  overflow: hidden;
  /* text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;*/
}
.info-top-l p {
  position: absolute;
  bottom: 3px;
  left: -10px;
  width: 100%;
  font-size: 14px;
  color: #ffffff;
  /*padding-right: 10px;*/
  box-sizing: border-box;
  padding-left: 7px;
  padding-bottom: 15px;
  text-align: center;
  /*margin: 0 auto;*/

}

.info-top-r {
  /*height: auto;*/
  width: 263.5px;
  height: 263.5px;
}

.info-top-r img {
  /*width: 207px;*/
  width: 100%;
  height: 100%;
}

.info-m {
  padding: 15px 20px 0px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.info-m .info-m-left {
  flex: 1;
  padding-right: 20px;
}

.info-m-left img {
  width: 100%;
}

.info-m .info-m-right {
  flex: 7;
  boder-bottom:1px solid #CCC;
}

.info-m .company-1 {
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}

.info-m .company-2 {
  font-size: 12px;
  color: #000000;

  padding-bottom: 10px;
}

.info-b {
  display: flex;
  justify-content: space-between;
  padding: 26px 20px 0px;
}

.info-b .info-b-l {
  flex: 1;

}

.info-b .cell {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 10px;
}

.info-b .info-b-r{
  width: 130px;
  height: 130px;
  margin-top: -10px;
}
.info-b-r img{
  width: 100%;
  height: 100%;
}

.text {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  padding: 20px;
}

.btns {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.btn {
  width: 233px;
  height: 48px;
  border-radius: 24px;
  font-size: 18px;
  line-height: 48px;
  color: #FFFFFF;
  text-align: center;
  margin: 50px 25px 0px;
  cursor: pointer;
}

.btn:nth-child(1) {
  background: #BEBEBE;
}

.btn:nth-child(2) {
  background: #359029;
}

</style>