<template>
  <el-dialog
      :visible="visible"
      :title="title"
      :width="dialogWidth"
      :fullscreen="fullscreen"
      :top="top"
      :modal="modal"
      :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody"
      :lock-scroll="lockScroll"
      :custom-class="customClass"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
      :show-close="showClose"
      :before-close="beforeClose"
      :center="center"
      :destroy-on-close="destroyOnClose"
      @close="onCancel"
  >
    <slot name="title" />
    <div class="hm-dialog-main" :style="{height: height,maxHeight:maxHeight,padding:padding}">
      <slot name="body" v-if="visible">

      </slot>
    </div>
    <template slot="footer" v-if="buttons.length>0">
      <slot name="footer">
        <el-button
            v-for="(btn,index) in (buttons)"
            :key="index"
            :loading="btn.loading || null"
            :type="btn.type || null"
            :size="btn.size || 'medium'"
            @click="btnEvt(btn,index)"
        >{{ btn.name }}
        </el-button>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'HmDialog',
    props: {
      refs: {
        type: String,
        default: 'form'
      },
      styleObject: {
        type: Object,
        default: null
      },
      gutter: {
        type: Number,
        default: 12
      },
      reset: {
        type: Function,
        default: null
      },
      inline: {
        type: Boolean,
        default: false
      },
      labelPosition: {
        type: String,
        default: 'right'
      },
      labelWidth: {
        type: String,
        default: '80px'
      },
      padding: {
        type: String,
        default: '0 20px'
      },
      labelSuffix: {
        type: String,
        default: ''
      },
      hideRequiredAsterisk: {
        type: Boolean,
        default: false
      },
      showMessage: {
        type: Boolean,
        default: true
      },
      inlineMessage: {
        type: Boolean,
        default: false
      },
      statusIcon: {
        type: Boolean,
        default: false
      },
      validateOnRuleChange: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      // props 兼容ElementUI el-dialog所有属性，新增height,max-height两个属性
      height: {
        type: String,
        default: null
      },
      maxHeight: {
        type: String,
        default: null
      },
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: null
      },
      width: {
        type: String,
        default: null
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
      top: {
        type: String,
        default: '15vh'
      },
      modal: {
        type: Boolean,
        default: true
      },
      modalAppendToBody: {
        type: Boolean,
        default: false
      },
      appendToBody: {
        type: Boolean,
        default: true
      },
      lockScroll: {
        type: Boolean,
        default: true
      },
      customClass: {
        type: String,
        default: null
      },
      closeOnClickModal: {
        type: Boolean,
        default: false
      },
      closeOnPressEscape: {
        type: Boolean,
        default: false
      },
      showClose: {
        type: Boolean,
        default: true
      },
      beforeClose: {
        type: Function,
        default: null
      },
      center: {
        type: Boolean,
        default: false
      },
      destroyOnClose: {
        type: Boolean,
        default: true
      },
      buttons: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        dialogWidth: this.width
      }
    },
    mounted() {
      if (!this.dialogWidth) {
        this.dialogWidth = this.initWidth()
      }
    },
    methods: {
      initWidth() {
        this.screenWidth = document.body.clientWidth
        if (this.screenWidth < 991) {
          return '90%'
        } else if (this.screenWidth < 1400) {
          return '45%'
        } else {
          return '800px'
        }
      },
      // emit用户点击事件
      btnEvt(button) {
        const key = button.key
        this.$emit('onOption', key)
      },
      // 关闭事件
      onCancel() {
        this.$emit('onCancel')
      }
    }
  }
</script>

<style scoped>
  .hm-dialog-main {
    overflow-y: auto;
    box-sizing: border-box;
  }
</style>
