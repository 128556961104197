<template>
  <div class="h-100">
    <div class="title">危险废物转移</div>
    <div class="content">
      <el-tabs v-model="tabName" @tab-click="handleClick">
        <el-tab-pane label="危险废物转移信息" name="DEVICE">
          <device ref="device" @onSuccess="onSuccess"></device>
        </el-tab-pane>
        <el-tab-pane label="创建危险废物转移模板" name="TEMPLATE">
          <env-template ref="envTemplate" @onSuccess="onSuccess"></env-template>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import CorpDataApi from "@/api/ep/corpData"
import FileApi from "@/api/file"
import Device from "./device";
import EnvTemplate from "./envTemplate";
export default {
  name: "folder",
  components: {EnvTemplate, Device},
  data() {
    return {
      tabName: 'DEVICE'
    }
  },
  created() {
  },
  methods: {
    handleClick(tab){
      this.tabName = tab.paneName
      if(this.tabName === 'TEMPLATE') {
        this.$refs.envTemplate.getData()
        return
      }
      this.$refs.device.getData()
    },
    onSuccess(type){
      if(type === 'template') {
        this.$refs.device.getData()
        return
      }
      this.$refs.envTemplate.getData()
    }
  }

}
</script>

<style scoped lang="scss">
.h-100 {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}
</style>
