import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.UC + 'user/'

export default {

  /**
     * 分页
     * @param params
     * @returns {*}
     */
  search(params) {
    return http.post(PREFIX + `search`, params)
  },

  /**
     * 查询结果
     * @param id
     * @returns {*}
     */
  get(id) {
    return http.get(PREFIX + `${id}`)
  },

  /**
     * 保存
     * @param params
     * @returns {*}
     */
  save(params) {
    return http.post(PREFIX + `save`, params)
  },

  /**
     * 字段值查询结果
     * @param fieldName
     * @param fieldValue
     * @returns {*}
     */
  listByField(fieldName, fieldValue) {
    return http.get(PREFIX + `${fieldName}/${fieldValue}`)
  },

  /**
     * 修改
     * @param params
     * @returns {*}
     */
  update(params) {
    return http.put(PREFIX + `update`, params)
  },

  /**
     * 批量删除
     * @param params
     * @returns {*}
     */
  batchDelete(params) {
    return http.delete(PREFIX + `batchDelete`, params)
  },
  /**
   * 重置密码
   * @param params
   * @returns {*}
   */
  resetPassword(params) {
    return http.post(PREFIX + `resetPassword`, params)
  },
  /**
     * 更新状态
     * @param params
     * @returns {*}
     */
  updateStatus(params) {
    return http.post(PREFIX + `updateStatus`, params)
  },

  /**
     * 预览excel
     * @param params
     * @returns {*}
     */
  previewExcel(params) {
    return http.post(PREFIX + `previewExcel`, params)
  },

  /**
     * 导出excel
     * @param params
     * @returns {*}
     */
  exportExcel(params) {
    return http.postDownload(PREFIX + `export`, params)
  },

  /**
     * 下载导入模板
     * @param params
     * @returns {*}
     */
  downloadExcelTpl(params) {
    return http.postDownload(PREFIX + `downloadExcelTpl`, params)
  },

  /**
     * 查询列表
     * @param params
     * @returns {*}
     */
  list(params) {
    return http.post(PREFIX + `list`, params || {})
  },
  /**
     * 获取个人信息
     * @returns {*}
     */
  getMine() {
    return http.get(PREFIX + `getMine`)
  },
  /**
     * 获取个人登录信息
     * @returns {*}
     */
  getLoginRecord() {
    return http.get(PREFIX + `getLoginRecord`)
  },
  /**
     * 更改头像
     * @returns {*}
     */
  updateAvatar(avatar) {
    return http.put(PREFIX + `updateAvatar`, { avatar: avatar })
  },
  /**
     * 修改密码
     * @returns {*}
     */
  updatePwd(params) {
    return http.put(PREFIX + `updatePwd`, params)
  },
  /**
     * 修改个人信息
     * @returns {*}
     */
  updateBase(params) {
    return http.put(PREFIX + `updateBase`, params)
  },

  /**
   * 导入人员
   * @returns {*}
   */
  importExcel() {
    return process.env.VUE_APP_DOMAIN + PREFIX + `importExcel`
  },

  /**
   * 确认人员是否有监督关系
   * @returns {*}
   */
  getUserRelationship(params) {
    return http.get(PREFIX + `getUserRelationship`, params)
  },
  /**
   * 重置密码
   * @returns {*}
   */
  resetPwd(params) {
    return http.put(PREFIX + `resetPwd`, params)
  }
}
