import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'protective/'

export default {
    /**
     * 查询生产设备列表
     * @param params
     * @returns {*}
     */
    protectiveList(params) {
        return http.get(PREFIX + `protectiveList`, params)
    },
    /**
     * 根据ID组查询生产设备列表
     * @param params
     * @returns {*}
     */
    protectiveListByIds(params) {
        return http.get(PREFIX + `protectiveListByIds?ids=` + params)
    },
    /**
     * 分页
     * @param params
     * @returns {*}
     */
    search(params) {
        return http.post(PREFIX + `search`, params)
    },
    /**
     * 查询结果
     * @param id
     * @returns {*}
     */
    get(id) {
        return http.get(PREFIX + `${id}`);
    },
	/**
	 * 导出excel
	 * @param params
	 * @returns {*}
	 */
	exportExcel(params) {
	  return http.postDownload(PREFIX + `export`, params);
	},
}
