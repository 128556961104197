<template>
  <div class="components">
    <div class="title">电子名片管理</div>
    <div class="content">
      <div style="display: flex;justify-content: space-between">
        <div class="btns-box">
          <el-button type="primary" @click="add">新增电子名片</el-button>
          <el-button type="primary" @click="batchDelete()">批量删除</el-button>
        </div>
        <hm-search-form ref="searchForm" :data="searchConfig" label-width="150" size="normal"/>
      </div>
      <div>
        <hm-table ref="table"
                  :stripe = "true"
                  :border = "true"
                  :header-cell-style="{ backgroundColor: '#F5F7FA',color: '#696B6F'}"
                  :api="listRequest"
                  :table-config="tableConfig">

          <div slot="operation" slot-scope="row">
            <el-button class="table-operation" type="text" @click="edit(row.data)">编辑</el-button>
            <el-button class="table-operation" type="text" @click="remove(row.data)">删除</el-button>
          </div>
          <div slot="avatar" slot-scope="row">
            <el-avatar :key="row.data.qrCard" :src="row.data.qrCard" fit="fill">
            </el-avatar>
          </div>
        </hm-table>
      </div>
    </div>
    <business-edit ref="form" @submitSuccess="submitSuccess"></business-edit>
  </div>
</template>

<script>
import CorpCardApi from "@/api/ep/corpCard"
import {SearchConfig, TableConfig} from "./config/businessCard";
import { mergeJson } from '@/utils/commons'
import BusinessEdit from "./businessEdit";
export default {
  name: "businessCard",
  components:{BusinessEdit},
  data() {
    return {
      listRequest: CorpCardApi.search,
      searchConfig: SearchConfig.bind(this)(),
      tableConfig: TableConfig.bind(this)(),
      searchParams: {
        // 搜索条件
        params: {},
        // table下拉条件
        tableParams: {},
      },
    }
  },
  methods: {
    edit(item) {
      this.$refs.form.init(item.id)
    },
    add() {
      this.$refs.form.init()
    },
    remove(data){
      this.batchDelete(data.id)
    },
    batchDelete(id) {
      let ids;
      if(id) {
        ids = [id]
      }else {
        ids = this.$refs.table.getSelections().ids
      }
      if (ids.length <= 0) {
        this.$message.error('至少选择一项')
        return false
      }

      this.$confirm('此操作将永久删除, 是否继续?', '提示', {type: 'warning'}).then(() => {
        CorpCardApi.batchDelete({ids: ids}).then((res) => {
          if (!res.isSuccess) {
            return
          }
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
      })
    },
    handleSearch(data) {
      this.searchParams.tableParams = data
      const params = mergeJson(this.searchParams.params, this.searchParams.tableParams)
      this.$refs.table.reload(params)
    },
    submitSuccess() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
.components {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 400;
  color: #030303;
}

.content {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  height: calc(100% - 60px);
  border-radius: 5px;
  box-sizing: border-box;
}
.table-operation {
  margin: 0 5px;
}
</style>
