<template>
  <div class="wrap register-wrap" v-if="isShow">
    <div class="banner">
      <img src="../assets/image/login-mini.png" alt="">
    </div>
    <div class="iconfont iconzuo" @click="goBcak">
    </div>
    <div class="form">
      <div class="form-box">
        <el-row>
          <img src="../assets/image/logo-mini.png" alt="" class="logo">
          <p class="logo-text">欢迎登录一二三环保平台</p>
        </el-row>
        <el-form ref="form" :model="form" :inline-message="true" class="el-form">
          <el-row>
            <el-col>
              <el-form-item prop="account">
                <el-input v-model="form.account" placeholder="输入联系方式"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item prop="password">
                <el-input type="password" v-model="form.password" placeholder="输入密码"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <p class="tip" style="text-align: right">忘记密码？
                <router-link class="link" :to="{path: '/changePwd'}">去找回！</router-link>
              </p>
            </el-col>
          </el-row>

          <el-row>
            <el-button class="submit" @click="login" :loading="loading">登录</el-button>
            <p class="tip">暂无账号？
              <router-link class="link" :to="{path: '/register'}">去注册！</router-link>
            </p>
          </el-row>

        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
  import LoginApi from '@/api/uc/login'

  export default {
    name: 'login',
    data() {
      return {
        form: {},
        loading: false,
        isShow: false
      }
    },
    components: {},
    created() {
      let key = this.$route.query.key || ''
      if (!key) {
        this.isShow = true;
      } else {
        let params = {
          key: key
        }

        LoginApi.ssoLogin(params).then((res) => {
          if (!res.isSuccess) {
            this.isShow = true
            return
          }

          const data = res.data;
          const token = data.token;
          const refreshToken = data.refreshToken;
          const avatar = data.avatar
          delete data.token
          delete data.refreshToken
          delete data.avatar
          this.$store.commit('user/setAvatar', avatar)
          this.$store.commit('user/setSso', true)
          this.$store.commit('user/setUser', data)
          this.$store.commit('user/setToken', token)
          this.$store.commit('user/setRefreshToken', refreshToken)
          this.$router.push('/userCenter/account')
        })
      }
    },
    methods: {
      goBcak() {
        this.$router.push('/')
      },
      //提交
      login() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.loading = true
            LoginApi.login(this.form).then((res) => {
              this.loading = false
              if (!res.isSuccess) {
                this.getCodeImage()
                return
              }
              const data = res.data;
              const token = data.token;
              const refreshToken = data.refreshToken;
              const avatar = data.avatar
              delete data.token
              delete data.refreshToken
              delete data.avatar
              this.$store.commit('user/setAvatar', avatar)
              this.$store.commit('user/setUser', data)
              this.$store.commit('user/setToken', token)
              this.$store.commit('user/setRefreshToken', refreshToken)
              this.$router.push('/userCenter/account')
            }).catch(res => {
              this.loading = false
            })
          }
        })
      },
    }
  }
</script>

<style scoped>
  .iconfont {
    position: relative;
    top: 5%;
    left: 5%;
    z-index: 100;
  }

  .wrap {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .banner {
    width: 50%;
  }

  .form {
    flex: 1;
  }

  .banner img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .form {
    transform: translateY(-5vh);
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    display: block;
    width: 140px;
    margin: 0 auto;
  }

  .logo-text {
    margin-top: 18px;
    font-size: 28px;
    font-family: Microsoft YaHei;
    line-height: 30px;
    color: #333333;
    text-align: center;
  }

  .submit {
    margin-top: 7vh;
    display: block;
    width: 100%;
    background-color: #359029;
    color: #fff;
    box-shadow: 0px 7px 22px rgba(103, 175, 94, 0.44);
  }

  .el-form {
    margin-top: 15vh;
  }

  .form-box {
    max-width: 60%;
  }

  .tip {
    margin-top: 10px;
    height: 13px;
    color: #999999;
    text-align: center;
  }

  .tip .link {
    color: #359029;
  }

  .tip .link:hover {
    text-decoration: underline;
    cursor: pointer;
  }
</style>
<style>
  .register-wrap .el-input__inner {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #DCDFE6;
  }

  .register-wrap .el-input__inner:focus {
    border-bottom: 1px solid #359029;
  }

  .register-wrap .el-form-item {
    margin-bottom: 30px;
  }

  .register-wrap .el-upload__tip {
    margin-top: 10px;
    line-height: 20px;
  }
</style>
