import { CTX } from '@/api/api'
import http from '@/utils/http'

const PREFIX ='/verification/'

export default {
  /**
   * 发送验证码
   * @param params
   * @returns {*}
   */
  send(params) {
    return http.post(PREFIX + `send`, params)
  }
}
