<template>
  <div class="footer-root">
    <div class="footer">
      <div class="main">
        <div class="title">主营业务</div>
        <div class="main-list">
          <div class="main-list-item"  v-for="(item,index) in list">
            <div class="sub" @click="toType(index,item.type.code)">{{item.title}}</div>
            <div class="ll">
              <div class="main-item" v-for="(item1,index1) in item.children" :key="index1" @click="toDetail(item1.id)">{{item1.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contact">
        <div class="title">联系我们</div>
        <div class="phone">联系电话:<div class="phone-number">{{ contactData.mobile }}/{{contactData.phone}}</div></div>

        <div class="code">
          <div class="code-item">
            <div class="code-pic"><img :src="contactData.customerCode"></div>
            <p>扫一扫联系我们</p>
          </div>
          <div class="code-item">
            <div class="code-pic"><img :src="contactData.wxCode"></div>
            <p>微信公众号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="root">
      <div class="root-l">Copyright 2021安徽一二三环保科技有限公司 皖ICP备19010065号-1 </div>
      <div class="root-r">技术支持：禾麦科技</div>
    </div>
  </div>
</template>

<script>
  import HomeAboutApi from '@/api/ep/homeAbout'
  import HomeCategoryApi from '@/api/ep/homeCategory'
    export default {
      data(){
          return{
            contactData:[],
            list:[]
          }
      },
      created() {
        this.getContactData()
        this.getBusiness()
      },
      methods:{
        toType(index,type){
          this.$router.push(`/mainBusiness?index=${index}&code=${type}`)
        },
        toDetail(id){
          this.$router.push(`/businessDetails?id=${id}`)
        },
        getContactData(){
          let params={}
          HomeAboutApi.getInfo(params).then((res)=>{
            this.contactData = res.data
          })
        },
        getBusiness(){
          HomeCategoryApi.getHomeCategoryList().then(res =>{
            this.list = res.data || []
          })
        },
      }
    }
</script>

<style scoped>
  @media screen and (min-width: 900px){
    .footer-root{
      background-color: #359029;
      padding-top: 60px;
    }
    .footer{
      width: 1300px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #67AF5E;
    }
    .footer .title{
      font-size: 24px;
      line-height: 31px;
      color: #FFFFFF;
    }
    .main{
      flex: 1;
    }
    .main-list{
      display: flex;
      justify-content: space-between;

    }
    .main-list .main-list-item .sub{
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      margin: 30px 0px;
      cursor: pointer;
    }
    .main-list .main-list-item .main-item{
      font-size: 16px;
      line-height: 21px;
      color: #B0DCAA;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .main-list .main-list-item .main-item:hover{
      color: #ffffff;
    }
    .contact{
      margin-left: 50px;
    }
    .contact .phone{
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin: 30px 0px 0px 0px;
    }
    .contact .phone-number{
      padding-top: 21px;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
    }
    .contact .code{
      display: flex;
      margin-top: 27px;
    }
    .contact .code .code-item{
      margin-left: 30px;
    }
    .contact .code .code-pic{
      padding: 6px;
      border: 1px solid #ffffff;
    }
    .contact .code  .code-pic img{

      width: 140px;
      height: 140px;
    }
    .contact .code p{
      font-size: 14px;
      line-height: 28px;
      color: #B0DCAA;
      text-align: center;
      margin-top: 13px;
    }
    .root{
      width: 1300px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 21px 0px;
    }
    .root .root-l,
    .root .root-r{
      font-size: 14px;
      line-height: 19px;
      color: #A5D29F;
    }
    .root .root-r{
      cursor: pointer;
    }
    .root .root-r:hover{
      color: #ffffff;
    }
  }

  @media screen and (max-width: 900px){
    .footer-root{
      background-color: #359029;
      padding-top: 20px;
    }
    .footer{
      width: 96%;
      margin: 0 auto;
      padding-bottom: 20px;
      border-bottom: 1px solid #67AF5E;
    }
    .footer .title{
      font-size: 20px;
      line-height: 31px;
      color: #FFFFFF;
    }
    .main-list .main-list-item .sub{
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      margin: 25px 0px 15px;
      cursor: pointer;
    }
    .main-list .main-list-item .ll{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .main-list .main-list-item .main-item{
      font-size: 16px;
      line-height: 21px;
      color: #B0DCAA;
      margin-bottom: 20px;
      cursor: pointer;
      padding: 0px 10px;
    }
    .main-list .main-list-item .main-item:hover{
      color: #ffffff;
    }
    .contact{
    }
    .contact .phone{
      padding-left: 10px;
      font-size: 16px;
      line-height: 28px;
      color: #FFFFFF;
      margin: 5px 0px 21px 0px;
      display: flex;
      align-items: center;
    }
    .contact .phone-number{
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
    .contact .code{
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .contact .code .code-item{
      margin: 0px 10px;
    }
    .contact .code .code-pic{
      padding: 6px;
      border: 1px solid #ffffff;
    }
    .contact .code  .code-pic img{

      width: 140px;
      height: 140px;
    }
    .contact .code p{
      font-size: 14px;
      line-height: 28px;
      color: #B0DCAA;
      text-align: center;
      margin-top: 13px;
    }
    .root{
      width: 96%;
      margin: auto;
      padding: 21px 0px;
    }
    .root .root-l,
    .root .root-r{
      font-size: 14px;
      line-height: 19px;
      color: #A5D29F;
    }
    .root .root-r{
      margin-top: 10px;
      cursor: pointer;
    }
    .root .root-r:hover{
      color: #ffffff;
    }
  }
</style>
