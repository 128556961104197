<template>
  <div class="head">
    <div class="come">
      <div class="come-wrap">
        <div class="welcome">欢迎来到一二三环保！</div>
        <div class="log-out" @click="loginout" v-if="token">退出登录</div>
        <div class="log-out" @click="loginHandle" v-else>登录</div>
      </div>
    </div>
    <div class="nav">
      <div class="logo">
        <img src="@/assets/image/logo.png">
      </div>
      <div class="nav-list">
        <div class="nav-content">
          <div class="nav-item" :class="item.route.includes(route.path)?'active':''" v-for="(item,index) in navList"
               :ref="item.ref"
               :key="index" @click="jumpPage(item.path)" @mouseenter="mouseenter(item.ref,item)"
               @mouseleave="outStyle()">{{
            item.nav }}
          </div>
          <div class="line" :style="{left:left+'px'}"></div>
        </div>
      </div>
      <div class="user" v-if="token" @click="toCenter">
        <div class="user-info">您好，{{ companyName}}</div>
        <div class="user-pic"><img :src="photo"></div>
      </div>
    </div>
    <div class="phone-nav">
      <div class="logo">
        <img src="@/assets/image/logo.png">
      </div>
      <div class="icon" @click="changeShow">
        <transition name="el-fade-in-linear">
          <i v-if="navShow" class="el-icon-close"></i>
          <i v-else class="el-icon-s-fold"></i>
        </transition>
      </div>
      <el-collapse-transition>
        <div class="nav-list" v-if="navShow">
          <div class="item" :class="item.route.includes(route.path)?'active':''" @click="jumpPage(item.path)" v-for="(item,index) in navList" :key="index">{{item.nav}}</div>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex"
  import CorpAccountApi from "@/api/ep/corpAccount"
  export default {
    data() {
      return {
        navShow:false,
        route: this.$route,
        navList: [
          {
            nav: '网站首页',
            ref: 'item-1',
            path: '/',
            route: ['/']
          },
          {
            nav: '关于我们',
            ref: 'item-2',
            path: '/about',
            route: ['/about']
          },
          {
            nav: '主营业务',
            ref: 'item-3',
            path: '/mainBusiness',
            route: ['/mainBusiness', '/businessDetails'],
          },
          {
            nav: '优秀案例',
            path: '/case',
            ref: 'item-4',
            route: ['/case', '/caseDetail'],
          },
          {
            nav: '信息公开',
            ref: 'item-5',
            path: '/information',
            route: ['/information', '/infoDetail'],
          },
          {
            nav: '联系我们',
            ref: 'item-6',
            path: '/contact',
            route: ['/contact'],
          },
        ],
        navIndex: 0,
        left: 22,
        companyName:'',
        photo:''
      }
    },
    computed: {
      ...mapGetters(['userInfo', 'token'])
    },
    mounted() {
      this.outStyle()
      this.getData()
    },
    methods: {
      //获取企业数据
      getData(){
        if(!this.token){
          return
        }
        CorpAccountApi.corpAccount().then(res => {
          this.companyName=res.data.companyName
          this.photo = res.data.logo
        })
      },
      outStyle() {
        this.route=this.$route
        for (let i in this.navList) {
          if (this.route.path === this.navList[i].path) {
            let ref = this.navList[i].ref
            let e = this.$refs[ref][0].offsetLeft
            this.left = e + 22
          }
        }
        if (this.route.path === '/businessDetails') {
          let ref = this.navList[2].ref
          let e = this.$refs[ref][0].offsetLeft
          this.left = e + 22
        }
        if (this.route.path === '/caseDetail') {
          let ref = this.navList[3].ref
          let e = this.$refs[ref][0].offsetLeft
          this.left = e + 22
        }
        if (this.route.path === '/infoDetail') {
          let ref = this.navList[4].ref
          let e = this.$refs[ref][0].offsetLeft
          this.left = e + 22
        }
      },
      mouseenter(item, i) {
        this.route = i
        let e = this.$refs[item][0].offsetLeft
        this.left = e + 22
      },
      toCenter() {
        this.$router.push('/userCenter/account')
      },
      loginout() {
        this.$confirm('确认退出登录吗?', '提示', {type: 'warning'}).then(() => {
          this.$store.dispatch('user/logout')
          window.location.reload()
        })
      },
      jumpPage(item, index) {
        this.$router.push(item)
        this.navShow=false
      },
      changeShow(){
        this.navShow=!this.navShow
      },
      //登录
      loginHandle() {
        this.$router.push('/login')
      },
    }
  }
</script>

<style scoped>
  @media screen and (min-width: 900px) {
    .head {
      width: 100%;
      /*position: fixed;*/
      top: 0px;
      left: 0px;
      z-index: 10;
      background-color: #ffffff;
    }

    .come {
      height: 44px;
      background-color: #359029;
    }

    .come .come-wrap {
      width: 1300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .come .come-wrap .welcome,
    .come .come-wrap .log-out {
      font-size: 14px;
      line-height: 44px;
      color: #FFFFFF;
    }

    .come .come-wrap .log-out {
      cursor: pointer;
    }

    .nav {
      width: 1300px;
      height: 100px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }

    .nav .logo {
      width: 220px;
      height: 82px;
    }

    .nav .logo img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .nav .nav-list {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 100px;
      padding-right: 15px;
    }

    .nav-content {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }

    .nav .nav-list .nav-item {
      font-size: 18px;
      line-height: 100px;
      color: #666666;
      cursor: pointer;
      padding: 0px 22px;
      height: 100px;
    }

    .nav .nav-list .line {
      position: absolute;
      background: #359029;
      width: 72px;
      height: 5px;
      bottom: 0px;
      transition: all 0.3s ease;
    }

    .nav .nav-list .active {
      color: #000000;
      position: relative;
    }

    /* .nav .nav-list .active:after {
     content: "";
     position: absolute;
     background: #359029;
     width: 72px;
     height: 5px;
     bottom: 0;
     left: 50%;
     transform: translateX(-50%);
   }
 */
    .user {
      height: 100%;
      width: 200px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      border-left: 1px solid #E1E1E1;
      padding-left: 22px;
      margin-left: 30px;
    }

    .user .user-pic {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .user .user-pic img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .user .user-info {
      margin-left: 14px;
    }
    .phone-nav{
      display: none;
    }
  }
  @media screen and (max-width: 900px){
    .head {
      width: 100%;
      /*position: fixed;*/
      top: 0px;
      left: 0px;
      z-index: 10;
      background-color: #ffffff;
    }
    .come {
      display: none;
    }
    .nav{
      display: none;
    }
    .phone-nav{
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    .phone-nav .logo img{
      width: 150px;
      object-fit: cover;
    }
    .phone-nav .icon i{
      font-size: 30px;
    }
    .phone-nav .nav-list{
      position: absolute;
      background-color: #ffffff;
      z-index: 10;
      top: 36px;
      right: 60px;
      padding: 10px 30px;
    }
    .phone-nav .nav-list .item{
      font-size: 16px;
      color: #666666;
      line-height: 24px;
      padding: 5px 0px;
    }
    .phone-nav .nav-list .active{
      color: #359029;
    }
  }
</style>
