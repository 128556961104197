// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const OutSideAccountSearchConfig = function() {
  return {
    list: [
      {
        label: '网站名称',
        key: 'siteName',
        type: 'input',
        placeholder: '请输入网站名称'
      },
      {
        label: '网站网址',
        key: 'siteAddress',
        type: 'input',
        placeholder: '请输入网站网址'
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
      selects: {},
      model: {
        'siteName':'',
        'siteAddress':'',
      }
    }
  }
}

// 表格展示配置
export const OutSideAccountTableConfig = function() {
  return {
    list: [
      {
        type: 'selection',
        align: "center",
        width: 60
      },
      {
        label: '编号',
        align: "center",
        type: 'index',
        width: 60
      },
      {
        prop: 'dictionaryId.data',
        align: 'center',
        'show-tool-tip': true,
        label: '名称',
      },
      {
        prop: 'siteAddress',
        align: 'center',
        label: '网址',
        slotScope: 'siteAddress'
      },
      {
        prop: 'accountNumber',
        align: 'center',
        label: '账号',
      },
      {
        prop: 'password',
        align: 'center',
        label: '账号密码',
      },
      {
        prop: 'attachmentName',
        align: 'center',
        label: '附件名称',
      },
      {
        prop: 'createTime',
        align: 'center',
        width: '150px',
        label: '录入时间',
      },
      {
        fixed: 'right',
        label: '操作',
        align: 'center',
        slotScope: 'operation',
        'min-width': 100
      }
    ]
  }
}

// 新增对象配置
export const OutSideAccountAddConfig = function () {
  return {
    list: [
      {
        label: '网站名称',
        key: 'dictionaryId',
        type: 'select',
        placeholder: '请输入项目名称'
      },
      {
        label: '网址',
        key: 'siteAddress',
        type: 'input',
        disabled: true
      },
      {
        label: '网址账户',
        key: 'accountNumber',
        type: 'input',
        placeholder: '请输入网址账户'
      },
      {
        label: '网址密码',
        key: 'password',
        type: 'input',
        placeholder: '请输入网址密码'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      model: {
        'dictionaryId':'',
        'siteAddress':'',
        'accountNumber':'',
        'password':''
      },
      onChange: (v,k) => {
        if(v === 'dictionaryId') {
          this.formChange(v,k);
        }
      },
      rules: {
        dictionaryId: [{required: true, message: '网站名称', trigger: 'blur'}],
        accountNumber: [{required: true, message: '网址账户', trigger: 'blur'}],
        password: [{required: true, message: '网址密码', trigger: 'blur'}]
      },
      selects: {
        dictionaryId:[]
      }
    }
  }
}
