<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`国字头账号管理--${id?'编辑':'新增'}`"
  />
</template>

<script>
import CorpStateAccount from "@/api/ep/corpStateAccount"
import corpAccountApi from "@/api/ep/corpAccount"
import { OutSideAccountAddConfig } from './config/outSideAccount'
import { mergeModelValue } from '@/utils/commons'

export default {
  name: 'outSideAccountEdit',
  data() {
    return {
      visible: false,
      formConfig: OutSideAccountAddConfig.bind(this)(),
      id: '',
      links:[]
    }
  },
  methods: {
    getLinks(){
      corpAccountApi.webList().then(res =>{
        this.links = res.data || [];

        let data = res.data.map(item => {
          return {
            text: item.name,
            value: item.code
          }
        })
        this.formConfig.config.selects['dictionaryId'] = data
      })
    },
    formChange(key,value) {
      if(key === 'dictionaryId') {
        let link = this.links.filter(item => {
          return item.code === value
        })[0] || null
        this.formConfig.config.model['siteAddress'] = link && link.describe || ''
      }
    },
    init(id) {
      this.id = id || ''
      this.visible = true
      this.formConfig = OutSideAccountAddConfig.bind(this)()
      this.getLinks()
      if (!id) {
        return
      }
      CorpStateAccount.get(this.id).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        const model = this.formConfig.config.model
        data.dictionaryId = data.dictionaryId.key
        mergeModelValue(model, data)
      })
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      result.dictionaryId = { key: result.dictionaryId }
      if (!this.id) {
        CorpStateAccount.save(result).then((res) => {
          if (!res.isSuccess) {
            return
          }

          const data = res.data
          this.visible = false
          this.$message.success('保存成功')
          this.$emit('submitSuccess', data)
        })
        return false
      }

      result.id = this.id
      CorpStateAccount.update(result).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.visible = false
        this.$message.success('保存成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>

<style scoped>

</style>
