<template>
  <div class="hm-form-wrap">
    <el-form
        :ref="refs"
        :model="model"
        :rules="rules"
        :inline="inline"
        :label-position="labelPosition"
        :label-width="labelWidth"
        :label-suffix="labelSuffix"
        :hide-required-asterisk="hideRequiredAsterisk"
        :show-message="showMessage"
        :inline-message="inlineMessage"
        :status-icon="statusIcon"
        :validate-on-rule-change="validateOnRuleChange"
        :disabled="disabled"
        :size="size"
    >
      <el-row class="row-margin-0" :gutter="gutter" :style="styleObject">
        <template v-for="(col,colIndex) in columns">
          <el-col
              v-if="!fieldConfig[col.key] || fieldConfig[col.key].show"
              :key="colIndex"
              :span="col.span || 24"
              :style="col.style"
          >
            <el-form-item
                v-if="!col.status || col.status != 'hidden'"
                :prop="col.key"
            >
              <slot :name="col.labelType">
                <template slot="label">{{ col.label }}</template>
              </slot>
              <!--form input start-->
              <el-input
                  v-if="'input|textarea|password|number'.indexOf(col.type) != -1"
                  v-model="model[col.key]"
                  :type="col.type == 'input' ? 'text' : col.type"
                  :maxlength="col.maxLength || null"
                  :minlength="col.minlength || null"
                  :show-word-limit="col['show-word-limit'] || false"
                  :clearable="true"
                  :show-password="col['show-password'] || false"
                  :disabled="col.disabled || false"
                  :size="col.size || null"
                  :prefix-icon="col['prefix-icon'] || null"
                  :suffix-icon="col['suffix-icon'] || null"
                  :rows="col.rows || 2"
                  :autosize="col.autosize || false"
                  :autocomplete="col.autocomplete || 'off'"
                  :name="col.name || null"
                  :readonly="col.readonly || false"
                  :max="col.max || null"
                  :min="col.min || null"
                  :step="col.step || null"
                  :resize="col.resize || null"
                  :autofocus="col.autofocus || false"
                  :form="col.form || null"
                  :label="col.label || null"
                  :tabindex="col.tabindex || null"
                  :validate-event="col['validate-event'] || true"
                  :placeholder="col.placeholder || null"
              >
                <template v-if="col.prefix" slot="prefix">
                  {{ col.prefix }}
                </template>

                <template v-if="col.suffix" slot="suffix">
                  {{ col.suffix }}
                </template>

                <template v-if="col.prepend" slot="prepend">
                  {{ col.prepend }}
                </template>

                <template v-if="col.append" slot="append">
                  {{ col.append }}
                </template>
              </el-input>
              <!-- form-input send-->
              <el-input-number
                  v-if="col.type === 'input-number'"
                  v-model="model[col.key]"
                  :max="col.max || Infinity"
                  :min="col.min || -Infinity"
                  :step="col.step || 1"
                  :step-strictly="col['step-strictly'] || false"
                  :precision="col['precision'] || undefined"
                  :size="col['size'] || undefined"
                  :disabled="col['disabled'] || false"
                  :controls="col['disabled'] || true"
                  :controls-position="col['controls-position'] || undefined"
                  :name="col.name || null"
                  :readonly="col.readonly || false"
                  :autofocus="col.autofocus || false"
                  :label="col.label || null"
                  @change="selectChange($event,col.key)"
                  :placeholder="col.placeholder || null"
              />
              <!-- radio -->
              <el-radio-group
                  v-if="col.type == 'radio'"
                  v-model="model[col.key]"
                  @change="selectChange($event,col.key)"
              >
                <el-radio
                    v-for="(child,childIndex) in (selects[col.key] || [])"
                    :key="childIndex"
                    :label="child[col.valueField || 'value']"
                >{{ child[col.keyField || 'text'] }}
                </el-radio>
              </el-radio-group>
              <!-- radio button -->
              <el-radio-group
                  v-if="col.type == 'radio-button'"
                  v-model="model[col.key]"
                  @change="selectChange($event,col.key)"
              >
                <el-radio-button
                    v-for="(child,childIndex) in (selects[col.key] || [])"
                    :key="childIndex"
                    :label="child[col.valueField || 'value']"
                >{{ child[col.keyField || 'text'] }}
                </el-radio-button>
              </el-radio-group>
              <!-- select -->
              <el-select
                  v-if="col.type == 'select'"
                  v-model="model[col.key]"
                  :placeholder="col.placeholder || ''"
                  type="select"
                  filterable
                  :remote="col.remote || false"
                  :remote-method="(q)=>onRemoteMethod(col.key,q)"
                  :clearable="true"
                  :disabled="col.readonly || false"
                  :multiple="col.multiple || false"
                  @change="selectChange($event,col.key)"
              >
                <el-option
                    v-for="item in (selects[col.key] || [])"
                    :key="item[col.valueField || 'value']"
                    :label="item[col.keyField || 'text']"
                    :value="item[col.valueField || 'value']"
                />
              </el-select>
              <!-- switch -->
              <el-switch
                  v-if="col.type == 'switch'"
                  v-model="model[col.key]"
                  type="switch"
                  :disabled="col.disabled"
                  :width = "col.width || undefined"
                  :active-text = "col['active-text'] || undefined"
                  :inactive-text = "col['inactive-text'] || undefined"
                  :active-value = "col['active-value'] || true"
                  :inactive-value = "col['inactive-value'] || false"
                  :active-color = "col['active-color'] || '#409EFF'"
                  :inactive-color = "col['inactive-color'] || '#C0CCDA'"
                  :name = "col['name'] || undefined"
                  @change="selectChange($event,col.key)"
              >
              </el-switch>
              <!-- checkbox -->
              <el-checkbox-group
                  v-if="col.type == 'checkbox'"
                  v-model="model[col.key]"
              >
                <el-checkbox
                    v-for="item in (selects[col.key] || [])"
                    :key="item[col.valueField || 'value']"
                    :label="item[col.keyField || 'text']"
                >{{ item[col.keyField || 'text'] }}
                </el-checkbox>
              </el-checkbox-group>
              <!-- checkbox button -->
              <el-checkbox-group
                  v-if="col.type == 'checkbox-button'"
                  v-model="model[col.key]"
              >
                <el-checkbox-button
                    v-for="item in (selects[col.key] || [])"
                    :key="item[col.valueField || 'value']"
                    :label="item[col.keyField || 'text']"
                >{{ item[col.keyField ||
                  'text'] }}
                </el-checkbox-button>
              </el-checkbox-group>
              <!-- date picker -->
              <el-date-picker
                  v-if="col.type == 'year' || col.type == 'date' || col.type == 'datetime' || col.type == 'daterange'"
                  v-model="model[col.key]"
                  :placeholder="col.placeholder||'选择日期'"
                  :type="col.type"
                  range-separator="至"
                  start-placeholder="开始日期"
                  @change="(e)=>onChangeDatePicker(col.key,e)"
                  end-placeholder="结束日期"
                  :value-format="col.format?col.format:(col.type == 'datetime'?'yyyy-MM-dd HH:mm:ss':'yyyy-MM-dd')"
              />
              <!-- time picker -->
              <el-time-picker
                  :is-range="col.type == 'timerange'"
                  v-if="col.type == 'timerange' || col.type == 'time'"
                  arrow-control
                  v-model="model[col.key]"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  :value-format="col.format?col.format:'HH:mm:ss'"
                  :placeholder="col.placeholder||'选择时间'">
              </el-time-picker>
              <!-- avatar -->
              <el-upload
                  v-if="col.type === 'avatar'"
                  class="avatar-uploader"
                  :action="uploadAction"
                  :headers="headers"
                  :data="{bizType:col.bizType,bizId:model.id || ''}"
                  :on-success="(e)=>onAvatarUploadSuccess(col.key,e)"
                  :show-file-list="false"
              >
                <img v-if="model[col.key]" :src="model[col.key]" class="avatar" alt="">
                <i v-else class="el-icon-plus avatar-uploader-icon"/>
                <div v-if="col.placeholder" slot="tip" class="el-upload__tip">{{ col.placeholder }}</div>
              </el-upload>
              <!-- video -->
              <ele-upload-video
                  v-if="col.type === 'video'"
                  :data="{bizType:col.bizType,bizId:model.id || ''}"
                  :fileSize="500"
                  style="margin: 50px"
                  :isShowTip="true"
                  :responseFn="(e)=>handleResponse(e,col.key)"
                  :headers="headers"
                  :action="uploadAction"
                  v-model="model[col.key]"
              />

              <!-- file-->
              <el-upload
                  v-if="col.type === 'file'"
                  class="upload-demo"
                  :action="uploadAction"
                  :limit="1"

                  :data="{bizType:col.bizType,bizId:model.id || ''}"
                  :headers="headers"
                  :file-list="model[col.key]"
                  :on-remove="(file, fileList)=>handleFileRemove(col, file, fileList)"
                  :on-success="(e)=>onFileUploadSuccess(col,e)"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <!-- 多图片 -->
              <el-upload
                  v-if="col.type === 'images'"
                  list-type="picture-card"
                  :action="uploadAction"
                  :multiple="col.multiple || false"
                  :on-preview="handlePictureCardPreview"
                  :data="{bizType:col.bizType, bizId:model.id || ''}"
                  :headers="headers"
                  :on-remove="(file, fileList)=>handleImageRemove(col, file, fileList)"
                  :on-success="(e)=>onImageUploadSuccess(col,e)"
                  :file-list="images[col.key]">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">{{col.placeholder || ''}}</div>
              </el-upload>
              <!-- tree -->
              <hm-tree-input
                  v-if="col.type === 'treeinput'"
                  :normalizer="col.normalizer"
                  :multiple="col.multiple || false"
                  :valueConsistsOf="col.valueConsistsOf || 'LEAF_PRIORITY'"
                  :data="selects[col.key] || []"
                  :default-value="model[col.key] || null"
                  :placeholder="col.placeholder || ''"
                  :request="col.request || null"
                  :param="col.param || null"
                  :disabled="col.readonly || false"
                  @select="(e)=>onTreeSelect(col.key,e)"
              />
              <!-- 富文本 -->
              <hm-editor
                  style="height: 500px;"
                  v-if="col.type === 'editor'"
                  v-model="model[col.key]"
                  :height="col.height"
                  @input="(e)=>onEditorInput(col.key,e)"
              />
              <!-- 城市选择器 -->
              <el-cascader
                  v-if="col.type === 'city'"
                  v-model="model[col.key]"
                  :options="areas"
                  clearable
                  filterable
              />
              <!-- 标签 -->
              <div v-if="col.type === 'tags'">
                <el-tag
                    :key="tag"
                    v-for="tag in (model[col.key]?model[col.key].split(','):[])"
                    closable
                    :disable-transitions="false"
                    @close="handleTagClose(col, tag)">
                  {{tag}}
                </el-tag>
                <el-input
                    class="input-new-tag"
                    v-if="col.inputTagVisible"
                    v-model="inputTagValue"
                    size="small"
                    v-focus
                    @keyup.enter.native="()=>handleTagInputConfirm(col)"
                    @blur="()=>handleTagInputConfirm(col)"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showTagInput(col)">+ 添加</el-button>
              </div>
              <!-- 多文件，附件 -->
              <el-upload
                  v-if="col.type === 'attachment'"
                  class="upload-demo"
                  :action="uploadAction"
                  :data="{bizType:col.bizType, bizId:model.id || ''}"
                  :headers="headers"
                  :on-remove="(file, fileList)=>handleAttachmentRemove(col, file, fileList)"
                  :on-success="(e)=>onAttachmentUploadSuccess(col,e)"
                  :file-list="model[col.key]">
                <el-button size="small" icon="el-icon-upload2">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">{{col.placeholder || ''}}</div>
              </el-upload>

              <el-rate
                  style="margin-top: 5px;"
                  v-if="col.type === 'rate'"
                  v-model="model[col.key]"
                  :max="col.max || 5"
                  :allow-half="col.allowHalf || false"
                  :disabled="col.disabled || false"
                  show-text>
              </el-rate>

              <slot v-if="col.type == 'slot'" :name="col.slotScope"/>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
      <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" :append-to-body="true">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </el-form>
  </div>
</template>

<script>

  import FileApi from '@/api/fs/file'
  import EleUploadVideo from "vue-ele-upload-video"
  import AreaApi from '@/api/common/area'

  export default {
    components: {
      EleUploadVideo
    },
    name: 'HmForm',
    props: { // 新增配置项
      columns: {
        type: Array,
        default: null
      },
      refs: {
        type: String,
        default: 'form'
      },
      model: {
        type: Object,
        default: null
      },
      fieldConfig: {
        type: Object,
        default: () => {
          return {}
        }
      },
      styleObject: {
        type: Object,
        default: null
      },
      gutter: {
        type: Number,
        default: 12
      },
      onSubmit: {
        type: Function,
        default: null
      },
      reset: {
        type: Function,
        default: null
      },
      // ElementUI form
      rules: {
        type: Object,
        default: null
      },
      selects: {
        type: Object,
        default: null
      },
      inline: {
        type: Boolean,
        default: false
      },
      labelPosition: {
        type: String,
        default: 'right'
      },
      labelWidth: {
        type: String,
        default: '80px'
      },
      labelSuffix: {
        type: String,
        default: ''
      },
      hideRequiredAsterisk: {
        type: Boolean,
        default: false
      },
      showMessage: {
        type: Boolean,
        default: true
      },
      inlineMessage: {
        type: Boolean,
        default: false
      },
      statusIcon: {
        type: Boolean,
        default: false
      },
      validateOnRuleChange: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        uploadAction: FileApi.singleUpload.bind(this)(),
        headers: {
          Authorization: this.$store.state.user.token
        },
        fileList: [],
        areas: [],
        inputTagVisible: false,
        dialogVisible: false,
        inputTagValue: '',
        dialogImageUrl: '',
      }
    },
    computed: {
      //多图片临时存储
      images() {
        let images = {};
        this.columns.forEach((item) => {
          if (item.type !== 'images') {
            return true
          }

          if (!this.model[item.key]) {
            images[item.key] = [];
            return true
          }

          let urls = [];
          this.model[item.key].split(',').forEach((item) => {
            urls.push({url: item})
          })

          images[item.key] = urls;
        })

        return images;
      },
    },
    created() {
      this.initAreaTree()
    },
    methods: {
      //select 远程搜索
      onRemoteMethod(key, query) {
        this.$emit('remoteMethod', key, query)
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleTagClose(col, tag) {
        let model = this.model, tags = model[col.key].split(',')
        tags.splice(tags.indexOf(tag), 1)
        model[col.key] = tags.join(',')
      },
      handleTagInputConfirm(col) {
        let model = this.model
        if (model[col.key]) {
          model[col.key] = model[col.key] + (this.inputTagValue ? ',' + this.inputTagValue : '')
        } else {
          model[col.key] = (this.inputTagValue ? this.inputTagValue : '')
        }

        col.inputTagVisible = false
        this.inputTagValue = ''
      },
      showTagInput(col) {
        col.inputTagVisible = true
      },
      initAreaTree() {
        let existArea = false
        if (!this.columns || this.columns.length <= 0) {
          return
        }

        this.columns.forEach((item) => {
          if (item.type === 'city') {
            existArea = true
          }
        })

        if (!existArea) {
          return
        }

        AreaApi.tree().then((res) => {
          if (!res.isSuccess) {
            return
          }

          const data = res.data

          function packageData(datas) {
            const areas = []
            datas.forEach((item) => {
              const area = {label: item.label, value: item.label}
              if (item.children) {
                area.children = packageData(item.children)
              }

              areas.push(area)
            })

            return areas
          }

          this.areas = packageData(data)
        })
      },
      /**
       * select change
       */
      selectChange(val, key) {
        this.$emit('change', key, val)
      },
      /**
       * editor change
       */
      onEditorInput(key, val) {
        this.model[key] = val
        this.$emit('change', key, val)
      },
      /**
       * tree下拉选择
       */
      onTreeSelect(key, val) {
        this.model[key] = val
        this.$emit('change', key, val)
      },
      /**
       * 监听切换时间
       */
      onChangeDatePicker(key, val) {
        this.$emit('change', key, val)
      },
      /**
       * 头像上传成功
       */
      onAvatarUploadSuccess(key, e) {
        this.model[key] = e.data.url
        this.$emit('change', key, e.data.url)
      },
      /**
       * 視頻上传成功
       */
      handleResponse(response, key) {
        this.model[key] = response.data.url
        return response.data.url;
      },
      /**
       * 删除文件
       */
      handleFileRemove(col, file, fileList) {
        this.model[col.key] = fileList
      },
      /**
       * 文件上传成功
       */
      onFileUploadSuccess(col, e) {
        e.data.name = e.data.submittedFileName;
        this.model[col.key] = [e.data]
      },
      /**
       * 删除附件
       */
      handleAttachmentRemove(col, file, fileList) {
        this.model[col.key] = fileList
      },
      /**
       * 附件上传成功
       */
      onAttachmentUploadSuccess(col, e) {
        let files = this.model[col.key]
        e.data.name = e.data.submittedFileName;
        files.push(e.data)
      },
      /**
       * 删除多图片
       */
      handleImageRemove(col, file, fileList) {
        this.images[col.key] = fileList
      },
      /**
       * 多图片上传成功
       */
      onImageUploadSuccess(col, e) {
        let files = this.images[col.key]
        files.push({url: e.data.url})
      },
      // 校验规则
      valid() {
        this.$refs[this.refs].validate((result) => {
          if (!result) return false
          let data = JSON.parse(JSON.stringify(this.model))
          //多图片封装
          for (let key in this.images) {
            if (this.images[key].length) {
              let urls = []
              this.images[key].forEach((item) => {
                urls.push(item.url)
              })

              data[key] = urls.join(',')
            }
          }

          this.onSubmit(data)
        })
      },
      // 充值表单
      resetForm() {
        this.$refs[this.refs].resetFields()
        this.clearValidate()
      },
      clearValidate() {
        this.$refs[this.refs].clearValidate()
      }
    }
  }

</script>

<style scoped>
  .row-margin-0 {
    margin: 0;
  }

  .el-select {
    width: 100%;
  }
</style>
<style>
  .hm-form-wrap  .ele-upload-video {
    margin: 0 !important;
  }

  .hm-form-wrap  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .hm-form-wrap  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .hm-form-wrap .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }

  .hm-form-wrap .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .hm-form-wrap  .el-tag {
    margin-right: 10px;
  }

  .hm-form-wrap  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .hm-form-wrap  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
