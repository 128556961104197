<template>
  <hm-dialog-form
      ref="form"
      :visible="visible"
      :data="formConfig"
      :title="`新增防治设施`"
  >
  <template slot="selects">
    <el-select @change="changeHandle" multiple="multiple" v-model="formConfig.config && formConfig.config.model.referIds" placeholder="请选择" style="width: 100%">
      <el-option
          v-for="item in formConfig.config &&formConfig.config.selects.referIds"
          :key="item.id"
          :value="item.id"
          :label="item.code">
        <span>{{ item.name }}</span>
        <span>-</span>
        <span>{{ item.code }}</span>
      </el-option>
    </el-select>
  </template>
  </hm-dialog-form>
</template>

<script>
import ProtectiveAPI from "@/api/ep/protective"
import OutletDeviceAPI from "@/api/ep/outletDevice"
import {AddConfig} from './config.js'

export default {
  data() {
    return {
      visible: false,
      formConfig: AddConfig.bind(this)(),
      id: ''
    }
  },
  methods: {
    changeHandle(val){
      console.log(val)
      let nameList=[]
      this.formConfig.config.selects['referIds'].forEach(item=>{
        val.forEach(cell=>{
          if (cell === item.id) {
            nameList.push(item.name)
          }
        })
      })
      if(nameList.length) {
        this.formConfig.config.model.name = nameList.join(',')
      }
    },
    init() {
      this.visible = true
      this.formConfig = AddConfig.bind(this)()
      this.getProtective()
    },
    getProtective() {
      ProtectiveAPI.protectiveList().then((res) => {
        this.formConfig.config.selects['referIds'] = res.data.map(item => {
          let obj = {}
          obj.id = item.id
          obj.code = item.code
          obj.name = item.name
          return obj
        })
        this.getUnusefulIds()
      })
    },
    getUnusefulIds() {
      const params = {}
      params.referType = 'PROTECTIVE'
      OutletDeviceAPI.getUnusefulIds(params).then((res) => {
        console.log(res)
        if (res.data !== null) {
          res.data.forEach(o => {
            this.formConfig.config.selects['referIds'] = this.formConfig.config.selects['referIds'].filter(item => item.id !== o);
          })
        }
      })
    },
    handleChange(key, value) {
      switch (key) {
        case 'referIds':
          ProtectiveAPI.protectiveListByIds(value).then((res) => {
            if (!res.isSuccess) {
              return
            }
            let name = "";
            res.data.forEach(o => {
              name += o.name + ","
            })
            this.formConfig.config.model.name = name
          })
          break;
      }
    },
    handleCancel() {
      this.formConfig = {}
      this.visible = false
    },
    handleSubmit(result) {
      const params = {}
      params.referType = 'PROTECTIVE'
      params.referIds = result.referIds.join(",")
      params.password = result.password
      OutletDeviceAPI.save(params).then((res) => {
        if (!res.isSuccess) {
          return
        }
        const data = res.data
        this.visible = false
        this.$message.success('保存成功')
        this.$emit('submitSuccess', data)
      })
    }
  }
}
</script>


<style scoped>
/deep/ .el-form-item__label {
  width: 100px !important;
}

/deep/ .el-form-item__content {
  margin-left: 100px !important;
}
</style>
