<template>
  <el-dialog
    width="600px"
    :visible.sync="visible"
    @button-event="buttonEvent"
  >
    <div slot="title" class="top-label clearfix">
      <div class="top-title">{{ config.title || '导入数据' }}</div>
      <div class="top-tabs">
        <div class="top-tab" :class="key === 'EXPORT'?'active':''" @click="changeTab('EXPORT')">
          数据导入
        </div>
        <div class="top-tab" :class="key === 'TABLE'?'active':''" @click="changeTab('TABLE')">
          导入记录
        </div>
      </div>
    </div>

    <div v-show="key === 'EXPORT'" class="content">
      <div class="tip">请先下载 <span class="link cursor" @click="onDownload"> 导入模板, </span>根据导入模板的格式，添加您的内容，然后上传
      </div>
      <div class="label-button">
        <span class="label">选择文件：</span>
        <span class="upload-filed">
          <el-upload
            ref="upload"
            :auto-upload="false"
            :file-list="fileList"
            :show-file-list="false"
            :headers="headers"
            :action="uploadUrl"
            :data="uploadParam"
            name="file"
            :limit="1"
            :on-error="(e)=>onError(e)"
            :on-success="(e)=>onSuccess(e)"
            :on-change="beforeUpload"
          >
            <el-input
              style="width: 300px; text-align: left;cursor: pointer"
              readonly
              :value="fileList.length>0?fileList[0].name:''"
              placeholder="点击选择文件"
            />
          </el-upload>
        </span>
        <span class="label-button-downbtn">
          <el-button
            type="primary"
            :loading="uploading"
            :disabled="fileList.length === 0"
            @click="submitUpload"
          >上传文件</el-button></span>
      </div>
      <div class="alert-error">
        <el-progress v-if="uploading" :percentage="percentage" style="width: 300px;" />
        <el-alert
          v-show="uploadStatus === 'error'"
          style="width: 300px;"
          show-icon
          :closable="false"
          title="导入失败，请详细检查数据格式！"
          type="error"
        />
        <el-alert
          v-show="uploadStatus === 'success'"
          show-icon
          title="恭喜您导入成功，请刷新查看数据"
          type="success"
          style="width: 300px;"
          :closable="false"
        />
        <span
          v-show="uploadStatus === 'error' && errorFileId"
          class="download-details link cursor"
          @click="downloadErrorFile"
        >下载错误信息</span>
      </div>
    </div>
    <div v-show="key === 'TABLE'" class="content">
      <hm-table
        ref="table"
        :api="listRequest"
        :table-config="tableConfig"
        :params="tableParams"
        :table-height="300"
      >
        <div slot="status" slot-scope="row">
          <el-tag :type="row.data.success | statusFilter">{{ row.data.success?'上传成功':'上传失败' }}</el-tag>
        </div>
        <div slot="operation" slot-scope="row">
          <el-tooltip content="下载" placement="top">
            <el-link icon="el-icon-download" class="table-operation" type="primary" @click="downloadRecord(row.data)" />
          </el-tooltip>
          <el-tooltip content="删除" placement="top">
            <el-link icon="el-icon-delete" class="table-operation" style="color:red" type="primary" @click="deleteRecord(row.data)" />
          </el-tooltip>
        </div>
      </hm-table>
    </div>
  </el-dialog>
</template>
<script>

import { downloadBlobFile } from '@/utils/commons'
import { TableConfig } from './config.js'
import FileApi from '@/api/fs/file'
import ImportRecordApi from '@/api/common/importRecord'

export default {
  name: 'HmImport',
  filters: {
    statusFilter(status) {
      return status ? 'success' : 'danger'
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      key: 'EXPORT',
      percentage: 0,
      visible: false,
      uploadStatus: '',
      uploading: false,
      fileList: [],
      headers: {
        Authorization: this.$store.state.user.token
      },
      errorFileId: '',
      listRequest: ImportRecordApi.search,
      tableConfig: TableConfig.bind(this)()
    }
  },
  computed: {
    uploadUrl() {
      return this.config.uploadConfig.action || ''
    },
    uploadParam() {
      const params = this.config.uploadConfig.param || {}
      params.bizType = this.config.bizType ? this.config.bizType + '_UPLOAD' : ''
      params.bizId = this.config.bizId || ''
      return params
    },
    downloadConfig() {
      return this.config.downloadConfig || {}
    },
    tableParams() {
      const params = {}
      params.bizType = this.config.bizType ? this.config.bizType + '_UPLOAD' : ''
      params.bizId = this.config.bizId || ''
      params.userId = this.$store.getters.userInfo.userId
      return { model: params }
    }
  },
  methods: {
    init() {
      this.fileList = []
      this.visible = true
    },
    changeTab(tab) {
      if (tab === this.key) {
        return
      }

      this.key = tab
      if (tab === 'TABLE') {
        this.$refs.table.refresh()
      }
    },
    submitUpload() {
      this.uploading = true
      this.$refs.upload.submit()
    },
    buttonEvent(type) {
      if (type === 'cancel') {
        this.visible = false
      }
    },
    onDownload() {
      const params = {
        bizTypes: [this.config.bizType],
        bizIds: this.config.bizId ? [this.config.bizId] : null
      }

      FileApi.downloadBizFile(params).then((response) => {
        downloadBlobFile(response)
      })
    },
    downloadRecord(data) {
      FileApi.downloadFile({ ids: [data.fileId] }).then((response) => {
        downloadBlobFile(response)
      })
    },
    deleteRecord(data) {
      this.$confirm('此操作将永久删除该信息吗, 是否继续?', '提示', { type: 'warning' }).then(() => {
        ImportRecordApi.batchDelete({ ids: [data.id] }).then((res) => {
          if (!res.isSuccess) {
            return
          }

          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
      })
    },
    beforeUpload(file) {
      if (file.status !== 'ready') {
        return
      }

      const fileName = file.name
      if (!fileName.endsWith('xls') && !fileName.endsWith('xlsx')) {
        this.$message.error(`只允许导入excel文件`)
        return false
      }

      this.fileList = [file]
      this.uploadStatus = ''
      this.errorFileId = ''
    },
    onError() {
      this.uploading = false
      this.fileList = []
    },
    onSuccess(result) {
      this.uploading = false
      this.$refs.upload.clearFiles()
      this.fileList = []
      this.uploadStatus = result.isSuccess ? 'success' : 'error'
      if (result.isSuccess) {
        this.config.onSuccess && this.config.onSuccess()
        return
      }

      // 导入失败处理
      if (result.data) {
        this.errorFileId = result.data
      } else if (result.msg) {
        this.$message.error(result.msg)
      }

      this.config.onFail && this.config.onFail()
    },
    downloadErrorFile() {
      FileApi.downloadFile({ ids: [this.errorFileId] }).then((response) => {
        downloadBlobFile(response)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-dialog__header {
    padding: 0 !important;
  }

  .top-label {
    border-bottom: 1px solid #e6e6e6;
    padding: 0 20px;
    box-sizing: border-box;

    .top-title {
      font-size: 16px;
      letter-spacing: 0.5px;
      padding: 13px 0;
      box-sizing: border-box;
      color: #212121;
      display: inline-block;
    }

    .top-tabs {
      margin-right: 20px;
      float: right;
      font-size: 16px;
      display: inline-block;
    }

    .top-tab {
      display: inline-block;
      cursor: pointer;
      padding: 14px 32px;
      box-sizing: border-box;
    }

    .top-tab.active {
      color: #206ef6;
      border-bottom: 1px solid #206ef6;
    }
  }

  .content {
    padding: 0 16px 16px 16px;
    padding-bottom: 53px;
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    word-break: break-word;
    font-size: 16px;

    .err-tip {
      padding-left: 40px;
      box-sizing: border-box;
      margin-top: 20px;
      font-size: 18px;

      .err-text {
        margin-left: 10px;
      }
    }

    .link {
      color: #1890ff;
      cursor: pointer;
    }

    .sucsess-tip {
      font-size: 18px;
      padding-left: 40px;
      box-sizing: border-box;
      margin-top: 20px;

      .link {
        margin-left: 10px;
      }
    }

    .label {
      line-height: 32px;
    }

    .label-button {
      height: 60px;
      margin-top: 30px;
      display: flex;
      align-items: center;

      .upload-filed {
        display: inline-block;
      }

      .label {
        display: inline-block;
      }

      .label-button-downbtn {
        display: inline-block;
        margin-left: 20px;
      }
    }

    .download-details {
      margin-left: 20px;
      font-size: 14px;
    }

    .alert-error {
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding-left: 80px;
      box-sizing: border-box;
    }
  }

</style>
