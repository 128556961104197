<template>
  <div class="main-business">
    <Head ref="header"></Head>
    <div class="banner">
      <img :src="bannerPath">
    </div>
    <div class="main">
      <div class="main-wrap">
        <div class="nav">
          <div class="nav-l">
            <div class="title">主营业务</div>
            <div class="sub">Product and Service Areas</div>
          </div>
          <div class="nav-r" @click="goContract">联系我们，了解更多</div>
        </div>
        <div class="tabs-list">
          <div class="tabs-item" v-for="(item,index) in tabsList" :key="index" @click="changeTabs(index,item.code)">
            <div class="icon"><img :src="tabsIndex===index?item.activePic:item.pic"></div>
            <div class="sub" :class="tabsIndex===index?'active':''">{{item.sub}}</div>
            <div class="triangle" v-if="tabsIndex===index"></div>
          </div>
        </div>
      </div>
      <div class="tab-contain">
        <div class="tab-contain-item" >
          <div class="item-list">
            <div class="info" v-for="(item,index) in businessList" :key="index" @click="toDetail(item)">
              <div class="picture">
                <img :src="item.coverUrl">
                <div class="mark">
                  <div class="sub">{{item.title}}</div>
                  <div class="text">
                    {{item.summary}}
                  </div>
                </div>
              </div>
              <p>{{item.title}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Head from '@/components/head'
  import Footer from '@/components/footer'
  import HomeCategoryApi from '@/api/ep/homeCategory'
  import HomeBannerApi from '@/api/ep/homeBanner'
  export default {
    name: "about",

    data() {
      return {
        //主营业务数据
        idx:this.$route.query.index || '',
        codes:this.$route.query.code || '',
        businessList: [],
        bannerPath:'',
        tabsIndex: 0,
        code:'HBGJ',
        tabsList: [
          {
            pic: require('@/assets/image/main_9.png'),
            activePic: require('@/assets/image/main_10.png'),
            sub: '环保管家',
            code:'HBGJ'
          },
          {
            pic: require('@/assets/image/main_2.png'),
            activePic: require('@/assets/image/main_6.png'),
            sub: '环保咨询服务',
            code:'HBZX'
          },
          {
            pic: require('@/assets/image/main_3.png'),
            activePic: require('@/assets/image/main_7.png'),
            sub: '污染防治设施',
            code:'WRFZ'
          },
          {
            pic: require('@/assets/image/main_4.png'),
            activePic: require('@/assets/image/main_8.png'),
            sub: '其他环保工程或服务',
            code:'OTHER'
          },
        ]
      }
    },
    watch:{
      $route(val){
        this.idx = val.query.index || 0
        this.codes = val.query.code || ''
        this.change()
      }
    },
    mounted() {
      this.change()
      this.getBusinessData()
      this.getBannerData()
    },
    methods: {
      change(){
        if(this.idx===''){
          return
        }
        this.tabsIndex=parseInt(this.idx)
        this.code=this.codes
        this.getBusinessData()
      },
      goContract(){
        this.$router.push('/contact')
      },
      //banner图数据
      getBannerData() {
        let params = {
          dictionary: 'BUSINESS'
        }
        HomeBannerApi.list(params).then((res) => {
          this.bannerPath =res.data[0].fileId
        })
      },
      getBusinessData(){
        let params={
          type:{
            code:this.code
          }
        }
        HomeCategoryApi.list(params).then((res)=>{
          this.businessList=res.data
        })
      },
      changeTabs(item,code) {
        this.tabsIndex = item
        this.code=code
        this.getBusinessData()
      },
      toDetail(item) {
        this.$router.push(`/businessDetails?id=${item.id}&code=${this.code}`)
      },

    },
    components: {Head, Footer}
  }
</script>

<style scoped>
  @media screen and (min-width: 900px){
    .banner img {
      width: 100%;
      height: 475px;
      object-fit: cover;
    }

    .main-wrap {
      width: 1200px;
      margin: 0 auto;
      padding: 50px 0px 0px 0px;
    }

    .main-wrap .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .main-wrap .nav .title {
      font-size: 28px;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
    }

    .main-wrap .nav .sub {
      font-size: 16px;
      line-height: 32px;
      color: #999999;
      margin-top: 5px;
    }

    .main-wrap .nav .nav-r {
      width: 174px;
      height: 45px;
      background: #FFFFFF;
      border: 1px solid #999999;
      border-radius: 10px;
      text-align: center;
      line-height: 45px;
      cursor: pointer;
      transition: 1s;
    }

    .main-wrap .nav .nav-r:hover {
      color: #359029;
      border: 1px solid #359029;
      box-shadow: 0px 0px 4px #359029;
    }

    .tabs-list .tabs-item .icon img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tabs-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 48px;
    }

    .tabs-list .tabs-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-bottom: 60px;
      position: relative;
    }

    .tabs-list .tabs-item:hover .icon {
      transform: translateY(-10px);
    }

    .tabs-list .tabs-item:hover .sub {
      transform: translateY(-10px);
    }

    .tabs-list .tabs-item .icon {
      transition: 1s;
    }

    .tabs-list .tabs-item:nth-child(1) .icon {
      width: 62px;
      height: 57px;
    }

    .tabs-list .tabs-item:nth-child(2) .icon {
      width: 63px;
      height: 57px;
    }

    .tabs-list .tabs-item:nth-child(3) .icon {
      width: 67px;
      height: 62px;
    }

    .tabs-list .tabs-item:nth-child(4) .icon {
      width: 66px;
      height: 66px;
    }

    .tabs-list .tabs-item .sub {
      font-size: 18px;
      line-height: 32px;
      color: #444444;
      margin-left: 20px;
      transition: 1s;
    }

    .tabs-list .tabs-item .active {
      font-weight: bold;
      color: #000000;
    }

    .tabs-list .tabs-item .triangle {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 40px solid #F9F9F9;
    }

    .tab-contain {
      background-color: #F9F9F9;
    }

    .tab-contain-item {
      width: 1200px;
      margin: 0 auto;
      padding-top: 60px;
    }

    .tab-contain-item .item-list {
      display: flex;
      flex-wrap: wrap;
    }

    .tab-contain-item .item-list .info {
      margin-right: 27px;
      margin-bottom: 50px;
    }

    .tab-contain-item .item-list .info:nth-child(3n+3) {
      margin-right: unset;
    }

    .tab-contain-item .picture {
      width: 382px;
      height: 284px;
      cursor: pointer;
      position: relative;
    }

    .tab-contain-item .picture img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tab-contain-item .picture .mark {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #359029;
      display: none;
    }

    .tab-contain-item .picture .mark .sub {
      font-size: 18px;
      line-height: 32px;
      color: #FFFFFF;
      padding: 50px 30px 30px 30px;
    }

    .tab-contain-item .picture .mark .text {
      font-size: 14px;
      line-height: 32px;
      color: #FFFFFF;
      padding: 0px 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }

    .tab-contain-item .picture:hover .mark {
      display: block;
    }

    .tab-contain-item p {
      font-size: 16px;
      line-height: 32px;
      color: #000000;
      text-align: center;
      padding: 20px 20px 0px 20px;
    }
  }
  @media screen and (max-width: 900px){
    .banner img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
    .main-wrap {
      width: 96%;
      margin: 0 auto;
      padding: 30px 0px 0px 0px;
    }

    .main-wrap .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .main-wrap .nav .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 32px;
      color: #000000;
    }

    .main-wrap .nav .sub {
      font-size: 16px;
      line-height: 32px;
      color: #999999;
      margin-top: 5px;
    }

    .main-wrap .nav .nav-r {
      display: none;
      width: 140px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #999999;
      border-radius: 10px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }

    .tabs-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;
    }

    .tabs-list .tabs-item {
      padding-left: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-bottom:15px;
      position: relative;
    }

    .tabs-list .tabs-item .icon {
      transition: 1s;
      width: 30px;
      text-align: center;
    }

    .tabs-list .tabs-item:nth-child(1) .icon img{
      height: 22px;
      object-fit: cover;
    }

    .tabs-list .tabs-item:nth-child(2) .icon img{
      height: 22px;
      object-fit: cover;
    }
    .tabs-list .tabs-item:nth-child(3) .icon img{
      height: 22px;
      object-fit: cover;
    }

    .tabs-list .tabs-item:nth-child(4) .icon img{
      height: 22px;
      object-fit: cover;
    }

    .tabs-list .tabs-item .sub {
      font-size: 15px;
      line-height: 25px;
      color: #444444;
      margin-left: 20px;
      flex: 1;
    }

    .tabs-list .tabs-item .active {
      font-weight: bold;
      color: #000000;
    }

    .tabs-list .tabs-item .triangle {
      display: none;
    }

    .tab-contain {
      background-color: #F9F9F9;
    }

    .tab-contain-item {
      width: 96%;
      margin: 0 auto;
      padding-top:20px;
    }

    .tab-contain-item .item-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .tab-contain-item .item-list .info {
      width: 90%;
      margin-bottom: 20px;
    }
    .tab-contain-item .picture {
      width: 100%;
      height: 250px;
      cursor: pointer;
      position: relative;
    }

    .tab-contain-item .picture img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tab-contain-item .picture .mark {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #359029;
      display: none;
    }

    .tab-contain-item .picture .mark .sub {
      font-size: 18px;
      line-height: 32px;
      color: #FFFFFF;
      padding: 20px 30px 10px 30px;
    }

    .tab-contain-item .picture .mark .text {
      font-size: 14px;
      line-height: 32px;
      color: #FFFFFF;
      padding: 0px 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }

    .tab-contain-item .picture:hover .mark {
      display: block;
    }
    .tab-contain-item p {
      font-size: 16px;
      line-height: 32px;
      color: #000000;
      text-align: center;
      padding: 10px 20px 0px 20px;
    }
  }
</style>
