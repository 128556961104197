<template>
  <el-dialog
      :visible="visible"
      :title="title"
      :width="dialogWidth"
      :fullscreen="fullscreen"
      :top="top"
      :modal="modal"
      :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody"
      :lock-scroll="lockScroll"
      :custom-class="customClass"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
      :show-close="showClose"
      :before-close="beforeClose"
      :center="center"
      :destroy-on-close="destroyOnClose"
      @close="onCancel"
  >
    <slot name="title" />
    <div class="hm-dialog-main" :style="{height: height,maxHeight:maxHeight,padding:padding}">
      <slot>
        <hm-form
            :on-submit="onSubmit"
            :columns="columns"
            :style-object="styleObject"
            :gutter="gutter"
            :refs="refs"
            :model="model"
            :rules="rules"
            :selects="selects"
            :field-config="fieldConfig"
            :inline="inline"
            :label-position="labelPosition"
            :label-width="labelWidth"
            :ref="refs"
            :label-suffix="labelSuffix"
            :hide-required-asterisk="hideRequiredAsterisk"
            :show-message="showMessage"
            :inline-message="inlineMessage"
            :status-icon="statusIcon"
            :validate-on-rule-change="validateOnRuleChange"
            :disabled="disabled"
            :reset="reset"
            :size="size"
            @change="onChange"
            @remoteMethod="onRemoteMethod"
        >
          <template v-for="(col) in columns">
            <template v-if="col.type == 'slot'" :slot="col.slotScope">
              <slot :name="col.slotScope" />
            </template>

          </template>
          <template v-for="(col) in columns" :slot="col.labelType">
            <slot :name="col.labelType" />
          </template>

        </hm-form>
      </slot>
    </div>
    <template slot="footer">
      <slot name="footer">
        <el-button
            v-for="(btn,index) in (buttons)"
            :key="index"
            :loading="btn.loading || null"
            :type="btn.type || null"
            :size="btn.size || 'medium'"
            @click="btnEvt(btn,index)"
        >{{ btn.name }}
        </el-button>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
  import HmForm from '../hm-form/hm-form'

  export default {
    name: 'HmDialogForm',
    components: { HmForm },
    props: {
      // form 所需属性
      data: {
        type: Object,
        default: null
      },
      refs: {
        type: String,
        default: 'form'
      },
      styleObject: {
        type: Object,
        default: null
      },
      gutter: {
        type: Number,
        default: 12
      },
      reset: {
        type: Function,
        default: null
      },
      inline: {
        type: Boolean,
        default: false
      },
      labelPosition: {
        type: String,
        default: 'right'
      },
      labelWidth: {
        type: String,
        default: '80px'
      },
      padding: {
        type: String,
        default: '0 20px'
      },
      labelSuffix: {
        type: String,
        default: ''
      },
      hideRequiredAsterisk: {
        type: Boolean,
        default: false
      },
      showMessage: {
        type: Boolean,
        default: true
      },
      inlineMessage: {
        type: Boolean,
        default: false
      },
      statusIcon: {
        type: Boolean,
        default: false
      },
      validateOnRuleChange: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      // props 兼容ElementUI el-dialog所有属性，新增height,max-height两个属性
      height: {
        type: String,
        default: null
      },
      maxHeight: {
        type: String,
        default: null
      },
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: null
      },
      width: {
        type: String,
        default: null
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
      top: {
        type: String,
        default: '15vh'
      },
      modal: {
        type: Boolean,
        default: true
      },
      modalAppendToBody: {
        type: Boolean,
        default: false
      },
      appendToBody: {
        type: Boolean,
        default: true
      },
      lockScroll: {
        type: Boolean,
        default: true
      },
      customClass: {
        type: String,
        default: null
      },
      closeOnClickModal: {
        type: Boolean,
        default: false
      },
      closeOnPressEscape: {
        type: Boolean,
        default: false
      },
      showClose: {
        type: Boolean,
        default: true
      },
      beforeClose: {
        type: Function,
        default: null
      },
      center: {
        type: Boolean,
        default: false
      },
      destroyOnClose: {
        type: Boolean,
        default: true
      },
      buttons: {
        type: Array,
        default: () => [
          {
            key: 'SUBMIT',
            name: '提交',
            loading: false,
            type: 'primary'
          },
          {
            key: 'CANCEL',
            name: '取消'
          }
        ]
      }
    },
    data() {
      return {
        dialogWidth: this.width
      }
    },
    computed: {
      columns() {
        return this.data ? this.data.list : []
      },
      model() {
        return this.data.config ? this.data.config.model : {}
      },
      rules() {
        return this.data.config ? this.data.config.rules : {}
      },
      selects() {
        return this.data.config ? this.data.config.selects : {}
      },
      fieldConfig() {
        return this.data.config ? this.data.config.field : {}
      }
    },
    mounted() {
      if (!this.dialogWidth) {
        this.dialogWidth = this.initWidth()
      }
    },
    methods: {
      initWidth() {
        this.screenWidth = document.body.clientWidth
        if (this.screenWidth < 991) {
          return '90%'
        } else if (this.screenWidth < 1400) {
          return '45%'
        } else {
          return '800px'
        }
      },
      // emit用户点击事件
      btnEvt(button) {
        const key = button.key
        switch (key) {
          case 'SUBMIT':
            if (this.$refs && this.$refs[this.refs]) {
              this.$refs[this.refs].valid()
            }

            break
          case 'CANCEL':
            this.onCancel()
            break
          default:
            this.data.config.onOption && this.data.config.onOption(key)
            break
        }
      },
      // 关闭事件
      onCancel() {
        if (this.data.config && this.data.config.onCancel) {
          this.data.config.onCancel()
        }
      },
      // 表单提交事件
      onSubmit(result) {
        this.data.config.onSubmit(result)
      },
      // 内容改变
      onChange(key, value) {
        if (this.data.config && this.data.config.onChange) {
          this.data.config.onChange(key, value)
        }
      },
      // 远程搜索
      onRemoteMethod(key, value) {
        if (this.data.config && this.data.config.onRemoteMethod) {
          this.data.config.onRemoteMethod(key, value)
        }
      }
    }
  }
</script>

<style scoped>
  .hm-dialog-main {
    overflow-y: auto;
    box-sizing: border-box;
  }
</style>
