// 组件参数数据
export const componentsData = {}

// 搜索表单配置
export const SearchConfig = function () {
  return {
    list: [
      {
        label: '名称',
        key: 'title',
        type: 'input',
        placeholder: '请输入主要材料名称'
      }
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
	  deriveBtn:()=>{
	  		  this.deriveBtnExcelClick()
	  },
      selects: {},
      model: {
        'title': ''
      }
    }
  }
}

// 表格展示配置
export const TableConfig = function () {
  return {
    list: [
      {
        type: 'selection',
        align: "center",
        width: 60
      },
      {
        label: '序号',
        align: "center",
        type: 'index',
        width: 60
      },
      {
        prop: 'recordCode',
        align: 'center',
        label: '日期',
      },
      {
        prop: 'designNumber',
        align: 'center',
        label: '设计产能',
      },{
        prop: 'normalDays',
        label: '正常工作天数',
        align: 'center',
      },
      {
        prop: 'dayNumber',
        align: 'center',
        label: '对应日产能',
      },

      {
        fixed: 'right',
        label: '操作',
        align: 'center',
        slotScope: 'operation',
        'min-width': 100
      }
    ]
  }
}

// 新增对象配置
export const FormConfig = function () {
  return {
    list: [
      {
        label: '名称',
        key: 'title',
        type: 'input',
        placeholder: '请输入名称'
      },
      {
        label: '关联企业资料',
        key: 'dataIds',
        type: 'treeinput',
        multiple: true,
        placeholder: '请输入主要污染物'
      },
      {
        label: '其他资料',
        key: 'otherFile',
        height: 500,
        type: 'editor',
        placeholder: '请选择'
      }
    ],
    config: {
      onSubmit: data => {
        this.handleSubmit(data)
      },
      onCancel: () => {
        this.handleCancel()
      },
      onChange: (k, v) => {
        this.onformChange(k, v)
      },
      model: {
        'title': '',
        'dataIds': [],
        'otherFile': '',
        qrCard: ''
      },
      rules: {
        title: [{required: true, message: '项目名称', trigger: 'blur'}]
      },
      selects: {
        dataIds: []
      }
    }
  }
}
