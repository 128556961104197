import {CTX} from '@/api/api'
import http from '@/utils/http'

const PREFIX = CTX.EP + 'dayCapacity/'
const PREFIXONE = CTX.EP + 'mainCapacity/'
export default {
    /**
     * 保存
     * @param params
     * @returns {*}
     */
    save(params) {
        return http.post(PREFIX + `save`,params);
    },

    /**
     * 修改
     * @param params
     * @returns {*}
     */
    update(params) {
        return http.post(PREFIX + `updateList`,params);
    },
    /**
     * 列表
     * @param params
     * @returns {*}
     */
    getList(params) {
        return http.post(PREFIXONE + `search`,params);
    },
	/**
	 * 列表
	 * @param params
	 * @returns {*}
	 */
	getById(params) {
	    return http.get(PREFIXONE + `getMainCapacity`,params);
	},
    /**
     * 列表
     * @param params
     * @returns {*}
     */
    search(params) {
        return http.post(PREFIX + `search`,params);
    },
  
    /**
     * 列表
     * @param params
     * @returns {*}
     */
    getSelect() {
        return http.get(PREFIX + `getSelect`);
    },/**
     * 列表
     * @param params
     * @returns {*}
     */
    getSelectChildren(params) {
        return http.get(PREFIX + `getSelectChildren`,params);
    },
	/**
	 * 删除
	 * @param params
	 * @returns {*}
	 */
	batchDelete(params) {
	    return http.delete(PREFIX + `batchDelete`, params);
	},
	/**
	 * 导出excel
	 * @param params
	 * @returns {*}
	 */
	exportExcel(params) {
	  return http.postDownload(PREFIX + `export`, params);
	},
	/**
	 *根据id获取原辅材料列表
	 * @param params
	 * @returns {*}
	 */
	getListById(params) {
	    return http.get(PREFIX + `getListById`, params);
	},
	
	getListByRecordCode(params){
		 return http.get(PREFIX + `getListByRecordCode`, params);
	}
}
