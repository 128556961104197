<template>
	<el-drawer :visible.sync="visible" :before-close="handleClose">
		<div class="identification container" v-if="visible">
			<div class="top">
				<p>{{ dischargePortDetail.corpName }}</p>
				<p>电子标示{{ dischargePortDetail.code }}</p>
				<p>安徽一二三环保科技有限公司</p>
			</div>
			<div class="main">
				<div class="title">
					<div class="number">01</div>
					<p>企业基本情况</p>
				</div>
				<div class="info">
					<ul>
						<li>单位名称:</li>
						<li>{{ dischargePortDetail.corpName }}</li>
					</ul>
					<ul>
						<li>法定代表人:</li>
						<li>{{ dischargePortDetail.corp && dischargePortDetail.corp.legalPerson }}</li>
					</ul>
					<ul>
						<li>统一社会信用代码:</li>
						<li>{{ dischargePortDetail.corp && dischargePortDetail.corp.licenseNumber }}</li>
					</ul>
					<ul>
						<li>单位住址:</li>
						<li>{{ dischargePortDetail.corp && dischargePortDetail.corp.address }} </li>
					</ul>
					<ul>
						<li>全厂排污口分布示意图:</li>
						<li><img :src="dischargePortDetail.wareHouseAddressImage" alt=""></li>
					</ul>
				</div>
				<div class="title">
					<div class="number">02</div>
					<p>电子标识牌</p>
				</div>
				<div class="signboard">
					<ul>
						<li>标识牌类别:</li>
						<li><img src="../../../assets/image/1.png" alt=""></li>
						<li>危险废物贮存间-{{ dischargePortDetail.warehouse.code }}</li>
					</ul>
					<div style="width: 340px;overflow-x: auto;">
						<table class="table">
							<tr>
								<th style="white-space: nowrap;" colspan="10">
									{{ dischargePortDetail.corpName }}-{{ dischargePortDetail.code }}
								</th>
							</tr>
							<tr>
								<td>固废贮存场所</td>
								<td colspan="10" style="text-align: center;">
									{{ dischargePortDetail.warehouse && dischargePortDetail.warehouse.name }}
								</td>
							</tr>
							<tr style="white-space: nowrap;">
								<td>危险废物名称</td>
								<td v-for="(item,index) in wXFwName" :key="index">
									{{item}}
								</td>
							</tr>
							<tr  style="white-space: nowrap;">
								<td>危险废物种类</td>
								<td v-for="(item,index) in wxFwCategoryList" :key="index">
									{{item}}
								</td>
							</tr>
							<tr  style="white-space: nowrap;">
								<td>危险废物代码</td>
								<td v-for="(item,index) in wxFwDmList" :key="index">
									{{item}}
								</td>
							</tr>
							<tr  style="white-space: nowrap;">
								<td>危险废物产生量</td>
								<td v-for="(item,index) in wxFwScList" :key="index">
									{{item}}
								</td>
							</tr>
							<tr  style="white-space: nowrap;">
								<td>处置方式</td>
								<td v-for="(item,index) in wxFwCzFsList" :key="index">
									{{item==1 ? '自行处置方式' : '委托处置'}}
								</td>
							</tr>
							<tr  style="white-space: nowrap;">
								<td>处置单位名称</td>
								<td v-for="(item,index) in wxCzDwNameList" :key="index">
									{{item}}
								</td>
							</tr>
							<tr  style="white-space: nowrap;">
								<td>处置单位经营许可编号</td>
								<td v-for="(item,index) in wxCzDwJyXklist" :key="index">
									{{item}}
								</td>
							</tr>
						</table>
					</div>
					<div class="step-box">
						<hr>
						<div class="step">1、危废处置协议</div>
					</div>
					<div class="file-box" v-if="wfCzxyList.length>0">
						<div class="file" v-for="checkFile in wfCzxyList">
							<a :href="checkFile.url">
								<ul class="img-box">
									<img v-if="checkFile.ext === 'png'" src="../../../assets/image/icon/icon_png.png"
										alt="">
									<img v-else-if="checkFile.ext === 'pdf'"
										src="../../../assets/image/icon/icon-pdf.png" alt="">
									<img v-else-if="checkFile.ext === 'word'"
										src="../../../assets/image/icon/icon-word.png" alt="">
									<img v-else-if="checkFile.ext === 'docx'"
										src="../../../assets/image/icon/icon-word.png" alt="">
									<img v-else-if="checkFile.ext === 'jpg'"
										src="../../../assets/image/icon/icon_jpg.png" alt="">
									<img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
								</ul>
								<ul>
									<li class="submittedFileName">{{checkFile.submittedFileName}}</li>
									<li>{{ (checkFile.size / 1024).toFixed(2)}}kb</li>
								</ul>
							</a>
						</div>
					</div>
					<div v-else>正在完善...请期待!</div>
					<div class="step-box">
						<hr>
						<div class="step">2、危废处置单位相关资质</div>
					</div>
					<div class="file-box" v-if="wfWxList.length>0">
						<div class="file" v-for="checkFile in wfWxList">
							<a :href="checkFile.url">
								<ul class="img-box">
									<img v-if="checkFile.ext === 'png'" src="../../../assets/image/icon/icon_png.png"
										alt="">
									<img v-else-if="checkFile.ext === 'pdf'"
										src="../../../assets/image/icon/icon-pdf.png" alt="">
									<img v-else-if="checkFile.ext === 'word'"
										src="../../../assets/image/icon/icon-word.png" alt="">
									<img v-else-if="checkFile.ext === 'docx'"
										src="../../../assets/image/icon/icon-word.png" alt="">
									<img v-else-if="checkFile.ext === 'jpg'"
										src="../../../assets/image/icon/icon_jpg.png" alt="">
									<img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
								</ul>
								<ul>
									<li class="submittedFileName">{{checkFile.submittedFileName}}</li>
									<li>{{ (checkFile.size / 1024).toFixed(2)}}kb</li>
								</ul>
							</a>
						</div>
					</div>
					<div v-else>正在完善...请期待!</div>
					<div class="step-box">
						<hr>
						<div class="step">3、排污许可</div>
					</div>
					<div class="file-box" v-if="wfZmList.length>0">
						<div class="file" v-for="file in wfZmList">
							<a :href="file.url">
								<ul class="img-box">
									<img v-if="file.ext === 'png'" src="../../../assets/image/icon/icon_png.png" alt="">
									<img v-else-if="file.ext === 'pdf'" src="../../../assets/image/icon/icon-pdf.png"
										alt="">
									<img v-else-if="file.ext === 'word'" src="../../../assets/image/icon/icon-word.png"
										alt="">
									<img v-else-if="file.ext === 'docx'" src="../../../assets/image/icon/icon-word.png"
										alt="">
									<img v-else-if="file.ext === 'jpg'" src="../../../assets/image/icon/icon_jpg.png"
										alt="">
									<img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
								</ul>
								<ul>
									<li class="submittedFileName">{{file.submittedFileName}}</li>
									<li>{{ (file.size / 1024).toFixed(2)}}kb</li>
								</ul>
							</a>
						</div>
					</div>
					<div v-else>正在完善...请期待!</div>
					<div class="step-box">
						<hr>
						<div class="step">4、危险品贮存间照片</div>
					</div>
					<div class="swiper" v-if="imgList.length !== 0">
						<el-carousel :interval="4000" type="card" height="200px">
							<el-carousel-item v-for="img in imgList">
								<img :src="img" alt="">
							</el-carousel-item>
						</el-carousel>
					</div>
					<div v-else>正在完善...请期待!</div>
				</div>
				<div class="bottom">
					<div class="logo-box">
						<p class="corpContent" v-if="dischargePortDetail.parameter !== null"
							v-html="dischargePortDetail.parameter && dischargePortDetail.parameter.value"></p>
						<p class="time">本排污口电子标识制作时间:{{ date }}</p>
					</div>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
	import DischargePortApi from "@/api/ep/dischargePortOne"
	import moment from "moment";
	export default {
		name: "detail",
		data() {
			return {
				visible: false,
				id: '',
				dischargePortDetail: {},
				imgList: [],
				date: '',
				wfCzxyList: [],
				wfWxList: [],
				// 材料证明
				wfZmList: [],
				// 危险废物名称
				wXFwName:[],
				wxFwCategoryList:[],
				wxFwDmList:[],
				wxFwScList:[],
				wxFwCzFsList:[],
				wxCzDwNameList:[],
				wxCzDwJyXklist:[]
			}
		},
		mounted() {

		},
		methods: {
			init(id) {
				this.id = id
				this.visible = true
				this.getDischargePortDetail(id)
			},
			handleClose() {
				this.visible = false
			},
			getDischargePortDetail(id) {
				this.wfCzxyList = []
				this.wfWxList = []
				this.wXFwName=[]
				this.wxFwCategoryList=[]
				this.wxFwDmList=[]
				this.wxFwCzFsList=[]
				this.wxCzDwNameList=[]
				this.wxCzDwJyXklist=[]
				DischargePortApi.get(id).then(res => {
					this.dischargePortDetail = res.data || {}
					if (res.data.warehouse && res.data.warehouse.hazardousWasteList.length > 0) {
						res.data.warehouse.hazardousWasteList.map(item => {
							this.wfCzxyList.push(item.corpWaste.hmAgreementFile)
							this.wfWxList.push(item.corpWaste.hmFile)
							this.wXFwName.push(item.name)
							this.wxFwCategoryList.push(item.category)
							this.wxFwDmList.push(item.code)
							this.wxFwScList.push(item.produceNum)
							this.wxFwCzFsList.push(item.disposeType)
							this.wxCzDwNameList.push(item.corpWaste.name)
							this.wxCzDwJyXklist.push(item.corpWaste.businessNumber)
						})
					}
					this.wfZmList = res.data.warehouse.emissionPermitList
					this.imgList = res.data.wareHouseImageList
					
				})
			},
		}
	}
</script>

<style scoped>
	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	img {
		width: 100%;
		height: 100%;
	}

	.container {
		width: 375px;
		margin: 0 auto;
	}

	.identification .top {
		background: #fcdb02;
		width: 100%;
		height: 150px;
		color: #FFFFFF;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		padding-top: 33px;
	}

	.main {
		padding: 30px 16px;
		border: 1px solid;
	}

	.main .title {
		display: flex;
		justify-content: flex-start;
		height: 48px;
		align-items: center;
		background: #fdf6c0;
		color: #fcdb02;
	}

	.main .title .number {
		width: 48px;
		height: 48px;
		background: #fcdb02;
		font-weight: bold;
		font-size: 20px;
		text-align: center;
		line-height: 48px;
		color: #FFFFFF;
	}

	.main .title p {
		padding-left: 15px;
	}

	.main .info ul {
		margin-top: 30px;
		color: #333333;
	}

	.main .info ul li:nth-child(1) {
		font-weight: bold;
		margin-bottom: 5px;
		color: #000000;
	}

	.signboard {
		margin-top: 30px;
	}

	.signboard ul {
		margin-bottom: 15px;
	}

	.signboard ul:nth-child(1) li:nth-child(1) {
		font-weight: bold;
		margin-bottom: 5px;
		color: #000000;
		text-align: left;
	}

	.signboard ul:nth-child(1) li:nth-child(2) {
		width: 120px;
		height: 120px;
		margin-top: 20px;
	}

	.signboard ul:nth-child(1) li:nth-child(3) {
		margin-top: 10px;
	}

	.signboard ul:nth-child(1) li:nth-child(2) img {
		border-radius: 8px;
	}

	.signboard ul:nth-child(1) li {
		margin: 0 auto;
		text-align: center;
	}

	table {
		border-collapse: collapse;
		color: #000000;
		margin-bottom: 40px;
	}

	table,
	table tr td {
		border: 1px solid #ccc;
	}

	.table th {
		font-weight: bold;
		background: #F0F2F5;
		font-size: 16px;
	}

	.table th,
	.table td {
		width: 33%;
		padding: 12px 10px;
	}

	.table td {
		font-size: 12px;
	}

	.step-box {
		position: relative;
		margin: 40px 0;
	}

	.step {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 37px;
		width: 214px;
		border-radius: 4px;
		background: #fcdb02;
		color: #FFFFFF;
		text-align: center;
		line-height: 37px;
		z-index: 10;
	}

	.file-box {
		box-shadow: #f1f0f0 0 0 8px 3px;
		border-radius: 6px;
		padding: 16px;
		width: 90%;
		margin: 0 auto;
	}

	.file-box .file a {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 5px 0;
		border-bottom: 1px solid #CCCCCC;
	}

	.file-box .file:last-child a {
		border: none;
	}

	.file-box .file .img-box {
		width: 39px;
		height: 48.5px;
	}

	.file-box .file ul:nth-child(2) {
		padding-right: 30px;
		margin-left: 10px;
		width: calc(100% - 50px);
	}

	.file-box .file ul:nth-child(2) li:nth-child(2) {
		font-size: 12px;
	}

	.file-box .submittedFileName {
		word-wrap: break-word;
	}

	.logo-box {
		border-top: 1px solid #fcdb02;
		margin: 50px 0 40px;
		padding-top: 30px;
	}

	.logo-box p {
		color: #000;
		padding: 10px 0;
	}

	.logo-box .time {
		text-align: center;
		font-size: 14px;
		margin-top: 20px;
		color: #000000;
	}

	/deep/.corpContent img {
		max-width: 100% !important;
	}

	/*.logo-box .logo{*/
	/*  width: 78px;*/
	/*  height: 78px;*/
	/*  margin: 25px auto 10px;*/
	/*}*/
	/*.logo-box h4{*/
	/*  font-size: 16px;*/
	/*  text-align: center;*/
	/*  color: #000000;*/
	/*}*/
	/*.bottom ul{*/
	/*  margin: 20px 0;*/
	/*}*/
	/*.bottom ul li:nth-child(1){*/
	/*  color: #000000;*/
	/*}*/
	/*.bottom p{*/
	/*  text-align: center;*/
	/*  margin-top: 15px;*/
	/*}*/
	/*.bottom .number{*/
	/*  text-align: center;*/
	/*  color: #000000;*/
	/*  margin: 40px 0;*/
	/*}*/
	/*.bottom h6 span{*/
	/*  color: #666666;*/
	/*}*/
	/*.bottom h5{*/
	/*  text-align: center;*/
	/*}*/
	/*.bottom .QR-code{*/
	/*  width: 114px;*/
	/*  height: 114px;*/
	/*  margin: 30px auto;*/
	/*}*/
	/*.bottom h1{*/
	/*  text-align: center;*/
	/*}*/
</style>
