<template>
  <div class="hm-form-wrap">
    <el-form
      :ref="refs"
      :model="model"
      :inline="true"
      :label-position="labelPosition"
      :label-width="labelWidth"
      :size="size"
      @submit.native.prevent
    >
      <template v-for="(col, colIndex) in columns">
        <el-form-item v-if="!col.status || col.status !== 'hidden'">
          <slot :name="col.labelType" v-if="showLabel">
            <template slot="label">{{ col.label }}</template>
          </slot>

          <!--form input start-->
          <el-input
            v-if="'input|textarea'.indexOf(col.type) !== -1"
            v-model="model[col.key]"
            :type="col.type === 'input' ? 'text' : col.type"
            :maxlength="col.maxLength || null"
            :minlength="col.minlength || null"
            :show-word-limit="col['show-word-limit'] || false"
            :clearable="true"
            :show-password="col['show-password'] || false"
            :size="col.size || null"
            :prefix-icon="col['prefix-icon'] || null"
            :suffix-icon="col['suffix-icon'] || null"
            :rows="col.rows || 2"
            :autosize="col.autosize || false"
            :autocomplete="col.autocomplete || 'off'"
            :name="col.name || null"
            :max="col.max || null"
            :min="col.min || null"
            :step="col.step || null"
            :resize="col.resize || null"
            :autofocus="col.autofocus || false"
            :form="col.form || null"
            :label="col.label || null"
            :tabindex="col.tabindex || null"
            :validate-event="col['validate-event'] || true"
            :placeholder="col.placeholder || null"
          >
            <template slot="prefix">
              <slot name="prefix" />
            </template>

            <template slot="suffix">
              <slot name="suffix" />
            </template>

            <template slot="prepend">
              <slot name="prepend" />
            </template>

            <template slot="append">
              <slot name="append" />
            </template>
          </el-input>
          <!-- form-input send-->
          <el-radio-group v-if="col.type == 'radio'" v-model="model[col.key]">
            <el-radio
              v-for="(child, childIndex) in selects[col.key] || []"
              :key="childIndex"
              :label="child.value"
              >{{ child.text }}
            </el-radio>
          </el-radio-group>

          <el-select
            v-if="col.type == 'select'"
            v-model="model[col.key]"
            type="select"
            filterable
            :placeholder="col.placeholder || null"
            :clearable="true"
            @change="selectChange($event, col.key)"
          >
            <el-option
              v-for="item in selects[col.key] || []"
              :key="item[col.valueField || 'value']"
              :label="item[col.keyField || 'text']"
              :value="item[col.valueField || 'value']"
            />
          </el-select>
          <el-checkbox-group
            v-if="col.type == 'checkbox'"
            v-model="model[col.key]"
          >
            <el-checkbox
              v-for="item in selects[col.key] || []"
              :key="item[col.valueField || 'value']"
              :label="item[col.keyField || 'text']"
              >{{ item[col.keyField || "text"] }}
            </el-checkbox>
          </el-checkbox-group>

          <el-date-picker
            v-if="
              col.type == 'date' ||
              col.type == 'datetime' ||
              col.type == 'daterange'
            "
            v-model="model['_tmpTimeRange' + colIndex]"
            :placeholder="col.placeholder || '选择日期'"
            style="width: 100%"
            :type="col.type"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :value-format="col.format"
            :clearable="true"
            @change="dateRangeChange($event, col)"
          />

          <!-- tree -->
          <hm-tree-input
            v-if="col.type === 'treeinput'"
            :normalizer="col.normalizer || {}"
            :data="selects[col.key] || []"
            :placeholder="col.placeholder || ''"
            :request="col.request || null"
            :default-value="model[col.key]"
            :param="col.param || null"
            @select="(e) => onTreeSelect(col.key, e)"
          />
          <el-cascader
            v-if="col.type === 'city'"
            v-model="model[col.key]"
            :options="areas"
            clearable
            filterable
          />
          <slot v-if="col.type == 'slot'" :name="col.slotScope" />
        </el-form-item>
      </template>
      <el-form-item label-width="0">
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>
      <el-form-item label-width="0" v-if="deriveShow">
        <el-button type="primary" @click="deriveBtn">导出</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import AreaApi from "@/api/common/area";
import moment from "moment";

export default {
  name: "HmSearchForm",
  props: {
    data: {
      type: [Object],
      required: true,
    },
    refs: {
      type: String,
      default: "form",
    },
    validate: {
      type: Function,
      default: null,
    },
    reset: {
      type: Function,
      default: null,
    },
    // ElementUI form
    labelPosition: {
      type: String,
      default: "right",
    },
    labelWidth: {
      type: String,
      default: "80px",
    },
    size: {
      type: String,
      default: "small",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    deriveShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      areas: [],
    };
  },
  computed: {
    model() {
      return this.data.config ? this.data.config.model : {};
    },
    columns() {
      return this.data.list;
    },
    selects() {
      return this.data.config ? this.data.config.selects : {};
    },
  },
  created() {
    this.initAreaTree();
  },
  methods: {
    /**
     * select change
     */
    selectChange(val, key) {
      if (this.data.config && this.data.config.onChange) {
        this.data.config.onChange(key, val);
      }
    },
    initAreaTree() {
      let existArea = false;
      if (!this.columns || this.columns.length <= 0) {
        return;
      }

      this.columns.forEach((item) => {
        if (item.type === "city") {
          existArea = true;
        }
      });

      if (!existArea) {
        return;
      }

      AreaApi.tree().then((res) => {
        if (!res.isSuccess) {
          return;
        }

        const data = res.data;

        function packageData(datas) {
          const areas = [];
          datas.forEach((item) => {
            const area = { label: item.label, value: item.label };
            if (item.children) {
              area.children = packageData(item.children);
            }

            areas.push(area);
          });

          return areas;
        }

        this.areas = packageData(data);
      });
    },
    /**
     * tree下拉选择
     */
    onTreeSelect(key, val) {
      this.model[key] = val;
    },
    dateRangeChange(dateRange, item) {
      if (item.type === "date") {
        if (!dateRange) {
          this.model[item.key] = "";
          return;
        }

        this.model[item.key] = moment(dateRange[0]).format("YYYY-MM-DD");
        return;
      }

      if (!dateRange) {
        this.model[item.key] = "";
        this.model[item.key1] = "";
        return;
      }

      this.model[item.key] = moment(dateRange[0]).format("YYYY-MM-DD");
      this.model[item.key1] = moment(dateRange[1]).format("YYYY-MM-DD");
    },
    // 充值表单
    resetForm() {
      this.$refs[this.refs].resetFields();
    },
    onSearch() {
      const data = { model: {}, extend: {} };

      function handleKey(key, value, currentObj) {
        const index = key.indexOf(".");
        if (index < 0) {
          currentObj[key] = value;
          return;
        }

        const value1 = key.substr(0, index);
        const value2 = key.substr(index + 1);
        if (!Object.prototype.hasOwnProperty.call(currentObj, value1)) {
          currentObj[value1] = {};
        }

        handleKey(value2, value, currentObj[value1]);
      }

      for (let key in this.model) {
        if (key.indexOf("_tmpTimeRange") === 0) {
          continue;
        }

        const value = this.model[key];
        if (key.indexOf(".") < 0) {
          data.model[key] = value;
          continue;
        }

        if (!key.startsWith("extend.")) {
          handleKey(key, value, data.model);
          continue;
        }

        key = key.replace("extend.", "");
        handleKey(key, value, data.extend);
      }
      this.data.config.onSearch && this.data.config.onSearch(data);
    },
    // 导出excel
    deriveBtn() {
      this.data.config.deriveBtn();
    },
  },
};
</script>

<style scoped>
.row-margin-0 {
  margin: 0;
}

.el-select {
  width: 100%;
}
</style>
