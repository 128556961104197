<template>
  <div class="archives container">
    <div class="top">
      <p>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.companyName }}</p>
      <p>电子环保档案</p>
      <p>安徽一二三环保科技有限公司</p>
    </div>
    <div class="main">
      <div class="title">
        <div class="number">01</div>
        <p>企业基本情况</p>
      </div>
      <div class="info">
        <ul>
          <li>单位名称:</li>
          <li>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.companyName }}</li>
        </ul>
        <ul>
          <li>法定代表人:</li>
          <li>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.legalPerson }}</li>
        </ul>
        <ul>
          <li>统一社会信用代码:</li>
          <li>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.licenseNumber }}</li>
        </ul>
        <ul>
          <li>单位住址:</li>
          <li>{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.address }} </li>
        </ul>
        <ul>
          <li>全场排污口分布示意图:</li>
          <li><img v-if="protectionArchivesDetail.signalImage" :src="protectionArchivesDetail.signalImage.value" alt=""></li>
        </ul>
        <ul class="process">
          <li>生产工艺流程:</li>
          <div style="margin-bottom: 5px">{{protectionArchivesDetail.processRemark}}</div>
          <div v-for="item in protectionArchivesDetail.processData">
            <div class="msg">{{item.title}}</div>
            <img :src="item.image" alt="">
          </div>
        </ul>
      </div>
      <div class="title">
        <div class="number">02</div>
        <p>企业现有环保档案</p>
      </div>
      <div class="signboard">
        <a class="down" href="#">下列文档可点击查看/下载</a>
        <table class="table">
          <tr>
            <th>项目名称</th>
            <th>手续/资料种类</th>
            <th>时间</th>
            <th>批复/备案文号</th>
          </tr>
<!--          <tr v-for="eepaData in protectionArchivesDetail.eepaData">-->
<!--            <td>{{ eepaData.dataName }}</td>-->
<!--            <td>{{ eepaData.data.data.dataName }}</td>-->
<!--            <td>{{ eepaData.dataYear }}</td>-->
<!--            <td>{{ eepaData.filingCopy }}</td>-->
<!--          </tr>-->
          <tr v-for="eepaData in protectionArchivesDetail.eepaData">
            <td :rowspan="eepaData.rowspan" v-if="eepaData.dataHandleName">{{ eepaData.dataHandleName }}</td>
            <td>{{ eepaData.data.data.dataName }}</td>
            <td>{{ eepaData.dataYear }}</td>
            <td>{{ eepaData.filingCopy }}</td>
          </tr>
        </table>
        <div v-for="(data, index) in protectionArchivesDetail.eepaData">
          <h3 class="project-name" v-if="data.files">{{ index + 1 }}、{{ data.dataName }} ——{{ data.data.data.dataName }}</h3>
          <div class="file-box" v-if="data.files ">
            <div class="file" v-for="file in data.files">
              <a :href="file.url">
                <ul class="img-box">
                  <img v-if="file.ext === 'png'" src="../../../assets/image/icon/icon_png.png" alt="">
                  <img v-else-if="file.ext === 'pdf'" src="../../../assets/image/icon/icon-pdf.png" alt="">
                  <img v-else-if="file.ext === 'word'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="file.ext === 'docx'" src="../../../assets/image/icon/icon-word.png" alt="">
                  <img v-else-if="file.ext === 'jpg'" src="../../../assets/image/icon/icon_jpg.png" alt="">
                  <img v-else src="../../../assets/image/icon/icon-excel.png" alt="">
                </ul>
                <ul>
                  <li class="submittedFileName">{{ file.submittedFileName}}</li>
                  <li>{{ (file.size / 1024).toFixed(2)}}kb</li>
                </ul>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <div class="number">03</div>
        <p>企业现场照片</p>
      </div>
      <div class="swiper" v-if="protectionArchivesDetail.sceneImages && protectionArchivesDetail.sceneImages.length">
        <div v-for="(sceneImage,index) in protectionArchivesDetail.sceneImages" :key="index" v-if="sceneImage.image">
          <h3 class="project-name">{{ index + 1 }}、{{ sceneImage.title }}</h3>
          <el-carousel :interval="4000" type="card" height="200px">
            <el-carousel-item :key="url" v-for="(url,index) in sceneImage.image.split(',')">
              <img :src="url" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="bottom">
        <div class="logo-box">
          <p v-if="protectionArchivesDetail.corpContent !== null" v-html="protectionArchivesDetail.corpContent && protectionArchivesDetail.corpContent.value"></p>
          <p class="time">本排污口电子标识制作时间:{{ protectionArchivesDetail.corpData && protectionArchivesDetail.corpData.createTime}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CorpDataApi from '@/api/ep/corpData'
import UserApi from '@/api/uc/user'
export default {
  name: "detail",
  data(){
    return {
      corpId:'',
      protectionArchivesDetail:{}
    }
  },
  mounted() {
    debugger
    this.corpId = this.$route.query.corpId
    CorpDataApi.getDetail(this.corpId).then(res => {
      debugger
      this.protectionArchivesDetail = res.data || {}
    })
  },
  methods: {
    // getUser(){
    //   UserApi.getMine().then(res => {
    //     this.getTemplateDetail(res.data.corpId)
    //   });
    // },
    // getTemplateDetail(){
    //   CorpDataApi.getDetail(this.corpId).then(res => {
    //     this.protectionArchivesDetail = res.data || {}
    //   })
    // },
  },
}
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
img{
  width: 100%;
  height: 100%;
}
.container{
  width:100%;
  margin: 0 auto;
}
.main{
  padding: 3rem 1.6rem;
  border: 0.2rem solid #CCCCCC;
}
.main .title{
  display: flex;
  justify-content: flex-start;
  height: 4.8rem;
  align-items: center;
  background: #C7E5D6;
  color: #20985A;
}
.main .title .number{
  width: 4.8rem;
  height: 4.8rem;
  background: #20985A;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  line-height: 4.8rem;
  color: #FFFFFF;
}
.main .title p{
  padding-left: 1.55rem;
}
.main .info ul{
  margin-top: 3rem;
  color: #333333;
  font-size: 1.6rem;
}
.main .info ul li:nth-child(1){
  font-weight: bold;
  margin-bottom: .5rem;
  color: #000000;
}
.signboard {
  margin-top: 3rem;
}
.signboard ul{
  margin-bottom: 1.5rem;
}
.signboard ul:nth-child(1) li:nth-child(1){
  font-weight: bold;
  margin-bottom: .5rem;
  color: #000000;
  text-align: left;
}
.signboard ul:nth-child(1) li{
  margin: 0 auto;
  text-align: center;
}
table{
  border-collapse: collapse;
  color: #000000;
  margin-bottom: 4rem;
}
table,table tr td {
  border:.1rem solid #ccc;
}
table{
  table-layout: fixed;
  word-break: break-all;
  word-wrap: break-word;
}
.table th{
  font-weight: bold;
  background: #F0F2F5;
  font-size: 1.6rem;
}
.table th, .table td{
  padding: 1.7rem 1rem;
}
.table td{
  font-size: 1.7rem;
}
.file-box{
  box-shadow: #f1f0f0 0 0 .8rem .3rem;
  border-radius: .6rem;
  padding: 1.6rem;
}
.file-box .file a{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: .5rem 0;
  border-bottom: .1rem solid #CCCCCC;
}
.file-box .file:last-child a{
  border: none;
}
.file-box .file .img-box{
  width: 3.9rem;
  height:4.85rem;
}
.file-box .file ul:nth-child(2){
  padding-right: 3rem;
  margin-left: 1rem;
  width: calc(100% - 5rem);
}
.file-box .file ul:nth-child(2) li:nth-child(2){
  font-size: 1.2rem;
}
.file-box .submittedFileName{
  word-wrap: break-word;
}
.logo-box{
  border-top: .1rem solid #20985A;
  margin: 5rem 0 4rem;
}
.logo-box .time{
  text-align: center;
  font-size: 1.4rem;
  color: #000000;
}
.archives .top{
  background: #205792;
  width: 100%;
  padding: 5.5rem 0;
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.archives .main .title{
  display: flex;
  justify-content: flex-start;
  height: 4.8rem;
  align-items: center;
  background: #C7D5E4;
  color: #205792;
  font-size: 1.6rem;
}
.archives .main .title .number{
  width: 4.8rem;
  height: 4.8rem;
  background: #205792;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  line-height: 4.8rem;
  color: #FFFFFF;
}
.archives .main .title p{
  padding-left: 1.5rem;
}
.archives .main .process{
  margin: 0 0 5rem;
}
.archives .main .process .msg{
  color: #333333;
}
.archives .signboard .down{
  color:#205792;
  display: block;
  margin-bottom: 2.5rem;
  font-size: 1.6rem;
}
.archives table{
  border-collapse: collapse;
  color: #000000;
  margin-bottom: 4rem;
}
.archives table,table tr td,table tr th{
  border:.1rem solid #ccc;
}
.archives .table th{
  font-weight: bold;
  background: #F0F2F5;
  font-size: 1.4rem;
}
.archives .table th, .table td{
  width: 25%;
  padding: 1.2rem 1rem;
  text-align: center;
}
.archives .table td{
  font-size: 1.2rem;
}
.archives .logo-box{
  border-top: .1rem solid #205792;
  margin: 5rem 0 4rem;
}
.archives .project-name{
  margin: 3rem 0;
}
.archives .signboard{
  margin-bottom: 3rem;
}
.archives .swiper{
  margin-top: 3rem;
}
</style>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 1.2rem;
  opacity: 0.75;
  line-height: 20rem;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>