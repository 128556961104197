import Regular from '@/utils/regular'
// 组件参数数据
export const componentsData = {}


// 生产设备搜索表单配置
export const ProductSearchConfig = function () {
  return {
    list: [
      {
        label: '名称',
        key: 'name',
        type: 'input',
        placeholder: '请输入生产设备名称'
      },
      {
        label: '编号',
        key: 'code',
        type: 'input',
        placeholder: '请输入生产设备编号'
      },
    ],
    config: {
      onSearch: data => {
        this.handleSearch(data)
      },
      
      deriveBtn: data => {
        this.deriveBtnExcelClick(data)
      },
      selects: {},
      model: {
        corpId: ''
      }
    }
  }
}

// 生产设备预览配置
export const ProductDetailConfig = function () {
  return {
    list: [
      {
        label: '生产设备名称',
        key: 'name',
      },
      {
        label: '生产设备编号',
        key: 'code'
      },
      {
        label: '生产设备工艺',
        key: 'workmanship',
      },
      {
        label: '生产设备参数名称',
        key: 'parameter'
      },
      {
        label: '计量单位',
        key: 'measure',
      },
      {
        label: '设计值',
        key: 'design'
      },
      {
        label: '其他设施参数信息',
        key: 'other',
      },
      {
        label: '生产设备现场照片',
        key: 'sceneImage',
        type: 'images'
      }
    ],
    config: {
      model: {
        name: '',
        code: '',
        workmanship: '',
        parameter: '',
        measure: '',
        design: '',
        other: '',
        sceneImage: ''
      }
    }
  }
}



